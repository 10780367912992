import { StyledButton, LeftArrowIcon } from './style';
import { deTranslations } from '../../../../translations';

interface Props {
  onClick: any,
  sx?: object,
}

// @ts-ignore
const BackButton = ({ onClick, sx }: Props) => {
  return <StyledButton onClick={onClick} sx={sx} disableRipple startIcon={<LeftArrowIcon />}>{deTranslations.back}</StyledButton>
};

export default BackButton;
