import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 370,
    maxHeight: 370,
    backgroundColor: '#2A2A2A',
    overflow: 'hidden',
}));

{/* @ts-ignore */}
export const ButtonBoxRight = styled.div(({ theme, description }) => ({
    position: 'absolute',
    right: '0%',
    display: 'flex',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderWidth: 1,
    height: 165,
    width: 20,
    marginTop: description ? 88 : 108,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',

    [theme.breakpoints.down("sm")]: {
        marginTop: 87,
        height: 166,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

{/* @ts-ignore */}
export const ButtonBoxLeft = styled.div(({ theme, description }) => ({
    position: 'absolute',
    left: '0%',
    display: 'flex',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderWidth: 1,
    height: 165,
    width: 20,
    marginTop: description ? 88 : 108,
    marginRight: -19,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',

    [theme.breakpoints.down("sm")]: {
        marginTop: 87,
        height: 166,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageRightCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,
    transform: 'rotate(180deg)',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLeftCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const Outline = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    marginLeft: 45,
    marginTop: 35,
    backgroundColor: '#2A2A2A',

    [theme.breakpoints.down("sm")]: {
        height: 203,
        marginLeft: 5,
    },
}));

export const TextCategoriesDiv = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    marginBottom: -10,

    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'start',
        marginBottom: 0,
    },
}));

export const HeadlineText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
    color: '#FFFFFF',

    [theme.breakpoints.down("sm")]: {
        marginRight: '0%',
    },
}));

export const VideoCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    marginTop: 10,
    marginBottom: 15,
    marginLeft: -5,
    maxHeight: 300,
}));

export const VideoDiv = styled.div(({ theme }) => ({
    width: 300,
    height: 240,
    padding: 10,
    maxWidth: 300,
    borderRadius: 6
}));

export const VideoDivTitle = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 15,
    fontWeight: 600,
    color: '#FFFFFF',
    maxHeight: 57,
    overflow: 'hidden'
}));

export const VideoDivSourceDate = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    gap: 3,
    color: '#FFFFFF'
}));

export const VideoDivSource = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 14,
    color: '#FFFFFF',
    opacity: 0.8
}));

export const VideoDivDate = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 14,
    color: '#FFFFFF',
    opacity: 0.6
}));

export const ButtonBoxElement = styled.div(({ theme }) => ({
    alignSelf: 'center',
    marginLeft :5
}));

{/* @ts-ignore */}
export const StepperElement = styled.div(({ theme, description }) => ({
    position: 'absolute',
    right: '1%',
    top: description ? '20%' : '24%'
}));

export const DescriptionTextDiv = styled.div(({ theme }) => ({
    marginTop: 10,

    [theme.breakpoints.down("sm")]: {
        width: 300
    },
}));

export const Image = styled.img(({ theme }) => ({
    width: 300,
    height: 166,
    borderRadius: 4,
    filter: 'drop-shadow(0px 2.736px 27.36px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0.912px 0.912px rgba(0, 0, 0, 0.06))',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageFinishedWatching = styled.img(({ theme }) => ({
    position: 'absolute',
    width: 17,
    height: 17,
    top: 5,
    right: 5
}));

export const VideoLength = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px 3px',
    gap: 10,

    position: 'absolute',
    height: 12,
    right: 10,
    bottom: 10,

    background: '#2E3436',
    borderRadius: 3
}));

export const VideoLengthNumber = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 10,
    fontWeight: 500,
    color: '#FFFFFF',
    width: 26,
    height: 12,
    textAlign: 'end'
}));

