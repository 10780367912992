import { Box } from "@mui/material";
import { Email, Name, Position } from "./style";

const ContactPerson = ({ imageUrl, name, position, email }: { imageUrl: string, name: string, position: string, email: string }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="250px" gap={1}>
            <img style={{borderRadius: '50%'}} width="100px" src={imageUrl} alt="profile image" />
            <Name>{name}</Name>
            <Position>{position}</Position>
            <Email href={`mailto:${email}`}>{email}</Email>
        </Box>
    )
};

export default ContactPerson;