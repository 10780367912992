// @ts-ignore
const extractLocationFromFormValues = (values) => {
  switch(values.occupation) {
    // doctor
    case(1): {
      switch(values.placeOfWork) {
        case('hospital'): {
          return {
            hospitalId: values.location,
            workRegionId: null,
            workRegion: null,
            officeRegionId: null,
            officeRegion: null,
            universityId: null,
            university: null
          }
        }
        case('public'): {
          return {
            workRegionId: values.location,
            hospitalId: null,
            hospital: null,
            officeRegionId: null,
            officeRegion: null,
            universityId: null,
            university: null
          }
        }
        case('private'): {
          return {
            officeRegionId: values.location,
            workRegionId: null,
            workRegion: null,
            hospitalId: null,
            hospital: null,
            universityId: null,
            university: null
          }
        }
      }
      break
    }
    // pharmacist
    case(2): {
      return {
        officeRegionId: values.location,
        workRegionId: null,
        workRegion: null,
        hospitalId: null,
        hospital: null,
        universityId: null,
        university: null
      }
    }
    // student
    case(3): {
      return {
        universityId: values.location,
        workRegionId: null,
        workRegion: null,
        hospitalId: null,
        hospital: null,
        officeRegionId: null,
        officeRegion: null
      }
    }
    // nurse
    case(4): {
      return {
        hospitalId: values.location,
        universityId: null,
        university: null,
        workRegionId: null,
        workRegion: null,
        officeRegionId: null,
        officeRegion: null
      }
    }
    // other
    case(5): {
      return {
        hospitalId: null,
        hospital: null,
        universityId: null,
        university: null,
        workRegionId: null,
        workRegion: null,
        officeRegionId: null,
        officeRegion: null
      }
    }
  }
}

// @ts-ignore
export const extractPayloadFromForm = (values) => {
  const location = extractLocationFromFormValues(values)

  return {
    email: values.email,
    password: values.password,
    isWebUser: true,
    // TODO: language is hardcoded due to the webinars being german only
    language: 'de',
    profile: {
      firstName: values.firstName,
      lastName: values.lastName,
      title: values.title ? values.title : null,
      oeak: values.occupation === 1 && values.oeak ? values.oeak : null,
      specialtyId: values.occupation === 1 && values.areaOfPractice ? values.areaOfPractice : null,
      specialty: values.occupation === 1 ? undefined : null,
      doctorWorkType: values.occupation === 1 && values.placeOfWork ? values.placeOfWork : null,
      occupationId: values.occupation,
      ...location
    }
  }
}

// @ts-ignore
export const extractPayloadFromFormWithoutProfile = (values) => {
  return {
    email: values.email,
    password: values.password,
    isWebUser: true,
    // TODO: language is hardcoded due to the webinars being german only
    language: 'de',
    profile: {
      // firstName: null,
      // lastName: null,
      // title: null,
      // oeak: null,
      // specialtyId: null,
      // specialty: null,
      // doctorWorkType: null,
      occupationId: values.occupation,
    },
  }
}
