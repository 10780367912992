import styled from 'styled-components';

export const Div = styled.div(({theme}) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
}));

{/* @ts-ignore */}
export const ButtonBoxRight = styled.div(({theme, description}) => ({
    position: 'absolute',
    right: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 169,
    width: 20,
    marginTop: description ? 92 : 111,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    zIndex: 150,

    [theme.breakpoints.down("sm")]: {
        top: description ? '-9%' : '10%',
        height: 166,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

{/* @ts-ignore */}
export const ButtonBoxLeft = styled.div(({theme, description}) => ({
    position: 'absolute',
    left: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 169,
    width: 20,
    marginTop: description ? 92 : 111,
    marginRight: -19,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 150,

    [theme.breakpoints.down("sm")]: {
        top: description ? '-9%' : '10%',
        height: 166,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageRightCaret = styled.img(({theme}) => ({
    width: 11,
    height: 14,
    transform: 'rotate(180deg)',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLeftCaret = styled.img(({theme}) => ({
    width: 11,
    height: 14,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLoading = styled.img(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '45%',
    height: 75,
    width: 75,

    [theme.breakpoints.down("sm")]: {
        width: 50,
        height: 50
    },
}));

export const Outline = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 45,
    marginRight: 45,
    marginTop: 35,

    '.descriptionRichText p': {
        color: '#676767',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '18px'
    },

    [theme.breakpoints.down("sm")]: {
        height: 200,
        marginLeft: 25,
        marginRight: 30,
        marginTop: 15,
    },
}));

export const TextCategoriesDiv = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: 0,
    gap: 15,
    flex: 'none',
    order: 0,
    flexGrow: 0,
    marginBottom: -10,

    '.descriptionRichTextIsMobile p': {
        color: '#404040',
        fontSize: '13px',
        lineHeight: '16px',
    },

    [theme.breakpoints.down("sm")]: {
        gap: 2,
        flexDirection: 'column',
        alignItems: 'start',
    }
}));

export const HeadlineText = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#2A2A2A',

    [theme.breakpoints.down("sm")]: {
        fontSize: 22
    }
}));

export const LinkToSeeMore = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#F05B4D',
    flex: 'none',
    order: 0,
    flexGrow: 0,

    [theme.breakpoints.down("sm")]: {},

    ':hover': {
        cursor: 'pointer',
    }
}));

export const LinkTo = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: 4,
    flex: 'none',
    order: 1,
    flexGrow: 0
}));

export const StraightLine = styled.div(({theme}) => ({
    flex: 'none',
    order: 0,
    flexGrow: 0,
    width: 1,
    height: 15,
    background: '#676767',
}));

export const Image = styled.img(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: 20,
    flex: 'none',
    order: 1,
    flexGrow: 0,
    maxHeight: 30,
    marginLeft: 30,

    [theme.breakpoints.down("sm")]: {
        height: 15,
        marginLeft: 0,
    }
}));

export const VideoCategoriesDiv = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: '20px',
    marginTop: 20,

    [theme.breakpoints.down("sm")]: {
        gap: '20px',
        width: 300,
    }
}));

export const VideoDiv = styled.div(({theme}) => ({
    width: 270,
    transition: 'all 0.3s',
    boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.09), 0px 0.912px 0.912px rgba(0, 0, 0, 0.01)',

    [theme.breakpoints.down("sm")]: {
        width: 300,
        maxWidth: '100%',
    }
}));

export const VideoImage = styled.img(({theme}) => ({
    width: '100%',
    height: '100%',

    [theme.breakpoints.down("sm")]: {
        width: 300,
        height: 166
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const PlayButton = styled.img(({theme}) => ({
    width: '100%',
    height: '100%',
    borderRadius: 5,
}));

export const VideoLength = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px 3px',
    gap: 10,
    position: 'absolute',
    height: 12,
    right: 10,
    bottom: 10,
    background: '#2E3436',
    borderRadius: 3
}));

export const VideoLengthNumber = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 10,
    fontWeight: 500,
    color: '#FFFFFF',
    width: 26,
    height: 12,
    textAlign: 'end'
}));

export const VideoDivContent = styled.div(({theme}) => ({
    marginTop: '12px',
    padding: '0 12px 12px'
}));

export const VideoDivTitle = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#2A2A2A',
    minHeight: '60px',
    overflow: 'hidden',
    marginBottom: '9px',

    [theme.breakpoints.down("sm")]: {}
}));

export const VideoDivDate = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: 3,
    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 0,
    color: '#8E8E8E'
}));

export const VideoDivSource = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#8E8E8E',

    [theme.breakpoints.down("sm")]: {}
}));

export const VideoDivPublicationDate = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#676767',
    opacity: 0.9,

    [theme.breakpoints.down("sm")]: {}
}));

export const ButtonBoxElement = styled.div(({theme}) => ({
    alignSelf: 'center',
    marginLeft: 5
}));

{/* @ts-ignore */}
export const StepperElement = styled.div(({theme, description}) => ({
    position: 'absolute',
    right: '1%',
    top: description ? '21%' : '26%'
}));

export const VideoDivDFPPoints = styled.div(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px'
}));

export const VideoDivDFPPointsText = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    color: '#434849',
    marginLeft: '6px'
}));

export const ContactButton = styled.div(({theme}) => ({
    marginTop: '10px',
    padding: '10px 16px',
    border: '1px solid #063F58',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15)) drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#063F58',

    ':hover': {
        cursor: 'pointer',
        backgroundColor: '#063F58',
        color: 'white'
    }
}));

export const ContactButtonText = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',

    ':hover': {
        cursor: 'pointer',
    }
}));