export const deTranslations = {
  webinar_registration: 'Anmeldung zum Webinar',
  password_successfully_reset: 'Passwort erfolgreich zurückgesetzt',
  you_ve_successfully_reset_password: "Sie haben Ihr Passwort erfolgreich zurückgesetzt. Versuchen Sie, sich einzuloggen.",
  restore_link_sent: 'Wiederherstellungslink gesendet',
  we_ve_sent_email: "Wir haben Ihnen eine E-Mail mit einem Link zur Wiederherstellung des Passworts an Ihre E-Mail-Adresse geschickt.",
  resend_email: 'E-Mail erneut versenden',
  sorry_illegible_for_webinar: 'Dieses Webinar ist leider nur für Ärzte zugänglich.',
  email_not_found_or_verified: 'E-Mail nicht gefunden oder noch nicht verifiziert',
  working_hard_on_content: 'Wir arbeiten mit Hochdruck an neuen Inhalten und werden Sie informieren, sobald etwas für Sie dabei ist.',
  webinar_restricted: 'Webinar eingeschränkt!',
  incorrect_username_or_password: 'Falscher Benutzername oder falsches Passwort',
  no_account: 'Haben Sie kein Konto?',
  remembered_password: 'Haben Sie Ihr Passwort vergessen?',
  back_to_login: 'Zurück zum Login',
  didnt_receive_email: 'Sie haben die E-Mail nicht erhalten?',
  we_ve_sent_you_a_restore_link_1: 'Wir haben Ihnen eine E-Mail mit einem Link zur Wiederherstellung des Passworts an Ihre E-Mail-Adresse',
  we_ve_sent_you_a_restore_link_2: 'geschickt.',
  name: 'Name',
  date: 'Datum',
  time: 'Start',
  we_ve_sent_confirmation_mail: 'Wir haben dir eine Bestätigungsmail geschickt',
  see_you_at_the_summit: 'Wir sehen uns beim Webinar!',
  see_you_at_the_event: 'Wir sehen uns bei der Veranstaltung',
  email: 'Email',
  doctorWorkType: {
    public: 'Kassenarzt',
    private: 'Wahlarzt',
    hospital: 'Krankenhaus',

  },
  occupations: {
    doctor: 'Arzt/Ärztin',
    pharmacist: 'Pharmazeut/In',
    student: 'Medizinstudent/In',
    nurse: 'Krankenpfleger/In',
    other: 'Sonstige',
  },
  occupation: 'Berufsgruppe',
  first_name_first_uppercased: 'Vorname',
  last_name_first_uppercased: 'Nachname',
  select_one_from_the_list: 'Wähle eine Berufsgruppe',
  your_personal_info: 'Persönliche Infos',
  enter_first_name: 'Vorname eingeben',
  enter_last_name: 'Nachname eingeben',
  enter_title: 'Titel eingeben',
  save_and_attend_webinar: 'Zum Webinar anmelden',
  attend_webinar: 'Zum Webinar anmelden',
  unsubscribe_webinar: 'Abbestellen',
  back: 'Zurück',
  token_already_used_error: 'Token ungültig oder bereits verwendet',
  video_list_featured: 'Featured',
  video_list_to_continue_watching: 'Weiter Ansehen',
  webinar_list_by_specialty: 'Webinare aus dem Thema',
  webinar_list_user_might_like: 'Webinare die dir gefallen könnten',
  webinar_list_subscribed: 'Meine Live Events',
  "password_empty_warning": "Passwort darf nicht leer sein",
  "password_short_warning": "Passwort muss mehr als 6 Zeichen enthalten",
  "password_no_match": "Passwörter stimmen nicht überein",
  "set_password_title": "Neues Passwort festlegen",
  "set_password_instructions": "Bitte erstelle jetzt dein neues Passwort.",
  "password_label_text": "PASSWORT",
  "password_repeat_label_text": "PASSWORT WIEDERHOLEN",
  "set_password_cta": "Neues Passwort festlegen",
  "hi_there": "Hallo",
  "welcome_greeting": "Willkommen bei meducately",
  "start_onboarding_cta": "Legen wir los",
  "already_have_account": "Hast du einen meducately Account?",
  "log_in_cta": "Anmelden",
  "animation_slider_text_1": "Finde neueste Artikel, Guidelines, Journale und Videos aus deinem Fachgebiet.",
  "animation_slider_text_2": "Erstelle Sammlungen direkt auf deinem Gerät und teile diese mit KollegInnen",
  "animation_slider_text_3": "Bringe deine Karriere voran, indem du dich für Seminare anmeldest und DFP-Punkte sammelst",
  "animation_slider_text_4": "Bleibe mit KollegInnen in Kontakt. Erfahre, was sie gerade lesen. Gib und erhalte Empfehlungen.",
  "create_new_journal_cta": "neu",
  "actions": "ACTIONS",
  "retry": "Wiederholen",
  "cancel": "Abbrechen",
  "added": "Hinzugefügt",
  "save": "Gespeichert",
  "edit": "Bearbeiten",
  "delete": "Löschen",
  "create": "Erstellen",
  "see_more": "Mehr sehen",
  "discover": "Entdecken",
  "people": "Personen",
  "tags": "Interessen",
  "journals": "Journals",
  "follow": "Folgen",
  "followers": "Followers",
  "following": "Following",
  "for_you": "Für dich",
  "all": "Alle",
  "articles": "Artikel",
  "videos": "Videos",
  "guidelines": "Guidelines",
  "check_out_more_articles": "Finde neue Artikel",
  "empty_journal_1": "Es scheint, dass du noch keine Artikel in dieser Sammlung hast. Du kannst Artikel, die dir gefallen, hinzufügen, indem du auf die Schaltfläche",
  "empty_journal_2": " Finde neue Artikel",
  "empty_journal_3": " klickst",
  "empty_journal_follower": "Es scheint, dass es noch keine Artikel in dieser Sammlung gibt.",
  "remove_from_journal": "Aus dieser Sammlung entfernen",
  "last_updated": "Letztes Update ",
  "by": "von",
  "privacy": "Sichtbarkeit",
  "journal_description_about": "Worum geht es?",
  "empty_journal_description": "Keine Beschreibung",
  "title_label": "TITEL",
  "description_label": "WORUM GEHT ES?",
  "description_hint": "Lass deine Follower wissen, worum es in dieser Sammlung geht...",
  "publish_journal_checkbox": "Sammlung publizieren",
  "publish_journal_hint": "Wenn du diese Option deaktivierst, ist deine Sammlung nur für dich sichtbar. Du kannst dies natürlich später ändern.",
  "email_invalid_warning": "Email ungültig",
  "email_sent_title": "Email gesendet",
  "reset_password_title": "Passwort zurücksetzen",
  "reset_email_description": "Gib deine E-Mail-Adresse an, die du für die Anmeldung verwendet hast, und wir senden dir einen Link zur Wiederherstellung des Passworts zu.",
  "email_label_text": "EMAIL ADRESSE",
  "email_hint_text": "example@gmail.com",
  "request_restore_cta": "Link zur Wiederherstellung anfordern",
  "login_title": "Anmelden",
  "forgot_password_cta": "Link zur Wiederherstellung anfordern",
  "messages_title": "Nachrichten",
  "clear": "Löschen",
  "no_messages": "Du hast noch keine Nachrichten.",
  "no_messages_description": "Sobald du anfängst, einigen Leuten zu folgen, wirst du hier neue Artikel und Publikationen sehen.",
  "added_new_journal_notification": "hat einen neuen Artikel in seiner Sammlung hinzugefügt ",
  "onboarding_title_1": "Wer bist du?",
  "onboarding_title_2": "Dein Fachgebiet",
  "onboarding_title_3": "Deine Interessen",
  "onboarding_title_4": "Deine Journale",
  "onboarding_description_1": "Du erhältst die für dich am besten geeigneten Inhalte auf der Grundlage deiner Auswahl.",
  "onboarding_description_2": "Wähle das Tätigkeitsumfeld, das du gerade ausübst.",
  "onboarding_description_3": "Wähle einige Themen aus, die dich im Moment interessieren. Du kannst diese auch später noch ändern.",
  "onboarding_description_4": "Folge den Top-Journalen deines Fachs und erhalte Erinnerungen, wenn es etwas Neues gibt. Du kannst deine Auswahl später ändern.",
  "onboarding_pick_interests": "Wähle deine Interessen",
  "continue_text": "Weiter",
  "out_of": " von ",
  "verify_account": "Überprüfe dein Konto",
  "sign_up": "Registrieren",
  "activate_account": "Account aktivieren",
  "likes": "Likes",
  "profile": "Profil",
  "no_likes": "Du hast noch keine Likes.",
  "you_can": "",
  "like": "Like ",
  "any_contnet": "jede Art von Inhalt und wer wird dir hier erscheinen",
  "user_has_no_journals": "Der Benutzer hat noch keine Sammlungen erstellt",
  "how_to_create_journals": "Du kannst Sammlungen erstellen und dort interessante Artikel, Videos oder Zitate hinzufügen.",
  "complete_profile": "Profil vervollständigen",
  "profile_picture": "PROFILBILD",
  "error_occurred": "Ooops, da ist ein Fehler passiert",
  "first_name": "VORNAME",
  "last_name": "NACHNAME",
  "title": "TITEL",
  "oeak": "ÖAK - NUMMER",
  "oeak_hint": "xxxx-xx",
  "place_of_work": "Arbeitsort",
  "next": "Nächstes",
  "where_practicing_medicine": "Wie praktizierst du derzeit als Arzt?",
  "about": "Über",
  "edit_profile": "Profil bearbeiten",
  "help_and_feedback": "Hilfe & Feedback",
  "imprint": "Impressum",
  "notifications": "Benachrichtigungen",
  "general_settings": "ALLGEMEINES",
  "enable_notifications": "Aktivieren von Benachrichtigungen",
  "content_settings": "INHALT",
  "new_article_settings": "Neuer Artikel in Sammlung",
  "personalized_reccomendations": "Personalisierte Empfehlungen",
  "social_settings": "SOCIAL",
  "new_follower": "Neuer Follower",
  "new_journal_subscription": "Neuer Abonnent deiner Sammlung",
  "privacy_policy": "Datenschutzbestimmungen",
  "settings": "Einstellungen",
  "profile_settings": "PROFILEINSTELLUNGEN",
  "sign_out": "Abmelden",
  "notifications_settings": "Benachrichtigungen",
  "edit_settings": "Einstellungen bearbeiten",
  "about_label": "ÜBER",
  "version": "Version",
  "build": "Build",
  "where_current_working_label": "WO ARBEITEST DU DERZEIT?",
  "where_current_working_description": "Das hilft uns, dir bessere Empfehlungen zu geben und für dich relevante Inhalte anzuzeigen.",
  "read_article": "Artikel lesen",
  "complete_profile_instructions_1": "Entdecke neue Themen, Journale und Menschen und erweitere dein Netzwerk.",
  "complete_profile_instructions_2": "Aber zuerst musst du dein Profil vervollständigen.",
  "logout_confirmation_dialog_title": "Bild du sicher, dass du dich von der App abmelden möchten?",
  "logout_confirmation_yes": "Ja, abmelden",
  "logout_confirmation_no": "Nein, eingeloggt bleiben",
  "delete_confirmation_dialog_title": "Bist du sicher, dass du löschen möchtest?",
  "delete_confirmation_yes": "Ja, löschen",
  "delete_confirmation_no": "Nein, abbrechen",
  "email_sent_verification": "Wir haben dir eine E-Mail mit einem Verifizierungslink an deine E-Mail-Adresse geschickt.",
  "email_sent_password_reset": "Wir haben dir eine E-Mail mit einem Link zur Wiederherstellung des Passworts an deine E-Mail-Adresse geschickt.",
  "email_sent_pls_check": "Bitte prüfe deinen Posteingang und klicke auf den Link",
  "to_set_password": " um dein neues Kennwort festzulegen.",
  "to_activate_account": " um deinen Account zu aktivieren.",
  "email_sent_didnt_receive_instructions": "Du hast keine Mail von uns erhalten? Bitte überprüfe auch deinen Spam-Ordner. Wenn dort nichts zu finden ist, klicke auf die Schaltfläche \"E-Mail erneut senden\".",
  "re_send_password_reset": "Link zum Zurücksetzen des Passworts erneut senden",
  "re_send_verification_link": "Verifizierungs-E-Mail erneut senden",
  "search_no_results": "Die Suche ergab keine Ergebnisse",
  "search_no_results_instructions": "Bitte versuche es erneut, indem du den Text im Suchfeld änderst",
  "seem_offline": "Du scheinst offline zu sein.",
  "no_internet_connection": "Es sieht so aus, als hättest du keine Internetverbindung. Bitte stelle sicher, dass du eine Verbindung hast und versuche es erneut.",
  "image_picker_dialog_title": "Möchtest du ein neues Bild aufnehmen oder ein bereits vorhandenes Bild auswählen?",
  "image_picker_dialog_gallery": "Bibliothek",
  "image_picker_dialog_take_new": "Neues Bild aufnehmen",
  "no_journals": "Du hast noch keine Sammlungen erstellt",
  "create_first_journal": "Erstelle deine erste Sammlung",
  "search": "Suche",
  "complete_profile_unlock": "Vervollständige dein Profil, um alle Inhalte und Funktionen freizuschalten",
  "unlock": "Freischalten",
  "item_bookmarked": "Der Inhalt wurde hinzugefügt",
  "item_already_bookmarked": "Der ausgewählte Inhalt ist in dieser Sammlung bereits vorhanden",
  "liked": "Liked",
  "like_text": "Like",
  "add_to_journal": "In Sammlung ablegen",
  "invite": "Einladen",
  "invite_hook_container_text": "Wie gefällt dir meducately bis jetzt? Gibt es jemanden, mit dem du diese App teilen möchtest?",
  "invite_hook_tile_title": "Nicht gefunden, wen du suchst?",
  "invite_hook_tile_description": "Zu meducately einladen",
  "share_subject": "meducately - Medical Literature Guide",
  "greeting": "Hallo, {name}!",
  "article_item_by_": "Für dich",
  "article_item_by_day": "Artikel des Tages",
  "article_item_by_week": "Artikel der Woche",
  "article_item_by_month": "Artikel des Monats",
  "article_list_by_interest": "Basierend auf dem Interesse #{value}",
  "@article_list_by_interest": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "article_list_by_specialty": "Neues aus deinem Fachbereich",
  "article_list_by_popularity": "Beliebte Artikel",
  "journal_list_by_specialty": "Sammlungen, die du kennen solltest",
  "quote_item_by_": "Zitat der Woche",
  "quote_item_by_day": "Zitat des Tages",
  "quote_item_by_week": "Zitat der Woche",
  "quote_item_by_month": "Zitat des Monats",
  "video_item_by_": "Video der Woche",
  "video_item_by_day": "Video des Tages",
  "video_item_by_week": "Video der Woche",
  "video_item_by_month": "Video des Monats",
  "video_list": "Andere Videos",
  "video_list_by_specialty": "{value} Videos",
  "@video_list_by_specialty": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "profile_list_by_same_hospital": "Personen, die in {value} arbeiten",
  "@profile_list_by_same_hospital": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "profile_list_by_same_university": "Andere Studierende bei {value}",
  "@profile_list_by_same_university": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "profile_list_by_same_region": "Personen, die in {value} arbeiten",
  "@profile_list_by_same_region": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "profile_list_popular_by_speciality": "Beliebt aus deinem Fachbereich",
  "tags_list_popular_by_speciality": "Basierend auf dem Bereich {value}",
  "@tags_list_popular_by_speciality": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "journals_list_popular_by_speciality": "Beliebt aus deinem Fachbereich",
  "journal_list_by_same_hospital": "Personen, die in {value} arbeiten",
  "@journal_list_by_same_hospital": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "journal_list_by_same_university": "Andere Studierende bei {value}",
  "@journal_list_by_same_university": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "journal_list_by_same_region": "Personen, die in {value} arbeiten",
  "@journal_list_by_same_region": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "invite_hook_profile_container_description": "Lade deine KollegInnen zu meducately ein!",
  "items": "Artikel",
  "follower_no_results": "Es sieht so aus, als hättest du noch keine Follower",
  "follower_no_results_instructions": "Es wird sicherlich nicht mehr lange dauern…",
  "see_all": "alle sehen",
  "terms_and_conditions": "Nutzungsbedingungen",
  "signup_accept_text_1": "Ich akzeptiere die ",
  "signup_accept_text_2": " von meducately und nehme die ",
  "signup_accept_text_3": " zur Kenntnis",
  "data_protection": "Datenschutzerklärung",
  "most_popular": "Am Beliebtesten",
  "tags_list_by_speciality": "{value} tags",
  "@tags_list_by_speciality": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "based_on": "Basierend auf dem Bereich {value}",
  "@based_on": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "journal_colleagues": "Sammlungen deiner KollegInnen",
  "journals_in": "Sammlungen im Bereich {value}",
  "@journals_in": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "latest": "Neueste",
  "journal_list_by_specialty_most_followed": "Sammlungen aus deinem Fachgebiet",
  "@journal_list_by_specialty_most_followed": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "journal_list_by_top_in_each_specialty": "Top Sammlungen deines Fachs",
  "journal_list_by_doctors": "Sammlungen von Ärzten",
  "video_list_by_popularity": "Beliebteste Videos",
  "guideline": "Guideline",
  "recommendation": "Empfehlung",
  "clinical_pathway": "Klinischer Algorithmus",
  "guideline_comment": "Guideline Kommentar",
  "statement": "Statement",
  "list": "Liste",
  "mark_read": "als gelesen markieren",
  "recent_activity": "Sieh dir einige neue Updates an!",
  "own_profile_followed_by": "folgt dir",
  "own_journal_followed_by": "hat deine Sammlung abonniert",
  "followed_journal_updated": "Informationen in dieser Sammlung wurden aktualisiert",
  "followed_journal_new_articles": "hat neue Artikel zu seiner Sammlung hinzugefügt",
  "followed_profile_new_journal": "hat eine neue Sammlung hinzugefügt",
  "must_not_empty": "darf nicht leer sein",
  "@must_not_empty": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "oeak_not_valid": "Die ÖAK Nummer ist nicht gültig",
  "request_access": "Zugang anfordern",
  "request_access_description": "Voller Zugang wird nur für Fachleute aus dem Gesundheitswesen gewährt. Bitte kontaktiere unseren Support, um dein Profil zu validieren.",
  "where_office_located": "Wo befindet sich deine Ordination?",
  "pick_hospital": "Wähle dein Krankenhaus",
  "where_located": "Wo befindest du dich?",
  "where_place_of_work": "Wo befindet sich dein Arbeitsort?",
  "which_university_study": "An welcher Universität studierst du?",
  "today": "heute",
  "share_button_text": "Erlebe medizinische Wissenschaft neu. Hier downloaden. https://meducately.com",
  "generic_error_message": "Bitte klicke auf den Button und versuche es erneut",
  "title_short_warning": "Titel muss mehr als 2 Zeichen enthalten",
  "new_journal": "Neue Sammlung",
  "my_journals": "Meine Sammlungen",
  "journal_followings_empty": "Du scheinst keinen Journalen zu folgen",
  "journal_followings_empty_instructions": "Du kannst Journale, denen du folgen möchten, im Feed finden oder nach Journalen, die du bereits kennst, suchen",
  "my_interests": "Meine Interessen",
  "profile_followings_empty": "Es scheint du folgst keinen anderen Personen",
  "profile_followings_empty_instructions": "Du kannst Personen folgen, die du im Feed siehst oder Personen direkt mit Hilfe der Suche finden.",
  "article_list_by_vendor_journal": "Neues in {value}",
  "@article_list_by_vendor_journal": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "journal_list_for_vendors_most_followed": "Meistgefolgte Journale",
  "journal_list_for_all_most_followed": "Meistgefolgte Journale & Sammlungen",
  "journal_list_for_collections_most_followed": "Meistgefolgte Sammlungen",
  "journal_list_for_vendors_most_followed_in_specialty": "Meistgefolgte Journale in {value}",
  "@journal_list_for_vendors_most_followed_in_specialty": {
    "placeholders": {
      "value": {
        "type": "String",
      },
    },
  },
  "my_dfp_courses": "Meine DFP Kurse"
}
