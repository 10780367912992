import { Div, Label, Text } from './style';


// @ts-ignore
const TextWithLabel = ({ label, children }) => {
  return (
    <Div>
      <Label>{label.toUpperCase()}</Label>
      <Text>{children}</Text>
    </Div>
  )
};

export default TextWithLabel;
