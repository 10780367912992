import React from 'react';
import {
    Div,
    ButtonBoxRight,
    ButtonBoxLeft,
    Outline,
    ImageLoading,
    HeadlineText,
    LinkToSeeMore,
    LinkTo,
    TextCategoriesDiv,
    Image,
    VideoCategoriesDiv,
    VideoDiv,
    VideoDivTitle,
    VideoDivDate,
    ButtonBoxElement,
    StepperElement,
    VideoImage,
    VideoLength,
    VideoLengthNumber,
    ImageRightCaret,
    ImageLeftCaret,
    VideoDivPublicationDate,
    VideoDivContent,
    VideoDivDFPPoints,
    VideoDivDFPPointsText,
    ContactButton,
    ContactButtonText
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ForwardButton from '../../../common/buttons/forward-button';
import Stepper from '../../../common/stepper';
import { useNavigate } from 'react-router-dom';
import VideoAPI from '../../../../api/video';
import { deTranslations } from '../../../../translations';
import Canvas from '../../../../utils/Canvas';
import PlayButtonIcon from '../../../icons/play-button-icon';
import { format } from "../../../../utils/time";
import DFPIcon from "../../../icons/dfp-icon";
import DFPSuccessMessage from "../../../common/dfp-success-message/DFPSuccessMessage";

// @ts-ignore
const DFPCourseChannelNew = ({ filter, filterMore, headlineValue, description, logo, channelId }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [data, setData] = React.useState([]);
    const [start, setStart] = React.useState(0);
    const [last, setLast] = React.useState(5);
    const [step, setStep] = React.useState(1);
    const [showPlayButton, setShowPlayButton] = React.useState(false);
    const [keyPlayButton, setKeyPlayButton] = React.useState(0);
    const [countStep, setCountStep] = React.useState(0);
    const [touchStart, setTouchStart] = React.useState(null);
    const [touchEnd, setTouchEnd] = React.useState(null);
    const [loadDone, setLoadDone] = React.useState(false);

    const [showContactButton, setShowContactButton] = React.useState(false);

    React.useEffect(() => {
        // @ts-ignore
        VideoAPI.getAllDfpVideos(filter).then((response: any) => {
            setData(response[1]);
            setLoadDone(true);
        });
    }, [filter, filterMore])

    React.useEffect(() => {
        if (window.innerWidth >= 3200) {
            setLast(10);
            setCountStep(10);
        } else if (window.innerWidth >= 2800) {
            setLast(8);
            setCountStep(8);
        } else if (window.innerWidth >= 2400) {
            setLast(7);
            setCountStep(7);
        } else if (window.innerWidth >= 2000) {
            setLast(6);
            setCountStep(6);
        } else if (window.innerWidth >= 1600) {
            setLast(5);
            setCountStep(5);
        } else if (window.innerWidth >= 1200) {
            setLast(4);
            setCountStep(4);
        } else if (window.innerWidth >= 914) {
            setLast(3);
            setCountStep(3);
        } else if (window.innerWidth >= 640) {
            setLast(2);
            setCountStep(2);
        } else if (window.innerWidth < 640 && !isMobile) {
            setLast(1);
            setCountStep(1);
        } else if (isMobile) {
            setLast(data?.length - 1);
        }
    }, [window.innerWidth, isMobile, data])

    let videos = data?.slice(start, last + 1);

    const seeMore = () => {
        // Used when courses in progress are clicked on because they don't have a channelId defined as it's computed channel
        let channelIdAdapted = channelId ? channelId : '92634';

        navigate('/' + 'dfp-channels' + '/' + channelIdAdapted + '/' + headlineValue.replaceAll(' ', '').replaceAll('/', ''));
    }

    const navigateToPage = (video: any) => {
        // Used when courses in progress are clicked on because they don't have a channelId defined as it's computed channel
        let channelIdAdapted = channelId ? channelId : '92634';

        navigate('/dfp-video-play' + '/' + 'channels' + '/' + channelIdAdapted + '/' + video.id + '/' + video?.video?.id + '/' +
            video?.title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', ''));
    }

    const setMoreVideos = () => {
        setStart(start + countStep);
        setLast(last + countStep);
        setStep(step + 1);
    }

    const setLessVideos = () => {
        setStart(start - countStep);
        setLast(last - countStep);
        setStep(step - 1);
    }

    const hoverShowPlayButton = (shouldShow: any, key: any) => {
        setShowPlayButton(shouldShow);
        setKeyPlayButton(key);
    }

    const minSwipeDistance = 50;

    const onTouchStart = (e: any) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) {
            return
        }

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            if (start !== last) {
                setStart(start + 1);
            }
        }

        if (isRightSwipe) {
            if (start !== 0) {
                setStart(start - 1);
            }
        }
    }

    // @ts-ignore
    return ((data?.length !== 0) &&
        ((headlineValue !== deTranslations.video_list_to_continue_watching || data?.length !== 0) &&
            <Div style={{
                height: isMobile && (description == null || description === '')
                    ? 490
                    : isMobile && !(description == null || description === '')
                        ? 530
                        : 500
            }}>
                {/* @ts-ignore */}
                {start > 0 && !isMobile && <ButtonBoxLeft onClick={() => setLessVideos()} description={description == null || description === ''}>
                    <ButtonBoxElement>
                        <ImageLeftCaret src={'chevron.png'} />
                    </ButtonBoxElement>
                </ButtonBoxLeft>
                }
                {/* @ts-ignore */}
                {(!isMobile && (data?.length - (last - countStep) >= 0)) && <StepperElement description={description == null || description === ''}>
                    <Stepper isVideoPlayPage={false} steps={Math.floor(data?.length / countStep) > 5
                        ? 5
                        : (Math.floor(data?.length / countStep) >= 1
                            ? Math.floor(data?.length / countStep) + 1
                            : 0)} step={step} />
                </StepperElement>
                }
                {!loadDone
                    ? <ImageLoading src={'loading-transparent-gif.gif'} />
                    : <Outline onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                        {!isMobile ? <div>
                            <TextCategoriesDiv>
                                <HeadlineText>{headlineValue}</HeadlineText>
                                <LinkTo>
                                    <LinkToSeeMore onClick={() => seeMore()}>{deTranslations.see_more}</LinkToSeeMore>
                                    <ForwardButton sx={{
                                        flex: 'none',
                                        order: 0,
                                        flexGrow: 0,
                                        width: 12,
                                        height: 8,
                                        marginLeft: -3.5
                                    }} onClick={() => seeMore()} isLanding={true} children={null} />
                                </LinkTo>
                            </TextCategoriesDiv>
                        </div> : <TextCategoriesDiv>
                            <div>
                                {logo != null ? <Image src={logo} /> : null}
                            </div>
                            <HeadlineText>{headlineValue}</HeadlineText>
                            <LinkTo>
                                <LinkToSeeMore onClick={() => seeMore()}>{deTranslations.see_more}</LinkToSeeMore>
                                <ForwardButton sx={{
                                    flex: 'none',
                                    order: 0,
                                    flexGrow: 0,
                                    width: 12,
                                    height: 8,
                                    marginLeft: -3.5
                                }} onClick={() => seeMore()} isLanding={true} children={null} />
                            </LinkTo>
                        </TextCategoriesDiv>
                        }
                        <VideoCategoriesDiv>
                            {videos?.map((video: any, index: number) => {
                                const videoLength = video?.video?.length ?? 0;

                                return (
                                    // @ts-ignore
                                    <VideoDiv key={index}
                                              onMouseEnter={() => hoverShowPlayButton(true, index)}
                                              onMouseLeave={() => hoverShowPlayButton(false, index)}>
                                        <div style={{ position: 'relative' }}>
                                            <VideoImage
                                                style={{ maxHeight: (showPlayButton && keyPlayButton === index) ? 190 : 170 }}
                                                onClick={() => navigateToPage(video)} src={video?.video?.coverURL} />
                                            {(showPlayButton && keyPlayButton === index) &&
                                            <PlayButtonIcon style={{
                                                width: 49,
                                                height: 49,
                                                position: 'absolute',
                                                top: '40%',
                                                left: '42%'
                                            }} onClick={() => navigateToPage(video)} />}
                                            {headlineValue === deTranslations.video_list_to_continue_watching &&
                                            <Canvas height={13} width={13}
                                                    percentage={video?.watching?.stoppedWatchingAt / videoLength * 360} />
                                            }
                                            <VideoLength
                                                style={{ width: videoLength / 3600 > 1 ? 41 : videoLength / 60 < 10 ? 28 : 28 }}>
                                                <VideoLengthNumber>
                                                    {(videoLength / 3600 > 1 ? Math.floor(videoLength / 3600) + ':' : null)}
                                                    {(videoLength % 3600 / 60 <= 9 ? '0' + Math.floor(videoLength % 3600 / 60) : Math.floor(videoLength % 3600 / 60))}
                                                    :{videoLength % 60 < 10 ? '0' + videoLength % 60 : videoLength % 60}
                                                </VideoLengthNumber>
                                            </VideoLength>
                                        </div>

                                        <VideoDivContent>
                                            <VideoDivTitle>
                                                {video?.title ?
                                                    video?.title.length <= 65 ? video?.title : <span title={video?.title}>{video?.title.substring(0, 65)}(...)</span>
                                                    : <></>
                                                }
                                            </VideoDivTitle>

                                            <VideoDivDate>
                                                <VideoDivPublicationDate>{video?.video?.publicationDate ? format(video?.video?.publicationDate, 'dd. MMMM yyyy') : ''}</VideoDivPublicationDate>
                                            </VideoDivDate>

                                            {video?.dfpPoints ? <VideoDivDFPPoints>
                                                <DFPIcon style={{width: 17.5}}/>
                                                <VideoDivDFPPointsText>{video?.dfpPoints === 1 ? '1 DFP Punkt' : `${video?.dfpPoints} DFP Punkte`}</VideoDivDFPPointsText>
                                            </VideoDivDFPPoints> : <></>
                                            }

                                            {video?.enrollment?.status === 'passed' ?
                                                <DFPSuccessMessage type={'success'}
                                                                   onClick={() => navigateToPage(video)}/> : video?.enrollment?.status === 'failed' ?
                                                    <DFPSuccessMessage type={'failure'}
                                                                       onClick={() => navigateToPage(video)}/>
                                                    : <ContactButton onClick={() => navigateToPage(video)}>
                                                        <ContactButtonText>
                                                            DFP Kurs starten
                                                        </ContactButtonText>
                                                    </ContactButton>
                                            }

                                        </VideoDivContent>
                                    </VideoDiv>
                                )
                            })}
                        </VideoCategoriesDiv>
                    </Outline>
                }
                {/* @ts-ignore */}
                {data?.length > (last - 1) && !isMobile && <ButtonBoxRight onClick={() => setMoreVideos()} description={description == null || description === ''}>
                    <ButtonBoxElement>
                        <ImageRightCaret src={'chevron.png'} />
                    </ButtonBoxElement>
                </ButtonBoxRight>
                }
            </Div>
        )
    )
}

export default DFPCourseChannelNew;
