import styled from 'styled-components';

export const Form = styled.form(({}) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Hr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.label}`,
  width: 'calc(100% + 44px)',
  marginLeft: -22,
}));

export const Title = styled.h3(({ theme }) => ({
  textAlign: 'center',
  color: '#676767',
  fontFamily: ['Inter', 'normal'].join(','),
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 1.2,
  marginBottom: 27,
}));

export const WebinarInfo = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 229,
  // [theme.breakpoints.up("sm")]: {
  //   alignSelf: 'center',
  // },
}));

export const FirstRow = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
}));

export const SecondRow = styled.div(({ theme }) => ({
  marginTop: 16,
  display: 'flex',
  justifyContent: 'space-between',
  marginRight: 13,
}));