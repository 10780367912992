import React from 'react';
import FeedAPI from '../../api/feed';
import WebinarAPI from '../../api/webinar';
import Hero from './hero';
import HeroFeatured from './hero-featured';
import WebinarFeed from './webinar-feed';
import { deTranslations } from '../../translations';
import {
    ImageLoading
} from './style';

const EventLanding = () => {

    const [eventData, setEventData] = React.useState([]);
    const [primaryWebinar, setPrimaryWebinar] = React.useState(null);
    const [secondaryWebinars, setSecondaryWebinars] = React.useState(null);
    const [doneSecondaryLoading, setDoneSecondaryLoading] = React.useState(false);
    const [donePrimaryLoading, setDonePrimaryLoading] = React.useState(false);
    const [allWebinarIds, setAllWebinarIds] = React.useState([]);
    const [feedLoaded, setFeedLoaded] = React.useState({});

    // @ts-ignore
    React.useEffect(() => {
        // @ts-ignore
        FeedAPI.getWebinarFeed().then( (response: any)=> {
            setEventData(response[1])
            if (response[1][0]?.headline === 'webinar_list_featured_primary') {
                WebinarAPI.getAllWebinars(response[1][0]?.filter + '&s=' + JSON.stringify(JSON.parse((response[1][0]?.meta?.query))) + '').then( (response: any)=> {
                    setPrimaryWebinar(response[1])
                    setDonePrimaryLoading(true)
                })
                WebinarAPI.getAllWebinars(response[1][1]?.filter + '&s=' + JSON.stringify(JSON.parse((response[1][1]?.meta?.query))) + '').then( (response: any)=> {
                    setSecondaryWebinars(response[1])
                    setDoneSecondaryLoading(true)
                })
            }
        });
    }, []);

    const setWebinarIds = (webinars: any, headlineValue: any) => {
        let webinarIds: any = []
        webinars?.map((w: any) => {
            webinarIds = [...webinarIds, w.id]
        })

        // @ts-ignore
        setAllWebinarIds(allWebinarIds => [...allWebinarIds, ...webinarIds])
        setFeedLoaded(allWebinarIds => ({...allWebinarIds, [headlineValue]: true}))
    }

    const isFeedLoaded = () => {
        const a = Object.keys(feedLoaded).length === webinarFeed.length
        const b = Object.values(feedLoaded).every(element => element)

        return a && b
    }

    // @ts-ignore
    const webinarFeed = eventData.filter((event) => event.headline !== 'webinar_list_featured_primary').filter((event) => event.headline !== 'webinar_list_featured_secondary').filter((event) => event.headline !== 'webinar_list_user_might_like')
    // @ts-ignore
    const webinarListUserMightLike = eventData.filter((event) => event.headline === 'webinar_list_user_might_like')
    // @ts-ignore
    return (!(donePrimaryLoading && doneSecondaryLoading) ? <ImageLoading src={'loading-transparent-gif.gif'}/>
            :
        <>
            {/* @ts-ignore */}
            {(primaryWebinar != null && primaryWebinar?.length !== 0) ?
              <Hero
                primaryData={primaryWebinar}
              />
                :
                <div>
                    {/* @ts-ignore */}
                    {(secondaryWebinars != null && secondaryWebinars?.length !== 0) ?
                <HeroFeatured
                    backgroundColor={'#2E3436'}
                    featuredColor={'#FFFFFF'}
                    secondaryData={secondaryWebinars}
                /> : null}
                </div>
            }
            {/* @ts-ignore */}
            {(primaryWebinar != null && secondaryWebinars != null && secondaryWebinars?.length !== 0 && primaryWebinar?.length !== 0) ?
                <HeroFeatured
                    backgroundColor={'#FCFCFA'}
                    featuredColor={'#2E3436'}
                    secondaryData={secondaryWebinars}
                /> : null
            }
            {/* @ts-ignore */}
            {webinarFeed?.map((feed: any, index: any) => (<WebinarFeed onWebinarsLoad={(webinars: any) => {setWebinarIds(webinars, feed?.headline)}}
                    key={index}
                    filter={feed?.filter}
                    headlineValue={feed?.headlineValue != null
                        ? feed?.headline === 'webinar_list_by_specialty'
                            ? deTranslations.webinar_list_by_specialty + ' ' + feed?.headlineValue
                            : feed?.headlineValue
                        : feed?.headline}
                />
            ))}
            {/* @ts-ignore */}
            {isFeedLoaded() ? webinarListUserMightLike?.map((feed: any, index: any) => (<WebinarFeed filter={allWebinarIds?.length === 0 ? feed?.filter : feed?.filter.concat('&filter=id||$notin||' + allWebinarIds?.toString())}
                    key={index}
                    headlineValue={feed?.headlineValue != null
                        ? feed?.headline === 'webinar_list_by_specialty'
                            ? deTranslations.webinar_list_by_specialty + ' ' + feed?.headlineValue
                            : feed?.headlineValue
                        : feed?.headline}
                />
            )) : null}
        </>
    )
}

export default EventLanding;
