import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  backgroundColor: '#1D1D1D',
  height: 330,

  [theme.breakpoints.down("sm")]: {
    height: 203,
  },
}));

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 980,
  marginTop: 135,
  [theme.breakpoints.down("sm")]: {
    width: '87%',
    opacity: 0,
  },
}));

export const Text = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 540,
  alignItems: 'flex-start',
  marginTop: 41,
  marginBottom: 26,
}));

export const WebinarTitle = styled.h1(({ theme }) => ({
  fontSize: '40px',
  lineHeight: '49px',
  fontFamily: ['Average', 'normal'].join(','),
  fontWeight: 400,
  color: '#FFFFFF',
  margin: 0,
}));

export const WebinarDescription = styled.p(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#C4C4C4',
  margin: 0,
  marginTop: 1,
}));

export const WebinarDatesAndTimes = styled.p(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '28px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#FCFCFC',
  margin: 0,
  marginTop: 33,
}));
