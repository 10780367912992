import {
    OnboardingCard,
    OnboardingCardHeader,
    OnboardingCardContent,
    OnboardingCardFooter,
    LineItem,
    OnboardingCardContentBold
} from './style';
import CheckCircleIcon from '../../icons/check-circle';
import React from 'react';
import { Box } from '@mui/material';

const SignupOnboardingWidget = ({ isProxyUser = false }: { isProxyUser?: boolean }) => {
    return (
        <OnboardingCard>
            {
                isProxyUser ? (
                    <OnboardingCardHeader>
                        ... erhalte kostenlosen Zugriff auf
                    </OnboardingCardHeader>
                ) : (
                <OnboardingCardHeader>
                    ... und werde Teil unserer <br/> wachsenden Community
                </OnboardingCardHeader>
                )
            }
            <OnboardingCardContent>
                <LineItem style={{marginBottom: '15px'}}>
                    <CheckCircleIcon />
                    <Box component="span" ml={1.2}>Mehr als 4000 Videos</Box>
                </LineItem>
                <LineItem style={{marginBottom: '15px'}}>
                    <CheckCircleIcon />
                    <Box component="span" ml={1.2}>Teilnahme an Webinaren</Box>
                </LineItem>
                <LineItem style={{marginBottom: '15px'}}>
                    <CheckCircleIcon />
                    <Box component="span" ml={1.2}>Benutzerdefinierte DFP Kurse</Box>
                </LineItem>
            </OnboardingCardContent>
            <OnboardingCardFooter>
                und wir versprechen: wir spammen dich nicht zu
                und erweitern unsere Datenbank regelmäßig
            </OnboardingCardFooter>
        </OnboardingCard>
    );
}

export default SignupOnboardingWidget;
