import { StyledModal, StyledDiv } from './style';
import React, { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import Subscribe from '../../login/subscribe';
import Login from '../../../components/login/initial';
import Signup from '../../../components/signup/initial';
import SubscribeExternal from '../../login/subscribe-external';

// @ts-ignore
const SubscribeExternalModal = ({ open, handleClose, webinarData, audienceId }) => {
  const { user } = useContext(AuthContext);
  const [isLogin, setIsLogin] = React.useState(false);
  const [subscriptionToken, setSubscriptionToken] = React.useState(null);

  return <StyledModal
    open={open}
    disableAutoFocus={true}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledDiv>
      {user != null
        ?
        <SubscribeExternal webinarData={webinarData} audienceId={audienceId} subscriptionToken={subscriptionToken} />
        :
        <>{isLogin ?
          <Login webinarData={webinarData} onSignupClick={() => setIsLogin(false)} audienceId={audienceId} />
          :
            // @ts-ignore
          <Signup webinarData={webinarData} onLoginClick={() => setIsLogin(true)} hideOnboardingWidget={true} setSubscriptionToken={setSubscriptionToken} />
        }</>
      }
    </StyledDiv>
  </StyledModal>
}

export default SubscribeExternalModal;
