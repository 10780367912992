import React from 'react';
import {
    Div,
    Outline,
    HeroContent,
    WebinarFeatured,
    WebinarDivTitle,
    WebinarDivDate,
    IconAndText,
    Buttons,
    IconText,
    ImageVideoIcon
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { format } from '../../../utils/time';
import DFPIcon from '../../icons/dfp-icon';
import VideoIcon from '../../icons/video-icon';
import PrimaryButton from '../../common/buttons/primary-button';
import SecondaryButton from '../../common/buttons/secondary-button';
import SubscribeModal from "../../webinar-landing/subscribe-modal";
import WebinarAPI from "../../../api/webinar";

// @ts-ignore
const Hero = ({ primaryData }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
    const [webinarData, setWebinarData] = React.useState({});

    const openSubscribeModal = React.useCallback((webinar: any) => {
        WebinarAPI.getWebinarById(webinar.id).then(response => {
            setWebinarData(response[1])
            setShowSubscribeModal(true);
        })
    }, [showSubscribeModal]);

    const closeSubscribeModal = React.useCallback(() => {
        setShowSubscribeModal(false);
        navigate(0)
    }, [showSubscribeModal])

    const calculateDFPPoints = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.dfpPoints))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    const calculateVortrage = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.slots.length))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    let banner = (primaryData != null && primaryData.length !== 0) ? primaryData[0]?.bannerURL : 'none'
    let title = (primaryData != null && primaryData.length !== 0) ? primaryData[0]?.title : null
    let startDate = (primaryData != null && primaryData.length !== 0) ? primaryData[0]?.startDate : null
    let endDate = (primaryData != null && primaryData.length !== 0) ? primaryData[0].endDate : null
    let agenda = (primaryData != null && primaryData.length !== 0) ? primaryData[0].agenda : null
    let webinar = (primaryData != null && primaryData.length !== 0) ? primaryData[0] : null
    let id = (primaryData != null && primaryData.length !== 0) ? primaryData[0].id : null

    return (
        <Div>
            <SubscribeModal
                open={showSubscribeModal}
                handleClose={closeSubscribeModal}
                webinarData={webinarData}
            />
            {/* @ts-ignore */}
            <Outline backgroundImage={banner}>
                <HeroContent>
                    <WebinarFeatured>FEATURED</WebinarFeatured>
                    <WebinarDivTitle>{title}</WebinarDivTitle>
                    {startDate != null && endDate != null &&
                    (format(startDate, 'dd. MMM yyyy.') === format(endDate, 'dd. MMM yyyy.'))
                        ? <WebinarDivDate>{format(endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                        : (format(startDate, 'yyyy.') !== format(endDate, 'yyyy.'))
                            ? <WebinarDivDate>{format(startDate, 'dd. MMM yyyy.')} - {format(endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                            : (format(startDate, 'MMM yyyy.') !== format(endDate, 'MMM yyyy.'))
                                ? <WebinarDivDate>{format(startDate, 'dd. MMM')} - {format(endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                : <WebinarDivDate>{format(startDate, 'dd.')} - {format(endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                    }
                    <IconAndText>
                        <DFPIcon style={{ width: 22, marginTop: 5 }} color={'white'}/>
                        <IconText>{agenda !== null ? calculateDFPPoints(agenda) : 0}</IconText>
                        <ImageVideoIcon src={'streamline-icon-video-player-movie-2.png'}/>
                        <IconText>{agenda !== null ? calculateVortrage(agenda) : 0} Vorträge</IconText>
                    </IconAndText>
                    <Buttons>
                        <PrimaryButton onClick={() => openSubscribeModal(webinar)}>Anmelden</PrimaryButton>
                        <SecondaryButton onClick={() => navigate('/webinar/' + id + '/' + title.replaceAll(' ', '').replaceAll('/', ''))}>Zum Event</SecondaryButton>
                    </Buttons>
                </HeroContent>
            </Outline>
        </Div>
    )
}

export default Hero;
