import React from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { StyledDrawer, Container, LinksWithImages, LinkWithImage, LinkText, TextLinks, StyledAccordion, NavLinkText } from './style';
import Search from '../search';
import LoginIcon from '../../../icons/login-icon';

import {
    SearchList,
    SearchListElement,
    SearchListElementAllText,
    SearchListElementImage,
    SearchListElementTextSource,
    SearchListElementTextTitle,
    SearchListElementTextType,
    SearchListLinkTo, SearchListMore,
    SearchListTitle
} from '../style';
import Divider from '@mui/material/Divider';
import { theme } from '../../../../styles';
import { VideoLength, VideoLengthNumber } from '../../../view-more-landing/style';
import ArrowRightIcon from '../../../icons/arrow-right-icon';
import { Box } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import PrimaryButton from '../../../common/buttons/primary-button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../../icons/close-icon';
import SearchIcon from '../../../icons/search-icon';

// @ts-ignore
const MobileMenu = ({open, handleClose, isLoggedIn, goToPage, openLoginModal, openProfileEditorModal, search, searchList, searchListRef, setSearch, setSearchList, setShowMore, navigateToPageViewMore, logout, handleSearchListElementClick, setSignupModalOpen, navigateToElement}) => {

    const navigate = useNavigate();

    return <StyledDrawer anchor='right' open={open} onClose={handleClose}>
        <>
            {
                isLoggedIn ? (
                    <Box width="100%"
                         height="60px"
                         sx={{'& > div': {borderRadius: 0}}}>
                        <Search setSearchList={setSearchList}
                                setShowMore={setShowMore}
                                search={search}
                                hideSearch={handleClose}
                                setSearch={setSearch}
                                navigateToPageViewMore={navigateToPageViewMore}/>
                    </Box>
                ) : (
                    <Box width="100%"
                         height="60px"
                         display="flex"
                         justifyContent="flex-end"
                         sx={{'& > div': {
                             borderRadius: 0
                             },
                             backgroundColor: '#2A2A2A'
                         }}>
                        <IconButton onClick={handleClose}
                                    aria-label="search"
                                    sx={{
                                        padding: 0,
                                        marginRight: '8px',
                                        marginTop: '11px',
                                        marginBottom: '8px',
                                        marginLeft: '11px'
                                    }}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                )
            }
            <Container>
                {isLoggedIn ? (
                    <>
                        {(searchList && searchList.length !== 0) &&
                            // @ts-ignore
                          <SearchList ref={searchListRef} decreaseHeight={searchList.length} style={{ zIndex: 1 }}>
                            <SearchListTitle>Vorschläge</SearchListTitle>
                            <Divider sx={{margin: '10px 0', borderColor: theme.palette.textColors.bodyLight}}/>
                              {searchList.slice(0, 3).map((data: any, index: number) => (
                                  <SearchListElement onClick={() => {
                                      handleClose();
                                      navigateToElement(data);
                                  }} key={index}>
                                      {data.hasOwnProperty('featuredVideo')
                                          ? <SearchListElementImage src={data.coverURL}/>
                                          : <div style={{position: 'relative'}}>
                                              <SearchListElementImage src={data.coverURL}/>
                                              <VideoLength
                                                  style={{width: data?.length / 3600 > 1 ? 33 : data?.length / 60 < 10 ? 24 : 27}}>
                                                  <VideoLengthNumber>
                                                      {(data?.length / 3600 > 1 ? Math.floor(data?.length / 3600) + ':' : null)}
                                                      {((data?.length % 3600) / 60 < 10 ? '0' + Math.floor(data?.length % 3600 / 60) : Math.floor(data?.length % 3600 / 60))}
                                                      :{data?.length % 60 < 10 ? '0' + data?.length % 60 : data?.length % 60}
                                                  </VideoLengthNumber>
                                              </VideoLength>
                                          </div>
                                      }
                                      <SearchListElementAllText>
                                          <SearchListElementTextType>
                                              {data.hasOwnProperty('featuredVideo') ? 'WEBINAR' : 'VIDEO'}
                                          </SearchListElementTextType>
                                          <SearchListElementTextTitle>
                                              {data.title.slice(0, 40) + '...'}
                                          </SearchListElementTextTitle>
                                          <SearchListElementTextSource>
                                              {data.hasOwnProperty('featuredVideo') ? data.featuredVideo?.source : data.source}
                                          </SearchListElementTextSource>
                                      </SearchListElementAllText>
                                  </SearchListElement>
                              ))}
                            <Divider sx={{margin: '10px 0', borderColor: theme.palette.textColors.bodyLight}}/>
                              {
                                  <SearchListLinkTo>
                                      {/* @ts-ignore */}
                                      <SearchListMore ref={searchListRef}
                                                      onClick={() => navigateToPageViewMore(searchList, search)}>
                                          Mehr anzeigen
                                      </SearchListMore>
                                      <ArrowRightIcon/>
                                  </SearchListLinkTo>
                              }
                          </SearchList>
                        }
                        <Box width="100%" flex={1} position="relative" pt="15px">
                            <LinkWithImage onClick={() => goToPage('/videos')}>
                                <LinkText className='linkText'>Videos</LinkText>
                            </LinkWithImage>
                            <LinkWithImage onClick={() => goToPage('/events')}>
                                <LinkText className='linkText'>Events</LinkText>
                            </LinkWithImage>
                            <LinkWithImage onClick={() => goToPage('/channels')}>
                                <LinkText className='linkText'>Channels</LinkText>
                            </LinkWithImage>
                            <LinkWithImage onClick={() => goToPage('/dfp-videos')}>
                                <LinkText className='linkText'>DFP Kurse</LinkText>
                            </LinkWithImage>
                            <StyledAccordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRight sx={{ color: '#FFF' }} />}
                                    aria-controls="profile-content"
                                    id="profile-header"
                                >
                                    <span>Mein Profil</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <LinkText
                                        onClick={openProfileEditorModal}
                                        style={{fontSize: 15, fontWeight: 500, cursor: 'pointer'}}
                                    >
                                        Profil Einstellungen
                                    </LinkText>
                                </AccordionDetails>
                            </StyledAccordion>
                            <StyledAccordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRight sx={{ color: '#FFF' }} />}
                                    aria-controls="about-content"
                                    id="about-header"
                                >
                                    <span>About</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <a onClick={() => goToPage('/ueberuns')}>Über uns</a>
                                    <a onClick={() => goToPage('/impressum')}>Impressum</a>
                                </AccordionDetails>
                            </StyledAccordion>
                            <StyledAccordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRight sx={{ color: '#FFF' }} />}
                                    aria-controls="support-content"
                                    id="support-header"
                                >
                                    <span>Support</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <a onClick={() => goToPage('/kontakt')}>Kontakt</a>
                                    <a onClick={() => goToPage('/agb')}>AGB</a>
                                    <a onClick={() => goToPage('/datenschutz')}>Datenschutz</a>
                                    <a onClick={() => goToPage('/faq')}>Hilfe und FAQ</a>
                                </AccordionDetails>
                            </StyledAccordion>
                            <LinkWithImage onClick={() => goToPage('/partnership')}>
                                <LinkText className='linkText'>Partnership</LinkText>
                            </LinkWithImage>
                        </Box>
                        <Box height="100px">
                            <LinkWithImage onClick={() => {
                                logout();
                                navigate('/');
                                navigate(0);
                            }} style={{ border: '0' }}>
                                <LinkText className='linkText'>Ausloggen</LinkText>
                            </LinkWithImage>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box width="100%" flex={1} position="relative" pt="15px">
                            <StyledAccordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRight sx={{ color: '#FFF' }} />}
                                    aria-controls="support-content"
                                    id="support-header"
                                >
                                    <span>About</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <a onClick={() => goToPage('/ueberuns')}>Über uns</a>
                                    <a onClick={() => goToPage('/impressum')}>Impressum</a>
                                </AccordionDetails>
                            </StyledAccordion>
                            <StyledAccordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRight sx={{ color: '#FFF' }} />}
                                    aria-controls="support-content"
                                    id="support-header"
                                >
                                    <span>Support</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <a onClick={() => goToPage('/kontakt')}>Kontakt</a>
                                    <a onClick={() => goToPage('/agb')}>AGB</a>
                                    <a onClick={() => goToPage('/datenschutz')}>Datenschutz</a>
                                    <a onClick={() => goToPage('/faq')}>Hilfe und FAQ</a>
                                </AccordionDetails>
                            </StyledAccordion>
                            <LinkWithImage onClick={() => goToPage('/partnership')}>
                                <LinkText className='linkText'>Partnership</LinkText>
                            </LinkWithImage>
                        </Box>
                        <Box display="flex" flexDirection="column" width="100%">
                            <PrimaryButton onClick={() => setSignupModalOpen(true)} style={{ width: '100%', fontSize: '17px', padding: '12px 20px'  }}>Werde jetzt Mitglied</PrimaryButton>
                            <LinkWithImage onClick={openLoginModal} style={{ border: '0', textAlign: 'center' }}>
                                <LinkText className='linkText'>Einloggen</LinkText>
                            </LinkWithImage>
                        </Box>
                    </>
                )}
            </Container>
        </>
    </StyledDrawer>
}

export default MobileMenu;
