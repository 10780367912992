import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  backgroundColor: theme.palette.backgroundColors.neutralBackground2,
}));

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 980,

  [theme.breakpoints.down("sm")]: {
    width: '87%',
  }
}));

export const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 540,
  marginTop: 0,
  marginBottom: 44,

  [theme.breakpoints.down("sm")]: {
    width: '100%',
  },

  '.descriptionRichText p': {
    color: '#676767',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px'
  }
}));

export const Images = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyItems: 'flex-end',
  width: 540,
  marginTop: 49,
  marginBottom: 44,
  gap: 19,

  [theme.breakpoints.down("sm")]: {
    width: '100%',
    marginBottom: 0,
  }
}));

export const Image = styled.img(({ theme }) => ({
  display: 'flex',
  maxHeight: 78,
  alignSelf: 'flex-start',
}));
