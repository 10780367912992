import Footer from '../components/layout/footer';
import { default as FaqComponent } from '../components/static-pages/faq';

function Faq () {
  return (
    <>
      <FaqComponent />
      <Footer />
    </>
  );
}

export default Faq;
