// @ts-ignore
function CheckCircleIcon(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.99935 17.707C14.256 17.707 17.7077 14.2562 17.7077 9.9987C17.7077 5.74203 14.256 2.29036 9.99935 2.29036C5.74268 2.29036 2.29102 5.74203 2.29102 9.9987C2.29102 14.2562 5.74268 17.707 9.99935 17.707Z" stroke="#F05B4D" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.08398 10.3095L9.06232 12.287L13.0173 8.33203" stroke="#F05B4D" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CheckCircleIcon;
