import styled from "styled-components";

export const ContainerDiv = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: 0,
    paddingTop: '40px',

    [theme.breakpoints.down('lg')]: {
        height: '100%',
    },

    [theme.breakpoints.down('md')]: {
        paddingTop: '12px',
    },
}));

export const InnerContainerDiv = styled.div(({theme}) => ({
    width: '980px',
    marginLeft: '-60px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: '0px',
    },
}))

export const PageContainer = styled.div(({theme}) => ({
    // backgroundColor: '#FCFCFA',
    // height: "calc(100vh - 300px)",
}))

export const HeaderContainer = styled.div(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderBottom: `1px solid rgba(196, 196, 196, 0.6)`,
    boxSizing: "border-box",

    [theme.breakpoints.up('xs')]: {
        padding: '100px 0 33px 15px',
    },

    [theme.breakpoints.up('md')]: {
        padding: '144px 0 33px 15px',
    }
}))

export const HeaderContainerContent = styled.div(({theme}) => ({
    width: '920px',
    marginLeft: '-60px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        // padding: '0 30px',
        marginLeft: '0px',
    },
    fontFamily: ['Inter', 'normal'].join(',')
}))

export const HeadlineTitle = styled.div(({theme}) => ({
    color: '#0E0E0E',
    marginBottom: '5px',
    fontWeight: 800,

    [theme.breakpoints.up('xs')]: {
        fontSize: '28px',
        lineHeight: '34px',
    },

    [theme.breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '40px',
    }
}));

export const HeadlineSubtitle = styled.div(({theme}) => ({
    fontWeight: 400,
    color: '#676767',
    fontSize: '16px',
    lineHeight: '24px'
}));

// @ts-ignore
export const TabName = styled.div(({isActive}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    marginRight: '22px',
    cursor: 'pointer',

    color: `${isActive ? '#F05B4D' : '#0E0E0E'}`,
    borderBottom: `${isActive ? '2px solid #F05B4D' : ''}`
}));

export const TabNameContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(196, 196, 196, 0.6)',
    padding: '0 30px 12px 30px',
    [theme.breakpoints.down('lg')]: {
        overflowX: 'scroll',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
            display: 'none'
        }
    }
}));

// @ts-ignore
export const TabContentCategory = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    fontSize: '28px',
    color: '#F05B4D',
    lineHeight: '34px',
    textTransform: 'uppercase',
    borderBottom: '1px solid rgba(196, 196, 196, 0.6)',
}));

export const TabContent = styled.div(({theme}) => ({
    borderBottom: '1px solid rgba(223, 223, 223, 0.6)',
    padding: '10px 0',
    cursor: 'pointer',
    '&:hover': {
        'div': {
            color: '#F05B4D',
        }
    }
}));

export const TabContentName = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2A2A2A',
    marginBottom: '2px'
}));

export const TabContentDescription = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#676767'
}));

export const TabContentWrapper = styled.div(({theme}) => ({
    padding: '15px 30px',
}));

export const TabContentInnerWrapper = styled.div(({theme}) => ({
    marginBottom: '30px'
}));