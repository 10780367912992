import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import api from '../api'
import TokenService from '../utils/tokenService'

// @ts-ignore
/**
 * @typedef {Object} User
 * @property {string} token
 * @property {string} email
 * @property {string} name
 */

export const AuthContext = React.createContext({
  /**
   * @param {{ responseData: any }} loginData
   */
  login: ({ responseData }: { responseData: any }) => { /**/ },
  logout: () => { /**/ },
  refreshToken: () => { /**/ },
  getUserFromToken: () => { /**/ },
  isLoading: true,
  userProfile: undefined,
  /**
   * @type {null|User}
   */
  user: {
    token: '',
    email: '',
    name: '',
    userId: '',
    role: '',
  },
})

// @ts-ignore
export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [userProfile, setUserProfile] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true)

  const getUserFromToken = useCallback(async () => {
    const _user = TokenService.getUser()
    if (!_user) {
      setIsLoading(false)
      return
    }

    // @ts-ignore
    setUser(_user)
    setIsLoading(false)
  }, [])

  const login = useCallback(({ responseData }: { responseData: any, setIsLoading?: any }) => {
    if (responseData?.auth?.token) {
      TokenService.setUser(responseData.auth)
      // @ts-ignore
      setUser(TokenService.getUser())
    }

    if (responseData?.user) {
      setUserProfile(responseData.user);
    }
  }, [])

  const logout = useCallback(async () => {
    setIsLoading(true)
    TokenService.removeUser()
    setIsLoading(false)
  }, [])

  const refreshToken = useCallback(async () => {
    setIsLoading(true)
    const _refreshToken = TokenService.getRefreshToken()

    let response

    try {
      response = await api.post("/auth/refresh", {
        refreshToken: _refreshToken
      })
    } catch (error) {
      setIsLoading(false)
      return true
    }

    if (response.data.auth.token) {
      TokenService.setToken(response.data.auth.token)
      // @ts-ignore
      setUser(TokenService.getUser())
    }

    setIsLoading(false)
    return false
  }, [])

  // @ts-ignore
  const registerUser = useCallback(async ({ name, email, confirmationCode, password, passwordConfirmation }) => {
    setIsLoading(true)
    setIsLoading(false)
    return null
  })

  /**
   * Initial mounting effect
   */
  // @ts-ignore
  useEffect(() => {
    async function fetchData() {
      getUserFromToken()
    }

    fetchData()
  }, [])

  const value = useMemo(() => ({
    user,
    userProfile,
    login,
    logout,
    isLoading,
    refreshToken,
    getUserFromToken,
  }), [
    user,
    userProfile,
    login,
    logout,
    isLoading,
    refreshToken,
    getUserFromToken,
  ])

  return (
    // @ts-ignore
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
