// @ts-ignore
function PlayButtonIcon(props) {
  return(
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle opacity="0.85" cx="24.1548" cy="24.1548" r="24.1548" fill="#2E3436"/>
      <g filter="url(#filter0_dd_717_61470)">
        <path d="M35 24L17 34.3923L17 13.6077L35 24Z" fill="white"/>
      </g>
      <defs>
        <filter id="filter0_dd_717_61470" x="12.3548" y="9.89129" width="27.2903" height="30.0755" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.929032"/>
          <feGaussianBlur stdDeviation="0.464516"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_717_61470"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.929032"/>
          <feGaussianBlur stdDeviation="2.32258"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_717_61470" result="effect2_dropShadow_717_61470"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_717_61470" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}

export default PlayButtonIcon;
