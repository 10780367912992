import React, { ReactNode } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectProps } from '@mui/material/Select';
import { deTranslations } from '../../../../translations';
import { StyledInput, DropdownIcon, Div, StyledMenuItem, Placeholder } from './style';
import FormHelperText from '@mui/material/FormHelperText';

interface IProps extends SelectProps {
  label: string
  options: Option[]
  errorMessage?: string
}

interface Option {
  id: number
  value: string
}

// @ts-ignore
const SelectOne = ({ label, placeholder, options, errorMessage, ...rest }: IProps ) => {
  // @ts-ignore
  return (
    <Div>
      <InputLabel sx={{ color: '#8E9190', fontSize: 12, fontWeight: 400, marginLeft: 1, textTransform: 'uppercase' }} htmlFor={rest.id}>
        {label}
      </InputLabel>
      <Select
        id={rest.id}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.05), 0px 0.912px 0.912px rgba(0, 0, 0, 0.02)',
              borderRadius: '4px',
              bgcolor: '#FFF',
              '& .MuiMenu-list': {
                padding: 0,
              },
              '& .MuiMenuItem-root': {
                padding: 2,
                fontSize: 15,
                fontWeight: 400,
                lineHeight: 1.2,
                fontFamily: ['Inter', 'normal'].join(','),
                '&:hover': {
                  backgroundColor: '#C8D4F3',
                }
              },
            },
          },
        }}
        IconComponent={DropdownIcon}
        input={
          <StyledInput
            fullWidth={rest.fullWidth}
          />
        }
        // @ts-ignore
        renderValue={(value) => {
          if (value === 0) {
            return <Placeholder>{deTranslations.select_one_from_the_list}</Placeholder>;
          }

          return options.find(option => option.id === value)?.value;
        }}
        {...rest}
      >
        {options.map((option) => (
          <StyledMenuItem key={option.id} value={option.id}>{option.value}</StyledMenuItem>
        ))}
      </Select>
      <FormHelperText
        sx={{
          color: '#F05B4D',
          fontSize: 13,
          fontWeight: 400,
          marginLeft: 1.5
        }}
        error={errorMessage !== ''}
      >
        {errorMessage}
      </FormHelperText>
    </Div>
  )
};

export default SelectOne;
