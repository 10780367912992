import React, {useContext, useState} from 'react';
import {useFormik} from 'formik';
import {AuthContext} from '../../../context/authContext';
import WebinarAPI from '../../../api/webinar';
import {Form} from './style';
import Card from '../../common/card';
import Button from '../../common/buttons/primary-button';
import {deTranslations} from '../../../translations';
import ConfirmationTicket from '../../tickets/confirmation-ticket';
import {FirstRow, SecondRow, Title, WebinarInfo} from './style';
import TextWithLabel from '../../common/text-with-label';
import ErrorModal from '../../common/error-modal';
import {format} from '../../../utils/time';
import NotEligible from '../../signup/not-eligible';
import ProfileAPI from '../../../api/profile';
import {useNavigate} from "react-router-dom";
import WebinarLocationList from '../../menus/webinar-location-list';

// @ts-ignore
const Subscribe = ({webinarData, utmSource = null}) => {
    const [success, setSuccess] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const {user} = useContext(AuthContext);
    const [userProfile, setUserProfile] = React.useState({});
    const [isEligible, setIsEligible] = React.useState(undefined);
    const [attendance, setAttendance] = React.useState('virtual');
    const navigate = useNavigate();

    React.useEffect(() => {
        async function getUserProfile() {
            const [getUserProfileError, userProfileData] = await ProfileAPI.getCurrentUserProfile(user.userId);
            if (getUserProfileError) {
                setOpenModal(true);
                return;
            }

            setUserProfile(userProfileData);

            // @ts-ignore
            const isEligibleUser = webinarData.filterOccupations.find((eligibleOccupation) => {
                // @ts-ignore
                return eligibleOccupation.occupationId === userProfileData?.occupation;
            });

            if (isEligibleUser !== undefined) {
                // @ts-ignore
                setIsEligible(true);
            } else {
                // @ts-ignore
                setIsEligible(false);
            }
        }

        getUserProfile();
    }, [])


    const startDate = webinarData.startDate;
    const endDate = webinarData.endDate;

    const formattedDate = webinarData?.agenda?.entries?.length === 1
        ? `${format(endDate, 'd. MMMM yyyy')}`
        : `${format(startDate, 'd.')} + ${format(endDate, 'd.')} ${format(startDate, 'MMMM yyyy')}`;
    const formattedTime = format(startDate, 'HH:mm')
    // @ts-ignore
    const fullName = `${userProfile?.title} ${userProfile?.firstName} ${userProfile?.lastName}`;
    const webinarLocation = webinarData.type === 'virtual' ? 'Virtuell' : webinarData.location ? webinarData.location : '-';
    const formik = useFormik({
        initialValues: {},
        onSubmit: (async values => {
            const [subscribeError, subscribeResponse] = await WebinarAPI.subscribeToWebinar(webinarData?.Id);
            if (subscribeError) {
                setOpenModal(true);
                return;
            }
            if (webinarData != null) {
                setSuccess(true);
            } else {
                navigate('/videos')
            }
            // setSuccess(true);
        }),
    });

    async function subscribeOnButtonSubscribe() {
        // @ts-ignore
        const [subscribeError] = await Promise.all([WebinarAPI.subscribeToWebinar(webinarData?.id, utmSource, attendance)]);

        if (subscribeError[0]) {
            setOpenModal(true);
            return;
        }
        setSuccess(true);
    }

    // async function unsubscribeOnButtonSubscribe() {
    //   // @ts-ignore
    //   const [subscribeError] = await Promise.all([WebinarAPI.unsubscribeToWebinar(webinarData?.id)]);
    //
    //   if (subscribeError[0]) {
    //     setOpenModal(true);
    //     return;
    //   }
    //   setSuccess(true);
    // }

    // @ts-ignore
    return (
        <>
            {isEligible === true && (
                <>
                    <ErrorModal
                        open={openModal}
                        handleClose={() => setOpenModal(false)}
                        retry={formik.handleSubmit}
                        title="Es ist ein Fehler aufgetreten"
                        message="Beim Abonnieren des Webinars ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns."
                    />
                    {!success &&
                        <Card title={deTranslations.webinar_registration}>
                            <Form onSubmit={formik.handleSubmit}>
                                <>
                                    <Title>{webinarData.title}</Title>
                                    <WebinarInfo>
                                        <FirstRow>
                                            <TextWithLabel label={deTranslations.first_name}>{fullName}</TextWithLabel>
                                        </FirstRow>
                                        <SecondRow>
                                            <TextWithLabel label={deTranslations.date}>{formattedDate}</TextWithLabel>
                                            <TextWithLabel label={deTranslations.time}>{formattedTime}</TextWithLabel>
                                        </SecondRow>
                                    </WebinarInfo>
                                    {
                                        webinarLocation &&
                                        <WebinarLocationList currentSelection={attendance}
                                                             onPicked={setAttendance}
                                                             showLiveAttendanceOption={webinarData.type !== 'virtual'}
                                                             location={webinarLocation}/>
                                    }
                                    <Button style={{marginTop: 32,}}
                                            onClick={subscribeOnButtonSubscribe}>{deTranslations.attend_webinar}</Button>
                                </>
                            </Form>
                        </Card>
                    }
                    {success &&
                        <ConfirmationTicket
                            // @ts-ignore
                            title={userProfile.title}
                            // @ts-ignore
                            firstName={userProfile.firstName}
                            // @ts-ignore
                            lastName={userProfile.lastName}
                            // @ts-ignore
                            email={userProfile.email}
                            formattedDate={formattedDate}
                            formattedTime={formattedTime}
                            webinarTitle={webinarData.title}
                            webinarSubtitle={webinarData.subtitle}
                            coverURL={webinarData.coverURL}
                            isSubscribed={webinarData.isSubscribed}
                        />
                    }
                </>
            )}
            {isEligible === false && <NotEligible/>}
        </>
    );
}

export default Subscribe;
