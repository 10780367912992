import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  backgroundColor: '#E8E8E8',
}));

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 980,
  marginTop: 53,

  [theme.breakpoints.down("sm")]: {
    width: '87%',
    marginTop: 26,
  },
}));

export const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 540,
  marginBottom: 66,

  [theme.breakpoints.down("sm")]: {
    width: '100%',
    marginBottom: 41,
  },
}));
