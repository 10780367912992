import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useContext, useEffect } from 'react';
import YouTube from 'react-youtube';
import VideoAPI from '../../../api/video';
import { AuthContext } from '../../../context/authContext';
import { format } from '../../../utils/time';
import {
    Container,
    Div,
    VideoBox,
    VideoBoxDescription,
    VideoBoxInfoSpeakers,
    VideoBoxSpeaker,
    VideoBoxSpeakers,
    VideoBoxText
} from './style';
//@ts-ignore
import Player from '@vimeo/player';
import { useNavigate } from 'react-router-dom';
import SignupModal from '../../signup/signup-modal';

// @ts-ignore
const Hero = ({video}) => {

    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [showSignupModal, setShowSignupModal] = React.useState(false);

    useEffect(() => {
        const vimeoIframe = document.querySelector('#vimeo-video');

        if (vimeoIframe) {
            // @ts-ignore
            const vimeoPlayer = new Player(vimeoIframe);

            vimeoPlayer.on('play', () => {
                if (!user) {
                    setShowSignupModal(true);
                    vimeoPlayer.pause();
                }
            });
        }
    }, []);

    function extracted(video: any) {
        const url = video?.videoURL?.substring(video?.videoURL?.indexOf("/"));
        if (url.includes('&list=')) {
            return video?.videoURL?.substring(video?.videoURL?.indexOf("/") + 26).split('&list',)[0]
        } else {
            return video?.videoURL?.substring(video?.videoURL?.indexOf("/") + 26)
        }
    }

    const onReady = (event: any) => {
        if (user) {
            event.target.seekTo(video?.watching?.stoppedWatchingAt);
        }
    }

    const onEnd = (event: any) => {
        if (user) {
            videoPaused(0, true);
        }
    }

    const onPause = (event: any) => {
        if (user) {
            videoPaused(event.target.getCurrentTime(), false);
        }
    }

    const onPlay = (event: any) => {
        if (!user) {
            setShowSignupModal(true);
            event.target.pauseVideo();
        }
    }

    async function videoPaused(time: any, isEnd: any) {
        // @ts-ignore
        await Promise.all([VideoAPI.videoStopped(video?.id, time, isEnd)]);
    }

    const extractVimeoSource = (video: any) => {
        const vimeoExtractFirstPart = video?.videoURL.split('com/')[1].split('/')[0]
        const vimeoExtractSecondPart = video?.videoURL.split('com/')[1].split('/')[1]
        const vimeoSrc = 'https://player.vimeo.com/video/' + vimeoExtractFirstPart + '?h=' + vimeoExtractSecondPart + '&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed'

        return vimeoSrc
    }

    const titleLength = video?.title?.length
    const isVimeoVideo = video?.videoURL.includes('vimeo')

    return (
        <Div>
            <Container>
                <VideoBox>
                    {isVimeoVideo
                        ? <iframe id="vimeo-video"
                                  src={extractVimeoSource(video)}
                                  width={isMobile ? '100%' : "865"}
                                  height={isMobile ? '200' : "514"}
                                  frameBorder="0"
                                  allowFullScreen
                                  allow={`encrypted-media; ${user ? 'autoplay' : ''}`} />
                        : <YouTube
                            videoId={extracted(video)}
                            onReady={onReady}
                            onPlay={onPlay}
                            onEnd={onEnd}
                            onPause={onPause}
                            opts={{
                                height: !isMobile ? '514' : '200',
                                width: !isMobile ? '865' : '100%',
                                playing: 0,
                                loop: 1,
                                controls: 0,
                                rel: 0,
                                modestBranding: 1,
                                playerVars: {
                                    autoplay: 0
                                }
                            }}
                        />
                    }
                    <div style={{position: 'relative'}}>
                        <VideoBoxText style={{
                            fontSize: titleLength < 70
                                ? isMobile ? 22 : 24
                                : titleLength < 90
                                    ? isMobile ? 22 : 24
                                    : isMobile ? 22 : 24
                        }}>
                            {video?.title}
                        </VideoBoxText>
                        <VideoBoxDescription>{format(video?.publicationDate, 'dd. MMM yyyy.')} | {video?.source}
                            {video?.extraLabel ? <> | <a
                                    style={{textDecoration: 'none', color: 'white'}}
                                    href={video?.extraLabelURL}
                                    target="_blank">{video?.extraLabel}</a></> :
                                <></>
                            }
                        </VideoBoxDescription>
                        {video?.speakers?.length !== 0 &&
                          <VideoBoxSpeaker>SPEAKER</VideoBoxSpeaker>
                        }
                        <VideoBoxInfoSpeakers>
                            {video?.speakers?.map((speaker: any, index: any) => (
                                <VideoBoxSpeakers
                                    key={index}> {speaker?.speaker?.title + ' ' + speaker?.speaker?.firstName + ' ' + speaker?.speaker?.lastName} • </VideoBoxSpeakers>
                            ))}
                        </VideoBoxInfoSpeakers>
                    </div>
                </VideoBox>
            </Container>
            <SignupModal
                open={showSignupModal}
                handleClose={() => setShowSignupModal(false)}
                onAuthFinished={() => navigate(0)}
                doneFunction={() => navigate(0)}
            />
        </Div>
    )
}

export default Hero;
