import React from 'react';
import {
    Div,
    ButtonBoxRight,
    ButtonBoxLeft,
    Outline,
    HeadlineText,
    TextCategoriesDiv,
    VideoCategoriesDiv,
    VideoDiv,
    VideoDivTitle,
    VideoDivSourceDate,
    VideoDivSource,
    VideoDivDate,
    ButtonBoxElement,
    StepperElement,
    DescriptionTextDiv,
    Image,
    ImageFinishedWatching,
    VideoLength,
    VideoLengthNumber,
    ImageRightCaret,
    ImageLeftCaret
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DescriptionText from '../../webinar-landing/common/description-text';
import Stepper from '../../common/stepper';
import { format } from '../../../utils/time';
import VideoAPI from "../../../api/video";

// @ts-ignore
const ListOfVideos = ({ videoPlayId, listOfVideos, setVideo, description, title, browserURL, setNextURL, setNextTitle }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sizeOfScreen1600 = useMediaQuery('(min-width:1600px)');
    const sizeOfScreen1900 = useMediaQuery('(min-width:1900px)');
    const sizeOfScreen2200 = useMediaQuery('(min-width:2200px)');
    const sizeOfScreen2500 = useMediaQuery('(min-width:2500px)');
    const sizeOfScreen2800 = useMediaQuery('(min-width:2800px)');
    const sizeOfScreen3100 = useMediaQuery('(min-width:3100px)');

    const [start, setStart] = React.useState(0)
    const [last, setLast] = React.useState(5)
    const [countStep, setCountStep] = React.useState(0);
    const [step, setStep] = React.useState(1);
    const [highlightedVideo, setHighlightedVideo] = React.useState(0)
    const [touchStart, setTouchStart] = React.useState(null)
    const [touchEnd, setTouchEnd] = React.useState(null)

    React.useEffect(() => {
        setHighlightedVideo(videoPlayId.id)
    }, [videoPlayId])

    React.useEffect(() => {
        if (window.innerWidth >= 3200) {
            setLast(10)
            setCountStep(10)
        } else if (window.innerWidth >= 2800) {
            setLast(8)
            setCountStep(8)
        } else if (window.innerWidth >= 2400) {
            setLast(7)
            setCountStep(7)
        } else if (window.innerWidth >= 2000) {
            setLast(6)
            setCountStep(6)
        } else if (window.innerWidth >= 1600) {
            setLast(5)
            setCountStep(5)
        } else if (window.innerWidth >= 1200) {
            setLast(4)
            setCountStep(4)
        } else if (window.innerWidth >= 914) {
            setLast(3)
            setCountStep(3)
        } else if (window.innerWidth >= 640) {
            setLast(2)
            setCountStep(2)
        } else if (window.innerWidth < 640 && !isMobile) {
            setLast(1)
            setCountStep(1)
        } else if (isMobile) {
            setLast(listOfVideos?.length - 1)
        }
    }, [window.innerWidth, isMobile, listOfVideos])

    let videos = listOfVideos?.slice(start, last + 1)

    const videoClicked = (video: any) => {
        VideoAPI.getVideoById(video?.id).then((response: any) => {
            setVideo(response[1])
            setNextTitle(response[1].id + '/' + response[1].title)
            setNextURL(browserURL + response[1].id + '/' + response[1].title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', ''))
            setHighlightedVideo(response[1].id)
        })
        // setNextTitle(video?.title)
        // setNextURL(browserURL + video?.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', ''))
    }

    const setMoreVideos = () => {
        setStart(start + countStep)
        setLast(last + countStep)
        setStep( step + 1)
    }

    const setLessVideos = () => {
        setStart(start - countStep)
        setLast(last - countStep)
        setStep( step - 1)
    }

    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            if (start !== last) {
                setStart(start + 1)
            }
        }
        if (isRightSwipe) {
            if (start !== 0) {
                setStart(start - 1)
            }
        }
    }

    // @ts-ignore
    return (
        <Div>
            {/* @ts-ignore */}
            {start > 0 && !isMobile && <ButtonBoxLeft onClick={() => setLessVideos()} description={description == null || description === ''}>
                <ButtonBoxElement>
                    {/*<ImageLeftCaret src={'chevron.png'} />*/}
                    {'<'}
                </ButtonBoxElement>
              </ButtonBoxLeft>
            }
            {/* @ts-ignore */}
            {(listOfVideos?.length - (last - countStep) > 0 && !isMobile) && <StepperElement description={description == null || description === ''}>
                <Stepper isVideoPlayPage={true} steps={Math.floor(listOfVideos?.length/countStep) > 5
                    ? 5
                    : (Math.floor(listOfVideos?.length/countStep) >= 1
                        ? Math.floor(listOfVideos?.length/countStep) + 1
                        : 0)} step={step}/>
              </StepperElement>
            }
            <Outline onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                {!isMobile ?
                    <div><TextCategoriesDiv>
                        <HeadlineText>{title}</HeadlineText>
                    </TextCategoriesDiv>
                    <DescriptionTextDiv>
                        <DescriptionText style={{fontFamily: ['Inter', 'normal'].join(','),
                            fontSize: '15px',
                            lineHeight: '18px',
                            fontWeight: 400,
                            color: '#FCFCFC',
                            opacity: 0.7}}
                                         colorProps={ true }>{description}</DescriptionText>
                    </DescriptionTextDiv>
                    </div> :
                <TextCategoriesDiv>
                    <HeadlineText>{title}</HeadlineText>
                </TextCategoriesDiv>
                }
                <VideoCategoriesDiv>
                    {videos?.map((video: any, index: number) => (
                        <VideoDiv key={index} onClick={() => videoClicked(video)}
                                  style={{ backgroundColor: video?.id === highlightedVideo ? '#595959' : '#2A2A2A', height: video?.id === highlightedVideo ? '100%' : 'initial'}}>
                            <div style={{ position: 'relative' }}>
                                <Image src={video?.coverURL}/>
                                {video?.watching?.isFinishedWatching && <ImageFinishedWatching src={'video-finished-watching-png'} />}
                                <VideoLength style={{ width: video?.length/3600 > 1 ? 33 : video?.length/60 < 10 ? 24 : 27 }}>
                                    <VideoLengthNumber>
                                        {(video?.length/3600 > 1 ? (video?.length/3600).toFixed(0) + ':' : null)}
                                        {(video?.length%3600/60 < 10 ? '0' + (video?.length%3600/60).toFixed(0) : (video?.length%3600/60).toFixed(0))}
                                        :{video?.length%60 < 10 ? '0' + video?.length%60 : video?.length%60}
                                    </VideoLengthNumber>
                                </VideoLength>
                            </div>
                            <VideoDivTitle>
                                {video?.title}
                            </VideoDivTitle>
                            <VideoDivSourceDate>
                                <VideoDivSource>{video?.source}</VideoDivSource> • <VideoDivDate>{format(video?.publicationDate, 'dd. MMM yyyy.')}</VideoDivDate>
                            </VideoDivSourceDate>
                        </VideoDiv>
                    ))}
                </VideoCategoriesDiv>
            </Outline>
            {/* @ts-ignore */}
            {listOfVideos?.length > (last - 1) && !isMobile && <ButtonBoxRight onClick={() => setMoreVideos()} description={description == null || description === ''}>
                <ButtonBoxElement>
                    {/*<ImageRightCaret src={'chevron.png'} />*/}
                    {'>'}
                </ButtonBoxElement>
              </ButtonBoxRight>
            }
        </Div>
    )
}

export default ListOfVideos;