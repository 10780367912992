import styled from 'styled-components';
import { Swiper as _Swiper } from 'swiper/react';

export const SidemenuLink = styled.a(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#2A2A2A',
  textDecoration: 'none',

  '&:focus': {
    color: 'red'
  }
}));

export const Section = styled.div(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: '46px',

    [theme.breakpoints.down('md')]: {
      marginBottom: '32px'
    },
  }
}));

export const SectionTitle = styled.h2(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#2A2A2A',
}));

export const SectionText = styled.p(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#404040',
}));

export const Swiper = styled(_Swiper)(({ theme }) => ({
  height: '100%',

  '.swiper-slide': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingRight: '12px',

    '&:after': {
      content: '""',
      height: '20px',
      width: '2px',
      backgroundColor: '#000',
      position: 'absolute',
      right: '0'
    }
  }
}));

export const Title = styled.h1(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 800,
  fontSize: '40px',
  lineHeight: '48px',
  color: '#404040',
  wordBreak: 'break-word',
  margin: '0 0 8px 0',

  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
    lineHeight: '34px'
  },
}));

export const Subtitle = styled.span(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '18px',
  color: '#676767'
}));
