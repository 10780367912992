import styled from 'styled-components';
import { Swiper as _Swiper } from 'swiper/react';
import AccordionSummary from "@mui/material/AccordionSummary";

export const SidemenuLink = styled.a(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#2A2A2A',
  textDecoration: 'none',

  '&:focus': {
    color: 'red'
  }
}));

export const Section = styled.div(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: '88px',

    [theme.breakpoints.down('md')]: {
      marginBottom: '80px'
    },
  }
}));

export const SectionTitle = styled.h2(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '18px',
  color: '#F05B4D',
  textTransform: 'uppercase'
}));

export const SectionText = styled.p(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#404040',
}));

export const Swiper = styled(_Swiper)(({ theme }) => ({
  height: '100%',

  '.swiper-wrapper': {
    alignItems: 'center'
  },

  '.swiper-slide': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingRight: '12px',

    '&:after': {
      content: '""',
      height: '20px',
      width: '2px',
      backgroundColor: '#000',
      position: 'absolute',
      right: '0'
    }
  }
}));

export const Title = styled.h1(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 800,
  fontSize: '40px',
  lineHeight: '48px',
  color: '#404040',
  wordBreak: 'break-word',
  margin: '0 0 24px 0',

  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
    lineHeight: '34px',
    marginBottom: '8px'
  },
}));

export const Subtitle = styled.span(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '18px',
  color: '#676767'
}));

export const SectionSubtitle = styled.h3(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px'
}));

export const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  }
}));

export const Sections = styled.div(({ theme }) => ({
  '.MuiAccordion-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px solid #EBEAE9',
    marginBottom: '0',
    borderRadius: '0 !important',

    '&:before': {
      display: 'none',
    },

    [theme.breakpoints.down("md")]: {
      margin: '0',
    },
  },

  '.MuiAccordionSummary-root': {
    paddingLeft: '0px',
    paddingRight: '0px',

    '.MuiTypography-root': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
      fontFamily: 'Inter',
      maxWidth: '100%'
    }
  },

  '.MuiAccordionDetails-root': {
    padding: '8px 16px 16px 0',

    '.MuiTypography-root': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 400,
      fontFamily: 'Inter',

      [theme.breakpoints.down("md")]: {
        maxWidth: '100%'
      },
    }
  },

  '.MuiAccordion-root.Mui-expanded': {
    margin: 0,

    '.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'auto',

      '.MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0'
      }
    },

    [theme.breakpoints.down("md")]: {
      margin: '0px',
    },
  }
}));
