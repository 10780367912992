import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Content,
    TextCategoriesDiv,
    Header,
    HeadlineText,
    ImageLogo,
    VideoCategoriesDiv,
    VideoDiv,
    VideoDivDate,
    VideoDivTitle,
    VideoImage,
    VideoLength,
    VideoLengthNumber,
    ContactButton,
    ContactButtonText,
    VideoDivContent,
    VideoDivDFPPoints,
    VideoDivDFPPointsText,
    VideoDivPublicationDate
} from './style';
import DescriptionText from '../webinar-landing/common/description-text';
import HorizontalRule from '../common/horizontal-rule';
import { format } from '../../utils/time';
import PlayButtonIcon from '../icons/play-button-icon';
import VideoAPI from '../../api/video';
import { AuthContext } from "../../context/authContext";
import SignupModal from "../signup/signup-modal";
import { ImageLoading } from "../video-landing/hero/style";
import FeedAPI from "../../api/feed";
import { deTranslations } from "../../translations";
import DFPIcon from "../icons/dfp-icon";
import DFPSuccessMessage from "../common/dfp-success-message/DFPSuccessMessage";

// @ts-ignore
const DFPChannelsLanding = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { id, title } = useParams();
    const [channelInfo, setChannelInfo] = React.useState(Object);
    const [listOfData, setListOfData] = React.useState([]);
    const [showPlayButton, setShowPlayButton] = React.useState(false);
    const [keyPlayButton, setKeyPlayButton] = React.useState(0);
    const [innerWindowSize, setInnerWindowSize] = React.useState(0);
    const { user } = useContext(AuthContext);
    const [signupModalOpen, setSignupModalOpen] = React.useState(false);
    const type = 'channels';
    const [isDataLoading, setDataLoading] = useState(true);

    React.useEffect(() => {
        // This is used for courses where enrollment has already started
        if (id === '92634') {
            FeedAPI.getDFPVideoFeed().then((response: any) => {
                if (response[1] && response[1].length > 0) {
                    let enrolledVideoFeed = response[1].find((item: any) => {
                        return item?.type === 'dfp_course' && item?.headline === 'dfp_course_list_enrolled';
                    });

                    if (enrolledVideoFeed) {
                        setChannelInfo({
                            id: '92634',
                            name: deTranslations.my_dfp_courses
                        });

                        VideoAPI.getAllDfpVideos(enrolledVideoFeed?.filter).then((response: any) => {
                            setListOfData(response[1]);
                        }).finally(() => {
                            setDataLoading(false);
                        });
                    }
                }
            });
        } else {
            VideoAPI.getChannelById(id).then((response: any) => {
                setChannelInfo(response[1]);
            })

            let filter = `?join=video&join=channels&filter=channels.channelId||$eq||${id}&sort=updatedAt,DESC`;

            VideoAPI.getAllDfpVideos(filter).then((response: any) => {
                setListOfData(response[1]);
            }).finally(() => {
                setDataLoading(false);
            });
        }

    }, [type, id, title, location])

    React.useEffect(() => {
        if (window.innerWidth >= 3200) {
            setInnerWindowSize(140);
        } else if (window.innerWidth >= 2133) {
            setInnerWindowSize(230);
        } else if (window.innerWidth >= 2000) {
            setInnerWindowSize(250);
        } else if (window.innerWidth >= 1600) {
            setInnerWindowSize(270);
        } else if (window.innerWidth >= 1455) {
            setInnerWindowSize(335);
        } else if (window.innerWidth >= 1280) {
            setInnerWindowSize(360);
        } else if (window.innerWidth >= 1067) {
            setInnerWindowSize(470);
        }
    }, [window.innerWidth]);

    const navigateToPage = (video: any) => {
        if (!user) {
            setSignupModalOpen(true);
        }

        navigate('/dfp-video-play' + '/' + 'channels' + '/' + channelInfo.id + '/' + video?.id + '/' + video?.video?.id + '/' +
            video?.video?.title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', ''));
    }

    const hoverShowPlayButton = (shouldShow: any, key: any) => {
        setShowPlayButton(shouldShow);
        setKeyPlayButton(key);
    }

    // @ts-ignore
    // const listOfVideos = listOfData?.hasOwnProperty('type') ? listOfData?.type === 'video' ? listOfData?.videos.map((video) => video.video) : [] : listOfData?.filter((item) => item.hasOwnProperty('videoURL'));
    // const numberOfData = listOfVideos.length;

    // @ts-ignore
    return (
        <>
            <SignupModal
                open={signupModalOpen}
                handleClose={() => setSignupModalOpen(false)}
                doneFunction={() => navigate(0)}
            />
            <Header>
                <TextCategoriesDiv>
                    <HeadlineText>{channelInfo?.name}</HeadlineText>
                    <ImageLogo src={channelInfo?.logoURL} />
                </TextCategoriesDiv>
                <DescriptionText>{channelInfo?.description}</DescriptionText>
            </Header>
            {/* @ts-ignore */}
            <HorizontalRule lighter={true} />
            {/* @ts-ignore */}
            {isDataLoading
                ? <ImageLoading src={'/loading-transparent-gif.gif'} />
                :
                <Content>
                    <VideoCategoriesDiv>
                        {listOfData?.map((video: any, index: number) => {
                            const videoLength = video?.video?.length ?? 0;

                            return (
                                // @ts-ignore
                                <VideoDiv key={index}
                                          onMouseEnter={() => hoverShowPlayButton(true, index)}
                                          onMouseLeave={() => hoverShowPlayButton(false, index)}>
                                    <div style={{ position: 'relative' }}>
                                        <VideoImage
                                            style={{ maxHeight: (showPlayButton && keyPlayButton === index) ? 190 : 170 }}
                                            onClick={() => navigateToPage(video)} src={video?.video?.coverURL} />
                                        {(showPlayButton && keyPlayButton === index) &&
                                        <PlayButtonIcon style={{
                                            width: 49,
                                            height: 49,
                                            position: 'absolute',
                                            top: '40%',
                                            left: '42%'
                                        }} onClick={() => navigateToPage(video)}/>}

                                        <VideoLength
                                            style={{ width: videoLength / 3600 > 1 ? 41 : videoLength / 60 < 10 ? 28 : 28 }}>
                                            <VideoLengthNumber>
                                                {(videoLength / 3600 > 1 ? Math.floor(videoLength / 3600) + ':' : null)}
                                                {(videoLength % 3600 / 60 <= 9 ? '0' + Math.floor(videoLength % 3600 / 60) : Math.floor(videoLength % 3600 / 60))}
                                                :{videoLength % 60 < 10 ? '0' + videoLength % 60 : videoLength % 60}
                                            </VideoLengthNumber>
                                        </VideoLength>
                                    </div>

                                    <VideoDivContent>
                                        <VideoDivTitle>
                                            {video?.title ?
                                                video?.title.length <= 65 ? video?.title : <span title={video?.title}>{video?.title.substring(0, 65)}(...)</span>
                                                : <></>
                                            }
                                        </VideoDivTitle>

                                        <VideoDivDate>
                                            <VideoDivPublicationDate>{video?.video?.publicationDate ? format(video?.video?.publicationDate, 'dd. MMMM yyyy') : ''}</VideoDivPublicationDate>
                                        </VideoDivDate>

                                        {video?.dfpPoints ? <VideoDivDFPPoints>
                                            <DFPIcon style={{width: 17.5}}/>
                                            <VideoDivDFPPointsText>{video?.dfpPoints === 1 ? '1 DFP Punkt' : `${video?.dfpPoints} DFP Punkte`}</VideoDivDFPPointsText>
                                        </VideoDivDFPPoints> : <></>
                                        }

                                        {video?.enrollment?.status === 'passed' ?
                                            <DFPSuccessMessage type={'success'}
                                                               onClick={() => navigateToPage(video)}/> : video?.enrollment?.status === 'failed' ?
                                                <DFPSuccessMessage type={'failure'}
                                                                   onClick={() => navigateToPage(video)}/>
                                                : <ContactButton onClick={() => navigateToPage(video)}>
                                                    <ContactButtonText>
                                                        DFP Kurs starten
                                                    </ContactButtonText>
                                                </ContactButton>
                                        }

                                    </VideoDivContent>
                                </VideoDiv>
                            )
                        })}
                    </VideoCategoriesDiv>
                </Content>
            }
        </>
    )
}

export default DFPChannelsLanding;
