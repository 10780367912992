import React from 'react';
import {
    Div,
    ButtonBoxRight,
    ButtonBoxLeft,
    Outline,
    ImageLoading,
    HeadlineText,
    LinkToSeeMore,
    LinkTo,
    TextCategoriesDiv,
    StraightLine,
    Image,
    VideoCategoriesDiv,
    VideoDiv,
    VideoDivTitle,
    VideoDivDate,
    VideoDivSource,
    VideoDivPublicationDate,
    ButtonBoxElement,
    StepperElement,
    VideoImage,
    VideoLength,
    VideoLengthNumber,
    ImageRightCaret,
    ImageLeftCaret
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DescriptionText from '../../webinar-landing/common/description-text';
import ForwardButton from '../../common/buttons/forward-button';
import Stepper from '../../common/stepper';
import { format } from '../../../utils/time';
import { useNavigate } from 'react-router-dom';
import VideoAPI from '../../../api/video';
import { deTranslations } from '../../../translations';
import Canvas from '../../../utils/Canvas';
import PlayButtonIcon from '../../icons/play-button-icon';
import ProgressBar from "../../common/progress-bar";

// @ts-ignore
const VideoChannel = ({ filter, filterMore, headlineValue, description, logo, channelId }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();
    const [data, setData] = React.useState([]);
    const [bigData, setBigData] = React.useState([]);
    const [start, setStart] = React.useState(0)
    const [last, setLast] = React.useState( 5)
    const [step, setStep] = React.useState(1);
    const [showPlayButton, setShowPlayButton] = React.useState(false);
    const [keyPlayButton, setKeyPlayButton] = React.useState(0);
    const [countStep, setCountStep] = React.useState(0);
    const [touchStart, setTouchStart] = React.useState(null)
    const [touchEnd, setTouchEnd] = React.useState(null)
    const [loadDone, setLoadDone] = React.useState(false)

    React.useEffect(() => {
        // @ts-ignore
        VideoAPI.getAllVideos(filter).then( (response: any)=> {
            setData(response[1]);
            setLoadDone(true);
        });

        VideoAPI.getAllVideos(filterMore).then( (response: any)=> {
            setBigData(response[1]);
        });
    }, [filter, filterMore]);

    React.useEffect(() => {
        if (window.innerWidth >= 3200) {
            setLast(10);
            setCountStep(10);
        } else if (window.innerWidth >= 2800) {
            setLast(8);
            setCountStep(8);
        } else if (window.innerWidth >= 2400) {
            setLast(7);
            setCountStep(7);
        } else if (window.innerWidth >= 2000) {
            setLast(6);
            setCountStep(6);
        } else if (window.innerWidth >= 1600) {
            setLast(5);
            setCountStep(5);
        } else if (window.innerWidth >= 1200) {
            setLast(4);
            setCountStep(4);
        } else if (window.innerWidth >= 914) {
            setLast(3);
            setCountStep(3);
        } else if (window.innerWidth >= 640) {
            setLast(2);
            setCountStep(2);
        } else if (window.innerWidth < 640 && !isMobile) {
            setLast(1);
            setCountStep(1);
        } else if (isMobile) {
            setLast(data?.length - 1);
        }
    }, [window.innerWidth, isMobile, data]);

    let videos = data?.slice(start, last + 1);

    const seeMore = () => {
        if (headlineValue === deTranslations.video_list_to_continue_watching) {
            navigate('/' + 'toContinue' + '/' + '96745' + '/' + headlineValue.replaceAll(' ', ''). replaceAll('/', ''));
        } else {
            navigate('/' + 'channels' + '/' + channelId + '/' + headlineValue.replaceAll(' ', ''). replaceAll('/', ''));
        }
    }

    const navigateToPage = (video: any) => {
        if (headlineValue === deTranslations.video_list_to_continue_watching) {
            navigate('/video-play' + '/' + 'toContinue' + '/' + '96745' + '/' + video.id + '/' +
                video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', ''));
        } else {
            navigate('/video-play' + '/' + 'channels' + '/' + channelId + '/' + video.id + '/' +
                video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', ''));
        }
    }

    const setMoreVideos = () => {
        setStart(start + countStep);
        setLast(last + countStep);
        setStep( step + 1);
    }

    const setLessVideos = () => {
        setStart(start - countStep);
        setLast(last - countStep);
        setStep( step - 1);
    }

    const hoverShowPlayButton = (shouldShow: any, key: any) => {
        setShowPlayButton(shouldShow);
        setKeyPlayButton(key);
    }

    const minSwipeDistance = 50;

    const onTouchStart = (e: any) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            if (start !== last) {
                setStart(start + 1);
            }
        }

        if (isRightSwipe) {
            if (start !== 0) {
                setStart(start - 1);
            }
        }
    }

    return ((headlineValue !== deTranslations.video_list_to_continue_watching || data?.length !== 0) &&
        <Div style={{ height: isMobile && (description == null || description === '')
                ? 365
                : isMobile && !(description == null || description === '')
                    ? 425
                    : 395}}>
            {/* @ts-ignore */}
            {start > 0 && !isMobile && <ButtonBoxLeft onClick={() => setLessVideos()} description={description == null || description === ''}>
                <ButtonBoxElement>
                  <ImageLeftCaret src={'chevron.png'} />
                </ButtonBoxElement>
              </ButtonBoxLeft>
            }
            {/* @ts-ignore */}
            {(!isMobile && (data?.length - (last - countStep) >= 0)) && <StepperElement description={description == null || description === ''}>
                <Stepper isVideoPlayPage={false} steps={Math.floor(data?.length/countStep) > 5
                    ? 5
                    : (Math.floor(data?.length/countStep) >= 1
                        ? Math.floor(data?.length/countStep) + 1
                        : 0)} step={step}/>
              </StepperElement>
            }
            {!loadDone
                ? <ImageLoading src={'loading-transparent-gif.gif'}/>
                : <Outline onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                    {!isMobile ? <div>
                        <TextCategoriesDiv>
                            <HeadlineText>{headlineValue}</HeadlineText>
                            <LinkTo>
                                <LinkToSeeMore onClick={() => seeMore()}>Mehr Sehen</LinkToSeeMore>
                                <ForwardButton sx={{ flex: 'none',
                                    order: 0,
                                    flexGrow: 0,
                                    width: 12,
                                    height: 8,
                                    marginLeft: -3.5
                                }} onClick={() => seeMore()} isLanding={true} children={null}/>
                                {headlineValue !== deTranslations.video_list_to_continue_watching
                                    ? <StraightLine/>
                                    : null
                                }
                                {logo != null ? <Image src={logo}/> : null}
                            </LinkTo>
                        </TextCategoriesDiv>
                        <DescriptionText isMobile={isMobile} style={{
                            marginTop:15,
                            fontFamily: ['Inter', 'normal'].join(','),
                            fontWeight: 400,
                            fontSize: 16,
                            color: '#676767' }}>{description}</DescriptionText>
                    </div> : <TextCategoriesDiv>
                        <div>
                            {logo != null ? <Image src={logo}/> : null}
                        </div>
                        <HeadlineText>{headlineValue}</HeadlineText>
                        {(description === '' || description == null) ? null
                            :
                            <DescriptionText isMobile={isMobile} style={{
                                marginTop:15,
                                fontFamily: ['Inter', 'normal'].join(','),
                                fontWeight: 400,
                                fontSize: 16,
                                color: '#676767' }}>{description}</DescriptionText>}
                        <LinkTo>
                            <LinkToSeeMore onClick={() => seeMore()}>Mehr Sehen</LinkToSeeMore>
                            <ForwardButton sx={{ flex: 'none',
                                order: 0,
                                flexGrow: 0,
                                width: 12,
                                height: 8,
                                marginLeft: -3.5
                            }} onClick={() => seeMore()} isLanding={true} children={null}/>
                        </LinkTo>
                    </TextCategoriesDiv>
                    }

                    <VideoCategoriesDiv>
                        {videos?.map((video: any, index: number) => (
                            <VideoDiv key={index}
                                      onMouseEnter={() => hoverShowPlayButton(true, index)}
                                      onMouseLeave={() => hoverShowPlayButton(false, index)}>
                                <div style={{ position: 'relative' }}>
                                        <VideoImage onClick={() => navigateToPage(video)} src={video.coverURL} style={{ position: 'relative', maxHeight: (showPlayButton && keyPlayButton === index) ? 190 : 170 }} />
                                        {headlineValue === deTranslations.video_list_to_continue_watching &&
                                            <ProgressBar value={isNaN(video?.watching?.stoppedWatchingAt) ? 0 : video?.watching?.stoppedWatchingAt} max={isNaN(video?.length) ? 100 : video?.length}/>
                                        }

                                    {(showPlayButton && keyPlayButton === index) &&
                                      <PlayButtonIcon style={{width: 49, height: 49, position: 'absolute', top: '40%', left: '42%'}}
                                                      onClick={() => navigateToPage(video)}/>}


                                    <VideoLength style={{ width: video?.length/3600 > 1 ? 33 : video?.length/60 < 10 ? 24 : 27 }}>
                                        <VideoLengthNumber>
                                            {(video?.length/3600 > 1 ? Math.floor(video?.length/3600) + ':' : null)}
                                            {(video?.length%3600/60 <= 9 ? '0' + Math.floor(video?.length%3600/60) : Math.floor(video?.length%3600/60))}
                                            :{video?.length%60 < 10 ? '0' + video?.length%60 : video?.length%60}
                                        </VideoLengthNumber>
                                    </VideoLength>
                                </div>
                                <VideoDivTitle>
                                    {video?.title}
                                </VideoDivTitle>
                                <VideoDivDate>
                                    <VideoDivSource>{video?.source}</VideoDivSource><div style={{ marginTop: 1}}> • </div>
                                    <VideoDivPublicationDate>{format(video?.publicationDate, 'dd. MMM yyyy.')}</VideoDivPublicationDate>
                                </VideoDivDate>
                            </VideoDiv>
                        ))}
                    </VideoCategoriesDiv>
                </Outline>
            }
            {/* @ts-ignore */}
            {data?.length > (last - 1) && !isMobile && <ButtonBoxRight onClick={() => setMoreVideos()} description={description == null || description === ''}>
                <ButtonBoxElement>
                  <ImageRightCaret src={'chevron.png'} />
                </ButtonBoxElement>
              </ButtonBoxRight>
            }
        </Div>
    )
}

export default VideoChannel;
