import React from 'react';
import Footer from '../components/layout/footer';
import DFPVideoPlayLanding from "../components/dfp-video-play-landing";

function DFPVideoPlayLandingPage() {
    return (
        <>
            <DFPVideoPlayLanding/>
            <Footer/>
        </>
    );
}

export default DFPVideoPlayLandingPage;