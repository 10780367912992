import React from 'react';
import {
    Card,
    TextContent,
    Title,
    Name,
    Occupation,
    Image,
} from './style';

// @ts-ignore
const AgendaDay = ({speaker}) => {
    return (
        <Card>
            <Image src={speaker.avatarURL}/>
            <TextContent>
                <Title>{speaker.title}</Title>
                <Name>{speaker.firstName} {speaker.lastName}</Name>
                <Occupation>{speaker.occupation}</Occupation>
            </TextContent>
        </Card>
    )
}

export default AgendaDay;