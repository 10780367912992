import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: -60,
  [theme.breakpoints.down("sm")]: {
    marginTop: -80
  },
}));

export const TicketBackground = styled.div(({ theme }) => ({
  width: 420,
  height: 660,
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='414' height='654' viewBox='0 0 414 654' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_dd_58_44647)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M57 72C57 62.0589 65.0589 54 75 54H339C348.941 54 357 62.0589 357 72V419.181C357 422.427 354.379 425.008 351.244 425.851C341.573 428.451 334.504 436.711 334.504 446.5C334.504 456.289 341.573 464.549 351.244 467.149C354.379 467.992 357 470.573 357 473.819V576C357 585.941 348.941 594 339 594H75C65.0589 594 57 585.941 57 576V473.819C57 470.573 59.6211 467.992 62.7562 467.149C72.4271 464.549 79.4955 456.289 79.4955 446.5C79.4955 436.711 72.4271 428.451 62.7562 425.851C59.6211 425.008 57 422.427 57 419.181V72Z' fill='%23FCFBF7'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_dd_58_44647' x='0.639999' y='0.375999' width='412.72' height='652.72' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='1' dy='0.912'/%3E%3CfeGaussianBlur stdDeviation='0.456'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_58_44647'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='2.736'/%3E%3CfeGaussianBlur stdDeviation='28.18'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow_58_44647' result='effect2_dropShadow_58_44647'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_58_44647' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  content: '""',
}));

export const Img = styled.img(({ theme }) => ({
  display: 'flex',
  height: 185,
  width: 274
}));

export const TicketCanvas = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 73,
  height: 510,
}));

export const Title = styled.h3(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.textColors.title,
  fontFamily: ['Inter', 'normal'].join(','),
  fontSize: 22,
  fontWeight: 400,
  lineHeight: 1.15,
  marginTop: 8,
  marginBottom: 4,
}));

export const Subtitle = styled.h4(({ theme }) => ({
  textAlign: 'center',
  color: '#676767',
  fontFamily: ['Inter', 'normal'].join(','),
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 1.2,
  marginTop: 0,
  marginBottom: 13,
}));

export const Hr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.separator}`,
  width: '100%',
}));

export const WebinarInfo = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 229,
  marginTop: 18.5,
}));

export const FirstRow = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
}));

export const SecondRow = styled.div(({ theme }) => ({
  marginTop: 16,
  display: 'flex',
  justifyContent: 'space-between',
  marginRight: 13,
}));

export const DashedHr = styled.div(({ theme }) => ({
  marginTop: 0,
  // border: '1px dashed #DFDFDF',
  // backgroundImage: 'linear-gradient(to right, #DFDFDF 50%, rgba(255,255,255,0) 0%)',
  // backgroundPosition: 'bottom',
  // backgroundSize: '20px 7px',
  // backgroundRepeat: 'repeat-x',
  width: 227,
  height: 1,
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='227' height='1' viewBox='0 0 227 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='0.5' x2='227' y2='0.5' stroke='%23DFDFDF' stroke-dasharray='8 8'/%3E%3C/svg%3E%0A")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  content: '""',
}));

export const ConfirmationText = styled.div(({ theme }) => ({
  width: 216,
  marginTop: 34,
  marginRight: 13,
  display: 'inline',
}));

export const Text = styled.p(({ theme }) => ({
  margin: 0,
  color: '#676767',
  fontFamily: ['Inter', 'normal'].join(','),
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 1.15,
}));

export const BoldTextBottom = styled.p(({ theme }) => ({
  color: '#676767',
  fontFamily: ['Inter', 'normal'].join(','),
  fontSize: 13,
  fontWeight: 700,
  lineHeight: 1.15,
}));
