import React from 'react';
import { Div, VisitedStep, NonVisitedStep } from './style';

// @ts-ignore
const Stepper = ({isVideoPlayPage, steps, step} ) => {
  const stepsArray = new Array(steps).fill(0);
  // @ts-ignore
  return (
    <Div>
      {stepsArray.map((_, index: number) => {
        if (index < step) {
          return <VisitedStep style={{ background: isVideoPlayPage ? '#FFFFFF' : '#063F58' }} key={index} />
        }

        return <NonVisitedStep key={index} />
      })}
    </Div>
  )
};

export default Stepper;
