import React, {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import LocationAPI from '../../../api/location';
import UserAPI from '../../../api/user';
import WebinarAPI from '../../../api/webinar';
import {Form} from './style';
import Card from '../../common/card';
import BackButton from '../../common/buttons/back-button';
import SearchList from '../../menus/search-list';
import TextField from '../../common/fields/text-field';
import Button from '../../common/buttons/primary-button';
import Stepper from '../../common/stepper';
import {deTranslations} from '../../../translations';
import {format} from '../../../utils/time';
import ErrorModal from '../../common/error-modal';
import ConfirmationTicket from '../../tickets/confirmation-ticket';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../../context/authContext";
import SignupOnboardingWidget from "../signup-onboarding-widget";
import {OnboardingContainer} from "./style";

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required(`${deTranslations.first_name_first_uppercased} ${deTranslations.must_not_empty}`),
    lastName: Yup.string()
        .required(`${deTranslations.last_name_first_uppercased} ${deTranslations.must_not_empty}`),
    title: Yup.string()
        .min(2, deTranslations.title_short_warning)
        .notRequired(),
});

const titleMap = new Map([
    [1, deTranslations.which_university_study],
    [2, deTranslations.your_personal_info],
])

const getTitle = (step: number) => {
    return titleMap.get(step);
}

// @ts-ignore
const Student = ({initialValues, goBack, profileId, subscriptionToken, webinarData, doneFunction, onAuthFinished, videoData, hideOnboardingWidget, setSubscriptionToken}) => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const {login, userProfile} = useContext(AuthContext);
    const valuesForLogin = {
        email: initialValues.email,
        password: initialValues.password
    }
    const formik = useFormik({
        initialValues: {
            location: 0,
            occupation: initialValues.occupation,
            email: initialValues.email,
            password: initialValues.password,
            firstName: '',
            lastName: '',
            title: '',
        },
        validationSchema: validationSchema,
        onSubmit: (async values => {
            const [userError, user] = await UserAPI.createUser(values);
            if (userError) {
                if (userError === 'E-Mail wird bereits verwendet') {
                    formik.setErrors({
                        email: 'E-Mail wird bereits verwendet',
                    });
                } else {
                    setOpenModal(true);
                }
                return;
            }

            if (videoData != {} && videoData != null) {
                await UserAPI.loginUser(valuesForLogin).then((response) => login({responseData: response[1]}));
                navigate('' + videoData + '')
            } else if (webinarData !== undefined) {
                await UserAPI.loginUser(valuesForLogin).then((response) => {
                    login({responseData: response[1]});
                    if (response[1] && response[1].user && response[1].user.subscriptionToken && setSubscriptionToken) {
                        setSubscriptionToken(response[1].user.subscriptionToken);
                    }
                });
                const [subscribeError, subscribeResponse] = await WebinarAPI.subscribeToWebinar(webinarData?.Id);
                if (subscribeError) {
                    setOpenModal(true);
                    return;
                }

                setSuccess(true);
            } else {
                await UserAPI.loginUser(valuesForLogin).then((response) => login({responseData: response[1]}));

                if (onAuthFinished) {
                    onAuthFinished();
                } else {
                    navigate('/videos')
                    doneFunction();
                }
            }
        }),
    });

    let formattedDate: string;
    let formattedTime: string;

    if (webinarData !== undefined) {
        const startDate = webinarData.startDate;
        const endDate = webinarData.endDate;
        formattedDate = (`${format(startDate, 'd.')} + ${format(endDate, 'd.')} ${format(startDate, 'MMMM yyyy')}`);
        formattedTime = format(startDate, 'HH:mm');
    }

    const [universities, setUniversities] = useState([]);

    // @ts-ignore
    useEffect(() => {
        async function fetchData() {
            const [universityError, universities] = await LocationAPI.getAllUniversityNamesAndIds();

            setUniversities(universities);
        }

        fetchData();
    }, []);

    return (
        <>
            <ErrorModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                retry={formik.handleSubmit}
                title="Es ist ein Fehler aufgetreten"
                message="Beim Abonnieren des Webinars ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns."
            />
            {!success &&
              // @ts-ignore
            <OnboardingContainer hideOnboardingWidget={hideOnboardingWidget}>
                <Card title={getTitle(step)}>
                    <Form onSubmit={formik.handleSubmit}>
                        {step === 1 &&
                        <SearchList
                            list={universities}
                            onPicked={(location: number) => {
                                formik.setFieldValue('location', location);
                                setStep(2);
                            }}
                            sx={{
                                maxHeight: 324,
                                minHeight: 324,
                                overflow: 'auto',
                                marginRight: -3,
                            }}
                            sm={{
                                smTop: 0,
                                smBottom: '6px'
                            }}
                        />
                        }
                        {step === 2 &&
                        <>
                            <TextField
                                fullWidth={true}
                                id='firstName'
                                name='firstName'
                                label={deTranslations.first_name}
                                placeholderText={deTranslations.enter_first_name}
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                errorMessage={formik.touched.firstName ? formik.errors.firstName : ''}
                            />
                            <TextField
                                fullWidth={true}
                                id='lastName'
                                name='lastName'
                                label={deTranslations.last_name}
                                placeholderText={deTranslations.enter_last_name}
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                errorMessage={formik.touched.lastName ? formik.errors.lastName : ''}
                            />
                            <TextField
                                fullWidth={true}
                                id='title'
                                name='title'
                                label={deTranslations.title}
                                placeholderText={deTranslations.enter_title}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                errorMessage={formik.touched.title ? formik.errors.title : ''}
                            />
                            <Button style={{marginTop: 8, marginBottom: 16}}
                                    type='submit'>{webinarData ? deTranslations.save_and_attend_webinar : 'Registrieren Sie sich'}</Button>
                        </>
                        }
                    </Form>
                    <div style={{
                        marginBottom: -22,
                        marginLeft: -22,
                        marginRight: -22,
                        height: 39,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTop: '0.5px solid #8E9190'
                    }}>
                        <div style={{flexGrow: 1, width: 69}}>
                            {step !== 1 &&
                            <BackButton sx={{flexGrow: 1}} onClick={() => {
                                if (step === 1) {
                                    goBack();
                                    return;
                                }

                                setStep(step - 1)
                            }}/>
                            }
                        </div>
                        <Stepper isVideoPlayPage={false} steps={2} step={step}/>
                        <div style={{flexGrow: 1, width: 69}}/>
                    </div>
                </Card>
                {!hideOnboardingWidget && <SignupOnboardingWidget/>}
            </OnboardingContainer>
            }
            {success &&
            <ConfirmationTicket
                title={formik.values.title}
                firstName={formik.values.firstName}
                lastName={formik.values.lastName}
                email={formik.values.email}
                // @ts-ignore
                formattedDate={formattedDate}
                // @ts-ignore
                formattedTime={formattedTime}
                webinarTitle={webinarData.title}
                webinarSubtitle={webinarData.subtitle}
                coverURL={webinarData.coverURL}
                isSubscribed={webinarData.isSubscribed}
            />
            }
        </>
    );
}

export default Student;
