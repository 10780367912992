import Card from '../../common/card';
import {deTranslations} from '../../../translations';
import {Img, Text, Div} from './style';

const NotEligible = () => {
    return <Div>
        <Card title={deTranslations.webinar_restricted}>
            <Text style={{marginBottom: 20, marginTop: 0}}>{deTranslations.sorry_illegible_for_webinar}</Text>
            <Img src='/sorry.png' alt=""/>
            <Text style={{marginTop: 20, marginBottom: 0}}>{deTranslations.working_hard_on_content}</Text>
        </Card>
    </Div>

};

export default NotEligible;
