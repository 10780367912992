import React from 'react';
import { StyledModal, StyledDiv } from './style';
import PlaceOfWorkRadioGroup from '../../../../menus/place-of-work-radio-group';
import Card from '../../../../common/card';
import { deTranslations } from '../../../../../translations';

// @ts-ignore
const DoctorModal = ({ open, handleClose, onPicked }) => {
  return <StyledModal
    open={open}
    disableAutoFocus={true}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledDiv>
      <Card title={deTranslations.place_of_work}>
        <PlaceOfWorkRadioGroup onPicked={onPicked} />
      </Card>
    </StyledDiv>
  </StyledModal>
}

export default DoctorModal;
