import React from 'react';
import FeedAPI from '../../api/feed';
import Hero from './hero';
import VideoChannel from './video-channel';
import WebinarVideoChannel from './webinar-video-channel';
import WebinarChannel from './webinar-channel';
import { deTranslations } from '../../translations';

const VideoLanding = () => {

    const [videoFeedData, setVideoFeedData] = React.useState([]);
    const [allWebinarIds, setAllWebinarIds] = React.useState([]);
    const [feedLoaded, setFeedLoaded] = React.useState({});

    React.useEffect(() => {
            // @ts-ignore
            FeedAPI.getVideoFeed().then((response: any) => {
                setVideoFeedData(response[1])
            });
    }, []);

    // @ts-ignore
    const webinarFeedData = videoFeedData.filter((webinar) => webinar?.type === 'webinar').filter((webinar) => webinar.headline !== 'webinar_list_user_might_like')

    const setWebinarIds = (webinars: any, headlineValue: any) => {
        let webinarIds: any = []
        webinars.map((w: any) => {
            webinarIds = [...webinarIds, w.id]
        })

        // @ts-ignore
        setAllWebinarIds(allWebinarIds => [...allWebinarIds, ...webinarIds])
        setFeedLoaded(allWebinarIds => ({...allWebinarIds, [headlineValue]: true}))
    }

    const isFeedLoaded = () => {
        const a = Object.keys(feedLoaded).length === webinarFeedData.length
        const b = Object.values(feedLoaded).every(element => element)

        return a && b
    }

    // @ts-ignore
    return (
        <>
            {videoFeedData?.map((feed: any, index) => (
                (feed?.type === 'video' && feed?.headline === 'video_list_featured') ?
                    <Hero
                        key={index}
                        filter={feed?.filter}
                        meta={feed?.meta?.query}
                    />
                : (feed?.type === 'video' && !feed?.meta?.hasOwnProperty('webinarId') && feed?.headline !== 'video_list_featured') ?
                    <div key={index}>
                        {/* @ts-ignore */}
                        <VideoChannel
                            key={index}
                            filter={feed?.filter}
                            filterMore={feed?.filterMore}
                            headlineValue={feed?.headlineValue != null ? feed?.headlineValue : deTranslations.video_list_to_continue_watching}
                            description={feed?.meta?.description}
                            channelId={feed?.meta?.channelId}
                            logo={feed?.meta?.logoURL}
                        />
                    </div>
                    : (feed?.type === 'video' && feed?.meta?.hasOwnProperty('webinarId') && feed?.headline !== 'video_list_featured') ?
                        <WebinarVideoChannel
                            key={index}
                            filter={feed?.filter}
                            filterMore={feed?.filterMore}
                            headlineValue={feed?.headlineValue}
                            description={feed?.meta?.description}
                            webinarId={feed?.meta?.webinarId}
                            logo={feed?.meta?.logoURL}
                        />
                        : feed?.type === 'webinar' && feed?.headline !== 'webinar_list_user_might_like' ?
                        <WebinarChannel onWebinarsLoad={(webinars: any) => {setWebinarIds(webinars, feed?.headline)}}
                            key={index}
                            filter={feed?.filter}
                            headlineValue={feed?.headline === 'webinar_list_by_specialty'
                                    ? deTranslations.webinar_list_by_specialty + ' ' + feed?.headlineValue
                                    : feed?.headline === 'webinar_list_user_might_like'
                                        ? deTranslations.webinar_list_user_might_like
                                        : feed?.headlineValue}
                        /> : isFeedLoaded() && <WebinarChannel onWebinarsLoad={null}
                                    key={index}
                                    filter={feed?.filter}
                                    headlineValue={feed?.headline === 'webinar_list_by_specialty'
                                        ? deTranslations.webinar_list_by_specialty + ' ' + feed?.headlineValue
                                        : feed?.headline === 'webinar_list_user_might_like'
                                            ? deTranslations.webinar_list_user_might_like
                                            : feed?.headlineValue}
                                />
            ))}
        </>
    )
}

export default VideoLanding;