import styled from 'styled-components';
import Modal from '@mui/material/Modal';

export const Form = styled.form(({}) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledDiv = styled.div(({ theme }) => ({
  '&&': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  backdropFilter: 'blur(10px)',
}));
