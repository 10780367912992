import { StyledModal, StyledDiv } from './style';
import React, { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import Subscribe from '../../login/subscribe';
import Login from '../../../components/login/initial';
import Signup from '../../../components/signup/initial';

// @ts-ignore
const SubscribeModal = ({ open, handleClose, webinarData, utmSource = null }) => {
  const { user } = useContext(AuthContext);
  const [isLogin, setIsLogin] = React.useState(true);

  return <StyledModal
    open={open}
    disableAutoFocus={true}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledDiv>
      {user != null
        ?
        <Subscribe webinarData={webinarData} utmSource={utmSource} />
        :
        <>{isLogin ?
          <Login webinarData={webinarData} onSignupClick={() => setIsLogin(false)} />
          :
          <Signup webinarData={webinarData} onLoginClick={() => setIsLogin(true)} />
        }</>
      }
    </StyledDiv>
  </StyledModal>
}

export default SubscribeModal;
