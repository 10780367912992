import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 22,
  marginBottom: 18,
}));

export const HeaderRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',

  [theme.breakpoints.down("sm")]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 10,
  }
}));

export const DayAndTime = styled.p(({ theme }) => ({
  fontSize: '13px',
  lineHeight: '16px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#404040',
  margin: 0,
}));

export const Points = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,
}));

export const AmountOFDFP = styled.p(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 600,
  color: '#0E0E0E',
  margin: 0,
}));

export const Dot = styled.p(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 700,
  color: theme.palette.textColors.title,
  margin: 0,
  marginTop: -3,
}));

export const PointsType = styled.p(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#404040',
  margin: 0,
  marginTop: -2,
}));

export const HeaderSeparator = styled.div(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.textColors.label}`,
  width: '100%',
  marginTop: 10,
}));

export const Talk = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid #AEAEAE',
  marginTop: 10,

  [theme.breakpoints.down("sm")]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 4,
  }
}));

export const TalkTime = styled.p(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#676767',
  margin: 0,
  width: 130,
  flexGrow: 2,
}));

export const TalkColumn = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
  width: 300,
  overflowWrap: 'break-word',
}));

export const TalkTitle = styled.p(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '20px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 500,
  color: '#0E0E0E',
  margin: 0,
  marginBottom: 4,
}));

export const TalkType = styled.p(({ theme }) => ({
  fontSize: 13,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: theme.palette.textColors.body,
  margin: 0,
  marginBottom: 4,
}));

export const TalkSpeaker = styled.p(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '18px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#404040',
  margin: 0,
}));
