import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import List from "@mui/material/List";

export const Div = styled.div<{ isLandingPage: boolean, isMobile: boolean }>(({theme, isLandingPage, isMobile}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 10,
    height: !isMobile ? 64 : 60,
    margin: 0,
    backgroundColor: theme.palette.onyx,
    zIndex: 151,
}));

export const Logo = styled.div(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    // width: 220,
    marginLeft: 28,

    ':hover': {
        cursor: 'pointer',
    },
}));

export const Img = styled.img(({theme}) => ({
    display: 'flex',
    width: 56,
    height: 56,
    marginRight: 19,
}));

export const Title = styled.h1(({theme}) => ({
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: 400,
    fontFamily: ['Average', 'serif'].join(','),
    color: theme.palette.white,
}));

export const SearchAndLinksDiv = styled.div(({theme,}) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white,
    gap: 25,
    height: 100,
    margin: 0,
}));

export const LinksContainer = styled.div(({theme,}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 50,
    alignItems: 'center',
    marginRight: 28,
}));

export const LinkWithImage = styled.a(({theme,}) => ({
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    margin: 0,
    textDecoration: 'none',

    ':hover svg path': {
        stroke: '#C4C4C4'
    },

    ':hover': {
        cursor: 'pointer',
    },

    ':hover .linkText': {
        color: '#C4C4C4'
    },
}));

export const NavLinkWithImage = styled(NavLink)(({theme}) => ({
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    margin: 0,
    textDecoration: 'none',

    ':hover': {
        cursor: 'pointer',
    },

    ':hover svg path': {
        stroke: '#C4C4C4',
    },

    ':hover .linkText': {
        color: '#C4C4C4'
    },

    '.linkText-active': {
        textDecoration: 'underline',
        textDecorationThickness: '6px',
        textDecorationColor: '#F05B4D'
    }
}));

export const LinkText = styled.p(({theme}) => ({
    fontSize: 17,
    lineHeight: '20px',
    fontWeight: 600,
    fontFamily: 'Inter',
    color: '#FFF',
    margin: 0,
}));

export const MyProfilePopover = styled.div(({theme,}) => ({
    width: 231
}));

export const MyProfileLinkWithImage = styled.div(({theme,}) => ({
    padding: '18px 24px',
    borderBottom: '1px solid rgba(196, 196, 196, 0.4)',
    ':hover': {
        cursor: 'pointer',
    },
    ':hover .linkText': {
        color: '#C4C4C4'
    },
    ':last-child': {
        borderBottom: 'none',
    },
}));

export const ClickableSvg = styled.div(({theme,}) => ({
    ':hover': {
        cursor: 'pointer',
    },
}));

// @ts-ignore
export const SearchList = styled.div(({theme, decreaseHeight}) => ({
    position: 'absolute',
    marginTop: decreaseHeight === 1 ? 285 : decreaseHeight === 2 ? 400 : 515,
    width: 517,
    backgroundColor: '#434849',
    overflow: 'hidden',

    [theme.breakpoints.down(1025)]: {
        width: '100%',
        marginTop: 0
    },
}));

export const SearchListTitle = styled.div(({theme}) => ({
    marginTop: 15,
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    paddingLeft: 20,
    fontFamily: ['Inter', 'normal'].join(',')
}));

export const SearchListElement = styled.div(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '5px 0 5px 20px',
    fontFamily: ['Inter', 'normal'].join(','),
    cursor: 'pointer',
    boxSizing: 'border-box',

    '&:hover': {
        backgroundColor: '#676767'
    },

    [theme.breakpoints.down(1025)]: {
        height: 86
    },
}));

export const SearchListElementImage = styled.img(({theme}) => ({
    width: 177,
    height: 98,
    minWidth: 177,
    minHeight: 98,
    borderRadius: 5,

    [theme.breakpoints.down(1025)]: {
        width: 120,
        height: 66,
        minWidth: 120,
        minHeight: 66,
    },
}));

export const VideoLength = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px 3px',
    gap: 10,

    position: 'absolute',
    height: 12,
    right: 10,
    bottom: 10,

    background: '#2E3436',
    borderRadius: 3,
}));

export const VideoLengthNumber = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 10,
    fontWeight: 500,
    color: '#FFFFFF',
    width: 26,
    height: 12,
    textAlign: 'end',
}));

export const SearchListElementAllText = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px'
}));

export const SearchListElementTextType = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    color: '#C4C4C4',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 400,
    opacity: 0.8
}));

export const SearchListElementTextTitle = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 600,
    marginTop: 3
}));

export const SearchListElementTextSource = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    color: '#FCFCFC',
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 400,
    opacity: 0.8
}));

export const SearchListLinkTo = styled.div(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '10px'
}));

export const SearchListMore = styled.div(({theme}) => ({
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 700,
    fontFamily: ['Inter', 'normal'].join(','),
    alignItems: 'center',
    marginRight: '5px',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const SearchListMoreImage = styled.img(({theme}) => ({
    width: 12,
    height: 8,
    marginTop: 13,

    ':hover': {
        cursor: 'pointer',
    }
}));
