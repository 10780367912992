import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Div,
    TermsAndConditions,
    Text,
    Link,
    Form,
    OnboardingContainer,
} from './style';
import Card from '../../common/card';
import TextField from '../../common/fields/text-field';
import Checkbox from '../../common/buttons/checkbox';
import Button from '../../common/buttons/primary-button';
import { deTranslations } from '../../../translations';
import SignupOnboardingWidget from "../signup-onboarding-widget";
import UserAPI from '../../../api/user/index';
import { AuthContext } from '../../../context/authContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ErrorModal from '../../common/error-modal';
import ForwardButton from '../../common/buttons/forward-button';

const validationSchema = Yup.object({
    email: Yup.string()
        .email(deTranslations.email_invalid_warning)
        .required(`${deTranslations.email} ${deTranslations.must_not_empty}`),
    password: Yup.string()
        .min(6, deTranslations.password_short_warning)
        .required(deTranslations.password_empty_warning),
    acceptedTerms: Yup.boolean()
        .required(`${deTranslations.terms_and_conditions} ${deTranslations.must_not_empty}`)
        // TODO: translation maybe if shown in the future
        .oneOf([true], 'You must accept the terms and conditions.'),
});

const InitialProxy = ({hideOnboardingWidget, onLoginClick, proxyUserHash}: { hideOnboardingWidget?: boolean, onLoginClick?: () => void, proxyUserHash: string }) => {
    const [isEmailTaken, setIsEmailTaken] = useState(false);
    const [proxyUser, setProxyUser] = useState({
        email: '',
        proxyActivationToken: '',
    });
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const getProxyUser = async () => {
            if (proxyUserHash) {
                const [error, data] = await UserAPI.getProxyUserData(proxyUserHash);

                if (error) {
                    setOpenModal(true);
                } else {
                    setProxyUser(data);
                }
            }
        };

        getProxyUser();
    }, [proxyUserHash]);

    const formik = useFormik({
        initialValues: {
            email: proxyUser.email,
            password: '',
            acceptedTerms: false,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (
            async values => {
                const [error, isSuccess] = await UserAPI.activateProxyUser({
                    email: values.email,
                    password: values.password,
                    proxyActivationToken: proxyUser.proxyActivationToken,
                });

                if (isSuccess) {
                    const [error, data] = await UserAPI.loginUser({
                        email: values.email,
                        password: values.password
                    });
                    // @ts-ignore
                    if (error) {
                        // do something
                    } else {
                        login({ responseData: data })
                        navigate('/videos', { replace: true })
                    }
                } else {
                    setOpenModal(true);
                }
            }),
    });

    const emailOnFocusHandle = () => {
        setIsEmailTaken(false);
    }

    // @ts-ignore
    return (
        <Div>
            <ErrorModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                retry={formik.handleSubmit}
                title={deTranslations.error_occurred}
                message={deTranslations.generic_error_message}
            />
            <Form onSubmit={formik.handleSubmit}>
                {/* @ts-ignore */}
                <OnboardingContainer hideOnboardingWidget={hideOnboardingWidget}>
                    <Card title={'Wähle dein Passwort'}>
                        <TextField
                            disabled={true}
                            fullWidth={true}
                            id='email'
                            name='email'
                            label={deTranslations.email_label_text}
                            placeholderText={deTranslations.email_hint_text}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onFocus={emailOnFocusHandle}
                            errorMessage={isEmailTaken ? 'E-Mail wird bereits verwendet' : formik.touched.email ? formik.errors.email : ''}
                        />
                        <TextField
                            autoFocus={true}
                            fullWidth={true}
                            id='password'
                            name='password'
                            label={deTranslations.password_label_text}
                            type='password'
                            placeholderText='•••••••••••••••'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.password ? formik.errors.password : ''}
                        />
                        <TermsAndConditions>
                            <Checkbox
                                style={{marginTop: 15, marginRight: 10}}
                                id='acceptedTerms'
                                checked={formik.values.acceptedTerms}
                                onChange={formik.handleChange}
                                // error={!!formik.errors.acceptedTerms}
                            />
                            {/* @ts-ignore */}
                            <Text error={formik.touched.acceptedTerms && !!formik.errors.acceptedTerms}>
                                {deTranslations.signup_accept_text_1}
                                <Link href='https://www.meducately.com/agb/' target='_blank'>
                                    {deTranslations.terms_and_conditions}
                                </Link>
                                {deTranslations.signup_accept_text_2}
                                <Link href='https://www.meducately.com/datenschutz/' target='_blank'>
                                    {deTranslations.data_protection}
                                </Link>
                                {deTranslations.signup_accept_text_3}
                            </Text>
                        </TermsAndConditions>
                        <Button style={{marginTop: 40}} type='submit'>{deTranslations.activate_account}</Button>
                        <Text style={{marginTop: 20, textAlign: 'center'}}>{deTranslations.already_have_account}</Text>
                        <ForwardButton sx={{marginTop: -2.5, marginBottom: -1}} onClick={onLoginClick}>
                            {deTranslations.login_title}
                        </ForwardButton>
                    </Card>
                    {
                        !hideOnboardingWidget && <SignupOnboardingWidget isProxyUser={true} />
                    }
                </OnboardingContainer>
            </Form>
        </Div>
    );
}

export default InitialProxy;
