// @ts-ignore
function CaretIcon(props) {
  return(
    <svg style={props.style} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 5.90039L5 10.1004L1 5.90039" stroke={props.fill ? props.fill : 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CaretIcon;
