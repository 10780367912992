import React from 'react';
import {Div, Container, Content, Images, Image} from './style';
import SectionHeader from '../common/section-header';
import DescriptionText from '../common/description-text';
import HorizontalRule from '../../common/horizontal-rule';

// @ts-ignore
const Organizers = ({description, additionalDescription, images}) => {
    return (
        <Div>
            <Container>
                <Content>
                    {/* @ts-ignore */}
                    <HorizontalRule lighter={true}/>
                    {/* @ts-ignore */}
                    <SectionHeader style={{
                        color: '#0E0E0E',
                        fontSize: '20px',
                        lineHeight: '24px',
                        fontWeight: 600,
                        marginTop: 35,
                        marginBottom: 16
                    }}>Veranstalter & Fortbildungsanbieter</SectionHeader>
                    {/* @ts-ignore */}
                    {description !== '' && <DescriptionText style={{marginBottom: 42}}>{description}</DescriptionText>}
                    {/* @ts-ignore */}
                    {additionalDescription !== '' &&
                    <DescriptionText style={{marginBottom: 53}}>{additionalDescription}</DescriptionText>}
                    {
                        images?.length !== 0 &&
                        <Images>
                            {images?.map((image: string, index: number) => <Image key={index} src={image}/>)}
                        </Images>
                    }
                </Content>
            </Container>
        </Div>
    )
}

export default Organizers;