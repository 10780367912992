import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import Card from '../card';

export const StyledCard = styled(Card)(({ theme }) => ({
  '&&': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
}));

export const Text = styled.p<{centerText: boolean}>(({ theme, centerText }) => ({
  marginTop: 0,
  fontSize: 16,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#676767',
  textAlign: centerText ? 'center' : 'left'
}));

export const Img = styled.img(({ theme }) => ({
  display: 'flex',
  width: 186,
  alignSelf: 'center',
}));

export const Hr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.label}`,
  width: 'calc(100% + 44px)',
  marginLeft: -22,
}));

