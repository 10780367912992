import React from "react";
import {DFPSuccessContainer} from "./style";

// @ts-ignore
const DFPSuccessMessage = ({ type, onClick }) => {

    const typeLowerCased = type.toLowerCase();
    const color = typeLowerCased === 'success' ? '#1F984F' : typeLowerCased === 'failure' ? '#F05B4D' : 'white';
    const message = typeLowerCased === 'success' ? 'Bestanden' : typeLowerCased === 'failure' ? 'Nicht bestanden' : '';
    const imageSource = typeLowerCased === 'success' ? '/dfp-success-icon.png' : typeLowerCased === 'failure' ? '/dfp-failure-icon.png' : '';
    const imageAlt = typeLowerCased === 'success' ? 'Success' : typeLowerCased === 'failure' ? 'Failure' : '';

    return <DFPSuccessContainer onClick={onClick} style={{
        color: color,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        marginTop: '10px',
        textAlign: 'center',
        padding: '10px 16px'
    }}>
        {message} <img
        style={{marginLeft: '10px', width: '12px', height: '12px'}}
        src={imageSource} alt={imageAlt}/>
    </DFPSuccessContainer>
}

export default DFPSuccessMessage;