import styled from 'styled-components';

export const List = styled.div(({ theme }) => ({
  marginBottom: 150,
}));

export const Button = styled.div(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 3px 30px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.02)',
  paddingLeft: 13,
  paddingRight: 13,
  marginLeft: -11,
  marginRight: -11,
  marginTop: 16,
  '&:hover': {
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.02)',
  }
}));

export const Label = styled.p(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  color: '#063F58',
}));

export const RadioIcon = styled.span(({ theme }) => ({
  marginRight: -1,
  width: 22,
  height: 20,
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='9.25' stroke='%23063F58' stroke-width='1.5'/%3E%3C/svg%3E%0A")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  content: '""',
}));
