import styled from 'styled-components';

export const Title = styled.h2(({ theme }) => ({
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#2A2A2A',
    margin: '0 0 20px 0'
  }));

  export const Form = styled.form(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
  }));

  export const Text = styled.span(({ theme }) => ({
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#404040',
    display: 'block'
  }));
