import React from 'react';
import FeedAPI from '../../../api/feed';
import DFPCourseChannel from "../../dfp-video-landing/dfp-video-channel";
import {deTranslations} from '../../../translations';
import DFPCourseChannelNew from "./dfp-video-channel-new";

const DFPVideoLandingNew = () => {

    const [videoFeedData, setVideoFeedData] = React.useState([]);
    const [allWebinarIds, setAllWebinarIds] = React.useState([]);
    const [feedLoaded, setFeedLoaded] = React.useState({});

    React.useEffect(() => {
        // @ts-ignore
        FeedAPI.getDFPVideoFeed().then((response: any) => {
            if (response[1] && response[1].length > 0) {
                setVideoFeedData(response[1])
            }
        });
    }, []);

    // @ts-ignore
    const webinarFeedData = videoFeedData.filter((webinar) => webinar?.type === 'webinar').filter((webinar) => webinar.headline !== 'webinar_list_user_might_like')

    const setWebinarIds = (webinars: any, headlineValue: any) => {
        let webinarIds: any = []
        webinars.map((w: any) => {
            webinarIds = [...webinarIds, w.id]
        })

        // @ts-ignore
        setAllWebinarIds(allWebinarIds => [...allWebinarIds, ...webinarIds])
        setFeedLoaded(allWebinarIds => ({...allWebinarIds, [headlineValue]: true}))
    }

    const isFeedLoaded = () => {
        const a = Object.keys(feedLoaded).length === webinarFeedData.length;
        const b = Object.values(feedLoaded).every(element => element);

        return a && b;
    }

    // @ts-ignore
    return (
        <>
            {videoFeedData?.map((feed: any, index) => (
                (feed?.type === 'dfp_course' && feed?.headline === 'dfp_course_list_featured') ?
                    <></>
                    : (feed?.type === 'dfp_course' && feed?.headline === 'dfp_course_list_enrolled') ?
                        <div key={index}>
                            {/* @ts-ignore */}
                            <DFPCourseChannelNew
                                key={index}
                                filter={feed?.filter}
                                filterMore={feed?.filterMore}
                                headlineValue={feed?.headlineValue != null ? feed?.headlineValue : deTranslations.my_dfp_courses}
                                description={feed?.meta?.description}
                                channelId={feed?.meta?.channelId}
                                logo={feed?.meta?.logoURL}
                            />
                        </div>
                        : (feed?.type === 'dfp_course' && feed?.headline === 'dfp_course_channel') ?
                            <div key={index}>
                                {/* @ts-ignore */}
                                <DFPCourseChannelNew
                                    key={index}
                                    filter={feed?.filter}
                                    filterMore={feed?.filterMore}
                                    headlineValue={feed?.headlineValue != null ? feed?.headlineValue : deTranslations.video_list_to_continue_watching}
                                    description={feed?.meta?.description}
                                    channelId={feed?.meta?.channelId}
                                    logo={feed?.meta?.logoURL}
                                />
                            </div>
                            : isFeedLoaded()
            ))}
        </>
    )
}

export default DFPVideoLandingNew;