const mockWebinar = {
    "createdAt": "2022-12-12T10:55:46.673Z",
    "updatedAt": "2022-12-12T10:55:46.673Z",
    "isSubscribed": false,
    "subscription": {
        "createdAt": "2023-01-24T21:46:09.842Z",
        "updatedAt": "2023-01-24T21:46:09.842Z",
        "id": 126,
        "deletedAt": null,
        "webinarId": 66,
        "userId": 3,
        "attendance": "virtual"
    },
    "id": 66,
    "deletedAt": null,
    "title": "COVID-19: Prävention und Therapie",
    "subtitle": "",
    "description": "<p>In unserem meducately Rheumatologie Refresher sprechen ausgewählte Expertinnen und Experten zu spannenden Themen aus der Rheumatologie. Sei dabei, wenn Fragestellungen rund um Rheumatoide Arthritis, Psoriasis-Arthritis und Kellagenosen diskutiert werden.</p>\n<p>Die Veranstaltung ist derzeit zur DFP Approbation bei der Österreichischen Akademie der Ärzte eingereicht. Nach erfolgreicher Approbation kannst du 3 DFP Punkte für deine Teilnahme erhalten.</p>",
    "startDate": "2023-03-07T17:00:00.000Z",
    "endDate": "2023-03-07T18:30:00.000Z",
    "provider": "Medizinische Universität Wien, Klinische Abteilung für Rheumatologie",
    "organizerDescription": "<p>Veranstalter: hello mint</p>\n<p>Wissenschaftlicher Fortbildungsanbieter: Medizinische Universität Wien, Klinische Abteilung für Rheumatologie</p>\n<p>Wissenschaftlicher Fortbildungsanbieter: Priv.-Doz. Dr. Hans Kiener</p>",
    "additionalOrganizerDescription": "",
    "audienceId": "69e0ca0d6b",
    "coverURL": "/external-webinar-placeholder.jpg",
    "bannerURL": null,
    "ranking": 1,
    "type": "virtual",
    "location": null,
    "featuredVideoId": null,
    "speakers": [
        {
            "createdAt": "2022-12-12T10:55:46.673Z",
            "updatedAt": "2022-12-12T10:55:46.673Z",
            "id": 1033,
            "deletedAt": null,
            "sort": 0,
            "webinarId": 66,
            "speakerId": 34,
            "speaker": {
                "createdAt": "2022-10-07T17:52:17.852Z",
                "updatedAt": "2022-10-07T17:52:17.852Z",
                "id": 34,
                "deletedAt": null,
                "firstName": "TBA",
                "lastName": "to be announced",
                "title": "",
                "occupation": "",
                "avatarURL": "https://meducately-dev.s3.eu-central-1.amazonaws.com/uploads/speakers/34/avatar.jpg?ts=1664352432369"
            }
        }
    ],
    "sponsors": [
        {
            "createdAt": "2022-12-12T10:55:46.676Z",
            "updatedAt": "2022-12-12T10:55:46.676Z",
            "id": 282,
            "deletedAt": null,
            "sort": 0,
            "webinarId": 66,
            "sponsorId": 5,
            "sponsor": {
                "createdAt": "2022-10-07T09:35:21.884Z",
                "updatedAt": "2022-10-07T09:35:22.041Z",
                "id": 5,
                "deletedAt": null,
                "name": "meducately",
                "logoURL": "https://meducately-dev.s3.eu-central-1.amazonaws.com/uploads/sponsors/5/logo.png?ts=1665135322039"
            }
        }
    ],
    "organizers": [
        {
            "createdAt": "2022-12-12T10:55:46.679Z",
            "updatedAt": "2022-12-12T10:55:46.679Z",
            "id": 263,
            "deletedAt": null,
            "sort": 0,
            "webinarId": 66,
            "sponsorId": 5,
            "sponsor": {
                "createdAt": "2022-10-07T09:35:21.884Z",
                "updatedAt": "2022-10-07T09:35:22.041Z",
                "id": 5,
                "deletedAt": null,
                "name": "meducately",
                "logoURL": "https://meducately-dev.s3.eu-central-1.amazonaws.com/uploads/sponsors/5/logo.png?ts=1665135322039"
            }
        }
    ],
    "agenda": {
        "createdAt": "2022-12-12T10:55:46.673Z",
        "updatedAt": "2022-12-12T10:55:46.673Z",
        "id": 274,
        "deletedAt": null,
        "entries": [
            {
                "date": "2023-01-31T10:35:12.000Z",
                "slots": [
                    {
                        "title": "Aktuelle Biologika Therapie in der RA: Guidelines, Sicherheitsprofil, Nebenwirkungen",
                        "endTime": "2023-01-31T17:45:00.000Z",
                        "startTime": "2023-01-31T17:00:44.000Z",
                        "speakerIds": [
                            34
                        ]
                    },
                    {
                        "title": "Fortschritte der Therapie bei Ankylosierende Spondylarthritis bzw. Psoriasis-Arthritis",
                        "endTime": "2023-01-31T18:30:17.000Z",
                        "startTime": "2023-01-31T17:45:00.000Z",
                        "speakerIds": [
                            34
                        ]
                    },
                    {
                        "title": "Kollagenosen: Diagnosekriterien und medikamentöse Therapien",
                        "endTime": "2023-01-31T19:15:00.000Z",
                        "startTime": "2023-01-31T18:30:38.000Z",
                        "speakerIds": [
                            34
                        ]
                    }
                ],
                "title": "Dienstag",
                "dfpPoints": "3"
            }
        ],
        "webinarId": 66
    },
    "featuredVideo": null,
    "filterOccupations": [
        {
            "createdAt": "2022-12-12T10:55:46.680Z",
            "updatedAt": "2022-12-12T10:55:46.680Z",
            "id": 275,
            "deletedAt": null,
            "webinarId": 66,
            "occupationId": 1
        }
    ],
    "filterSpecialties": []
};

export default mockWebinar;
