import { Box } from '@mui/material';
import { Title, Text } from './style';

const GOOGLE_MAPS_LINK = 'https://www.google.com/maps/place/W%C3%A4hringer+Str.+39%2F5,+1090+Wien,+Austria';
const GOOGLE_MAPS_STATIC_LINK = `https://maps.googleapis.com/maps/api/staticmap?center=W%C3%A4hringer+Str.+39%2F5,+1090+Wien,+Austria&zoom=17&size=359x510&maptype=roadmap&markers=color:red%7C48.2206043,16.3552456&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

const InfoWithMapWidget = () => {
    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} width={{ xs: '100%', md: '718px' }} borderRadius="10px" overflow="hidden" boxShadow="0px 3px 27px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.02)">
            <Box width={{ xs: '100%', md: '50%' }} display="flex" flexDirection="column" justifyContent="center" boxSizing="border-box" p={{ xs: '20px 40px', md: '0 0 0 50px' }} sx={{ backgroundColor: '#FFF' }}>
                <Title>Hellomint GmbH</Title>
                <Text>Geschäftsführer: <strong>Dr. Fritz Höllerer</strong></Text>
                <Text>Währinger Straße 39/5, 1090 Wien</Text>
                <Text>Handelsgericht Wien, FN 378987i</Text>
                <Text>UID: ATU67147928</Text>
                <Text>Kammer: Wirtschaftskammer</Text>
                <Text>Österreich</Text>
                <Box m={{ xs: 0, md: '50px 0 0 0' }}>
                    <Text>
                        <strong>Für den Inhalt verantwortlich</strong>
                    </Text>
                    <Text>Copyright Hellomint GmbH</Text>
                </Box>
            </Box>
            {/* MAP */}
            <Box width={{ xs: '100%', md: '50%' }} height={{ xs: '238px', md: 'auto' }} onClick={() => window.open(GOOGLE_MAPS_LINK, '_blank')} sx={{cursor: 'pointer'}}>
                <img width="100%" height="100%" style={{ objectFit: 'cover' }} src={GOOGLE_MAPS_STATIC_LINK} alt="location map" />
            </Box>
        </Box>
    )
};

export default InfoWithMapWidget;