import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    CustomAccordionSummary,
    FAQContainer,
    FAQInnerContainer,
    FAQDescriptionContainer,
    SplitSubtitle,
    SplitTitle
} from "./style";

const FaqWidget = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <FAQContainer>

            <FAQInnerContainer>

                <FAQDescriptionContainer>
                    <SplitSubtitle>{'bist du mehr interessiert'.toUpperCase()}</SplitSubtitle>
                    <SplitTitle>HÄUFIGE FRAGEN ZUM PRODUKT</SplitTitle>
                </FAQDescriptionContainer>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <CustomAccordionSummary
                        expandIcon={<KeyboardArrowRightIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{flexShrink: 0}}>
                            Was genau ist meducately?
                        </Typography>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Meducately ist eine kostenlose Plattform für medizinische Videoinhalte. Wir sammeln und
                            kuratieren Fortbildungsvideos von unterschiedlichen Quellen und stellen diese gesammelt und
                            passend zu deiner Fachrichtung zusammen. Außerdem veranstalten wir regelmäßige Webinare, wo
                            man
                            sich direkt über uns anmelden kann.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <CustomAccordionSummary
                        expandIcon={<KeyboardArrowRightIcon/>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{flexShrink: 0}}>Woher bezieht meducately seine
                            Videos?</Typography>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Die Videos stammen von mehr als 100 unterschiedlichen Quellen. Darunter fallen medizinische
                            Fachgesellschaften, Kongressveranstalter, Agenturen oder Pharmafirmen. Außerdem produziert
                            meducately auch selbst Inhalte, um das Angebot stetig zu erweitern.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <CustomAccordionSummary
                        expandIcon={<KeyboardArrowRightIcon/>}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{flexShrink: 0}}>
                            Muss ich ein Arzt oder Ärztin sein, um mich anzumelden?
                        </Typography>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Du musst nicht Arzt sein, um meducately nutzen zu können. Auch für anderes
                            Gesundheitspersonal
                            wie Apotheker, Medizinstudenten oder Mitarbeiter der Pharmaindustrie gibt es spezifische
                            Inhalte. Einige Inhalte oder Webinare sind aber nur für ÄrztInnen verfügbar.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <CustomAccordionSummary
                        expandIcon={<KeyboardArrowRightIcon/>}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{flexShrink: 0}}>Warum brauche ich einen Account für
                            meducately?</Typography>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Da einige unserer Videos nur für medizinisches Fachpersonal zur Verfügung stehen, sind wir
                            verpflichtet diese Personen zu überprüfen. Dafür benötigen wir einige Informationen zu
                            deiner
                            Person.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <CustomAccordionSummary
                        expandIcon={<KeyboardArrowRightIcon/>}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <Typography sx={{flexShrink: 0}}>Ist meducately kostenlos?</Typography>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Unsere Plattform ist kostenlos und wird es auch bleiben.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <CustomAccordionSummary
                        expandIcon={<KeyboardArrowRightIcon/>}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                    >
                        <Typography sx={{flexShrink: 0}}>Kann ich selbst auch Videos oder Webinare auf
                            meducately hochladen?</Typography>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Meducately ist eine offene Plattform und bietet jedem die Möglichkeit seine Videos oder
                            Webinareinladungen zu platzieren. Voraussetzung ist natürlich, dass unsere
                            Qualitätsstandards
                            eingehalten werden. Willst du Videos hochladen oder Webinare promoten, dann melde dich unter
                            partner@meducately.com
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <CustomAccordionSummary
                        expandIcon={<KeyboardArrowRightIcon/>}
                        aria-controls="panel7bh-content"
                        id="panel7bh-header"
                    >
                        <Typography sx={{flexShrink: 0}}>Warum soll ich einen Ad-Blocker
                            installieren?</Typography>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Viele der Videos sind über YouTube eingebettet. Damit deine Experience nicht durch Werbungen
                            unterbrochen wird, raten wir dir bestimmte YouTube Ad-Blocker zu installieren. Dabei
                            vertrauen
                            wir auf etablierte und seriöse Plugins, die millionenfach im Einsatz sind.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </FAQInnerContainer>
        </FAQContainer>
    );
}

export default FaqWidget;
