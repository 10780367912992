import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '&:focus-within label': {
    color: theme.palette.textColors.body
  }
}));

export const StyledTextInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0.4),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    // color: theme.palette.textColors.body,
    color: '#404040',
    backgroundColor: '#F6F6F6',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18px',
    padding: '10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Inter', 'normal'].join(','),
    '&:focus': {
      border: '1px solid #434849',

      padding: '9px 11px',
    },
    '&:focus::placeholder': {
      opacity: 0,
    },
    '&::placeholder': {
      color: theme.palette.textColors.label,
      opacity: 100,
    },
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&&': {
    color: '#8E9190',
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 1,
    textTransform: 'uppercase',
  },
}));
