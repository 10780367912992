import React, { useRef, useEffect } from 'react';

interface CanvasProps {
    width: number;
    height: number;
    percentage: number;
}

const Canvas = ({ width, height, percentage }: CanvasProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            if (context) {
                context.strokeStyle = "#2E3436";
                context.lineWidth = 7;
                context.beginPath();
                context.arc(7, 7, 3.5,
                    (-90 * (Math.PI/180)) + (0 * (Math.PI/180)),
                    (-90 * (Math.PI/180)) + (percentage * (Math.PI/180)));
                context.stroke();
            }
        }
    },[]);

    return <canvas style={{ position: 'absolute', top: 5, right: 5, background: '#FFFFFF', borderRadius: '50%' }}
                   ref={canvasRef} height={height} width={width} />;
};

Canvas.defaultProps = {
    width: window.innerWidth,
    height: window.innerHeight
};

export default Canvas;