import React from 'react';
import './style.css';

const DescriptionText = ({ children, style, colorProps, isMobile }: { children: any, style?: any, colorProps?: any, isMobile?: any }) => {
  return (
    <div style={style} className={isMobile ? 'descriptionRichTextIsMobile' : colorProps ? 'descriptionRichTextWhite' : 'descriptionRichText'} dangerouslySetInnerHTML={{ __html: children }}/>
  )
}

export default DescriptionText;
