import api from '../';

// @ts-ignore
const getAllChannels = async () => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/channels`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

// @ts-ignore
const getAllChannelsList = async () => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/channels?noJoin=true`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const getChannelById = async (id: any) => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/channels/${id}`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const getChannelByIdForVideoPlay = async (id: any) => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/channels/${id}`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const getVideoById = async (id: any) => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/videos/${id}?join=speakers&join=speakers.speaker&join=watchers&join=watchers.user`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const searchVideo = async (query: any, filter = null) => {
    let response;
    const [textQuery, idQuery] = extractSearchQuery(query);

    if (filter) {
        response = await api.get(`/v1/videos?limit=50${textQuery}${idQuery}&filter=${filter}&join=speakers&join=speakers.speaker&sort=publicationDate,DESC`);
    } else {
        response = await api.get(`/v1/videos?limit=50${textQuery}${idQuery}&join=speakers&join=speakers.speaker&sort=publicationDate,DESC`);
    }

    return response?.data;
}

const extractSearchQuery = (query: any) => {
    let textQuery = ''
    let idQuery = ''
    if (query?.length > 0) {
        textQuery = `&filter=title||$contL||${query}`
        if (!isNaN(query)) {
            idQuery = `&or=id||$eq||${query}`
        }
    }

    return [textQuery, idQuery]
}

const getAllVideos = async (filter: any) => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/videos${filter}&join=speakers&join=speakers.speaker`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const videoStopped = async (id: any, time: any, isEnd: any) => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/videos/${id}/watch?stoppedWatchingAt=${time}&isFinishedWatching=${isEnd}`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const getAllDfpVideos = async (filter: any) => {
    let response;
    let error;

    try {
        response = await api.get(`/v1/dfpCourses${filter}`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const getDFPVideoById = async (id: any) => {
    let response;
    let error;

    try {
        response = await api.get(`/v1/dfpCourses/${id}?join=video&join=sponsors&join=sponsors.sponsor&join=speakers&join=speakers.speaker&join=organizers&join=organizers.sponsor&join=watchers&join=watchers.user`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const enrollInDFP = async (dfpCourseId: number) => {
    let response;
    let error;

    try {
        response = await api.get(`/v1/dfpCourses/${dfpCourseId}/enroll`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

export default {
    getAllChannels,
    getAllChannelsList,
    searchVideo,
    getAllVideos,
    videoStopped,
    getChannelById,
    getVideoById,
    getChannelByIdForVideoPlay,
    getAllDfpVideos,
    getDFPVideoById,
    enrollInDFP
};