import api from '../';
import { extractPayloadFromForm, extractRelevantData } from './utils';

// @ts-ignore
const addProfileForUser = async (profileId, subscriptionToken, payload) => {
  const extractedPayload = extractPayloadFromForm(payload);
  let response;
  let error;
  try {
    response = await api.patch(`/v1/profiles/${profileId}?subscriptionToken=${subscriptionToken}`, extractedPayload);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.data];
};

// @ts-ignore
const getCurrentUserProfile = async (userId) => {
  let response;
  let error;
  try {
    response = await api.get(`/v1/profiles/`);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }

  // @ts-ignore
  const currentUser = response?.data.find(userProfile => {
    return userProfile.user.id === +userId
  });

  const relevantData = extractRelevantData(currentUser);


  return [error, relevantData];
};

// @ts-ignore
const updateProfileForUser = async (profileId, payload) => {
  const extractedPayload = extractPayloadFromForm(payload);
  let response;
  let error;
  try {
    response = await api.patch(`/v1/profiles/${profileId}`, extractedPayload);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.data];
};

export default {
  addProfileForUser,
  getCurrentUserProfile,
  updateProfileForUser,
};
