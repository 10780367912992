// @ts-ignore
function ArrowRightIcon(props) {
  return(
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
          <path d="M8.8 1L13 5M13 5L8.8 9M13 5H1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}

export default ArrowRightIcon;
