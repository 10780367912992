import React from 'react';
import AGB from '../components/static-pages/agb';
import Footer from '../components/layout/footer';

function AGBPage () {
  return (
    <>
      <AGB />
      <Footer />
    </>
  );
}

export default AGBPage;
