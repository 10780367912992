import React from 'react';
import { StyledButton, RightArrowIcon } from './style';

interface Props {
  onClick: any,
  sx?: object,
  children: any,
  isLanding?: boolean
}

// @ts-ignore
const ForwardButton = ({ onClick, sx, children, isLanding = false }: Props) => {
  // TODO: maybe translate
  {/* @ts-ignore */}
  return <StyledButton onClick={onClick} sx={sx} disableRipple endIcon={<RightArrowIcon isLanding={isLanding} />}>
    {children}
  </StyledButton>;
};

export default ForwardButton;
