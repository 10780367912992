import {Box, CardMedia, useMediaQuery} from '@mui/material';
import PrimaryButton from '../../common/buttons/primary-button';
import ContactPerson from "../../common/contact-person";
import {Separator} from "./style";
import { useNavigate } from 'react-router-dom';

const Partnership = () => {
    const navigate = useNavigate();
    const isXs = useMediaQuery("(max-width:1400px)");

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: '#FFFFFF'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: {
                    lg: '1230px',
                    xs: '90%'
                },
                padding: '0 15px',
                margin: '0 auto',
                fontFamily: 'Inter'
            }}>
                <Box sx={{
                    padding: {
                        md: '48px 131px',
                        xs: '40px 20px 16px 20px'
                    },
                }}>
                    <Box sx={{
                        textTransform: 'uppercase',
                        textAlign: {
                            md: 'center',
                            xs: 'left'
                        },
                        color: '#F05B4D',
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18px'
                    }}>
                        Willst du kooperieren
                    </Box>

                    <Box sx={{
                        textTransform: {
                            md: 'uppercase',
                            xs: 'capitalize',
                        },
                        textAlign: {
                            md: 'center',
                            xs: 'left'
                        },
                        color: '#1D1D1D',
                        fontWeight: 800,
                        fontSize: {
                            md: '40px',
                            xs: '28px',
                        },
                        lineHeight: {
                            md: '48px',
                            xs: '34px',
                        },
                        marginBottom: {
                            md: '24px',
                            xs: '16px'
                        }
                    }}>
                        Partnerschaften
                    </Box>

                    <Box sx={{
                        textAlign: {
                            md: 'center',
                            xs: 'left'
                        },
                        color: '#404040',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>
                        Meducately ist offen für alle. Egal ob du eine medizinische Gesellschaft, Universität,
                        Medizinverlag, Agentur oder ein Mitglied der Pharmaindustrie bist. Wir freuen uns immer auf
                        spannende Gespräche und mögliche Kooperationen, um unsere Datenbank zum Wohle der medizinischen
                        Fortbildung stetig zu erweitern.
                    </Box>
                </Box>

                <Separator/>

                <Box sx={{
                    backgroundColor: '#FCFCFC',
                    display: 'flex',
                    flexDirection: {
                        lg: 'row',
                        xs: 'column',
                    },

                    alignItems: {
                        lg: 'initial',
                        sm: 'center',
                        xs: 'initial'
                    },

                    padding: {
                        lg: '85px 0px 90px 130px',
                        xs: '24px 20px'
                    },
                    marginTop: {
                        md: 'initial',
                        xs: '16px'
                    },
                    marginBottom: {
                        md: 'initial',
                        xs: '16px'
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '24px',
                        paddingRight: {
                            xl: '160px',
                            lg: '60px',
                            xs: 'initial'
                        }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '24px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 800,
                                fontSize: '40px',
                                lineHeight: '48px',
                                color: '#F05B4D'
                            }}>
                                1
                            </Box>
                            <Box sx={{
                                paddingLeft: '20px'
                            }}>
                                <Box sx={{
                                    fontWeight: 500,
                                    fontSize: '15px',
                                    lineHeight: '18px',
                                    color: '#F05B4D',
                                    textTransform: 'uppercase'
                                }}>
                                    MEDIZINISCHE INHALTE
                                </Box>
                                <Box sx={{
                                    fontWeight: 700,
                                    fontSize: '24px',
                                    lineHeight: '32px',
                                    color: '#1D1D1D'
                                }}>
                                    VIDEOS & WEBINARE
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{
                            paddingLeft: {
                                md: '40px',
                                xs: '0px'
                            },
                            marginBottom: '30px',
                            width: '300px'
                        }}>
                            <Box sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#2A2A2A',
                                marginBottom: '8px'
                            }}>
                                Stelle Fortbildungsvideos zur Verfügung
                            </Box>
                            <Box sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#676767'
                            }}>
                                und teile deine Inhalte mit anderen KollegInnen und Kollegen.
                            </Box>
                        </Box>

                        <Box sx={{
                            paddingLeft: {
                                md: '40px',
                                xs: '0px'
                            },
                            marginBottom: '24px',
                            width: '300px'
                        }}>
                            <Box sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#2A2A2A',
                                marginBottom: '8px'
                            }}>
                                Positioniere deine Webinare auf einer Plattform
                            </Box>
                            <Box sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#676767'
                            }}>
                                und lasse alle wissen, wann dein Event stattfindet.
                            </Box>
                        </Box>

                        <Box sx={{
                            paddingLeft: {
                                md: '40px',
                                xs: '0px'
                            }
                        }}>
                            <PrimaryButton onClick={() => navigate('/kontakt')} sx={{
                                width: {
                                    md: 'initial',
                                    xs: '100%'
                                },
                                fontSize: {
                                    md: 'initial',
                                    xs: '17px !important'
                                },
                                lineHeight: {
                                    md: 'initial',
                                    xs: '21px !important'
                                }
                            }}>
                                Jetzt kontakieren
                            </PrimaryButton>
                        </Box>
                    </Box>

                    <Box sx={{
                        marginLeft: {
                            lg: '50px',
                            xs: 'initial'
                        },

                        display: 'flex',
                        alignItems: {
                            lg: 'initial',
                            xs: 'center'
                        },
                    }}>
                        <CardMedia
                            image={'webinars-new.png'}
                            sx={{
                                height: {
                                    lg: '100%',
                                    md: '360px',
                                    xs: '280px'
                                },
                                width: {
                                    md: '640px',
                                    xs: '480px'
                                },
                            }}
                        />
                    </Box>

                </Box>

                <Separator/>

                <Box sx={{
                    backgroundColor: '#FCFCFC',
                    display: 'flex',

                    flexDirection: {
                        lg: 'row-reverse',
                        xs: 'column',
                    },

                    alignItems: {
                        lg: 'initial',
                        sm: 'center',
                        xs: 'initial'
                    },

                    padding: {
                        // lg: '120px 150px 120px 0px',
                        xs: '24px 20px'
                    },

                    marginTop: {
                        md: 'initial',
                        xs: '16px'
                    },
                    marginBottom: {
                        md: 'initial',
                        xs: '16px'
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',

                        padding: {
                            xl: '120px 150px 120px 150px',
                            lg: '120px 150px 120px 50px'
                            // xs: '24px 20px'
                        },
                        paddingBottom: {
                            md: 'initial',
                            xs: '24px'
                        }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '24px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 800,
                                fontSize: '40px',
                                lineHeight: '48px',
                                color: '#F05B4D'
                            }}>
                                2
                            </Box>
                            <Box sx={{
                                paddingLeft: '20px'
                            }}>
                                <Box sx={{
                                    fontWeight: 500,
                                    fontSize: '15px',
                                    lineHeight: '18px',
                                    color: '#F05B4D',
                                    textTransform: 'uppercase'
                                }}>VERMARKTUNG</Box>
                                <Box sx={{
                                    fontWeight: 700,
                                    fontSize: '24px',
                                    lineHeight: '32px',
                                    color: '#1D1D1D',
                                    maxWidth: '320px'
                                }}>CHANNEL & NEWSLETTER</Box>
                            </Box>
                        </Box>

                        <Box sx={{
                            paddingLeft: {
                                md: '40px',
                                xs: '0px'
                            },
                            marginBottom: '30px',
                            width: '300px'
                        }}>
                            <Box sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#2A2A2A',
                                marginBottom: '8px'
                            }}>Gruppiere deine Videos in einem Channel</Box>
                            <Box sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#676767'
                            }}>und teile diesen mit deinen gewünschten ÄrztInnen.</Box>
                        </Box>

                        <Box sx={{
                            paddingLeft: {
                                md: '40px',
                                xs: '0px'
                            },
                            marginBottom: '24px',
                            width: '300px'
                        }}>
                            <Box sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#2A2A2A',
                                marginBottom: '8px'
                            }}>Erreiche deine Zielgruppen mit</Box>
                            <Box sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#676767'
                            }}>Hilfe unserer Newsletter</Box>
                        </Box>

                        <Box sx={{
                            paddingLeft: {
                                md: '40px',
                                xs: '0px'
                            }
                        }}>
                            <PrimaryButton onClick={() => navigate('/kontakt')} sx={{
                                width: {
                                    md: 'initial',
                                    xs: '100%'
                                },
                                fontSize: {
                                    md: 'initial',
                                    xs: '17px !important'
                                },
                                lineHeight: {
                                    md: 'initial',
                                    xs: '21px !important'
                                }
                            }}>
                                Jetzt kontakieren
                            </PrimaryButton>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: {
                            lg: 'initial',
                            xs: 'center'
                        },
                    }}>
                        <CardMedia
                            image={'landing-page-webinars-new.png'}
                            sx={{
                                height: {
                                    lg: '100%',
                                    md: '560px',
                                    xs: '380px'
                                },
                                width: {
                                    md: '640px',
                                    xs: '460px'
                                },
                            }}
                        />
                    </Box>

                </Box>

                <Separator/>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: {
                        md: '55px 0 65px 0',
                        xs: '40px 0 12px 0'
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        textTransform: 'uppercase',
                        textAlign: {
                            xs: 'center'
                        },
                        color: '#F05B4D',
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18px'
                    }}>Meducately team</Box>

                    <Box sx={{
                        display: 'flex',
                        textTransform: {
                            md: 'uppercase',
                            xs: 'capitalize',
                        },
                        textAlign: {
                            xs: 'center'
                        },
                        color: '#0E0E0E',
                        fontWeight: 800,
                        fontSize: {
                            xs: '28px',
                        },
                        lineHeight: {
                            xs: '34px',
                        }
                    }}>ZU IHRER VERFÜGUNG</Box>
                </Box>

                <Box m={{xs: '32px 0 0 0', md: '0 0 60px 0'}} flexDirection={{xs: 'column', md: 'row'}} display="flex"
                     justifyContent="space-evenly">
                    <Box  p={{xs: '20px 0 50px 0', md: 0}} display={{xs: 'flex', md: 'block'}} justifyContent="center">
                        <ContactPerson imageUrl="/fh.jpg" name="Dr. Fritz Höllerer" position="HEAD OF COOPERATIONS"
                                       email="f.hoellerer@meducately.com"/>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: {
                        md: '48px',
                        xs: '32px'
                    }
                }}>
                    <Box sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        textAlign: 'center',
                        color: '#404040',
                        maxWidth: {
                            md: '600px',
                            xs: '335px'
                        },
                        marginBottom: {
                            md: '8px',
                            xs: '32px'
                        }
                    }}>Wir hoffen einen kleinen Beitrag zu leisten, um medizinische Fortbildung sichtbarer zu machen und
                        freuen uns auf viele begeisterte User.</Box>

                    <Box sx={{
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: {
                            md: '19px',
                            xs: '24px'
                        },
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: '#404040',
                        marginBottom: '32px'
                    }}>
                        Euer meducately Team
                    </Box>

                    <Box sx={{
                        fontWeight: 400,
                        fontSize: '15px',
                        maxWidth: {
                            md: 'initial',
                            xs: '335px'
                        },
                        lineHeight: {
                            md: '18px',
                            xs: '24px'
                        },
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: '#676767'
                    }}>
                        Hinweis: meducately ist eine Marke der Hellomint GmbH und wird von dieser betrieben.
                    </Box>
                </Box>
            </Box>
        </Box>
    )
};

export default Partnership;
