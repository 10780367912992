import React from 'react';
import WebinarLanding from '../components/webinar-landing';
import Footer from '../components/layout/footer';
import { getParameterByName } from '../utils/location';

function WebinarLandingPage () {
  const utmSource = getParameterByName('utm_source');

  return (
    <>
      {/* @ts-ignore */}
      <WebinarLanding utmSource={utmSource}/>
      <Footer />
    </>
  );
}

export default WebinarLandingPage;
