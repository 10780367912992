import React from 'react';
import {
  VideoThumbnail
} from './style';

// @ts-ignore
const VideoThumbnailComponent = ({ card }) => {
    return (
     <div>
        <VideoThumbnail src={card?.featuredVideo?.video?.coverURL || card.coverURL}
        style={{ height: card?.title?.length <= 35 ? 230 : 195}}/>
     </div>
  )
}

export default VideoThumbnailComponent;