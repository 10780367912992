import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import {
  Div,
  ContainerDiv,
  CategoriesDiv,
  Category,
  CategoryTitle,
  CategoryItem,
  BottomContainerDiv,
  BottomContainerInnerDiv,
  Copyright,
  SocialIcons
} from './style';
import { deTranslations } from '../../../translations';
import FacebookIcon from '../../icons/facebook-icon';
import LinkedinIcon from '../../icons/linkedin-icon';
import InstagramIcon from '../../icons/instagram-icon';
import { getCurrentYear } from '../../../utils/time';

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const currentYear = getCurrentYear();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  {/* TODO: update links to pages */}
  return (
    <Div>
      {/* @ts-ignore */}
      <Box height="1px" backgroundColor="#C4C4C4" width="100%" />
      <ContainerDiv>
        <CategoriesDiv>
          <Category>
            <CategoryTitle>ABOUT</CategoryTitle>
            <CategoryItem href={'/ueberuns'}>Über uns</CategoryItem>
            {/*<CategoryItem href={'/ueberuns'}>Jobs</CategoryItem>*/}
            <CategoryItem href={'/impressum'}>Impressum</CategoryItem>
          </Category>
          {/* @ts-ignore */}
          <Box height="1px" backgroundColor="#C4C4C4" width="100%" m="10px 0 25px 0" display={{ xs: 'block', sm: 'none' }} />
          <Category>
            <CategoryTitle>SUPPORT</CategoryTitle>
            <CategoryItem href={'/kontakt'}>Kontakt</CategoryItem>
            <CategoryItem href={'/agb'}>AGB</CategoryItem>
            <CategoryItem href={'/datenschutz'}>Datenschutz</CategoryItem>
            <CategoryItem href={'/faq'}>Hilfe und FAQ</CategoryItem>
          </Category>
          {/* @ts-ignore */}
          <Box height="1px" backgroundColor="#C4C4C4" width="100%" m="10px 0 25px 0" display={{ xs: 'block', sm: 'none' }} />
          <Category>
            <CategoryTitle>SOLUTIONS</CategoryTitle>
            <CategoryItem href={'/partnership'}>Medizinische Einrichtungen</CategoryItem>
            <CategoryItem href={'/partnership'}>Pharmaindustrie</CategoryItem>
          </Category>
        </CategoriesDiv>
      </ContainerDiv>
      <BottomContainerDiv>
        <BottomContainerInnerDiv>
          <Copyright>
            © {currentYear} Meducately, Alle Rechte vorbehalten  |  Währinger Straße 39/5 1090 Wien, Österreich
          </Copyright>
          <SocialIcons>
            <a href="https://www.facebook.com/people/Meducately/100083109500117/" target="_blank">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/meducately/" target="_blank">
              <InstagramIcon />
            </a>
            <a href="https://www.linkedin.com/company/81562944/admin/" target="_blank">
              <LinkedinIcon />
            </a>
          </SocialIcons>
        </BottomContainerInnerDiv>
      </BottomContainerDiv>
    </Div>
  )
}

export default Footer;
