import React from 'react';
import {
  H2,
} from './style';

const SectionHeader = ({ children, style } : { children: any, style?: any }) => {
  return (
    <H2 style={style}>
      {children}
    </H2>
  )
}

export default SectionHeader;