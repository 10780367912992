import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { BpCheckedIcon, BpIcon } from './style';

// @ts-ignore
const CustomCheckbox = (props: CheckboxProps) => {
  return <Checkbox
    sx={{ width: 25, height: 25, padding: 0, }}
    disableRipple
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    {...props}
  />
};

export default CustomCheckbox;
