import styled from 'styled-components';

export const Div = styled.div(({theme}) => ({
    display: 'flex',
    width: '100%',
    backgroundColor: '#1D1D1D',
    padding: '10px 0 50px 0',

    [theme.breakpoints.down("sm")]: {
        padding: '0 0 20px 0'
    },
}));

export const Container = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
        width: '100%'
    },
}));

export const VideoBox = styled.div(({theme}) => ({
    width: 865,

    [theme.breakpoints.down("sm")]: {
        width: '100%',
        height: '100%',
        marginTop: 0,
    },
}));

export const VideoBoxText = styled.div(({theme}) => ({
    margin: '10px 0',
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    color: '#FFFFFF',
    fontSize: '24px',
    lineHeight: '32px',

    [theme.breakpoints.down("sm")]: {
        margin: 10,
        fontSize: 22
    },
}));

export const VideoBoxDescription = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    color: '#F7F7F7',

    [theme.breakpoints.down("sm")]: {
        margin: 10,
    },
}));

export const VideoBoxSpeaker = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 13,
    fontWeight: 400,
    color: '#FFFFFF',
    opacity: 0.7,

    [theme.breakpoints.down("sm")]: {
        margin: 10
    },
}));

export const VideoBoxInfoSpeakers = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    width: 865,
    gap: 5,

    [theme.breakpoints.down("sm")]: {
        width: '100%'
    },
}));

export const VideoBoxSpeakers = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 14,
    fontWeight: 400,
    color: '#FFFFFF',

    [theme.breakpoints.down("sm")]: {
        margin: 10
    },
}));

export const DFPInfoBox = styled.div(({theme}) => ({
    // display: 'flex',
    // flexDirection: 'row',
    width: 865,
    marginTop: 30,

    [theme.breakpoints.down("sm")]: {
        width: '100%',
        // flexDirection: 'column',
    },
}));

export const DFPInfoBoxContentContainer = styled.div(({theme}) => ({
    marginBottom: '35px',

    [theme.breakpoints.down("sm")]: {
        width: '100%',
        marginBottom: '15px',
    },
}));

export const DFPInfoBoxLabel = styled.div(({theme}) => ({
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#C4C4C4',
    textTransform: 'uppercase',
    marginBottom: 5,
    fontFamily: ['Inter', 'normal'].join(','),
}));

export const DFPInfoBoxContent = styled.span(({theme}) => ({
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    color: '#FFFFFF',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    fontFamily: ['Inter', 'normal'].join(','),

    [theme.breakpoints.down("sm")]: {
        display: 'block',
        marginBottom: 5
    },
}));

export const Buttons = styled.div(({theme}) => ({
    marginTop: 10,

    [theme.breakpoints.down("sm")]: {
        marginBottom: 20
    },
}));

export const Spacer = styled.span(({theme}) => ({
    marginLeft: '10px',
    marginRight: '10px',

    [theme.breakpoints.down("sm")]: {
        display: 'none'
    },
}));

export const DFPInfoBoxOuterContainer = styled.div(({theme}) => ({
    display: 'flex',

    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        padding: '0 10px'
    },
}));

export const DFPInfoBoxInnerContainer = styled.div(({theme}) => ({
    width: '50%',

    [theme.breakpoints.down("sm")]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
}));