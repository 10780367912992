import {List, Button, Label, RadioIcon} from './style';

interface Props {
    currentSelection: string,
    onPicked: Function,
    showLiveAttendanceOption: boolean,
    location?: string,
}

// @ts-ignore
const WebinarLocationList = ({
                                 onPicked,
                                 currentSelection,
                                 showLiveAttendanceOption,
                                 location = 'Hilton Vienna Stadtpark'
                             }: Props) => {
    return (
        <List>
            <Button onClick={() => onPicked('virtual')}>
                <Label>Virtuell</Label>
                <RadioIcon selected={currentSelection === 'virtual'}/>
            </Button>
            {
                showLiveAttendanceOption && (
                    <Button onClick={() => onPicked('onsite')}>
                        <Label>Live vor Ort ({location})</Label>
                        <RadioIcon selected={currentSelection === 'onsite'}/>
                    </Button>
                )
            }
        </List>
    )
};

export default WebinarLocationList;
