// @ts-ignore
function SearchIcon(props) {
  return(
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.02938 14.8198C11.7795 14.8198 14.8195 11.7798 14.8195 8.0297C14.8195 4.27962 11.7795 1.23958 8.02938 1.23958C4.2793 1.23958 1.23926 4.27962 1.23926 8.0297C1.23926 11.7798 4.2793 14.8198 8.02938 14.8198Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.8301 12.8286L18.8479 18.8464" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SearchIcon;
