import jwt_decode from 'jwt-decode';

const LOCAL_STORAGE_TOKEN = 'meducation-usr-token';
const LOCAL_STORAGE_REFRESH_TOKEN = 'meducation-usr-refresh-token';

class _TokenService {
  getToken() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN);
  }

  getRefreshToken() {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
  }

  // @ts-ignore
  setToken(token) {
    return localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
  }

  // @ts-ignore
  _setRefreshToken(refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  }

  getUser() {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
    if (!token) {
      return;
    }

    const decodedToken = jwt_decode(token);

    // @ts-ignore
    return {
      // @ts-ignore
      role: decodedToken.role,
      // @ts-ignore
      userId: decodedToken.sub,
    }
  }

  getUserRole() {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
    if (!token) {
      return;
    }

    // @ts-ignore
    return jwt_decode(token).role;
  }

  // @ts-ignore
  setUser({ token, refreshToken }) {
    this.setToken(token);
    this._setRefreshToken(refreshToken);
  }

  removeUser() {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  }
}

const TokenService = new _TokenService()

export default TokenService
