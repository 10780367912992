import api from '../';

// @ts-ignore
const getVideoFeed = async () => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/feed/web/videos`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const getWebinarFeed = async () => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/feed/web/webinars`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};

const getDFPVideoFeed = async () => {
    let response;
    let error;
    try {
        response = await api.get(`/v1/feed/web/dfpCourses`);
    } catch (err) {
        // @ts-ignore
        error = err.message;
    }
    return [error, response?.data];
};


export default {
    getVideoFeed,
    getWebinarFeed,
    getDFPVideoFeed
};