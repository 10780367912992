import React from 'react';
import {Div, Container, Content, ContactButton, Img} from './style';
import HorizontalRule from '../../common/horizontal-rule';
import SectionHeader from '../common/section-header';
import DescriptionText from '../common/description-text';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AnyQuestions = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Div>
            <Container>
                {/* @ts-ignore */}
                <HorizontalRule lighter={true} style={{ width: !isMobile ? '76%' : '100%', marginBottom: !isMobile ? 56 : 0 }} />
                <Content>
                    {isMobile &&
                      <Content style={{ marginTop: 56 }}>
                        <Img src='/animation-500.gif' alt=""/>
                      </Content>}
                    <SectionHeader style={{
                        marginBottom: 16,
                        width: '100%',
                        fontFamily: ['Inter', 'normal'].join(','),
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#0E0E0E',
                        alignSelf: isMobile ? 'center' : null,
                        textAlign: isMobile ? 'center' : null
                    }}>Noch Fragen?</SectionHeader>

                    <DescriptionText style={{
                        width: isMobile ? '80%' : '100%',
                        fontFamily: ['Inter', 'normal'].join(','),
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#676767',
                        marginBottom: '16px',
                        alignSelf: isMobile ? 'center' : null,
                        textAlign: isMobile ? 'center' : null
                    }}>
                        Zögere nicht falls du noch mehr Informationen über das Webinar brauchst
                    </DescriptionText>
                    <ContactButton onClick={() => window.location.href = 'mailto:office@meducately.com'}>
                        Kontaktiere uns
                    </ContactButton>
                </Content>
                {!isMobile &&
                  <Img src='/animation-500.gif' alt=""/>
                  }
            </Container>
        </Div>
    )
}

export default AnyQuestions;