import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';

export const Header = styled.div(({ theme }) => ({
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 20,

    '.descriptionRichText p': {
        fontSize: '15px',
        lineHeight: '18px',
        fontWeight: 400,
        color: '#676767',
    },
}));

export const TextCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    marginBottom: 5
}));

export const HeadlineText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#2A2A2A',
}));

export const ImageLogo = styled.img(({ theme }) => ({
    maxHeight: 33,
    marginLeft: 30
}));

// @ts-ignore
export const Content = styled.div(({ theme }) => ({
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
    }
}));

export const VideoImage = styled.img(({ theme }) => ({
    width: '100%',
    height: '100%',
    borderRadius: 5,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const VideoLength = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px 3px',
    gap: 10,

    position: 'absolute',
    height: 12,
    right: 10,
    bottom: 10,

    background: '#2E3436',
    borderRadius: 3,

    [theme.breakpoints.down(1025)]: {
        top: 45,
        right: 5
    },
}));

export const VideoLengthNumber = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 10,
    fontWeight: 500,
    color: '#FFFFFF',
    width: 26,
    height: 12,
    textAlign: 'end'
}));

export const VideoCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    marginBottom: 15,
    gap: 10,

    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 0,
    }
}));

export const VideoDiv = styled.div(({ theme }) => ({
    width: 285,
    height: 230,
    marginBottom: 40,
    maxHeight: 300,
    // filter: 'drop-shadow(0px 2.736px 27.36px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0.912px 0.912px rgba(0, 0, 0, 0.06))',
    transition: 'all 0.3s',

    ':hover': {
        // width: 350,
        // height: 300,
        // marginBottom: -40,
        transform: useMediaQuery(theme.breakpoints.down("sm")) ? 'scale(1.0)' : 'scale(1.1)',
        zIndex: 100
    }
}));

export const VideoDivTitle = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#1D1D1D',
    marginBottom: 3,
    maxHeight: 76,
    overflow: 'hidden',

    ':hover': {
        cursor: 'pointer'
    }
}));

export const VideoDivSourceDate = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    gap: 2,
    color: '#8E8E8E',
    fontSize: '15px',
    lineHeight: '18px',
}));

export const VideoDivSource = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#8E8E8E',
}));

export const VideoDivDate = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#8E8E8E',
}));

export const WebinarCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 15,
    gap: 20,
}));

export const WebinarDiv = styled.div(({ theme }) => ({
    position: 'relative',
    width: 250,
    height: 318,
    boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.09), 0px 0.912px 0.912px rgba(0, 0, 0, 0.01)',
    borderRadius: 4,
}));

export const WebinarDivTitle = styled.div(({ theme }) => ({
    position: 'absolute',
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#1D1D1D',
    marginLeft: 10,
    marginRight: 10,
    maxHeight: 57,
    overflow: 'hidden',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const WebinarDivDate = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 216,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#8E8E8E',
    opacity: 0.9,
    marginLeft: 10,
}));

export const ImageWebinar = styled.img(({ theme }) => ({
    width: 250,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const IconAndText = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 235,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    color: '#8E8E8E',
    opacity: 0.8,
    marginLeft: 10,
}));

export const IconText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#8E8E8E',
}));

export const ImageVideoIcon = styled.img(({ theme }) => ({
    width: 17.5,
    height: 17.5
}));

export const ContactButton = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 272,
    width: 233,
    height: 34,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    border: '1px solid #063F58',
    borderRadius: 4,
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15)) drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15))',
    marginLeft: 10,

    [theme.breakpoints.down("sm")]: {
    },

    ':hover': {
        cursor: 'pointer'
    }
}));

export const ContactButtonText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',

    ':hover': {
        cursor: 'pointer'
    }
}));
