import styled from 'styled-components';

// @ts-ignore
export const Text = styled.p(({ theme, small, big }) => ({
  fontFamily: 'Inter',
  fontSize: big ? 20 : small ? 15 : 16,
  lineHeight: big ? '24px' : small ? '18px' : '19px',
  fontWeight: big ? 600 : small ? 500 : 400,
  color: big ? '#2A2A2A' : small ? '#8E8E8E' : '#676767',
}));

export const Img = styled.img(({ theme }) => ({
  display: 'flex',
  width: 200,
  alignSelf: 'center',
}));
