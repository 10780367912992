import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  marginTop: 'auto',
}));

export const ContainerDiv = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '82px 0',
  boxSizing: 'border-box',
  margin: 0,
  backgroundColor: '#FCFCFC',
  [theme.breakpoints.down("sm")]: {
    padding: '20px',
    alignItems: 'flex-start'
  },
}));

export const BottomContainerDiv = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 48,
  width: '100%',
  backgroundColor: '#2A2A2A',
  padding: '0 20px',
  boxSizing: 'border-box',
  [theme.breakpoints.down("md")]: {
    height: 88
  },
}));

export const BottomContainerInnerDiv = styled.div(({ theme }) => ({
  display: 'flex',
  width: 940,
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down("md")]: {
    flexDirection: 'column',
    gap: '16px'
  },
}));

export const Copyright = styled.p(({ theme }) => ({
  margin: 0,
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '15px',
  color: '#FCFCFC',
  textAlign: 'center'
}));

export const SocialIcons = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '16px'
}));

export const CategoriesDiv = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '60px',
  [theme.breakpoints.down("md")]: {
    gap: '0',
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: 'column',
    width: '100%'
  },
}));

export const ImageCategoriesDiv = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  margin: 0,
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    marginRight: 24,
  },
}));

export const Category = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '190px'
}));

export const ImageCategory = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  margin: 0,
}));

export const CategoryTitle = styled.h5(({ theme }) => ({
  fontSize: 12,
  lineHeight: '15px',
  color: '#676767',
  fontWeight: 600,
  fontFamily: 'Inter',
  marginTop: 0,
  marginBottom: 20,
}));

export const CategoryItem = styled.a(({ theme }) => ({
  fontSize: 14,
  lineHeight: '17px',
  color: theme.palette.black,
  fontWeight: 400,
  fontFamily: 'Inter',
  marginBottom: 10,
  textDecoration: 'none',
  alignSelf: 'flex-start',

  ':hover': {
    cursor: 'pointer',
  }
}));

export const CategoryImage = styled.img(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  width: 100,
  marginTop: 6,
}));

export const DudeStandingOnHorizontalRule = styled.img(({ theme }) => ({
  height: 118,
}));

export const HorizontalRuleWithTheDudeOnTop = styled.div(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.textColors.label}`,
  width: 530,
  marginTop: 170,
  marginBottom: 25,
  position: 'absolute',
  display: 'block',
  [theme.breakpoints.down("sm")]: {
    width: '100%',
    marginTop: 241,
  },
}));

export const RightsTextDiv = styled.div(({ theme }) => ({
  marginTop: 0,
  marginBottom: 6,
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down("sm")]: {
    marginBottom: 48,
  },
}));

export const RightsText = styled.p(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.2,
  color: '#434849',
  fontWeight: 400,
  fontFamily: ['Inter', 'normal'].join(','),
  whiteSpace: 'break-spaces',
}));
