import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(({ theme }) => ({
  '&&': {
    color: '#063F58',
    fontSize: 14,
    lineHeight: 1.1,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    fontStyle: 'bold',
    textTransform: 'none',
    padding: '12px 20px',
    transition: '0.2s',
  },

  '&&:hover': {
    backgroundColor: 'transparent',
    transition: '0.2s',
    '& .MuiButton-endIcon': {
      // marginLeft: theme.spacing(4),
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0.5),
    }
  },
  '& .MuiButton-endIcon': {
    // marginLeft: theme.spacing(4),
    transition: '0.2s',
    marginTop: 3,
    paddingRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  }
}));

{/* @ts-ignore */}
export const RightArrowIcon = styled.span(({ theme, isLanding }) => ({
  '&:before': {
    display: 'block',
    width: 14,
    height: 12,
    backgroundImage: isLanding ? "url('arrow-right.svg')" : `url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.2 3L1 7M1 7L5.2 11M1 7H13' stroke='%23063F58' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    content: '""',
    transform: isLanding ? '' : 'rotate(180deg)',
  }
}));
