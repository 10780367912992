import React from 'react';
import Initial from '../components/signup/initial';
import Footer from '../components/layout/footer';
import { useNavigate } from 'react-router-dom';

function Signup () {
  const navigate = useNavigate();

  return (
    <>
      <Initial onLoginClick={() => navigate('/login')}/>
      <Footer />
    </>
  );
}

export default Signup;
