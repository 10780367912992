import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 41,
  fontSize: 18,
  lineHeight: 1.1,
  fontWeight: 400,
  width: '380px',
  borderRadius: 4,
  // Use the system font instead of the default Roboto font.
  fontFamily: ['Inter', 'normal'].join(','),
  backgroundColor: '#373737',

  [theme.breakpoints.down(1025)]: {
    width: '100%',
    height: '100%',
    backgroundColor: '#2A2A2A',
  },
}));

export const SearchInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    // borderRadius: 4,
    // color: '#063F58',
    // backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    fontSize: 15,
    lineHeight: 1.2,
    fontWeight: 400,
    paddingLeft: 15,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Inter', 'normal'].join(','),
    '&::placeholder': {
      color: '#FFFFFF',
      opacity: 100,
    },
  },
}));

export const SearchHr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.label}`,
  width: 'calc(100% + 44px)',
  marginLeft: -22,
  marginTop: 1,
}));
