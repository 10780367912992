import React from 'react';

// @ts-ignore
const YoutubeEmbed = ({ src }) => {
  return (
    <div className="video-responsive">
      <iframe
        width={src.width}
        height={src.height}
        src={'https://www.youtube.com/embed' + src.url}
        title={src.title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

export default YoutubeEmbed;