import styled from 'styled-components';

export const ContainerDiv = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: -100,
}));

export const LandingPageHero = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: 798,
    backgroundColor: '#0E0E0E',
    backgroundImage: 'url("/landing-page-hero.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    backgroundSize: '1045px 798px',
    [theme.breakpoints.down("sm")]: {
        height: 613,
        backgroundPosition: 'left center',
    },
}));

export const HeroContent = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 1050,
    marginTop: 238,
    [theme.breakpoints.down("lg")]: {
        width: '85%',
        marginTop: 238,
    },
    [theme.breakpoints.down("sm")]: {
        width: '80%',
        margin: '120px auto',
    },
}));

export const Title = styled.p(({theme}) => ({
    fontSize: 55,
    lineHeight: '60px',
    color: theme.palette.white,
    fontWeight: 800,
    fontFamily: 'Inter',
    textTransform: 'uppercase',
    margin: 0,

    [theme.breakpoints.down("sm")]: {
        fontSize: 40,
        lineHeight: '48px'
    },
}));

export const SubtitleText = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 10,
    [theme.breakpoints.down("sm")]: {
        maxWidth: 320,
    },
}));

export const Subtitle = styled.p(({theme}) => ({
    fontSize: 30,
    lineHeight: 1.2,
    color: theme.palette.black,
    fontFamily: 'Inter',
    fontWeight: 800,
    textTransform: 'uppercase',

    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
        fontSize: 24,
        marginTop: 35
    },
}));

export const ExtraText = styled.p(({ theme }) => ({
    fontSize: 16,
    lineHeight: '24px',
    color: '#C4C4C4',
    fontWeight: 400,
    fontFamily: 'Inter',
    whiteSpace: 'break-spaces',
    maxWidth: 390,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
        maxWidth: 300,
        fontSize: 16,
    },
}));

export const Buttons = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 22,
    [theme.breakpoints.down("sm")]: {
        maxWidth: 320,
    },
}));

export const PlatformDescriptionContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.backgroundColors.white,
}));

export const PlatformDescriptionContent = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 980,
    marginTop: 55,
    marginBottom: 71,

    [theme.breakpoints.down("lg")]: {
        width: '80%',
    },
}));

export const PlatformTitle = styled.p(({theme}) => ({
    fontSize: 27,
    lineHeight: 1.2,
    color: '#2E3436',
    alignSelf: 'flex-center',
    textAlign: 'center',
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
}));

export const PlatformSubtitle = styled.p(({theme}) => ({
    alignSelf: 'flex-center',
    fontSize: 15,
    lineHeight: 1.2,
    color: '#DDA303',
    fontWeight: 500,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
}));

export const PlatformItems = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    gap: 40,
    marginTop: 30,

    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export const PlatformStatsContainer = styled.div(({theme}) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 120,
    background: 'linear-gradient(90deg, #F05B4D 0%, #FCAF6D 68.75%, #FDC93A 100%)',
    width: 740,
    height: 120,
    marginLeft: '28%',
    borderRadius: 75,

    [theme.breakpoints.down("sm")]: {
        width: 315,
        height: 54,
        gap: 30,
        marginLeft: '24%',
    },
}));

export const PlatformRedLineContainer = styled.div(({theme}) => ({
    position: 'absolute',
    right: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(270deg, #F15D4E 0%, rgba(241, 92, 78, 0) 51.13%)',
    width: 380,
    height: 2,

    [theme.breakpoints.down("sm")]: {
        width: 35,
        height: 2
    },
}));

export const PlatformYellowLineContainer = styled.div(({theme}) => ({
    position: 'absolute',
    left: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(90deg, #FEC93B 0%, rgba(254, 201, 60, 0) 52.17%)',
    width: 330,
    height: 2,

    [theme.breakpoints.down("sm")]: {
        width: 35,
        height: 2,
        left: '99%',
    },
}));

export const VideosContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.backgroundColors.white,
}));

export const VideosContent = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '1200px',
    height: '565px',
    backgroundColor: '#FCFCFC',

    [theme.breakpoints.down("lg")]: {
        flexDirection: 'column-reverse',
        width: '80%',
        height: 'auto',
        paddingTop: '30px',
    },

    [theme.breakpoints.down("md")]: {
        width: '90%',
        marginBottom: '0px'
    },

    [theme.breakpoints.down("sm")]: {
        textAlign: 'center'
    },
}));

export const SplitTitle = styled.p(({theme}) => ({
    fontSize: '28px',
    lineHeight: '34px',
    color: '#0E0E0E',
    fontWeight: 800,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
    textTransform: 'uppercase',
    marginBottom: '16px'
}));

export const SplitTitleForSponsors = styled.p(({theme}) => ({
    fontSize: '28px',
    lineHeight: '34px',
    color: '#0E0E0E',
    fontWeight: 800,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
    textTransform: 'uppercase',
    marginBottom: '78px',

    [theme.breakpoints.down("md")]: {
        marginBottom: '0px',
        width: '225px'
    },
}));

export const SplitSubtitle = styled.p(({theme}) => ({
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.ctaError,
    fontWeight: 500,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
    textTransform: 'uppercase',

    [theme.breakpoints.down("md")]: {
        width: '225px'
    },
}));

export const Img = styled.img(({theme}) => ({
    display: 'flex',
    alignSelf: 'center',
    maxWidth: '100%',

    [theme.breakpoints.down("lg")]: {
        marginTop: '30px'
    },
}));

export const WebinarDescriptionContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 307,
    flexShrink: 0,
    marginLeft: '130px',
    marginRight: '200px',
    boxSizing: 'border-box',

    [theme.breakpoints.down(1400)]: {
        marginRight: '100px',
    },

    [theme.breakpoints.down("md")]: {
        width: '300px',
        marginTop: '30px',
        textAlign: 'left'
    },

    [theme.breakpoints.down("sm")]: {
        width: '94%',
        margin: 0
    },
}));

export const VideosDescriptionContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 307,
    flexShrink: 0,

    [theme.breakpoints.down("md")]: {
        width: '300px',
        marginTop: '30px',
        textAlign: 'left',
        marginLeft: '130px',
        marginRight: '200px',
    },
    [theme.breakpoints.down("sm")]: {
        width: '94%',
        margin: 0
    },
}));

export const Header = styled.p(({theme}) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2A2A2A',
    fontWeight: 600,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
    marginBottom: '8px',

    [theme.breakpoints.down("lg")]: {
        alignSelf: 'center',
        width: '300px',
    },
    [theme.breakpoints.down("sm")]: {
        alignSelf: 'start',
    },
}));

export const Description = styled.p(({theme}) => ({
    margin: 0,
    marginBottom: '25px',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#404040',
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),

    [theme.breakpoints.down("lg")]: {
        alignSelf: 'center',
        width: '300px',
        marginBottom: '15px'
    },
    [theme.breakpoints.down("sm")]: {
        alignSelf: 'start',
    },
}));

export const WebinarsContainer = styled.div(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.backgroundColors.white,
}));

export const WebinarsContent = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 auto',
    width: '1200px',
    height: '565px',
    backgroundColor: '#FCFCFC',

    [theme.breakpoints.down("lg")]: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        // marginBottom: '45px',
        height: 'auto',
        paddingTop: '30px',
    },

    [theme.breakpoints.down("md")]: {
        width: '90%',
        marginBottom: '0px'
    },

    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'center',
        // gap: 60,
        textAlign: 'center'
    },
}));

export const SponsorsContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
}));

export const Separator = styled.div(({theme}) => ({
    borderTop: `1px solid #C4C4C4`,
    width: 638,
    opacity: 0.4,
    margin: '0 auto',

    [theme.breakpoints.down("md")]: {
        width: '80%',
    },

    [theme.breakpoints.down("sm")]: {
        width: '84%',
    },
}));

export const SponsorsContent = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
    marginTop: 52,
    marginBottom: 69,

    [theme.breakpoints.down("md")]: {
        width: '90%',
        marginTop: '32px',
        marginBottom: '32px',
        textAlign: 'center'
    },
}));

export const Sponsors = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 811,

    [theme.breakpoints.down("md")]: {
        width: '100%',
    },
}));

export const SponsorsExtraText = styled.p(({theme}) => ({
    fontSize: 20,
    lineHeight: 1.2,
    color: theme.palette.textColors.bodyLight,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    fontStyle: 'italic',
    margin: 0,
    marginTop: 105,
}));

export const AppContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.backgroundColors.white,
    marginBottom: 91,
}));

export const AppContent = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 100,
    alignItems: 'center',
    marginLeft: -240,
    width: 980,
    marginTop: 30,

    [theme.breakpoints.down("lg")]: {
        flexDirection: 'column',
        marginLeft: 0,
        gap: 40,
        width: '80%',
    },
}));

export const AppDescriptionContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 321,
    flexShrink: 0,
}));

export const AppTitle = styled.p(({theme}) => ({
    fontSize: 27,
    lineHeight: 1.2,
    color: theme.palette.textColors.title,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    marginTop: 101,

    [theme.breakpoints.down("sm")]: {
        width: '80%',
        marginTop: 60,
    },
}));

export const AppDownloadTitle = styled.p(({theme}) => ({
    fontSize: 22,
    lineHeight: 1.2,
    color: theme.palette.textColors.title,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    marginTop: 55,
    marginBot: 26,
}));

export const AppStoreIcons = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 19,
    marginTop: 37,
}));

// OutroContainer,
//   OutroContent,
//   OutroTextAndButton,
//   OutroText,

export const OutroContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.textColors.body,
}));

export const OutroContent = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 718,
    marginTop: 90,
    marginBottom: 110,

    [theme.breakpoints.down("md")]: {
        width: '90%',
    },
}));

export const OutroTextAndButton = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 17,

    [theme.breakpoints.down("md")]: {
        flexDirection: 'column',
        gap: 24,
        width: '100%',
    },
}));

export const OutroText = styled.p(({theme}) => ({
    fontSize: 20,
    lineHeight: 1.2,
    color: theme.palette.white,
    maxWidth: 402,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
}));
