import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material';

export const Div = styled.div(({ theme }) => ({
  // display: 'flex',
  // flexDirection: 'column',
  // margin: theme.spacing(1),
  marginBottom: 10,
  // '&:focus-within label': {
  //   color: theme.palette.textColors.body
  // }
}));

export const DropdownIcon = styled.span({
  marginRight: 6,
  '&:before': {
    display: 'block',
    width: 15,
    height: 15,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6.25 6.25L11.5 1' stroke='%23063F58' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    content: '""',
    transform: 'rotate(360deg)'
  },
});

export const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0.4),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    color: theme.palette.textColors.body,
    backgroundColor: '#F6F6F6',
    fontSize: 15,
    fontWeight: 400,
    padding: '10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Inter', 'normal'].join(','),
    '&::placeholder': {
      color: theme.palette.textColors.label,
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '& .MuiMenuItem-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1.5),
    },
    '&:active': {
      backgroundColor: '#000',
    },
  },
}));

export const Placeholder = styled.div(({ theme }) => ({
  color: '#8E9190',
}));

// export const StyledMenu = styled(Menu)(({ theme }) => ({
//   '& .MuiPaper-root': {
//     borderRadius: 20,
//     marginTop: theme.spacing(1),
//     minWidth: 180,
//     color:
//       theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
//     boxShadow:
//       'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//     '& .MuiMenu-list': {
//       padding: '4px 0',
//     },
//     '& .MuiMenuItem-root': {
//       '& .MuiSvgIcon-root': {
//         fontSize: 18,
//         color: theme.palette.text.secondary,
//         marginRight: theme.spacing(1.5),
//       },
//       '&:active': {
//         backgroundColor: alpha(
//           theme.palette.primary.main,
//           theme.palette.action.selectedOpacity,
//         ),
//       },
//     },
//   },
// }));
