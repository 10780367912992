import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { AuthContext } from '../../../context/authContext';
import WebinarAPI from '../../../api/webinar';
import { Form } from './style';
import Card from '../../common/card';
import Button from '../../common/buttons/primary-button';
import { deTranslations } from '../../../translations';
import ConfirmationTicket from '../../tickets/confirmation-ticket';
import { FirstRow, SecondRow, Title, WebinarInfo } from './style';
import TextWithLabel from '../../common/text-with-label';
import ErrorModal from '../../common/error-modal';
import { format } from '../../../utils/time';
import NotEligible from '../../signup/not-eligible';
import ProfileAPI from '../../../api/profile';
import {useNavigate} from "react-router-dom";
import AreaOfPracticeList from '../../menus/place-of-work-radio-group';
import WebinarLocationList from '../../menus/webinar-location-list';

// @ts-ignore
const SubscribeExternal = ({ webinarData, audienceId, subscriptionToken }) => {
  const [success, setSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { user } = useContext(AuthContext);
  const [userProfile, setUserProfile] = React.useState({});
  const [isEligible, setIsEligible] = React.useState(undefined);
  const [attendance, setAttendance] = React.useState('virtual');
  const navigate = useNavigate();

  React.useEffect(() => {
    async function getUserProfile() {
      const [getUserProfileError, userProfileData] = await ProfileAPI.getCurrentUserProfile(user.userId);

      if (getUserProfileError) {
        setOpenModal(true);
        return;
      }

      setUserProfile(userProfileData);
    }

    getUserProfile();
  }, [])

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (success) {
      timeoutId = setTimeout(() => {
        window.open(
            "https://www.pfizer.at/", "_blank");
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    }
  }, [success]);


  const startDate = webinarData.startDate;
  const endDate = webinarData.endDate;

  const formattedDate = webinarData?.agenda?.entries?.length === 1
    ? `${format(endDate, 'd. MMMM yyyy')}`
    : `${format(startDate, 'd.')} + ${format(endDate, 'd.')} ${format(startDate, 'MMMM yyyy')}`;
  const formattedTime = format(startDate, 'HH:mm')
  // @ts-ignore
  const fullName = `${userProfile?.title} ${userProfile?.firstName} ${userProfile?.lastName}`;
  const formik = useFormik({
    initialValues: {
    },
    onSubmit: (async values => {
      const [subscribeError, subscribeResponse] = await WebinarAPI.subscribeToWebinar(webinarData?.Id);
      if (subscribeError) {
        setOpenModal(true);
        return;
      }
      if (webinarData != null) {
        setSuccess(true);
      } else {
        navigate('/videos')
      }
      // setSuccess(true);
    }),
  });

  async function subscribeOnButtonSubscribe() {
    // @ts-ignore
    const [subscribeError] = await Promise.all([WebinarAPI.subscribeToExternalWebinar(audienceId, attendance, subscriptionToken)]);

    if (subscribeError[0]) {
      setOpenModal(true);
      return;
    }
    setSuccess(true);
  }

  // async function unsubscribeOnButtonSubscribe() {
  //   // @ts-ignore
  //   const [subscribeError] = await Promise.all([WebinarAPI.unsubscribeToWebinar(webinarData?.id)]);
  //
  //   if (subscribeError[0]) {
  //     setOpenModal(true);
  //     return;
  //   }
  //   setSuccess(true);
  // }

  // @ts-ignore
  return (
      <>
        <ErrorModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            retry={formik.handleSubmit}
            title="Es ist ein Fehler aufgetreten"
            message="Beim Abonnieren des Webinars ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns."
        />
        {!success &&
            <Card title="Anmelden" sx={{ 'h2': {fontWeight: '600'} }}>
              <Form onSubmit={formik.handleSubmit}>
                <>
                  <Title>{webinarData.title}</Title>
                  <WebinarInfo>
                    <FirstRow>
                      <TextWithLabel label={deTranslations.first_name}>{fullName}</TextWithLabel>
                    </FirstRow>
                    <SecondRow>
                      <TextWithLabel label={deTranslations.date}>07. März 2023</TextWithLabel>
                      <TextWithLabel label={deTranslations.time}>18:00</TextWithLabel>
                    </SecondRow>
                  </WebinarInfo>
                  <WebinarLocationList currentSelection={attendance} onPicked={setAttendance} showLiveAttendanceOption />
                  <Button style={{marginTop: 32,}} onClick={subscribeOnButtonSubscribe}>Teilnehmen</Button>
                </>
              </Form>
            </Card>
        }
        {success &&
            <ConfirmationTicket
                // @ts-ignore
                title={userProfile.title}
                // @ts-ignore
                firstName={userProfile.firstName}
                // @ts-ignore
                lastName={userProfile.lastName}
                // @ts-ignore
                email={userProfile.email}
                formattedDate={formattedDate}
                formattedTime={formattedTime}
                webinarTitle={webinarData.title}
                webinarSubtitle={webinarData.subtitle}
                coverURL={webinarData.coverURL}
                isSubscribed={webinarData.isSubscribed}
                isExternalEvent={true}
            />
        }
      </>
  );
}

export default SubscribeExternal;
