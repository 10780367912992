import { List, Button, Label, RadioIcon } from './style';
import { deTranslations } from '../../../translations';

interface Props {
  onPicked: Function,
  sx?: object,
}

// @ts-ignore
const AreaOfPracticeList = ({ onPicked, sx }: Props) => {
  return (
    <List>
      <Button onClick={() => onPicked('private')}>
        <Label>{deTranslations.doctorWorkType.private}</Label>
        <RadioIcon />
      </Button>
      <Button onClick={() => onPicked('public')}>
        <Label>{deTranslations.doctorWorkType.public}</Label>
        <RadioIcon />
      </Button>
      <Button onClick={() => onPicked('hospital')}>
        <Label>{deTranslations.doctorWorkType.hospital}</Label>
        <RadioIcon />
      </Button>
    </List>
  )
};

export default AreaOfPracticeList;
