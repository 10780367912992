import styled from 'styled-components';

export const Header = styled.div(({theme}) => ({
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 20,

    '.descriptionRichText p': {
        fontSize: '15px',
        lineHeight: '18px',
        fontWeight: 400,
        color: '#676767',
    },
}));

export const TextCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    marginBottom: 5
}));

export const HeadlineText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
    color: '#2A2A2A',
}));

export const ImageLogo = styled.img(({ theme }) => ({
    maxHeight: 33,
    marginLeft: 30
}));

// @ts-ignore
export const Content = styled.div(({ theme }) => ({
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
    }
}));

export const VideoImage = styled.img(({theme}) => ({
    width: '100%',
    height: '100%',

    [theme.breakpoints.down("sm")]: {
        width: 300,
        height: 166
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const VideoLength = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px 3px',
    gap: 10,
    position: 'absolute',
    height: 12,
    right: 10,
    bottom: 10,
    background: '#2E3436',
    borderRadius: 3
}));

export const VideoLengthNumber = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 10,
    fontWeight: 500,
    color: '#FFFFFF',
    width: 26,
    height: 12,
    textAlign: 'end'
}));

export const VideoCategoriesDiv = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: '20px',
    marginTop: 20,
    flexWrap: 'wrap',

    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
    }
}));

export const VideoDiv = styled.div(({theme}) => ({
    width: 270,
    transition: 'all 0.3s',
    boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.09), 0px 0.912px 0.912px rgba(0, 0, 0, 0.01)',

    [theme.breakpoints.down("sm")]: {
        width: 300,
        maxWidth: '100%',
    }
}));

export const VideoDivTitle = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#2A2A2A',
    minHeight: '60px',
    overflow: 'hidden',
    marginBottom: '9px',

    [theme.breakpoints.down("sm")]: {}
}));

export const VideoDivDate = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#8E8E8E',
}));

export const WebinarCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 15,
    gap: 20,
}));

export const WebinarDivTitle = styled.div(({ theme }) => ({
    position: 'absolute',
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 15,
    color: '#2E3436',
    marginLeft: 10,
    marginRight: 10,
    maxHeight: 57,
    overflow: 'hidden',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const WebinarDivDate = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 216,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    fontSize: 13,
    color: '#676767',
    opacity: 0.9,
    marginLeft: 10,
}));

export const ImageWebinar = styled.img(({ theme }) => ({
    width: 250,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const IconAndText = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 235,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    color: '#434849',
    opacity: 0.8,
    marginLeft: 10,
}));

export const IconText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 12,
    color: '#434849',

    [theme.breakpoints.down("sm")]: {
    }
}));

export const ImageVideoIcon = styled.img(({ theme }) => ({
    width: 17.5,
    height: 17.5
}));

export const ContactButton = styled.div(({theme}) => ({
    marginTop: '10px',
    padding: '10px 16px',
    border: '1px solid #063F58',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15)) drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#063F58',

    ':hover': {
        cursor: 'pointer',
        backgroundColor: '#063F58',
        color: 'white'
    }
}));

export const ContactButtonText = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const VideoDivContent = styled.div(({theme}) => ({
    marginTop: '12px',
    padding: '0 12px 12px'
}));

export const VideoDivDFPPoints = styled.div(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px'
}));

export const VideoDivDFPPointsText = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    color: '#434849',
    marginLeft: '6px'
}));

export const VideoDivPublicationDate = styled.div(({theme}) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#676767',
    opacity: 0.9,

    [theme.breakpoints.down("sm")]: {}
}));