import React, { useState } from 'react';
import {
    Div,
    FeaturedContent,
    Outline,
    ButtonBoxElement,
    ButtonBoxLeft,
    ButtonBoxRight,
    VideoCard,
    Image,
    ImageLoading,
    VideoLength,
    VideoLengthNumber,
    VideoBoxText,
    VideoBoxSource,
    ImageRightCaret,
    ImageLeftCaret,
    StyledAliceCarouselWrapper,
    FeaturedContentDescription
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import VideoAPI from '../../../api/video';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
 import 'react-alice-carousel/lib/alice-carousel.css';

// @ts-ignore
const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {

// @ts-ignore
    return items.map((item, i) => (
        <div className="thumb" onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
            {item}
        </div>
    ));
};

// @ts-ignore
const Hero = ({ filter, meta }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sizeOfScreen1600 = useMediaQuery('(min-width:1600px)');
    const sizeOfScreen2000 = useMediaQuery('(min-width:2000px)');
    const sizeOfScreen2500 = useMediaQuery('(min-width:2500px)');
    const sizeOfScreen2950 = useMediaQuery('(min-width:2950px)');
    const sizeOfScreen3400 = useMediaQuery('(min-width:3400px)');

    const [bigData, setBigData] = React.useState([]);
    const [start, setStart] = React.useState(0)
    const [last, setLast] = React.useState(3)
    const [step, setStep] = React.useState(3);
    const [countStep, setCountStep] = React.useState(2);
    const [touchStart, setTouchStart] = React.useState(null)
    const [touchEnd, setTouchEnd] = React.useState(null)
    const [loadDone, setLoadDone] = React.useState(false)

    const [nextEnabled, setIsNextEnabled] = React.useState(true)
    const [prevEnabled, setIsPrevEnabled] = React.useState(true)
    const navigate = useNavigate();

    const [mainIndex, setMainIndex] = useState(0);
    const [mainAnimation, setMainAnimation] = useState(false);
    const [thumbIndex, setThumbIndex] = useState(0);
    const [thumbAnimation, setThumbAnimation] = useState(false);
    const [thumbs] = useState(thumbItems(bigData, [setThumbIndex, setThumbAnimation]));

    const slideNext = () => {
        if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex + 1);
        }
    };

    const slidePrev = () => {
        if (!thumbAnimation && thumbIndex > 0) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex - 1);
        }
    };

    const syncMainBeforeChange = (e: any) => {
        setMainAnimation(true);
    };

    const syncMainAfterChange = (e : any) => {
        setMainAnimation(false);

        if (e.type === 'action') {
            setThumbIndex(e.item);
            setThumbAnimation(false);
        } else {
            setMainIndex(thumbIndex);
        }
    };

    const syncThumbs = (e: EventObject) => {
        setThumbIndex(e.item);
        setThumbAnimation(false);

        if (!mainAnimation) {
            setMainIndex(e.item);
        }

        setIsPrevEnabled(!e.isPrevSlideDisabled);
        setIsNextEnabled(!e.isNextSlideDisabled);
    };

    React.useEffect(() => {
        // @ts-ignore
        VideoAPI.getAllVideos(filter + '&s=' + JSON.stringify(JSON.parse(meta)) + '').then( (response: any)=> {
            setBigData(response[1]);
            setLoadDone(true);
        });
    }, []);

    React.useEffect(() => {
        if (sizeOfScreen3400) {
            setLast(7);
            setCountStep(7);
        } else if (sizeOfScreen2950) {
            setLast(6);
            setCountStep(6);
        } else if (sizeOfScreen2500) {
            setLast(5);
            setCountStep(5);
        } else if (sizeOfScreen2000) {
            setLast(4);
            setCountStep(4);
        } else if (sizeOfScreen1600) {
            setLast(3);
            setCountStep(3);
        } else if (isMobile) {
            setLast(bigData?.length - 1);
        }
    }, [sizeOfScreen3400, sizeOfScreen2950, sizeOfScreen2500, sizeOfScreen2000, sizeOfScreen1600, isMobile, bigData])

    const navigateToPage = (video: any) => {
        navigate('/video-play' + '/' + 'featured' + '/' + '92634' + '/' + video.id + '/' +
            video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', ''));
    }

    const setMoreVideos = (  ) => {
        // setStart(start + countStep)
        // setLast(last + countStep)
        // setStep( step + 1)
        // @ts-ignore
        carouselRef?.current?.slideNext();
    }

    const setLessVideos = ( ) => {
        // setStart(start - countStep)
        // setLast(last - countStep)
        // setStep( step - 1)
        // @ts-ignore
        carouselRef?.current?.slidePrev();
    }

    const onSlideChaged = (e : EventObject) => {
        // setStart(!e.isPrevSlideDisabled ? 0 : 100)
        // setLast(e.isNextSlideDisabled? 100:0)
        setIsNextEnabled(!e.isNextSlideDisabled);
        setIsPrevEnabled(!e.isPrevSlideDisabled);
    }

    const minSwipeDistance = 50;

    const onTouchStart = (e: any) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            if (start !== last) {
                setStart(start + 1);
            }
        }

        if (isRightSwipe) {
            if (start !== 0) {
                setStart(start - 1);
            }
        }
    };

    const handleDragStart = (e : any) => e.preventDefault();   

    const  items = bigData.map((video: any, index: number) => (
        <VideoCard key={index+20} onClick={() => navigateToPage(video)}  onDragStart={handleDragStart}>
            <div style={{ position: 'relative' }}>
                <Image src={video?.coverURL}/>
                <VideoLength style={{ width: video?.length/3600 > 1 ? 33 : video?.length/60 < 10 ? 23 : 27 }}>
                    <VideoLengthNumber>
                        {(video?.length/3600 > 1 ? Math.floor(video?.length/3600) + ':' : null)}
                        {(video?.length%3600/60 <= 9 ? '0' + Math.floor(video?.length%3600/60) : Math.floor(video?.length%3600/60))}
                        :{video?.length%60 < 10 ? '0' + video?.length%60 : video?.length%60}
                    </VideoLengthNumber>
                </VideoLength>
            </div>
            <VideoBoxText>{video.title}</VideoBoxText>
            <VideoBoxSource>{video.source}</VideoBoxSource>
        </VideoCard>
    ));

    const responsive = {
        0: { items: 1 },
        900: { items: 2 },
        1450: { items: 3 },
    };

    const carouselRef = React.useRef(null);

    const renderPrev = ({isDisabled} : {isDisabled: any}) => {
       return ( <ButtonBoxLeft >
        <ButtonBoxElement>
          <ImageLeftCaret src={'chevron.png'} />
        </ButtonBoxElement>
      </ButtonBoxLeft> )
    };

    const renderNext = ({isDisabled} : {isDisabled: any}) => {
        return ( <ButtonBoxLeft >
         <ButtonBoxElement>
           <ImageLeftCaret src={'chevron.png'} />
         </ButtonBoxElement>
       </ButtonBoxLeft> )
     };

    return (
        <Div>
            {prevEnabled && !isMobile &&
              <ButtonBoxLeft onClick={() => setLessVideos()}>
                <ButtonBoxElement>
                  <ImageLeftCaret src={'chevron.png'} />
                </ButtonBoxElement>
              </ButtonBoxLeft>
            }
            {!loadDone
                ? <ImageLoading src={'loading-transparent-gif.gif'}/>
                : <Outline >
                    <FeaturedContent>Featured</FeaturedContent>
                    <FeaturedContentDescription>Mit meducately geht eine neue Plattform an den Start, die medizinische Fort- und Weiterbildung am Puls der Zeit anbietet
                    </FeaturedContentDescription>
                    <StyledAliceCarouselWrapper>
                        <AliceCarousel autoWidth
                                       mouseTracking={isMobile}
                                       onSlideChanged={syncThumbs}
                                       onInitialized={syncThumbs}
                                       onResized={syncThumbs}
                                       activeIndex={thumbIndex}
                                       ref={carouselRef}// onSlideChanged={onSlideChaged} onInitialized={onSlideChaged}
                                       responsive={responsive}
                        >
                            {items}
                        </AliceCarousel>
                    </StyledAliceCarouselWrapper>
                </Outline>
            }

            {nextEnabled && !isMobile &&
              <ButtonBoxRight onClick={() => setMoreVideos()}>
                <ButtonBoxElement>
                  <ImageRightCaret src={'chevron.png'} />
                </ButtonBoxElement>
              </ButtonBoxRight>
            }
        </Div>
    )
}

export default Hero;