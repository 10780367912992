import React, {useContext} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Content,
    TextCategoriesDiv,
    Header,
    HeadlineText,
    ImageLogo,
    VideoCategoriesDiv,
    VideoDiv,
    VideoDivSourceDate,
    VideoDivSource,
    VideoDivDate,
    VideoDivTitle,
    VideoImage,
    WebinarCategoriesDiv,
    WebinarDiv,
    WebinarDivTitle,
    WebinarDivDate,
    ImageWebinar,
    IconAndText,
    IconText,
    ContactButton,
    ContactButtonText,
    VideoLength,
    VideoLengthNumber,
    ImageVideoIcon
} from './style';
import DescriptionText from '../webinar-landing/common/description-text';
import HorizontalRule from '../common/horizontal-rule';
import { format } from '../../utils/time';
import DFPIcon from '../icons/dfp-icon';
import SubscribeModal from '../webinar-landing/subscribe-modal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlayButtonIcon from '../icons/play-button-icon';
import VideoAPI from '../../api/video';
import WebinarAPI from "../../api/webinar";
import FeedAPI from "../../api/feed";
import { AuthContext } from "../../context/authContext";
import SignupModal from "../signup/signup-modal";

// @ts-ignore
const ViewMoreLanding = () => {

    const theme = useTheme();

    const location = useLocation();
    const navigate = useNavigate();
    const { type, id, title } = useParams();
    const [listOfData, setListOfData] = React.useState([])
    const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
    const [webinarData, setWebinarData] = React.useState({});
    const [videoData, setVideoData] = React.useState({});
    const [showPlayButton, setShowPlayButton] = React.useState(false);
    const [showContactButton, setShowContactButton] = React.useState(false);
    const [keyPlayButton, setKeyPlayButton] = React.useState(0);
    const [innerWindowSize, setInnerWindowSize] = React.useState(0);
    const { user } = useContext(AuthContext);
    const [signupModalOpen, setSignupModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (type === 'channels') {
            VideoAPI.getChannelById(id).then( (response: any)=> {
                setListOfData(response[1])
            });
        }

        if (type === 'search') {
            if (location?.state != null) {
                // @ts-ignore
                setListOfData(location?.state?.data.sort((x, y) => {
                    const xDate = x.publicationDate ? new Date(x.publicationDate) : new Date(x.startDate);
                    const yDate = y.publicationDate ? new Date(y.publicationDate) : new Date(y.startDate);

                    return xDate > yDate;
                }))
            } else {
                const prepareDataForSearchList = async () => {
                    const dateFilter = new Date();
                    // Set date to 3 years in the past
                    dateFilter.setDate(dateFilter.getDate() - 1095);

                    const videoFilter = `publicationDate||$gte||${dateFilter.toISOString()}`;
                    const webinarFilter = `startDate||$gte||${dateFilter.toISOString()}`;

                    // @ts-ignore
                    const responseVideo = await Promise.all([VideoAPI.searchVideo(title, videoFilter)])
                    // @ts-ignore
                    const responseWebinar = await Promise.all([WebinarAPI.searchWebinars(title, webinarFilter)])
                    const prepareSearchList = [...responseWebinar, ...responseVideo];
                    let searchArray: any = [];
                    prepareSearchList.map((option: any) => {
                        option.map((data: any) => {
                            searchArray = [...searchArray, data]
                        })
                    })

                    return searchArray
                }

                prepareDataForSearchList().then((response) => {
                    // @ts-ignore
                    setListOfData(response.sort((x, y) => {
                        const xDate = x.publicationDate ? new Date(x.publicationDate) : new Date(x.startDate);
                        const yDate = y.publicationDate ? new Date(y.publicationDate) : new Date(y.startDate);

                        return xDate > yDate;
                    }))
                })
            }
        }

        if (type === 'toContinue') {
            FeedAPI.getVideoFeed().then((response: any) => {
                VideoAPI.getAllVideos(response[1].filter((feed: any) => feed.headline === 'video_list_to_continue_watching')[0].filterMore).then((response: any) => {
                    setListOfData(response[1])
                });
            });
        }
    }, [type, id, title, location])

    React.useEffect(() => {
        if (window.innerWidth >= 3200) {
            setInnerWindowSize(140)
        } else if (window.innerWidth >= 2133) {
            setInnerWindowSize(230)
        } else if (window.innerWidth >= 2000) {
            setInnerWindowSize(250)
        } else if (window.innerWidth >= 1600) {
            setInnerWindowSize(270)
        } else if (window.innerWidth >= 1455) {
            setInnerWindowSize(335)
        } else if (window.innerWidth >= 1280) {
            setInnerWindowSize(360)
        } else if (window.innerWidth >= 1067) {
            setInnerWindowSize(470)
        }
    }, [window.innerWidth])

    const navigateToPage = (video: any) => {
        if (user != null) {
            if (type === 'channels') {
                navigate('/video-play' + '/' + 'channels' + '/' + id + '/' + video.id + '/' +
                    video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            } else if (type === 'search') {
                navigate('/video-play' + '/' + 'search' + '/' + title + '/' + video.id + '/' +
                    video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            } else {
                navigate('/video-play' + '/' + 'toContinue' + '/' + '96745' + '/' + video.id + '/' +
                    video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            }
        } else {
            setSignupModalOpen(true)
            if (type === 'channels') {
                setVideoData('/video-play' + '/' + 'channels' + '/' + id + '/' + video.id + '/' +
                    video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            } else if (type === 'search') {
                setVideoData('/video-play' + '/' + 'search' + '/' + title + '/' + video.id + '/' +
                    video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            } else {
                setVideoData('/video-play' + '/' + 'toContinue' + '/' + '96745' + '/' + video.id + '/' +
                    video.title.replaceAll(' ', ''). replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            }
        }
    }

    const calculateDFPPoints = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.dfpPoints))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    const calculateVortrage = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.slots.length))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    const openSubscribeModal = React.useCallback((webinar: any) => {
        setWebinarData(webinar)
        setShowSubscribeModal(true);
    }, [showSubscribeModal]);

    const closeSubscribeModal = React.useCallback(() => {
        setShowSubscribeModal(false);
        navigate(0)
    }, [showSubscribeModal])

    const hoverShowPlayButton = (shouldShow: any, key: any) => {
        setShowPlayButton(shouldShow)
        setKeyPlayButton(key)
    }

    const hoverChangeColorContactButton = (shouldShow: any, key: any) => {
        setShowContactButton(shouldShow)
        setKeyPlayButton(key)
    }

    // @ts-ignore
    const listOfVideos = listOfData?.hasOwnProperty('type') ? listOfData?.type === 'video' ? listOfData?.videos.map((video) => video.video) : [] : listOfData?.filter((item) => item.hasOwnProperty('videoURL'))
    // @ts-ignore
    const listOfWebinars = listOfData?.hasOwnProperty('type') ? listOfData?.type === 'webinar' ? listOfData?.webinars.map((webinar) => webinar.webinar) : [] : listOfData?.filter((item) => !item.hasOwnProperty('videoURL'))
    // @ts-ignore
    const description = listOfData?.description
    // @ts-ignore
    const logo = listOfData?.logoURL
    // @ts-ignore
    const numberOfData = listOfVideos.length + listOfWebinars.length

    return (
        <>
            <SubscribeModal
                open={showSubscribeModal}
                handleClose={closeSubscribeModal}
                webinarData={webinarData}
            />
            <SignupModal
                open={signupModalOpen}
                videoData={videoData}
                handleClose={() => setSignupModalOpen(false)}
                doneFunction={() => navigate(0)}
            />
            <Header>
                <TextCategoriesDiv>
                    <HeadlineText>
                        {/*@ts-ignore*/}
                        {type === 'channels' ? listOfData?.name : type === 'toContinue' ? 'Weiter Ansehen' :
                            <><span style={{fontWeight: 700, fontSize: '24px', lineHeight: '32px'}}>Zeige Suchergebnisse für</span> <span style={{color: '#F05B4D', fontWeight: 700, fontSize: '24px', lineHeight: '32px'}}>“{title}”</span>
                            </>
                        }
                    </HeadlineText>
                    {logo ? <><div style={{color: '#676767', marginLeft: '30px', lineHeight: '32px'}}>|</div><ImageLogo src={logo}/></> : ''}
                </TextCategoriesDiv>
                <DescriptionText>{description}</DescriptionText>
                <span style={{fontFamily: 'Inter', color: '#676767', fontWeight: 400, fontSize: '15px', lineHeight: '18px'}}>{numberOfData} Ergebnisse zu diesem Begriff gefunden</span>
            </Header>
            {/* @ts-ignore */}
            <HorizontalRule lighter={true} />
            {/* @ts-ignore */}
            <Content style={{ height: '100%'}}>
                <WebinarCategoriesDiv>
                    {listOfWebinars?.map((webinar: any, index: number) => {
                        const dfpPoints = webinar?.agenda !== null ? calculateDFPPoints(webinar?.agenda) : 0;
                        const vortrage = webinar?.agenda !== null ? calculateVortrage(webinar?.agenda) : 0;

                        return (
                            <WebinarDiv key={index}
                                        onMouseEnter={() => hoverChangeColorContactButton(true, index)}
                                        onMouseLeave={() => hoverChangeColorContactButton(false, index)}>
                                <ImageWebinar style={{ height: webinar?.title.length < 33
                                        ? 185
                                        : (webinar?.title.length >= 33 && webinar?.title.length < 63) ? 166 : 147}}
                                              src={webinar?.coverURL}
                                              onClick={() => navigate('/webinar/' + webinar.id + '/' + webinar.title.replaceAll(' ', ''). replaceAll('/', ''))}/>
                                <WebinarDivTitle style={{ top: webinar?.title.length < 33
                                        ? 195
                                        : (webinar?.title.length >= 33 && webinar?.title.length < 63) ? 176 : 157}}
                                                 onClick={() => navigate('/webinar/' + webinar.id + '/' + webinar.title.replaceAll(' ', ''). replaceAll('/', ''))}>
                                    {webinar?.title}
                                </WebinarDivTitle>
                                {(format(webinar?.startDate, 'dd. MMM yyyy.') === format(webinar?.endDate, 'dd. MMM yyyy.'))
                                    ? <WebinarDivDate>{format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                    : (format(webinar?.startDate, 'yyyy.') !== format(webinar?.endDate, 'yyyy.'))
                                        ? <WebinarDivDate>{format(webinar?.startDate, 'dd. MMM yyyy.')} - {format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                        : (format(webinar?.startDate, 'MMM yyyy.') !== format(webinar?.endDate, 'MMM yyyy.'))
                                            ? <WebinarDivDate>{format(webinar?.startDate, 'dd. MMM')} - {format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                            : <WebinarDivDate>{format(webinar?.startDate, 'dd.')} - {format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                }
                                <IconAndText>
                                    <DFPIcon style={{ width: 17.5, marginTop: 4 }} />
                                    <IconText>{dfpPoints === 1 ? '1 DFP Punkt' : `${dfpPoints} DFP Punkte`}</IconText>
                                    <ImageVideoIcon src={'streamline-icon-video-player-movie.png'}/>
                                    <IconText>{vortrage === 1 ? '1 Vortrag' : `${vortrage} Vorträge`}</IconText>
                                </IconAndText>
                                <ContactButton style={{ backgroundColor: showContactButton && keyPlayButton === index ? '#063F58' : '#FFFFFF' }}
                                               onClick={() => openSubscribeModal(webinar)}>
                                    <ContactButtonText style={{ color: showContactButton && keyPlayButton === index ? '#FFFFFF' : '#063F58' }}>
                                        Anmelden
                                    </ContactButtonText>
                                </ContactButton>
                            </WebinarDiv>
                        )
                    })}
                </WebinarCategoriesDiv>
                <VideoCategoriesDiv>
                    {listOfVideos?.map((video: any, index: number) => (
                        <VideoDiv key={index}
                                  onMouseEnter={() => hoverShowPlayButton(true, index)}
                                  onMouseLeave={() => hoverShowPlayButton(false, index)}>
                            <div style={{ position: 'relative' }}>
                                <VideoImage style={{ maxHeight: (showPlayButton && keyPlayButton === index) ? 190 : 160 }}
                                            onClick={() => navigateToPage(video)}
                                            key={index} src={video?.coverURL}/>
                                {(showPlayButton && keyPlayButton === index) &&
                                  <PlayButtonIcon style={{width: 49, height: 49, position: 'absolute', top: '40%', left: '42%'}}
                                                  onClick={() => navigateToPage(video)}
                                  />}
                                <VideoLength style={{ width: video?.length/3600 > 1 ? 33 : video?.length/60 < 10 ? 24 : 27 }}>
                                    <VideoLengthNumber>
                                        {(video?.length/3600 > 1 ? Math.floor(video?.length/3600) + ':' : null)}
                                        {((video?.length%3600)/60 < 10 ? '0' + Math.floor(video?.length%3600/60) : Math.floor(video?.length%3600/60))}
                                        :{video?.length%60 < 10 ? '0' + video?.length%60 : video?.length%60}
                                    </VideoLengthNumber>
                                </VideoLength>
                            </div>
                            <VideoDivTitle>
                                {video?.title}
                            </VideoDivTitle>
                            <VideoDivSourceDate>
                                <VideoDivSource>{video?.source}</VideoDivSource><div style={{ marginTop: 1}}> • </div>
                                <VideoDivDate>{format(video?.publicationDate, 'dd. MMM yyyy.')}</VideoDivDate>
                            </VideoDivSourceDate>
                        </VideoDiv>
                    ))}
                </VideoCategoriesDiv>
            </Content>
        </>
    )
}

export default ViewMoreLanding;
