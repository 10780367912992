import styled from 'styled-components';

export const H2 = styled.h2(({ theme }) => ({
  fontSize: 27,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: theme.palette.textColors.body,
  margin: 0,
}));
