import { StyledModal, StyledDiv } from './style';
import React, { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import Login from '../initial';
import Signup from '../../signup/initial';

// @ts-ignore
const LoginModal = ({ open, handleClose, doneFunction }: { open: boolean, handleClose: any, doneFunction?: any }) => {
  const { userProfile } = useContext(AuthContext);
  const [isLogin, setIsLogin] = React.useState(true);

  React.useEffect(() => {
    setIsLogin(true);
  }, [open])

  return <StyledModal
    open={open}
    disableAutoFocus={true}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledDiv>
      {isLogin ?
          <Login onSignupClick={() => setIsLogin(false)} handleClose={handleClose} doneFunction={doneFunction} />
          :
          <Signup onLoginClick={() => setIsLogin(true)} doneFunction={doneFunction} />
      }
    </StyledDiv>
  </StyledModal>
}

export default LoginModal;