import React from 'react';
import ChannelsLanding from '../components/channels';
import Footer from '../components/layout/footer';

function ChannelsLandingPage() {

    return (
        <>
            <ChannelsLanding/>
            <Footer/>
        </>
    );
}

export default ChannelsLandingPage;
