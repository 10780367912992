import styled from 'styled-components';

export const Title = styled.h2(({theme}) => ({
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#2A2A2A',
    margin: '0 0 20px 0'
}));

export const Form = styled.form(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',

    'div': {
        position: 'relative',
    },

    '.MuiInputBase-root': {
        marginBottom: 2
    },

    '.MuiFormHelperText-root': {
        fontSize: 10,
        position: 'absolute',
        bottom: '-12px'
    }
}));
