import Card from '../../common/card';
import { deTranslations } from '../../../translations';
import { Img, Text } from './style';
import BackButton from '../../common/buttons/back-button';

// TODO: move these string to translations at some point
const TYPES = {
  missingOeak: {
    title: 'Deine ÖÄK Nummer fehlt',
    subtitle: 'Klicke auf „Mein Profil“ im Menü oben und danach auf „Profil Einstellungen“. Dort kannst du deine Nummer neu hinterlegen.'
  },
  wrongOccupation: {
    title: 'Teilnahme nicht möglich',
    subtitle: 'Das Sammeln von DFP Punkten ist nur ÄrztInnen vorbehalten. Wir bitten um Verständnis.'
  },
}

const NotEligible = ({ onClose, type }: { onClose: () => void, type: string }) => {
  console.log('TYPE: ', type);
  // @ts-ignore
  return <Card title={type ? TYPES[type].title : ''} sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}}>
    {/* @ts-ignore */}
    <Text style={{ marginBottom: 20, marginTop: 0, textAlign: 'center' }}>{type ? TYPES[type].subtitle : ''}</Text>
    <Img src='/sorry.png' alt="" />
    <div style={{
      marginTop: 22,
      marginBottom: -22,
      marginLeft: -22,
      marginRight: -22,
      height: 39,
      borderTop: '1px solid #8E9190'
    }}>
      <BackButton onClick={onClose} />
    </div>
  </Card>
};

export default NotEligible;
