// @ts-ignore
function CloseIcon(props) {
  return(
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 3L21 22" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M21 3L2 22" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}

export default CloseIcon;
