import React from 'react';
import { Container, SearchInput } from './style';
import Divider from '@mui/material/Divider';
import { theme } from '../../../../styles';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '../../../icons/search-icon';
import VideoAPI from '../../../../api/video';
import WebinarAPI from '../../../../api/webinar';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '../../../icons/close-icon';

// @ts-ignore
const Search = ({isDrawer, setSearchList, setShowMore, search, setSearch, navigateToPageViewMore, hideSearch}
                    : {
    isDrawer?: boolean, setSearchList?: any, setShowMore?: any,
    search?: any, setSearch?: any, navigateToPageViewMore: any,
    hideSearch?: () => void
}) => {
    const label = isDrawer ? 'Suche' : 'Suche nach Videos und Webinaren';

    const isMobile = useMediaQuery('@media (max-width:1024px)');

    const prepareDataForSearchList = async (search: any) => {
        const dateFilter = new Date();
        // Set date to 3 years in the past
        dateFilter.setDate(dateFilter.getDate() - 1095);

        const videoFilter = `publicationDate||$gte||${dateFilter.toISOString()}`;
        const webinarFilter = `startDate||$gte||${dateFilter.toISOString()}`;

        setSearch(search)
        // @ts-ignore
        const responseVideo = await Promise.all([VideoAPI.searchVideo(search, videoFilter)])
        // @ts-ignore
        const responseWebinar = await Promise.all([WebinarAPI.searchWebinars(search, webinarFilter)])
        const prepareSearchList = [...responseWebinar, ...responseVideo];
        let searchArray: any = [];
        prepareSearchList.map((option: any) => {
            option.map((data: any) => {
                searchArray = [...searchArray, data]
            })
        })

        setTimeout(setSearchList(searchArray), 750)

        return searchArray
    }

    const mobileSearchList = async () => {
        await prepareDataForSearchList(search).then((response) => navigateToPageViewMore(response, search))
    }

    const handleSearchCloseOnMobile = () => {
        if (hideSearch) {
            hideSearch();
            setSearch('');
        }
    }

    return <Container>
        <SearchInput
            sx={{ml: 1, flex: 1, color: '#FFFFFF',}}
            placeholder={label}
            value={search}
            inputProps={{'aria-label': search}}
            onChange={(event: any) => prepareDataForSearchList(event.target.value)}
            onKeyDown={(event: any) => {
                if (event.code === "Enter" || event.code === "NumpadEnter") {
                    mobileSearchList()
                    event.preventDefault();
                }
            }}
        />
        {!isMobile && <Divider sx={{height: 41, borderColor: theme.palette.textColors.bodyLight}} orientation="vertical"/>}
        <IconButton onClick={!isMobile
            ? () => mobileSearchList()
            : handleSearchCloseOnMobile}
                    aria-label="search"
                    sx={{
                        padding: 0,
                        marginRight: '8px',
                        marginTop: '11px',
                        marginBottom: '8px',
                        marginLeft: '11px'
                    }}>
            {isMobile ? <CloseIcon/> : <SearchIcon/>}
        </IconButton>
    </Container>
}

export default Search;
