import styled from 'styled-components';

export const VideoThumbnail = styled.img(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  objectFit: 'cover',
  width: 340,
  borderRadius: '5px 5px 0 0',
}));

export const VideoPlay = styled.div(({ theme }) => ({
  zIndex: 4,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  width: 340,
  height: 195,
  alignItems: 'center',
  justifyContent: 'center',

  ':hover': {
    cursor: 'pointer',
  }
}));
