import React from 'react';
import Impressum from '../components/static-pages/impressum';
import Footer from '../components/layout/footer';

function ImpressumPage () {
  return (
    <>
      <Impressum />
      <Footer />
    </>
  );
}

export default ImpressumPage;
