import styled from 'styled-components';

export const Div = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.backgroundColors.neutralBackground2,
}));

export const Container = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 980,
    marginTop: 69,

    [theme.breakpoints.down("sm")]: {
        width: '87%',
        marginTop: 540,
    },

    '.descriptionRichText p': {
        color: '#676767',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
    }
}));

export const Content = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 540,
    marginBottom: 95,

    [theme.breakpoints.down("sm")]: {
        width: '100%',
        marginBottom: 39,
    },
}));
