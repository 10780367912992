import styled from 'styled-components';

export const Card = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 18px',
  boxSizing: 'border-box',
  width: 260,
  boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.05), 0px 0.912px 0.912px rgba(0, 0, 0, 0.02)',
  borderRadius: '5px',
  backgroundColor: theme.palette.white,
}));

export const Image = styled.img(({ theme }) => ({
  marginTop: -28,
  marginLeft: 225,
  display: 'flex',
  alignSelf: 'center',
  height: 53,
  width: 53,
  borderRadius: '50%',
  border: '3px solid #FFFFFF',
  filter: 'drop-shadow(0px 2.736px 27.36px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0.912px 0.912px rgba(0, 0, 0, 0.02))',
}));

export const TextContent = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: -28,
}));

export const Title = styled.p(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '15px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#404040',
  margin: 0,
}));

export const Name = styled.p(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '20px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 600,
  color: '#2A2A2A',
  margin: 0,
  marginBottom: 5,
}));

export const Occupation = styled.p(({ theme }) => ({
  fontSize: '13px',
  lineHeight: '16px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#676767',
  margin: 0,
}));
