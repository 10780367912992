import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  backgroundColor: theme.palette.backgroundColors.neutralBackground2,
}));

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 980,
  marginTop: 81,

  [theme.breakpoints.down("sm")]: {
    width: '87%',
    alignItems: 'center',
    marginTop: 48,
  }
}));

export const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: 20,
  rowGap: 25,
  width: 540,
  marginTop: 44,
  marginBottom: 75,

  [theme.breakpoints.down("sm")]: {
    width: '100%',
    justifyContent: 'center',
    marginBottom: 36,
  }
}));
