import React from 'react';
import { SearchInput, SearchIcon, StyledList, StyledListItem, StyledDivider, Hr, StyledListSubheader, SearchHr } from './style';
import InputAdornment from '@mui/material/InputAdornment';
import { deTranslations } from '../../../translations';

interface Props {
  sx?: object
  list: Array<Option>
  onPicked: Function,
    sm?: {
      smTop: string|number, smBottom: string|number
    }
}

interface Option {
  id: number
  value: string
  state?: string
}

// @ts-ignore
const SearchList = ({ list, onPicked, sx, sm = {smTop: -1, smBottom: 2}}: Props) => {
  const [search, setSearch] = React.useState('');
  // @ts-ignore
  const filteredData = [];


  for (const group of list) {
    // @ts-ignore
    const filteredGroup = group[1].filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(search.toLowerCase())
    });

    if (filteredGroup.length > 0) {
      // @ts-ignore
      filteredData.push([group[0], filteredGroup]);
    }
  }

  return (
    <>
      <SearchInput
        id="standard-adornment-password"
        type={'text'}
        value={search}
        placeholder={deTranslations.search}
        sx={{
          borderRadius: '10px',
          color: '#063F58',
          backgroundColor: '#F6F6F6',
          marginTop: sm.smTop,
          marginBottom: sm.smBottom,
          padding: '7px 12px',
        }}
        onChange={(event: any) => setSearch(event.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <SearchHr />
      <StyledList
        sx={sx}
      >
        {/* @ts-ignore */}
        {filteredData.map((group, index) => {
          return (
            <div key={`group${index}`}>
              {/* @ts-ignore */}
              <StyledListSubheader>{group[0].toUpperCase()}</StyledListSubheader>
              {/* @ts-ignore */}
              {group[1].map((item, index) => (
                <div key={`div${item.id}`}>
                  <StyledListItem key={item.id} onClick={() => onPicked(item.id)}>{item.value}</StyledListItem>
                  {index !== group[1].length - 1 && <StyledDivider key={`divider${item.id}`}/>}
                </div>
              ))}
            </div>
          );
        })}
      </StyledList>
    </>
  )
};

export default SearchList;
