import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  flexGrow: 2,
  gap: 5,
  justifyContent: 'center',
}));

export const VisitedStep = styled.div(({ theme }) => ({
  width: 27,
  height: 3,
  borderRadius: 3,
}));

export const NonVisitedStep = styled.div(({ theme }) => ({
  background: '#8E9190',
  width: 27,
  height: 3,
  borderRadius: 3,
}));
