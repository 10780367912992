import React from 'react';
import VideoLanding from '../components/video-landing';
import Footer from '../components/layout/footer';

function VideoLandingPage () {
    return (
        <>
            <VideoLanding/>
            <Footer />
        </>
    );
}

export default VideoLandingPage;