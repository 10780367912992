import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
    display: 'flex',
    width: '100%',
    // backgroundColor: theme.palette.onyx,
    backgroundColor: '#1D1D1D',
}));

export const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
        width: '100%'
    },
}));

export const VideoBox = styled.div(({ theme }) => ({
    padding: '70px 0 24px 0',
    width: 865,

    [theme.breakpoints.down("sm")]: {
        padding: '18px 0',
        width: '100%',
        marginTop: 0,
    },
}));

export const VideoBoxText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    color: '#FFFFFF',
    lineHeight: '32px',
    margin: '18px 0 8px 0',

    [theme.breakpoints.down("sm")]: {
        margin: 10,
        fontSize: 22
    },
}));

export const VideoBoxDescription = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: '16px',
    fontWeight: 400,
    color: '#FCFCFC',
    opacity: 0.7,
    lineHeight: '24px',
    marginBottom: 24,

    [theme.breakpoints.down("sm")]: {
        margin: '10px 10px 20px 10px',
    },
}));

export const VideoBoxSpeaker = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#FFFFFF',
    marginBottom: 5,

    [theme.breakpoints.down("sm")]: {
        margin: '0 10px'
    },
}));

export const VideoBoxInfoSpeakers = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: 865,
    gap: 5,

    color: '#FFFFFF',
    opacity: 0.7,
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),

    [theme.breakpoints.down("sm")]: {
        width: '100%'
    },
}));

export const VideoBoxSpeakers = styled.div(({ theme }) => ({
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#FFFFFF',
    fontFamily: ['Inter', 'normal'].join(','),

    [theme.breakpoints.down("sm")]: {
        margin: 10
    },
}));
