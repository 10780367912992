import styled from 'styled-components';

export const Name = styled.span(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px'
}));

export const Position = styled.span(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '18px',
  color: '#8E8E8E'
}));

export const Email = styled.a(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '17px',
  color: '#404040',
  textDecoration: 'none'
}));

