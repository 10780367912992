import {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
    Div,
    TermsAndConditions,
    Text,
    Link,
    Form,
    OnboardingContainer,
} from './style';
import Doctor from '../doctor';
import Pharmacist from '../pharmacist';
import Student from '../student';
import Nurse from '../nurse';
import Other from '../other';
import ErrorModal from '../../common/error-modal';
import Card from '../../common/card';
import TextField from '../../common/fields/text-field';
import SelectOne from '../../common/fields/select';
import Checkbox from '../../common/buttons/checkbox';
import Button from '../../common/buttons/primary-button';
import ForwardButton from '../../common/buttons/forward-button';
import {deTranslations} from '../../../translations';
import NotEligible from '../not-eligible';
import {useLocation} from 'react-router-dom';
import SignupOnboardingWidget from "../signup-onboarding-widget";
import UserAPI from '../../../api/user/index';

const validationSchema = Yup.object({
  occupation: Yup.number()
    .min(1, `${deTranslations.occupation} ${deTranslations.must_not_empty}`)
    .max(5, `${deTranslations.occupation} ${deTranslations.must_not_empty}`)
    .required(`${deTranslations.occupation} ${deTranslations.must_not_empty}`),
  email: Yup.string()
    .email(deTranslations.email_invalid_warning)
    .required(`${deTranslations.email} ${deTranslations.must_not_empty}`),
  password: Yup.string()
    .min(6, deTranslations.password_short_warning)
    .required(deTranslations.password_empty_warning),
  acceptedTerms: Yup.boolean()
    .required(`${deTranslations.terms_and_conditions} ${deTranslations.must_not_empty}`)
    // TODO: translation maybe if shown in the future
    .oneOf([true], 'You must accept the terms and conditions.'),
});

const occupations = [
  {
    id: 1,
    value: deTranslations.occupations.doctor,
  },
  {
    id: 2,
    value: deTranslations.occupations.pharmacist,
  },
  {
    id: 3,
    value: deTranslations.occupations.student,
  },
  {
    id: 4,
    value: deTranslations.occupations.nurse,
  },
  {
    id: 5,
    value: deTranslations.occupations.other,
  },
];

const Initial = ({
                     onLoginClick,
                     webinarData,
                     doneFunction,
                     onAuthFinished,
                     videoData,
                    hideOnboardingWidget,
                     setSubscriptionToken
                 }: { onLoginClick: any, webinarData?: any, doneFunction?: any, onAuthFinished?: any, videoData?: any, hideOnboardingWidget?: boolean , setSubscriptionToken?: () => void}) => {
    const location = useLocation();
    const [initialStepDone, setInitialStepDone] = useState(false);
    const [profileId, setProfileId] = useState(0);
    const [subscriptionToken] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const isViewMorePage = location.pathname.includes('/channels/') || location.pathname.includes('/search/') || location.pathname.includes('/toContinue/');
    const isWebinarPage = location.pathname.includes('/webinar/');
    const isLandingPage = location.pathname === '/';
    const [isEmailTaken, setIsEmailTaken] = useState(false);

    const formik = useFormik({
        initialValues: {
            occupation: hideOnboardingWidget ? 1 : 0,
            email: '',
            password: '',
            acceptedTerms: false,
        },
        validationSchema: validationSchema,
        onSubmit: (
            async values => {
                const [error, data] = await UserAPI.checkIsEmailUnique(values.email);

                setIsEmailTaken(data);

                if (data) {
                    return;
                }

                setInitialStepDone(true);
            }),
    });

    const occupationSwitch = (occupation: number) => {
        // @ts-ignore
        if (webinarData) {
            // @ts-ignore
            if (!webinarData.filterOccupations.find((eligibleOccupation) => eligibleOccupation.occupationId === occupation) && !hideOnboardingWidget) {
                return <NotEligible/>
            }
        }

        switch (occupation) {
            case 1:
                return <Doctor
                    initialValues={formik.values}
                    profileId={profileId}
                    subscriptionToken={subscriptionToken}
                    goBack={() => setInitialStepDone(false)}
                    webinarData={webinarData}
                    doneFunction={doneFunction}
                    onAuthFinished={onAuthFinished}
                    videoData={videoData}
                    hideOnboardingWidget={hideOnboardingWidget}
                    setSubscriptionToken={setSubscriptionToken}
                />;
            case 2:
                return <Pharmacist
                    initialValues={formik.values}
                    goBack={() => setInitialStepDone(false)}
                    profileId={profileId}
                    subscriptionToken={subscriptionToken}
                    webinarData={webinarData}
                    doneFunction={doneFunction}
                    onAuthFinished={onAuthFinished}
                    videoData={videoData}
                    hideOnboardingWidget={hideOnboardingWidget}
                    setSubscriptionToken={setSubscriptionToken}
                />;
            case 3:
                return <Student
                    initialValues={formik.values}
                    goBack={() => setInitialStepDone(false)}
                    profileId={profileId}
                    subscriptionToken={subscriptionToken}
                    webinarData={webinarData}
                    doneFunction={doneFunction}
                    onAuthFinished={onAuthFinished}
                    videoData={videoData}
                    hideOnboardingWidget={hideOnboardingWidget}
                    setSubscriptionToken={setSubscriptionToken}
                />;
            case 4:
                return <Nurse
                    initialValues={formik.values}
                    goBack={() => setInitialStepDone(false)}
                    profileId={profileId}
                    subscriptionToken={subscriptionToken}
                    webinarData={webinarData}
                    doneFunction={doneFunction}
                    onAuthFinished={onAuthFinished}
                    videoData={videoData}
                    hideOnboardingWidget={hideOnboardingWidget}
                    setSubscriptionToken={setSubscriptionToken}
                />;
            case 5:
                return <Other
                    initialValues={formik.values}
                    goBack={() => setInitialStepDone(false)}
                    profileId={profileId}
                    subscriptionToken={subscriptionToken}
                    webinarData={webinarData}
                    doneFunction={doneFunction}
                    onAuthFinished={onAuthFinished}
                    videoData={videoData}
                    hideOnboardingWidget={hideOnboardingWidget}
                    setSubscriptionToken={setSubscriptionToken}
                />;
        }
    }

    const emailOnFocusHandle = () => {
        setIsEmailTaken(false);
    }

    // @ts-ignore
    return (
        <Div>
            <ErrorModal open={openModal} handleClose={() => setOpenModal(false)} retry={formik.handleSubmit}/>
            {!initialStepDone &&
            <Form onSubmit={formik.handleSubmit}>
                {/* @ts-ignore */}
                <OnboardingContainer hideOnboardingWidget={hideOnboardingWidget}>
                    <Card title={'Registriere dich jetzt...'}>
                        <SelectOne
                            disabled={hideOnboardingWidget}
                            fullWidth={true}
                            id='occupation'
                            name='occupation'
                            label={deTranslations.onboarding_title_1}
                            value={formik.values.occupation}
                            onChange={formik.handleChange}
                            // TODO: if needed
                            // @ts-ignore
                            errorMessage={formik.touched.occupation && formik.errors.occupation}
                            options={occupations}
                        />
                        <TextField
                            fullWidth={true}
                            id='email'
                            name='email'
                            label={deTranslations.email_label_text}
                            placeholderText={deTranslations.email_hint_text}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onFocus={emailOnFocusHandle}
                            errorMessage={isEmailTaken ? 'E-Mail wird bereits verwendet' : formik.touched.email ? formik.errors.email : ''}
                        />
                        <TextField
                            fullWidth={true}
                            id='password'
                            name='password'
                            label={deTranslations.password_label_text}
                            type='password'
                            placeholderText='•••••••••••••••'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.password ? formik.errors.password : ''}
                        />
                        <TermsAndConditions>
                            <Checkbox
                                style={{marginTop: 15, marginRight: 10}}
                                id='acceptedTerms'
                                checked={formik.values.acceptedTerms}
                                onChange={formik.handleChange}
                                // error={!!formik.errors.acceptedTerms}
                            />
                            {/* @ts-ignore */}
                            <Text error={formik.touched.acceptedTerms && !!formik.errors.acceptedTerms}>
                                {deTranslations.signup_accept_text_1}
                                <Link href='https://www.meducately.com/agb/' target='_blank'>
                                    {deTranslations.terms_and_conditions}
                                </Link>
                                {deTranslations.signup_accept_text_2}
                                <Link href='https://www.meducately.com/datenschutz/' target='_blank'>
                                    {deTranslations.data_protection}
                                </Link>
                                {deTranslations.signup_accept_text_3}
                            </Text>
                        </TermsAndConditions>
                        <Button style={{marginTop: 8}} type='submit'>{deTranslations.sign_up}</Button>
                        <Text style={{marginTop: 20, textAlign: 'center'}}>{deTranslations.already_have_account}</Text>
                        <ForwardButton sx={{marginTop: -2.5, marginBottom: -1}} onClick={onLoginClick}>
                            {deTranslations.login_title}
                        </ForwardButton>
                    </Card>
                    {
                        !hideOnboardingWidget && <SignupOnboardingWidget />
                    }
                </OnboardingContainer>
            </Form>
            }
            {initialStepDone && (<>{(isViewMorePage || isWebinarPage || isLandingPage) && occupationSwitch(formik.values.occupation)}</>)}
        </Div>
    );
}

export default Initial;
