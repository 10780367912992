import React from 'react';
import {
    ImageDescriptionWithArrow,
    ImageFeed,
    ImageUnlocked, ImageWebinarPlaceholderMobile
} from './style';
import { Box } from '@mui/material';

// @ts-ignore
const NoLiveContent = ({ webinarChannel }) => {
    return (
        <>
            <Box width="100%">
                <Box width={{ xs: '100%', md: '680px' }} margin="0 auto" position="relative" display={{ xs: 'flex', md: 'block' }} flexDirection="column" alignItems="center">
                    <ImageFeed src={'/webinar-placeholder.png'}/>
                    <ImageDescriptionWithArrow src={'/description-with-arrow.png'}/>
                    <ImageUnlocked src={'/app-unlocked.png'}/>
                    <ImageWebinarPlaceholderMobile src={'/webinar-placeholder-mobile.png'} />
                </Box>
            </Box>
        </>
    )
}

export default NoLiveContent;
