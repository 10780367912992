import React from 'react';
import ResetPasswordComponent from '../components/reset-password';
import { getParameterByName } from '../utils/location';

function ResetPassword() {
    const token = getParameterByName('token');
    const email = getParameterByName('email');

    return (
        <>
            <ResetPasswordComponent passwordToken={token} email={email}/>
        </>
    )
}

export default ResetPassword;
