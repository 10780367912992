import Card from '../../common/card';
import { deTranslations } from '../../../translations';
import { Img, Text } from './style';
import BackButton from '../../common/buttons/back-button';
import { Box } from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '../../icons/close-icon';
import CloseIconAlt from '../../icons/close-icon-alt';

// TODO: move these string to translations at some point
const TYPES = {
  passed: {
    title: 'Herzlichen Glückwunsch',
    subtitle: '<strong>Du hast das Quiz bestanden</strong> und dir deine DPF Punkte verdient. Wir buchen diese automatisch auf dein DFP Konto. Sie sollten in Kürze sichtbar sein.',
    imagePath: '/app-unlocked-large.png'
  },
  failed: {
    title: 'Sorry! Du hast es nicht geschafft.',
    subtitle: 'Du hast hast das <strong>Quiz leider nicht bestanden</strong>. Deshalb erhältst du auch keine Fortbidlungspunkte. Vielleicht klappt es beim nächsten Quiz.',
    imagePath: '/app-who-are-you.png'
  },
  // during loading only loader will be shown
  loading: {
    title: '',
    subtitle: '',
    imagePath: ''
  },
  // this type probably won't be used, it's a fallback from dev API
  started: {
    title: '',
    subtitle: '',
    imagePath: '/app-who-are-you.png'
  },
}

const StatusModal = ({ onClose, type, dfpPoints, score }: { onClose: () => void, type: string, dfpPoints: number, score: number }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('type: ', type);
  // @ts-ignore
  return <Card title={type ? TYPES[type].title : ''} sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: {
      xs: '35px 20px !important',
      sm: '35px 66px 30px 66px !important'
    },
    width: {
      xs: '90% !important',
      sm: '460px !important'
    },
    borderRadius: '4px !important',
    'h2': {
      padding: {
        xs: 0,
        sm: '0 10px'
      },
      textAlign: 'center',
      fontWeight: 800,
      fontSize: '28px',
      lineHeight: '34px'
    }
  }}>
    {
      type === 'loading' ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src="/loading-white-gif.gif" alt="loading" />
        </Box>
      ) : (
        <>
          {/* @ts-ignore */}
          <Box position="absolute" top={{ xs: 15, sm: 30 }} right={{ xs: 15, sm: 30 }} sx={{ cursor: 'pointer' }} onClick={onClose}>
            <CloseIconAlt />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" mt="25px" p="35px 16px" borderTop="1px solid #DFDFDF" borderBottom="1px solid #DFDFDF">
              {/* @ts-ignore */}
              <Text style={{ marginBottom: 35, marginTop: 0, textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: type ? TYPES[type].subtitle : '' }} />
            {/* @ts-ignore */}
            <Img src={type ? TYPES[type].imagePath : ''} alt="" />
            <Box display="flex" justifyContent="space-between" mt="45px">
              <Box>
                {/* @ts-ignore */}
                <Text small style={{ margin: 0 }}>DFP PUNKTE</Text>
                {/* @ts-ignore */}
                <Text big style={{ margin: 0 }}>{dfpPoints}</Text>
              </Box>
              <Box>
                {/* @ts-ignore */}
                <Text small style={{ margin: 0 }}>PERCENTAGE</Text>
                {/* @ts-ignore */}
                <Text big style={{ margin: 0 }}>{Math.round(score)}%</Text>
              </Box>
            </Box>
          </Box>
        </>
      )
    }
  </Card>
};

export default StatusModal;
