import React from 'react';
import {
    Div,
    Container,
    Text,
    WebinarTitle,
    WebinarDescription,
    WebinarDatesAndTimes,
} from './style';
import {useTheme} from '@mui/material/styles';

// @ts-ignore
const Hero = ({title, subtitle, formattedDate}) => {
    const theme = useTheme();

    return (
        <Div>
            <Container>
                <Text>
                    <WebinarTitle style={{
                        marginTop: title?.length > 92
                            ? -140
                            : title?.length > 56
                                ? -95
                                : title?.length > 29
                                    ? -40
                                    : 0
                    }}>{title}</WebinarTitle>
                    <WebinarDescription>{subtitle}</WebinarDescription>
                    <WebinarDatesAndTimes>{formattedDate}</WebinarDatesAndTimes>
                </Text>
            </Container>
        </Div>
    )
}

export default Hero;