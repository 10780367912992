import { StyledModal, StyledCard, Text, Img, Hr } from './style';
import { deTranslations } from '../../../translations';
import Button from '../buttons/primary-button';
import React from 'react';
import BackButton from '../buttons/back-button';

// @ts-ignore
const ErrorModal = ({ open, handleClose, retry, title, message }: { open: boolean, handleClose: any, retry?: any, title?: string, message?: string }) => {
  return <StyledModal
    open={open}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledCard sx={{
      position: 'absolute' as 'absolute',
      top: '43%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }} title={title || deTranslations.seem_offline}>
      <Text centerText={true}>{message || deTranslations.no_internet_connection}</Text>
      <Img src='/app-offline.png' alt=""/>
      {
        retry ? (
          <Button style={{marginTop: 8, marginBottom: 34, width: 210, alignSelf: 'center'}} fullWidth={false} onClick={() => {
            retry()
            handleClose()
          }}>{deTranslations.retry}</Button>
        ) : null
      }
      <Hr />
      <div style={{
        marginBottom: -22,
        marginLeft: -22,
        marginRight: -22,
        height: 39,
      }}>
        <BackButton sx={{alignSelf: 'flex-start', paddingBottom: 0}} onClick={() => handleClose()}/>
      </div>
    </StyledCard>
  </StyledModal>;
};

export default ErrorModal;
