import React from 'react';
import EventLanding from '../components/event-landing';
import Footer from '../components/layout/footer';

function EventLandingPage () {
    return (
        <>
            {/* @ts-ignore */}
            <EventLanding/>
            <Footer />
        </>
    );
}

export default EventLandingPage;