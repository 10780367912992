import styled from 'styled-components';
import {Accordion} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

export const FAQContainer = styled.div(({theme}) => ({
    width: '1200px',
    margin: '0 auto',

    [theme.breakpoints.up("lg")]: {
        backgroundColor: '#FCFCFC',
    },

    [theme.breakpoints.down("lg")]: {
        width: '100%',
    },

    [theme.breakpoints.down("md")]: {
        width: '100%',
    }
}));

export const FAQInnerContainer = styled.div(({theme}) => ({
    width: '80%',
    margin: '0 auto',
    padding: '80px 0',
    backgroundColor: '#FCFCFC',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.down("md")]: {
        width: '90%',
        padding: '20px 0',
    },

    [theme.breakpoints.down("sm")]: {
        paddingLeft: '1%',
    },

    '.MuiAccordion-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '1px solid #EBEAE9',
        marginBottom: '0',
        borderRadius: '0 !important',

        '&:before': {
            display: 'none',
        },

        [theme.breakpoints.down("md")]: {
            margin: '0 10px',
        },
    },

    '.MuiAccordionSummary-root': {
        paddingLeft: '0px',
        paddingRight: '0px',

        '.MuiTypography-root': {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 600,
            fontFamily: ['Inter', 'normal'].join(','),

            [theme.breakpoints.down("md")]: {
                maxWidth: '100%'
            },
        }
    },

    '.MuiAccordionDetails-root': {
        padding: '8px 16px 16px 0',

        '.MuiTypography-root': {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 400,
            fontFamily: ['Inter', 'normal'].join(','),

            [theme.breakpoints.down("md")]: {
                maxWidth: '100%'
            },
        }
    },

    '.MuiAccordion-root.Mui-expanded': {
        margin: 0,

        '.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto',

            '.MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 0'
            }
        },

        [theme.breakpoints.down("md")]: {
            margin: '0 10px',
        },
    }
}));


export const FAQTitle = styled.p(({theme}) => ({
    fontSize: 27,
    lineHeight: 1.2,
    color: theme.palette.textColors.title,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    marginTop: 74,

    [theme.breakpoints.down("sm")]: {
        width: '80%',
        marginTop: 50,
        marginBottom: 10,
    },
}));

export const Separator = styled.div(({theme}) => ({
    borderTop: `1px solid #C4C4C4`,
    width: 638,
    opacity: 0.4,

    [theme.breakpoints.down("md")]: {
        width: '90%',
    },
}));

export const CustomAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: "rotate(90deg)"
    }
}));

export const FAQDescriptionContainer = styled.div(({theme}) => ({
    width: '450px',
    marginBottom: '32px',

    [theme.breakpoints.down("md")]: {
        width: '250px',
        padding: '0 10px'
    },
}));

export const SplitSubtitle = styled.p(({theme}) => ({
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.ctaError,
    fontWeight: 500,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
    textTransform: 'uppercase'
}));

export const SplitTitle = styled.p(({theme}) => ({
    fontSize: '28px',
    lineHeight: '34px',
    color: '#0E0E0E',
    fontWeight: 800,
    fontFamily: ['Inter', 'normal'].join(','),
    margin: 0,
    textTransform: 'uppercase'
}));
