// @ts-ignore
function DFPIcon(props) {
  return(
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.7268 15.817H2.07816C1.82847 15.817 1.58901 15.7178 1.41246 15.5412C1.23591 15.3647 1.13672 15.1252 1.13672 14.8755V1.69535C1.13672 1.44566 1.23591 1.2062 1.41246 1.02965C1.58901 0.853093 1.82847 0.753906 2.07816 0.753906H21.8484C22.0981 0.753906 22.3376 0.853093 22.5141 1.02965C22.6907 1.2062 22.7899 1.44566 22.7899 1.69535V14.8755C22.7899 15.1252 22.6907 15.3647 22.5141 15.5412C22.3376 15.7178 22.0981 15.817 21.8484 15.817H16.1998" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.13672 5.93183L6.31464 0.753906" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6133 15.8146L22.7912 10.6367" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.963 16.759C11.5245 16.7596 11.0903 16.6724 10.686 16.5024C10.2818 16.3325 9.91571 16.0833 9.60938 15.7695V21.4662L11.963 18.6419L14.3166 21.4662V15.7695C14.0102 16.0833 13.6441 16.3325 13.2399 16.5024C12.8357 16.6724 12.4015 16.7596 11.963 16.759Z" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.963 16.7541C13.7828 16.7541 15.2581 15.2789 15.2581 13.4591C15.2581 11.6393 13.7828 10.1641 11.963 10.1641C10.1432 10.1641 8.66797 11.6393 8.66797 13.4591C8.66797 15.2789 10.1432 16.7541 11.963 16.7541Z" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9629 14.879C12.7429 14.879 13.3751 14.2468 13.3751 13.4668C13.3751 12.6869 12.7429 12.0547 11.9629 12.0547C11.183 12.0547 10.5508 12.6869 10.5508 13.4668C10.5508 14.2468 11.183 14.879 11.9629 14.879Z" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.60938 3.57812H14.3166" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.25781 6.40234H16.6722" stroke={props.color != null ? props.color : '#434849'} strokeWidth="0.941441" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DFPIcon;
