import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const Label = styled.p(({ theme }) => ({
  color: theme.palette.textColors.label,
  fontSize: 12,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  lineHeight: 1.15,
  margin: 0,
  marginBottom: 3,
}));

export const Text = styled.p(({ theme }) => ({
  color: theme.palette.textColors.body,
  fontSize: 15,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  lineHeight: 1.15,
  margin: 0,
}));
