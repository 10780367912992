import api from '../index';

const sendContactForm = async (data: any) => {
    let isSent = false;
    let isError = false;
    try {
        const response = await api.post(`/v1/contact`, data);

        if (response.status === 200) {
            isSent = true;
        } else {
            isError = true;
        }
    } catch (err) {
        // @ts-ignore
        isError = true;
    }

    return [isError, isSent];
};

export default {
    sendContactForm
}
