// @ts-ignore
const extractLocationFromFormValues = (values) => {
  switch(values.occupation) {
    // doctor
    case(1): {
      switch(values.placeOfWork) {
        case('hospital'): {
          return {
            hospitalId: values.location,
            workRegionId: null,
            workRegion: null,
            officeRegionId: null,
            officeRegion: null,
            universityId: null,
            university: null
          }
        }
        case('public'): {
          return {
            workRegionId: values.location,
            hospitalId: null,
            hospital: null,
            officeRegionId: null,
            officeRegion: null,
            universityId: null,
            university: null
          }
        }
        case('private'): {
          return {
            officeRegionId: values.location,
            workRegionId: null,
            workRegion: null,
            hospitalId: null,
            hospital: null,
            universityId: null,
            university: null
          }
        }
      }
      break
    }
    // pharmacist
    case(2): {
      return {
        officeRegionId: values.location,
        workRegionId: null,
        workRegion: null,
        hospitalId: null,
        hospital: null,
        universityId: null,
        university: null
      }
    }
    // student
    case(3): {
      return {
        universityId: values.location,
        workRegionId: null,
        workRegion: null,
        hospitalId: null,
        hospital: null,
        officeRegionId: null,
        officeRegion: null
      }
    }
    // nurse
    case(4): {
      return {
        hospitalId: values.location,
        universityId: null,
        university: null,
        workRegionId: null,
        workRegion: null,
        officeRegionId: null,
        officeRegion: null
      }
    }
    // other
    case(5): {
      return {
        hospitalId: null,
        hospital: null,
        universityId: null,
        university: null,
        workRegionId: null,
        workRegion: null,
        officeRegionId: null,
        officeRegion: null
      }
    }
  }
};

// @ts-ignore
export const extractPayloadFromForm = (values) => {
  const location = extractLocationFromFormValues(values)

  return {
    firstName: values.firstName,
    lastName: values.lastName,
    title: values.title ? values.title : null,
    oeak: values.occupation === 1 && values.oeak ? values.oeak : null,
    specialtyId: values.occupation === 1 && values.areaOfPractice ? values.areaOfPractice : null,
    specialty: values.occupation === 1 ? undefined : null,
    doctorWorkType: values.occupation === 1 && values.placeOfWork ? values.placeOfWork : null,
    occupationId: values.occupation,
    ...location,
  }
};

// @ts-ignore
export const extractRelevantData = (values) => {
  // TODO: extract location from profile
  const location = extractLocationFromApi(values)

  return {
    firstName: values.firstName || '',
    lastName: values.lastName || '',
    title: values.title ? values.title : '',
    oeak: values.occupationId === 1 && values.oeak ? values.oeak : '',
    email: values?.user?.email || '',
    id: values.id || 0,
    occupation: values.occupationId,
    ...location,
  }
};

// @ts-ignore
const extractLocationFromApi = (values) => {
  switch(values.occupationId) {
    // doctor
    case(1): {
      if (values.hospitalId !== null) {
        return {
          placeOfWork: 'hospital',
          location: values.hospitalId,
          locationName: values.hospital.name,
        }
      }

      if (values.workRegionId !== null) {
        return {
          placeOfWork: 'public',
          location: values.workRegionId,
          locationName: values.workRegion.name,
        }
      }

      if (values.officeRegionId !== null) {
        return {
          placeOfWork: 'private',
          location: values.officeRegionId,
          locationName: values.officeRegion.name,
        }
      }

      return {
        location: 0,
        locationName: 'Location not set',
      }
    }
    // pharmacist
    case(2): {
      return {
        location: values.officeRegionId || 0,
        locationName: values.officeRegion.name || 'Location not set',
      }
    }
    // student
    case(3): {
      return {
        location: values.universityId || 0,
        locationName: values.university.name || 'Location not set',
      }
    }
    // nurse
    case(4): {
      return {
        location: values.hospitalId || 0,
        locationName: values.hospital.name || 'Location not set',
      }
    }
    // other
    case(5): {
      return {
        location: null,
      }
    }
  }
};