import api from '../';
import { extractPayloadFromForm, extractPayloadFromFormWithoutProfile } from './utils';

const SKIP_MAIL = process.env.REACT_APP_SKIP_MAIL;
const PASSWORD_RESET_URL = process.env.REACT_APP_RESET_PASSWORD_URL;

// @ts-ignore
const createUser = async (payload) => {
  const extractedPayload = extractPayloadFromForm(payload);
  let response;
  let error;
  try {
    response = await api.post(`/auth/register?skipMail=${SKIP_MAIL}&isDeepLink=false`, extractedPayload);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.data];
};

// @ts-ignore
const createUserWithoutProfile = async (payload) => {
  const extractedPayload = extractPayloadFromFormWithoutProfile(payload);
  let response;
  let error;
  try {
    response = await api.post(`/auth/register?skipMail=${SKIP_MAIL}&isDeepLink=false`, extractedPayload);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.data];
};

// @ts-ignore
const loginUser = async (payload) => {
  let response;
  let error;
  try {
    response = await api.post(`/auth/login`, payload);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.data];
};

// @ts-ignore
const forgotPassword = async (payload) => {
  const payloadWithResetURL = {
    redirectURL: PASSWORD_RESET_URL,
    ...payload,
  }

  let response;
  let error;
  try {
    response = await api.post(`/auth/sendToken?type=password&isDeepLink=false`, payloadWithResetURL);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.data];
};

// @ts-ignore
const resetPassword = async (payload) => {
  let response;
  let error;
  try {
    response = await api.post('/auth/confirm?type=password', payload);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.data];
}

// Returns HTTP 200 if email is already in use, or 404 if email is not currently registered in the system
const checkIsEmailUnique = async (email: string) => {
  let response;
  let error;

  try {
    response = await api.get(`auth/emailExists?email=${email}`);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }

  return [error, response?.status === 200];
}

const getProxyUserData = async (hash: string) => {
  let response;
  let error;

  try {
    response = await api.get(`/auth/resolve/proxy/${hash}`);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }

  return [error, response?.data];
}

//@ts-ignore
const activateProxyUser = async (payload) => {
  let response;
  let error;
  try {
    response = await api.post(`/auth/confirm?type=proxy`, payload);
  } catch (err) {
    // @ts-ignore
    error = err.response?.data?.message;
  }
  return [error, response?.status === 200];
};

export default {
  createUserWithoutProfile,
  createUser,
  loginUser,
  forgotPassword,
  resetPassword,
  checkIsEmailUnique,
  getProxyUserData,
  activateProxyUser,
};
