import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.onyx,
    height: 500
}));

// @ts-ignore
export const Outline = styled.div(({ theme, backgroundImage }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: 550,
    backgroundColor: 'radial-gradient(32.08% 318.75% at 86.93% 43.71%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
    backgroundImage: 'url("'+backgroundImage+'")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',

    [theme.breakpoints.down("sm")]: {
        height: 450,
        backgroundImage: 'none',
    },
}));

export const HeroContent = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 1050,
    marginTop: 140,
    [theme.breakpoints.down("sm")]: {
        width: '85%',
        marginTop: 40,
    }
}));

export const WebinarFeatured = styled.div(({ theme }) => ({
    fontWeight: 400,
    fontSize: 23,
    fontFamily: ['Inter', 'normal'].join(','),
    color: '#FFFFFF',

    [theme.breakpoints.down("sm")]: {
        fontSize: 18,
    }
}));

export const WebinarDivTitle = styled.div(({ theme }) => ({
    fontWeight: 400,
    fontSize: 40,
    fontFamily: ['Inter', 'normal'].join(','),
    color: '#FFFFFF',

    [theme.breakpoints.down("sm")]: {
        fontSize: 30,
    }
}));

export const WebinarDivDate = styled.div(({ theme }) => ({
    fontWeight: 700,
    fontSize: 18,
    fontFamily: ['Inter', 'normal'].join(','),
    color: '#FFFFFF',
    opacity: 0.9
}));

export const IconAndText = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 7,
    marginTop: 15,
    color: '#FFFFFF',
    opacity: 0.8
}));

export const ImageVideoIcon = styled.img(({ theme }) => ({
    width: 22,
    height: 22
}));

export const IconText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 12,
    color: '#FFFFFF',

    [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        marginBottom: 0,
    }
}));

export const Buttons = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 22,
    marginTop: 55,
    [theme.breakpoints.down("sm")]: {
        maxWidth: 320,
        marginTop: 25,
    },
}));