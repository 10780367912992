// @ts-ignore
function CloseIconAlt(props) {
  return(
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M15 1L8 8M8 8L15 15M8 8L1 1M8 8L1 15" stroke="#2E3436" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}

export default CloseIconAlt;
