import styled from 'styled-components';

export const Text = styled.p(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#434849',
}));

export const Img = styled.img(({ theme }) => ({
  display: 'flex',
  width: 153,
  alignSelf: 'center',
}));

export const Div = styled.div(({ theme }) => ({
  '.MuiPaper-root>h2': {
    fontWeight: 600,
    textAlign: 'left',
    fontSize: '20px',
    lineHeight: '24px',
    paddingBottom: '10px',
    color: '#0E0E0E'
  }
}));