import { useState, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import UserAPI from '../../../api/user';
import { AuthContext } from '../../../context/authContext';
import { Div, Text, Form, Hr } from './style';
import Card from '../../common/card';
import TextField from '../../common/fields/text-field';
import Button from '../../common/buttons/primary-button';
import ForwardButton from '../../common/buttons/forward-button';
import Subscribe from '../subscribe';
import { deTranslations } from '../../../translations';
import NotEligible from '../../signup/not-eligible';

const validationSchema = Yup.object({
  email: Yup.string()
    .email(deTranslations.email_invalid_warning)
    .required(`${deTranslations.email} ${deTranslations.must_not_empty}`),
  password: Yup.string()
    .min(6, deTranslations.password_short_warning)
    .required(deTranslations.password_empty_warning),
});

const Initial = ({ handleClose, onSignupClick, webinarData, doneFunction, onAuthFinished, videoData, audienceId }: { handleClose?: any, onSignupClick: any, webinarData?: any, doneFunction?: any, onAuthFinished?: any, videoData?: any, audienceId?: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isWebinar = webinarData !== undefined;
  const { login, userProfile } = useContext(AuthContext);
  const [initialStepDone, setInitialStepDone] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (async values => {
      // @ts-ignore
      const [error, data] = await UserAPI.loginUser(values);
      // @ts-ignore
      if (error) {
        if (error === 'Account noch nicht verifiziert') {
          formik.setErrors({
            password: 'Konto ist noch nicht verifiziert. Bitte überprüfen Sie Ihre E-Mail.',
          });
        } else {
          formik.setErrors({
            password: deTranslations.incorrect_username_or_password,
          });
        }
        return;
      } else {
        login({ responseData: data })

        if (onAuthFinished) {
          onAuthFinished();
        } else {
          // TODO: this should be reworked, some conditions make no sense
          if (videoData != {} && videoData != null) {
            navigate('' + videoData + '')
          } else
          if (webinarData != null) {} else {
            navigate('/videos')
          }
        }
      }

      setInitialStepDone(true);
    }),
  });

  const onClickForgotPassword = () => {
    handleClose()
    navigate('/forgot-password')
  }

  const occupationSwitch = (occupation: number) => {
    // @ts-ignore
    if (!webinarData.filterOccupations.find((eligibleOccupation) => eligibleOccupation.occupationId === occupation)) {
      return <NotEligible />
    }

    switch(occupation) {
      case 1:
        return <Subscribe webinarData={webinarData} />;
      case 2:
        return <Subscribe webinarData={webinarData} />;
      case 3:
        return <Subscribe webinarData={webinarData} />;
      case 4:
        return <Subscribe webinarData={webinarData} />;
      case 5:
        return <Subscribe webinarData={webinarData} />;
    }
  }

  return (
    <Div>
      {!initialStepDone &&
      <Form onSubmit={formik.handleSubmit}>
        <Card title={deTranslations.login_title} sx={{ 'h2': {fontWeight: '600'} }}>
          <TextField
            fullWidth={true}
            id='email'
            name='email'
            label={deTranslations.email_label_text}
            placeholderText={deTranslations.email_hint_text}
            value={formik.values.email}
            onChange={formik.handleChange}
            errorMessage={formik.touched.email ? formik.errors.email : ''}
          />
          <TextField
            fullWidth={true}
            id='password'
            name='password'
            label={deTranslations.password_label_text}
            type='password'
            placeholderText='•••••••••••••••'
            value={formik.values.password}
            onChange={formik.handleChange}
            errorMessage={formik.touched.password ? formik.errors.password : ''}
          />
          <Button style={{marginTop: 8}} type='submit'>{deTranslations.login_title}</Button>
          <Text style={{marginTop: 20, textAlign: 'center'}}>{deTranslations.forgot_password_cta}</Text>
          <ForwardButton sx={{marginTop: -2.5, marginBottom: -1}} onClick={() => onClickForgotPassword()}>
            {deTranslations.reset_password_title}
          </ForwardButton>
          <Hr />
          <Text style={{marginTop: 20, textAlign: 'center'}}>{deTranslations.no_account}</Text>
          <ForwardButton sx={{marginTop: -2.5, marginBottom: -1}} onClick={onSignupClick}>
            {deTranslations.sign_up}
          </ForwardButton>
        </Card>
      </Form>
      }
      {/* @ts-ignore */}
      {initialStepDone && (<>{isWebinar ? occupationSwitch(userProfile?.profile?.occupation?.id) : doneFunction()}</>)}
    </Div>
  );
}

export default Initial;
