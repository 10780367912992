import { createGlobalStyle } from 'styled-components';
import { createTheme } from '@mui/material/styles';

const GlobalStyle = createGlobalStyle`
  html {height: 100vh;}
  body {
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    background-color: #FCFCFA;
  }
  :target::before {
    content: "";
    display: block;
    height: 100px; /* fixed header height*/
    margin: -100px 0 0; /* negative fixed header height */
  }
`;

const theme = createTheme({
  palette: {
    backgroundColors: {
      floralWhite: '#FCFAF3',
      neutralBackground: '#FCFBF7',
      neutralBackground2: '#FCFBF9',
      checkbox: '#F6F6F6',
    },
    textSeparator: '#2E3436',
    avatar: {
      base: '#FDC93A',
      complementary: '#063F58',
    },
    ctaError: '#F05B4D',
    white: '#FFFFFF',
    black: '#000000',
    onyx: '#2E3436',
    textColors: {
      title: '#2E3436',
      body: '#434849',
      bodyLight: '#676767',
      label: '#8E9190',
      separator: '#DFDFDF',
    },
  },
  typography: {
    button: {
      fontFamily: [
        'Inter',
        'normal',
      ].join(','),
    }
  }
});

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      backgroundColors: {
        floralWhite: string,
        neutralBackground: string,
        neutralBackground2: string,
        checkbox: string,
      },
      textSeparator: string,
      avatar: {
        base: string,
        complementary: string,
      },
      ctaError: string,
      onyx: string,
      white: string,
      black: string,
      textColors: {
        title: string,
        body: string,
        bodyLight: string,
        label: string,
        separator: string,
      },
    },
  }
  interface PaletteOptions {
    backgroundColors: {
      floralWhite: string,
      neutralBackground: string,
      neutralBackground2: string,
      checkbox: string,
    },
    textSeparator: string,
    avatar: {
      base: string,
      complementary: string,
    },
    ctaError: string,
    onyx: string,
    white: string,
    black: string,
    textColors: {
      title: string,
      body: string,
      bodyLight: string,
      label: string,
      separator: string,
    },
  }
  // allow configuration using `createTheme`
  // interface ThemeOptions {
  //   palette?: {
  //     backgroundColors?: {
  //       floralWhite?: string,
  //       neutralBackground?: string,
  //     },
  //     textSeparator?: string,
  //     avatar?: {
  //       base?: string,
  //       complementary?: string,
  //     },
  //     ctaError?: string,
  //     white?: string,
  //     black?: string,
  //     text?: {
  //       title?: string,
  //       body?: string,
  //       bodyLight?: string,
  //       label?: string,
  //       separator?: string,
  //     },
  //   },
  // }
}

export { GlobalStyle, theme };
