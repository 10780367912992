import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ContainerDiv} from './style';
import Hero from './hero';
import About from './about';
import Agenda from './agenda';
import Speakers from './speakers';
import Organizers from './organizers';
import Support from './support';
import FloatingCard from './floating-card';
import WebinarAPI from '../../api/webinar';
import { WebinarData } from '../../api/webinar/type';
import { format } from '../../utils/time';
import SubscribeModal from './subscribe-modal';
import AnyQuestions from './any-questions';
import PrimaryButton from "../common/buttons/primary-button";
import { AuthContext } from "../../context/authContext";
import SignupModal from "../signup/signup-modal";
import SubscribeExternal from '../login/subscribe-external';
import SubscribeExternalModal from './subscribe-external-modal';

// @ts-ignore
const WebinarLanding = ({ utmSource = null }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [webinarData, setWebinarData] = React.useState<WebinarData>({});
  const [formattedDate, setFormattedDate] = React.useState('');
  const [speakerMap, setSpeakerMap] = React.useState(new Map());
  const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
  const [showSubscribeExternalModal, setShowSubscribeExternalModal] = React.useState(false);
  const { user, userProfile, logout } = React.useContext(AuthContext);
  const [signupModalOpen, setSignupModalOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const audienceId = searchParams.get('audienceId');

  const openSubscribeModal = React.useCallback(() => {
    setShowSubscribeModal(true);
  }, [webinarData, showSubscribeModal]);

  const closeSubscribeModal = React.useCallback(() => {
    setShowSubscribeModal(false);
    navigate(0)
  }, [webinarData, showSubscribeModal])

  const closeSubscribeExternalModal = React.useCallback(() => {
    navigate('/events');
  }, [webinarData, showSubscribeExternalModal])

  React.useEffect(() => {
    async function getWebinarData() {
      // @ts-ignore
      const [error, data] = await WebinarAPI.getWebinarById(id, audienceId !== null);

      if (error) {
        return;
      }

      // @ts-ignore
      const startDate = data.startDate;
      // @ts-ignore
      const endDate = data.endDate;
      const speakersArray = data.speakers?.map((speaker) => [speaker.speaker.id, speaker.speaker]);
      // @ts-ignore
      setSpeakerMap(new Map(speakersArray));
      const date = data?.agenda?.entries?.length === 1
        ? `${format(endDate, 'dd.MM.yyyy')}`
        : `${format(startDate, 'dd.MM')} - ${format(endDate, 'dd.MM.yyyy')}`;
      const time = `${format(startDate, 'HH:mm')} - ${format(endDate, 'HH:mm')}`;
      setFormattedDate(`${date} • ${time}`);
      setWebinarData(data);
    }

    getWebinarData();
  }, [id]);

  React.useEffect(() => {
    let rootElement: HTMLElement | null = null;

    if (audienceId) {
      rootElement = document.querySelector('#root');

      if (rootElement) {
        // @ts-ignore
        rootElement.style.filter = 'blur(15px)';
      }
    }

    return () => {
      if (rootElement) {
        // @ts-ignore
        rootElement.style.filter = 'blur(0px)';
      }
    }
  }, []);

  React.useEffect(() => {
    if (audienceId && Object.keys(webinarData).length > 0 && !showSubscribeExternalModal) {
      setShowSubscribeExternalModal(true);
    }
  }, [audienceId, webinarData, showSubscribeExternalModal])

  const openSignupModalOpen = () => {
    setSignupModalOpen(true)
  }

  // @ts-ignore
  return (
    <>
      <ContainerDiv>
        <SubscribeExternalModal
            open={showSubscribeExternalModal}
            handleClose={closeSubscribeExternalModal}
            webinarData={webinarData}
            audienceId={audienceId}
        />
        <SubscribeModal
          open={showSubscribeModal}
          handleClose={closeSubscribeModal}
          webinarData={webinarData}
          utmSource={utmSource}
        />
        <SignupModal
            open={signupModalOpen}
            handleClose={() => setSignupModalOpen(false)}
            doneFunction={() => navigate(0)}
        />
        <FloatingCard
          card={{
            title: webinarData.title,
            subtitle: webinarData.subtitle,
            formattedDate,
            points: webinarData?.agenda?.entries?.reduce((aggregator, entry) => aggregator + +entry.dfpPoints, 0),
            type: 'Video-on-Demand im Anschluss',
            locationType: webinarData.type,
            locationAddress: webinarData.location,
            specialties: webinarData.provider,
            sponsors: webinarData.sponsors,
            featuredVideo: webinarData?.featuredVideo,
            coverURL: webinarData.coverURL,
            isSubscribed: webinarData.isSubscribed,
            hasDFPApprobation: webinarData.hasDFPApprobation,
            hasOnDemandVideo: webinarData.hasOnDemandVideo
          }}
          user={user}
          subscribeOnClick={openSubscribeModal}
          openSignupModalOpen={openSignupModalOpen}
        />
        <Hero
          title={webinarData.title}
          subtitle={webinarData.subtitle}
          formattedDate={formattedDate}
        />
        <About
          description={webinarData.description}
        />
        <Agenda
          // @ts-ignore
          agendaEntries={webinarData.agenda?.entries}
          speakerMap={speakerMap}
        />
        <Speakers
          speakers={Array.from(speakerMap.values())}
        />
        <Organizers
          // TODO: add real data once API supports it
          description={webinarData.organizerDescription}
          additionalDescription={webinarData.additionalOrganizerDescription}
          // @ts-ignore
          images={webinarData?.organizers?.map((organizer) => organizer.sponsor.logoURL)}
        />
        <Support
          // TODO: add real data once API supports it
          // @ts-ignore
          images={webinarData?.sponsors?.map((sponsor) => sponsor.sponsor.logoURL)}
        />
        <AnyQuestions/>
        {isMobile ?
            webinarData.isSubscribed
                ? null
                : <PrimaryButton style={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: 50, width: '100%', borderRadius: 0 }}
                                   fullWidth={true} onClick={openSubscribeModal}>
                    Jetzt Anmelden
                  </PrimaryButton> : null
        }
      </ContainerDiv>
    </>
  )
}

export default WebinarLanding;
