import styled from 'styled-components';

export const ImageFeed = styled.img(({ theme }) => ({
    width: '268px',
    height: '332px',

    [theme.breakpoints.down("md")]: {
        display: 'none'
    }
}));

export const ImageDescriptionWithArrow = styled.img(({ theme }) => ({
    width: '420px',
    position: 'absolute',
    bottom: '58px',
    left: '236px',

    [theme.breakpoints.down("md")]: {
        display: 'none'
    }
}));

export const ImageUnlocked = styled.img(({ theme }) => ({
    position: 'absolute',
    right: '80px',
    top: '22px',
    width: '170px',
    height: '170px',

    [theme.breakpoints.down("md")]: {
        display: 'none'
    }
}));

export const ImageWebinarPlaceholderMobile = styled.img(({ theme }) => ({
    height: '350px',

    [theme.breakpoints.up("md")]: {
        display: 'none'
    }
}));
