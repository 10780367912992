import React from 'react';
import './style.css';

interface Props {
    style?: any,
    value: number,
    max: number
}

const ProgressBar = ({style, value = 0, max = 100}: Props) => {
    return (
        <progress style={style} value={value} max={max}/>
    )
}

export default ProgressBar;
