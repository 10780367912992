import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import UserAPI from '../../api/user';
import { Div, Text, Form, Img } from './style';
import Card from '../common/card';
import TextField from '../common/fields/text-field';
import Button from '../common/buttons/primary-button';
import ForwardButton from '../common/buttons/forward-button';
import { deTranslations } from '../../translations';
import ErrorModal from '../common/error-modal';
import FormHelperText from '@mui/material/FormHelperText';

const validationSchema = Yup.object({
  email: Yup.string()
    .email(deTranslations.email_invalid_warning)
    .required(`${deTranslations.email} ${deTranslations.must_not_empty}`),
});

const Initial = () => {
  const navigate = useNavigate();
  const [initialStepDone, setInitialStepDone] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [emailNotFoundError, setEmailNotFoundError] = useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (async values => {
      // @ts-ignore
      const [sendError, send] = await UserAPI.forgotPassword(values);
      // @ts-ignore
      if (sendError === 'Email not found or not yet activated') {
        setEmailNotFoundError(deTranslations.email_not_found_or_verified);
        return;
      }

      // @ts-ignore
      if (sendError) {
        setOpenModal(true);
        return;
      }
      setInitialStepDone(true);
    }),
  });

  return (
    <Div>
      <ErrorModal open={openModal} handleClose={() => setOpenModal(false)} retry={formik.handleSubmit} />
      {!initialStepDone &&
      <Form onSubmit={formik.handleSubmit}>
        <Card title={deTranslations.reset_password_title}>
          <Text style={{ marginTop: 0 }}>{deTranslations.reset_email_description}</Text>
          <TextField
            fullWidth={true}
            id='email'
            name='email'
            label={deTranslations.email_label_text}
            placeholderText={deTranslations.email_hint_text}
            value={formik.values.email}
            onChange={formik.handleChange}
            errorMessage={formik.touched.email ? formik.errors.email : ''}
          />
          <FormHelperText
            sx={{
              color: '#F05B4D',
              fontSize: 13,
              fontWeight: 400,
              marginLeft: 1.5
            }}
            error={emailNotFoundError !== ''}
          >
            {emailNotFoundError}
          </FormHelperText>
          <Button style={{marginTop: 8}} type='submit'>{deTranslations.reset_password_title}</Button>
          <Text style={{marginTop: 20, textAlign: 'center'}}>{deTranslations.remembered_password}</Text>
          <ForwardButton sx={{marginTop: -2.5, marginBottom: -1}} onClick={() => navigate('/login')}>
            {deTranslations.back_to_login}
          </ForwardButton>
        </Card>
      </Form>
      }
      {initialStepDone &&
      <Card title={deTranslations.restore_link_sent}>
        <Text style={{marginBottom: 0}}>{deTranslations.we_ve_sent_you_a_restore_link_1}: <b>{formik.values.email}</b> {deTranslations.we_ve_sent_you_a_restore_link_2}</Text>
        <Img src='/app-notifications.png' alt=""/>
        <Text style={{marginTop: 20, textAlign: 'center'}}>{deTranslations.didnt_receive_email}</Text>
        <ForwardButton sx={{marginTop: -2.5, marginBottom: -1}} onClick={() => formik.handleSubmit()}>
          {deTranslations.resend_email}
        </ForwardButton>
      </Card>
      }
    </Div>
  );
}

export default Initial;
