import styled from 'styled-components';

export const ContainerDiv = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}));

export const Subscribed = styled.div(({ theme }) => ({
  marginTop: 20,
  color: '#608856',
  fontFamily: ['Inter', 'normal'].join(','),
  fontSize: 18,
  fontWeight: 700,
  width: '100%',
  textAlign: 'center'
}));

export const ImageCheck = styled.img(({ theme }) => ({
  width: 12,
  height: 12,
  marginLeft: 5
}));
