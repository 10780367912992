import styled from 'styled-components';

// @ts-ignore
export const Div = styled.div(({ theme, backgroundColor }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: backgroundColor,
    height: 600,
    overflow: 'hidden'
}));

export const ButtonBoxRight = styled.div(({ theme }) => ({
    position: 'absolute',
    right: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 408,
    width: 20,
    marginTop: 109,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,

    [theme.breakpoints.down("sm")]: {
        marginTop: 76,
        height: 435,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ButtonBoxLeft = styled.div(({ theme }) => ({
    position: 'absolute',
    left: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 408,
    width: 20,
    marginTop: 109,
    marginRight: -19,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,

    [theme.breakpoints.down("sm")]: {
        marginTop: 76,
        height: 435,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageRightCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,
    transform: 'rotate(180deg)',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLeftCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,

    ':hover': {
        cursor: 'pointer',
    }
}));

// @ts-ignore
export const Outline = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 45,
    marginTop: 35,
    height: 600,
    [theme.breakpoints.down("sm")]: {
        height: 450
    },
}));

// @ts-ignore
export const FeaturedContent = styled.div(({ theme, featuredColor }) => ({
    color: featuredColor,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '30px',

    [theme.breakpoints.down("sm")]: {
        fontSize: 30,
    }
}));

export const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 25,
    gap: 20,

    [theme.breakpoints.down("sm")]: {
        marginTop: 5,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const WebinarDiv = styled.div(({ theme }) => ({
    position: 'relative',
    maxWidth: 350,
    width: 350,
    borderRadius: 4,
    height: 435,
    backgroundColor: '#FFFFFF',
    filter: 'drop-shadow(0px 3.58499px 35.8499px rgba(0, 0, 0, 0.09)) drop-shadow(0px 1.195px 1.195px rgba(0, 0, 0, 0.01))',

    [theme.breakpoints.down("sm")]: {
        width: 300
    }
}));

export const Image = styled.img(({ theme }) => ({
    width: 350,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,

    [theme.breakpoints.down("sm")]: {
        width: 300
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const WebinarDivTitle = styled.div(({ theme }) => ({
    position: 'absolute',
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 21,
    color: '#2E3436',
    marginLeft: 15,
    marginRight: 15,
    maxHeight: 78,
    overflow: 'hidden',

    [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        maxHeight: 70,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const WebinarDivDate = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 285,
    fontFamily: ['Inter', 'normal'].join(','),
    color: '#676767',
    opacity: 0.9,
    fontWeight: 700,
    fontSize: 17,
    marginLeft: 15,

    [theme.breakpoints.down("sm")]: {
    }
}));

export const ButtonBoxElement = styled.div(({ theme }) => ({
    alignSelf: 'center',
    marginLeft :5
}));

export const StepperElement = styled.div(({ theme }) => ({
    position: 'absolute',
    right: '1%',
    top: '16%'
}));

export const ImageVideoIcon = styled.img(({ theme }) => ({
    width: 23,
    height: 23
}));

export const IconAndText = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 315,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 7,
    color: 'grey',
    opacity: 0.8,
    marginLeft: 15,

    [theme.breakpoints.down("sm")]: {
    }
}));

export const IconText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 14,
    color: '#434849',

    [theme.breakpoints.down("sm")]: {
    }
}));

export const Subscribed = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 360,
    left: '14%',
    color: '#608856',
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 18,
    fontWeight: 700,
    paddingBottom: 12,
    paddingTop: 12,
    width: 240,
    marginLeft: 14,
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
        left: '6%',
    }
}));

export const ImageCheck = styled.img(({ theme }) => ({
    width: 12,
    height: 12,
    marginLeft: 5
}));

export const ContactButton = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 360,
    color: theme.palette.avatar.complementary,
    height: 45,
    border: '1.3103px solid #063F58',
    borderRadius: 5,
    width: '90%',
    marginLeft: 15,
    filter: 'drop-shadow(0px 5.24122px 13.103px rgba(0, 0, 0, 0.15)) drop-shadow(1.3103px 1.3103px 2.62061px rgba(0, 0, 0, 0.15))',

    [theme.breakpoints.down("sm")]: {
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ContactButtonText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 11,

    ':hover': {
        cursor: 'pointer',
    }
}));
