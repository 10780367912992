import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import { ListSubheader } from '@mui/material';

export const SearchInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    // borderRadius: 4,
    // color: '#063F58',
    // backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    fontSize: 16,
    lineHeight: 1.1,
    fontWeight: 400,
    paddingLeft: 4,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Inter', 'normal'].join(','),
    '&::placeholder': {
      color: '#063F58',
      opacity: 100,
    },
  },
}));

export const SearchHr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.label}`,
  width: 'calc(100% + 44px)',
  marginLeft: -22,
  marginTop: 1,
}));

export const SearchIcon = styled.span(({ theme }) => ({
  width: 16,
  height: 16,
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.7'%3E%3Cpath d='M6.3833 12.7666C7.76123 12.7666 9.04785 12.3184 10.0938 11.5713L14.0283 15.5059C14.2109 15.6885 14.4517 15.7798 14.7007 15.7798C15.2402 15.7798 15.6304 15.3647 15.6304 14.8335C15.6304 14.5845 15.5474 14.3521 15.3647 14.1694L11.4551 10.2515C12.2769 9.17236 12.7666 7.83594 12.7666 6.3833C12.7666 2.87207 9.89453 0 6.3833 0C2.86377 0 0 2.87207 0 6.3833C0 9.89453 2.86377 12.7666 6.3833 12.7666ZM6.3833 11.3887C3.63574 11.3887 1.37793 9.12256 1.37793 6.3833C1.37793 3.64404 3.63574 1.37793 6.3833 1.37793C9.12256 1.37793 11.3887 3.64404 11.3887 6.3833C11.3887 9.12256 9.12256 11.3887 6.3833 11.3887Z' fill='%23063F58'/%3E%3C/g%3E%3C/svg%3E%0A")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  content: '""',
}));

export const StyledList = styled(List)(({ theme }) => ({
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&&::-webkit-scrollbar': {
    display: 'none',
  },

  /* Hide scrollbar for IE, Edge and Firefox */
  '&&': {
    msOverflowStyle: 'none',  /* IE and Edge */
    scrollbarWidth: 'none',  /* Firefox */
    paddingTop: 0,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&&': {
    cursor: 'pointer',
    paddingLeft: 22,
    height: 45,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    lineHeight: 1.1,
    color: theme.palette.textColors.title,
  },
  '&&:hover': {
    // fontStyle: 'bold',
  }
}));

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  '&&': {
    paddingLeft: 22,
    paddingTop: 8,
    height: 32,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    lineHeight: 1.1,
    color: theme.palette.textColors.label,
    borderBottom: `0.5px solid rgba(0, 0, 0, 0.12)`,
  },
  '&&:hover': {
    // fontStyle: 'bold',
  }
}));

export const StyledDivider = styled(Divider)({
  '&&': {
    marginLeft: 22,
  }
});

export const Hr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.label}`,
  width: 'calc(100% + 44px)',
  marginLeft: -22,
  marginTop: 10,
}));
