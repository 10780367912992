import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 40,
  [theme.breakpoints.down("sm")]: {
    marginTop: 0,
  },
}));

export const Form = styled.form(({}) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Hr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.label}`,
  width: '100%',
  marginTop: 20,
}));

export const Text = styled.p(({ theme }) => ({
  fontSize: 12,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: theme.palette.textColors.body,
}));

export const Link = styled.a(({ theme }) => ({
  fontSize: 12,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 700,
  color: '#063F58',
  textDecorationLine: 'underline',
}));
