import React from 'react';
import Datenschutz from '../components/static-pages/datenschutz';
import Footer from '../components/layout/footer';

function DatenschutzPage () {
  return (
    <>
      <Datenschutz />
      <Footer />
    </>
  );
}

export default DatenschutzPage;
