// @ts-ignore
function HamburgerIcon(props) {
  return(
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="1.5" fill="white"/>
      <rect y="8.5" width="20" height="1.5" fill="white"/>
      <rect y="17" width="20" height="1.5" fill="white"/>
    </svg>

  )
}

export default HamburgerIcon;
