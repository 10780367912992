import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { deAT } from 'date-fns/locale';

// @ts-ignore
const formatInTimeZone = (date, fmt) => {
  const parsedTime = parseISO(date);

  return format(parsedTime, fmt, { locale: deAT });
}

export const getCurrentYear = () => {
  return format(new Date(), 'yyyy')
};

export { formatInTimeZone as format };
