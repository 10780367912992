import api from '../';

const getAllHospitalNamesAndIds = async () => {
  let response;
  let error;
  try {
    response = await api.get('/v1/hospitals?select=id,name,state');
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }

  const mappedResponse = response?.data.map((datum: any) => ({
    id: datum.id,
    value: datum.name,
    state: datum.state,
  }));

  // @ts-ignore
  const hospitalsGroupedByState = new Map();

  for (const hospital of mappedResponse) {
    if (hospitalsGroupedByState.has(hospital.state)) {
      const hospitals = hospitalsGroupedByState.get(hospital.state);
      hospitals.push({ id: hospital.id, value: hospital.value });
    } else {
      hospitalsGroupedByState.set(hospital.state, [ {id: hospital.id, value: hospital.value} ]);
    }
  }
  const hospitalsGroupedByStateArray = Array.from(hospitalsGroupedByState);

  // @ts-ignore
  return [error, hospitalsGroupedByStateArray];
};

const getAllRegionNamesAndIds = async () => {
  let response;
  let error;
  try {
    response = await api.get('/v1/regions?select=id,name');
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }

  const mappedResponse = response?.data.map((datum: any) => ({
    id: datum.id,
    value: datum.name,
  }));
  // @ts-ignore
  return [error, [['Bezirk', mappedResponse]]];
};

const getAllUniversityNamesAndIds = async () => {
  let response;
  let error;
  try {
    response = await api.get('/v1/universities?select=id,name');
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }

  const mappedResponse = response?.data.map((datum: any) => ({
    id: datum.id,
    value: datum.name,
  }));
  // @ts-ignore
  return [error, [['Universität', mappedResponse]]];
};

export default {
  getAllHospitalNamesAndIds,
  getAllRegionNamesAndIds,
  getAllUniversityNamesAndIds,
};
