import React, {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import LocationAPI from '../../../api/location';
import SpecialtyAPI from '../../../api/specialty';
import WebinarAPI from '../../../api/webinar';
import {Form} from './style';
import Card from '../../common/card';
import {Hr} from './style';
import AreaOfPracticeList from '../../menus/area-of-practice-list';
import BackButton from '../../common/buttons/back-button';
import PlaceOfWorkRadioGroup from '../../menus/place-of-work-radio-group';
import SearchList from '../../menus/search-list';
import TextField from '../../common/fields/text-field';
import Button from '../../common/buttons/primary-button';
import Stepper from '../../common/stepper';
import {deTranslations} from '../../../translations';
import ConfirmationTicket from '../../tickets/confirmation-ticket';
import ErrorModal from '../../common/error-modal';
import {format} from '../../../utils/time';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from "../../../context/authContext";
import UserAPI from "../../../api/user";
import SignupOnboardingWidget from "../signup-onboarding-widget";
import {OnboardingContainer} from "./style";
import {useMediaQuery} from "@mui/material";

function validateOeak (value: string) {
    const regExp = new RegExp(
        "^([0-9]{3,})-([0-9]{2})$",
        "i"
    );

    if (!regExp.test(value)) {
        return false;
    }

    const numberComponents = value.split("-");
    if (numberComponents.length <= 1) {
        return false;
    }

    const oeak = numberComponents[0];
    const checksum = numberComponents[1];

    const weights = [7, 3, 1];
    const enumaratedOeak = oeak.split("");
    const oeakDigits = enumaratedOeak.map(Number);

    const mapOeak: { [key: number]: number } = {};
    let i = 0;
    for (const oeakDigit of oeakDigits) {
        mapOeak[i++] = oeakDigit;
    }

    // @ts-ignore
    const calculatedChecksum = Object.keys(mapOeak).map(key => weights[key % weights.length] * mapOeak[key]).reduce((a, b) => a + b) % 100;

    return Number(checksum) === calculatedChecksum;
}

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required(`${deTranslations.first_name_first_uppercased} ${deTranslations.must_not_empty}`),
    lastName: Yup.string()
        .required(`${deTranslations.last_name_first_uppercased} ${deTranslations.must_not_empty}`),
    title: Yup.string()
        .min(2, deTranslations.title_short_warning)
        .notRequired(),
    oeak: Yup.string()
        // @ts-ignore
        .test('oeak-validation', deTranslations.oeak_not_valid, validateOeak)
        .required(`${deTranslations.oeak} ${deTranslations.must_not_empty}`),
});

const titleMap = new Map([
    [1, deTranslations.onboarding_title_2],
    [2, deTranslations.place_of_work],
    [3, 'officeType'],
    [4, deTranslations.your_personal_info],
])

const getTitle = (step: number, officeType: string) => {
    if (step === 3) {
        return officeType === 'hospital' ? deTranslations.pick_hospital : deTranslations.where_office_located;
    }

    return titleMap.get(step);
}

// @ts-ignore
const Doctor = ({initialValues, goBack, profileId, subscriptionToken, webinarData, doneFunction, onAuthFinished, videoData, hideOnboardingWidget, setSubscriptionToken}) => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const {login, userProfile} = useContext(AuthContext);
    const valuesForLogin = {
        email: initialValues.email,
        password: initialValues.password
    }
    const formik = useFormik({
        initialValues: {
            areaOfPractice: 0,
            placeOfWork: '',
            location: 0,
            occupation: initialValues.occupation,
            email: initialValues.email,
            password: initialValues.password,
            firstName: '',
            lastName: '',
            title: '',
            oeak: '',
        },
        validationSchema: validationSchema,
        onSubmit: (async values => {
            const [userError, user] = await UserAPI.createUser(values);
            if (userError) {
                if (userError === 'E-Mail wird bereits verwendet') {
                    formik.setErrors({
                        email: 'E-Mail wird bereits verwendet',
                    });
                } else {
                    setOpenModal(true);
                }
                return;
            }

            if (videoData != {} && videoData != null) {
                await UserAPI.loginUser(valuesForLogin).then((response) => login({responseData: response[1]}));
                navigate('' + videoData + '')
            } else if (webinarData !== undefined) {
                await UserAPI.loginUser(valuesForLogin).then((response) => {
                    login({responseData: response[1]});
                    if (response[1] && response[1].user && response[1].user.subscriptionToken && setSubscriptionToken) {
                        setSubscriptionToken(response[1].user.subscriptionToken);
                    }
                });
                const [subscribeError, subscribeResponse] = await WebinarAPI.subscribeToWebinar(webinarData?.Id);
                if (subscribeError) {
                    setOpenModal(true);
                    return;
                }

                setSuccess(true);
            } else {
                await UserAPI.loginUser(valuesForLogin).then((response) => login({responseData: response[1]}));
                if (onAuthFinished) {
                    onAuthFinished();
                } else {
                    if (webinarData != null) {
                        setSuccess(true);
                    } else {
                        navigate('/videos')
                    }
                    // TODO: this makes no sense based on previous condition, should be reworked at some point
                    doneFunction();
                }
            }
        }),
    });

    let formattedDate: string;
    let formattedTime: string;

    if (webinarData !== undefined) {
        const startDate = webinarData.startDate;
        const endDate = webinarData.endDate;
        formattedDate = (`${format(startDate, 'd.')} + ${format(endDate, 'd.')} ${format(startDate, 'MMMM yyyy')}`);
        formattedTime = format(startDate, 'HH:mm');
    }

    const [hospitals, setHospitals] = useState([]);
    const [regions, setRegions] = useState([]);
    const [specialties, setSpecialties] = useState([]);

    // @ts-ignore
    useEffect(() => {
        async function fetchData() {
            const [[hospitalError, hospitals], [regionError, regions], [specialtyError, specialties]] = await Promise.all([
                LocationAPI.getAllHospitalNamesAndIds(),
                LocationAPI.getAllRegionNamesAndIds(),
                SpecialtyAPI.getAllSpecialtyNamesAndIds(),
            ]);

            setHospitals(hospitals);
            setRegions(regions);
            setSpecialties(specialties);
        }

        fetchData();
    }, []);

    const sizeOfScreen1200 = useMediaQuery('(min-width:1200px)');

    return (
        <>
            <ErrorModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                retry={formik.handleSubmit}
                title="Es ist ein Fehler aufgetreten"
                message="Beim Abonnieren des Webinars ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns."
            />
            {!success &&
              // @ts-ignore
            <OnboardingContainer hideOnboardingWidget={hideOnboardingWidget}>
              <Card title={getTitle(step, formik.values.placeOfWork)}>
                <Form onSubmit={formik.handleSubmit}>
                    {step === 1 &&
                    <>
                        <Hr/>
                        <AreaOfPracticeList
                            id='areaOfPractice'
                            sx={{
                                maxHeight: 344,
                                minHeight: 380,
                                overflow: 'auto',
                                marginRight: -3,
                                color: 'red'
                            }}
                            areas={specialties}
                            onPicked={(id: number) => {
                                formik.setFieldValue('areaOfPractice', id);
                                setStep(2);
                            }}
                        />
                    </>
                    }
                    {step === 2 &&
                    <>
                        <Hr/>
                        <PlaceOfWorkRadioGroup
                            onPicked={(placeOfWork: number) => {
                                formik.setFieldValue('placeOfWork', placeOfWork);
                                setStep(3);
                            }
                            }/>
                    </>
                    }
                    {step === 3 &&
                    <>
                        {formik.values.placeOfWork === 'hospital'
                            ? <SearchList
                                list={hospitals}
                                onPicked={(location: number) => {
                                    formik.setFieldValue('location', location);
                                    setStep(4);
                                }}
                                sx={{
                                    maxHeight: 324,
                                    minHeight: 324,
                                    paddingBottom: 0,
                                    overflow: 'auto',
                                    marginRight: -3,
                                    marginLeft: -3,
                                }}
                                sm={{
                                    smTop: 0,
                                    smBottom: '6px'
                                }}
                            />
                            : <SearchList
                                list={regions}
                                onPicked={(location: number) => {
                                    formik.setFieldValue('location', location);
                                    setStep(4);
                                }}
                                sx={{
                                    maxHeight: 324,
                                    minHeight: 324,
                                    overflow: 'auto',
                                    marginRight: -3,
                                    marginLeft: -3,
                                }}
                                sm={{
                                    smTop: 0,
                                    smBottom: '6px'
                                }}
                            />
                        }
                    </>
                    }
                    {step === 4 &&
                    <>
                        <TextField
                            fullWidth={true}
                            id='firstName'
                            name='firstName'
                            label={deTranslations.first_name}
                            placeholderText={deTranslations.enter_first_name}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.firstName ? formik.errors.firstName : ''}
                        />
                        <TextField
                            fullWidth={true}
                            id='lastName'
                            name='lastName'
                            label={deTranslations.last_name}
                            placeholderText={deTranslations.enter_last_name}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.lastName ? formik.errors.lastName : ''}
                        />
                        <TextField
                            fullWidth={true}
                            id='title'
                            name='title'
                            label={deTranslations.title}
                            placeholderText={deTranslations.enter_title}
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.title ? formik.errors.title : ''}
                        />
                        <TextField
                            fullWidth={true}
                            id='oeak'
                            name='oeak'
                            label={deTranslations.oeak}
                            placeholderText={deTranslations.oeak_hint}
                            value={formik.values.oeak}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.oeak ? formik.errors.oeak : ''}
                        />
                        <Button style={{marginTop: 8, marginBottom: sizeOfScreen1200 ? 0 : 16}}
                                type='submit'>{webinarData ? deTranslations.save_and_attend_webinar : 'Registrieren Sie sich'}</Button>
                    </>
                    }
                </Form>
                <div style={{
                    marginBottom: -22,
                    marginLeft: -22,
                    marginRight: -22,
                    height: 47,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderTop: '0.5px solid #8E9190'
                }}>
                    <div style={{flexGrow: 1, width: 69}}>
                        {step !== 1 &&
                        <BackButton sx={{flexGrow: 1}} onClick={() => {
                            if (step === 1) {
                                goBack();
                                return;
                            }

                            setStep(step - 1)
                        }}/>
                        }
                    </div>
                    <Stepper isVideoPlayPage={false} steps={4} step={step}/>
                    <div style={{flexGrow: 1, width: 69}}/>
                </div>
            </Card>
                {!hideOnboardingWidget && <SignupOnboardingWidget/>}
            </OnboardingContainer>
            }
            {success &&
            <ConfirmationTicket
                title={formik.values.title}
                firstName={formik.values.firstName}
                lastName={formik.values.lastName}
                email={formik.values.email}
                // @ts-ignore
                formattedDate={formattedDate}
                // @ts-ignore
                formattedTime={formattedTime}
                webinarTitle={webinarData.title}
                webinarSubtitle={webinarData.subtitle}
                coverURL={webinarData.coverURL}
                isSubscribed={webinarData.isSubscribed}
            />
            }
        </>
    );
}

export default Doctor;
