import AliceCarousel from 'react-alice-carousel';
import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#1D1D1D',
    height: '500px',
    overflow: 'hidden',

    [theme.breakpoints.down("sm")]: {
        height: '440px',
    },
}));

export const StyledAliceCarouselWrapper =  styled.div`
 
  .alice-carousel {
    margin-top: 20px; 
  }
  width: 100%;
  
 

  /* li.alice-carousel__stage-item :not(.__cloned) {
    width: 450px;
    margin-right: 30px;
  }

  li.alice-carousel__stage-item {
    width: 480px;
    margin-right: 30px;
  } */
`

export const StyledAliceCarousel =  styled(AliceCarousel)`
  /* width: 100%;
  
 

  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin-right: 1rem;
    padding: 40px !important;
  } */
`

export const Outline = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    height: 330,
    color: theme.palette.white,
    marginLeft: 45,
    marginTop: 35,
    fontSize: 25,

    [theme.breakpoints.down("sm")]: {
        marginLeft: 25,
        marginTop: 15,
    },
}));

export const FeaturedContent = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 800,
    fontSize: '30px',
    lineHeight: '48px',
    color: '#FFFFFF'
}));

export const FeaturedContentDescription = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#C4C4C4'
}));

export const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 35,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
        width: '87%'
    },
    ':hover': {
        cursor: 'pointer',
    }
}));

export const ButtonBoxLeft = styled.div(({ theme }) => ({
    position: 'absolute',
    left: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 270,
    width: 20,
    marginTop: 88,
    marginRight: -19,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,

    [theme.breakpoints.down("sm")]: {
        marginTop: 65,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ButtonBoxRight = styled.div(({ theme }) => ({
    position: 'absolute',
    right: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 270,
    width: 20,
    marginTop: 88,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,

    [theme.breakpoints.down("sm")]: {
        marginTop: 65,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageRightCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,
    transform: 'rotate(180deg)',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLeftCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const VideoCard = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 485,
    height: 350,
    [theme.breakpoints.down("sm")]: {
        width: 355,
    },
}));

export const Image = styled.img(({ theme }) => ({
    display: 'flex',
    alignSelf: 'left',
    objectFit: 'cover',
    height: 270,
    width: 450,
    borderRadius: 5,

    [theme.breakpoints.down("sm")]: {
        width: 320,
        height: 200
    },
}));

export const ImageLoading = styled.img(({ theme }) => ({
    position: 'absolute',
    top: '25%',
    left: '45%',
    height: 75,
    width: 75,

    [theme.breakpoints.down("sm")]: {
        width: 50,
        height: 50
    },
}));

export const VideoLength = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px 3px',
    gap: 10,

    position: 'absolute',
    height: 12,
    right: 10,
    bottom: 10,

    background: '#2E3436',
    borderRadius: 3
}));

export const VideoLengthNumber = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 10,
    fontWeight: 500,
    color: '#FFFFFF',
    width: 26,
    height: 12,
    textAlign: 'end'
}));

export const VideoBoxText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    marginTop: 10,
    alignItems: 'flex-start',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#FFFFFF',
    alignSelf: 'stretch'
}));

export const VideoBoxSource = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    marginTop: 3,
    alignItems: 'flex-start',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    opacity: 0.8
}));

export const ButtonBoxElement = styled.div(({ theme }) => ({
    alignSelf: 'center',
    marginLeft :5
}));
