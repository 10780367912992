import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.backgroundColors.neutralBackground2,
}));

export const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 980,

    [theme.breakpoints.down("sm")]: {
        width: '87%',
        flexDirection: 'column',
    }
}));

export const Content = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    marginTop: 0,
    marginBottom: 44,
    float: 'left',

    [theme.breakpoints.down("sm")]: {
        width: '100%',
        marginBottom: -60,
        alignItems: 'center'
    }
}));

export const ContactButton = styled.div(({ theme }) => ({
    color: '#0E0E0E',
    border: '1px solid #0E0E0E',
    borderRadius: '8px',
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    padding: '12px 20px',
    textAlign: 'center',
    width: '60%',

    [theme.breakpoints.down("sm")]: {
        width: '46%',
        marginBottom: 135
    },

    ':hover': {
        cursor: 'pointer',
    }

}));

export const Img = styled.img(({ theme }) => ({
    display: 'flex',
    alignSelf: 'center',
    width: '31%',
    marginTop: -56,

    [theme.breakpoints.down("sm")]: {
        width: '90%',
    }
}));