// @ts-nocheck
import React, {useState, useEffect} from "react";
import VideoAPI from "../../api/video";
import {ImageLoading} from "../video-landing/hero/style";
import {
    ContainerDiv,
    InnerContainerDiv,
    PageContainer,
    HeaderContainer,
    HeaderContainerContent,
    HeadlineTitle,
    HeadlineSubtitle,
    TabName,
    TabNameContainer,
    TabContentCategory,
    TabContent,
    TabContentName,
    TabContentDescription,
    TabContentWrapper,
    TabContentInnerWrapper
} from "./style";
import {useNavigate} from "react-router-dom";

const ChannelsLanding = () => {

    const [isDataLoading, setDataLoading] = useState(true);
    const [sortedData, setSortedData] = useState({});
    const [existingKeys, setExistingKeys] = useState([]);
    const [activeTab, setActiveTab] = useState('Alle'); // Default active tab
    const navigate = useNavigate();

    useEffect(() => {

        VideoAPI.getAllChannelsList().then((response: any) => {

            let tempExistingKeys: string[] = [];
            let data = {};

            response[1].forEach(el => {
                if (el.name !== '') {

                    // Get key based on first letter of the name and uppercase it
                    let key = el.name.charAt(0).toUpperCase();

                    // Check if key already exists and if not add it to array of existing keys
                    if (tempExistingKeys.indexOf(key) === -1) {
                        tempExistingKeys.push(key);
                    }

                    // Data object we're pushing into sorted array
                    let dataObject = {
                        id: el.id,
                        name: el.name,
                        description: el.description,
                        channelType: el.type
                    };

                    // Filling the now properly sorted array with dataObjects
                    if (Array.isArray(data[key])) {
                        data[key] = [...data[key], dataObject];
                    } else {
                        data[key] = [dataObject];
                    }
                }
            });

            // Set existing keys for later use
            setExistingKeys(tempExistingKeys.sort());

            // Sort data by key
            data = Object.keys(data).sort().reduce(
                (obj, key) => {
                    obj[key] = data[key];
                    return obj;
                }, {}
            );

            // Set now sorted data for later use in tabs
            setSortedData(data);
        }).finally(() => {
            setDataLoading(false);
        });
    }, []);

    const seeMore = (channelId, channelName, type: string) => {
        if (type === 'dfp_course') {
            navigate('/' + 'dfp-channels' + '/' + channelId + '/' + channelName.replaceAll(' ', '').replaceAll('/', ''));
        } else {
            navigate('/' + 'channels' + '/' + channelId + '/' + channelName.replaceAll(' ', '').replaceAll('/', ''));
        }
    }

    return (
        <PageContainer>
            <HeaderContainer>
                <HeaderContainerContent>
                    <HeadlineTitle>Kanäle</HeadlineTitle>
                    <HeadlineSubtitle>Hier findest du die Übersicht aller Kanäle auf meducately</HeadlineSubtitle>
                </HeaderContainerContent>
            </HeaderContainer>
            <ContainerDiv>
                <InnerContainerDiv>
                    {isDataLoading
                        ? <ImageLoading src={'loading-transparent-gif.gif'}/>
                        :
                        <div>
                            <TabNameContainer>
                                <TabName isActive={activeTab === 'Alle'} onClick={() => {
                                    setActiveTab('Alle');
                                }}>Alle</TabName>
                                {existingKeys.map((element) => {
                                    return <TabName isActive={activeTab === element} key={element} onClick={() => {
                                        setActiveTab(element);
                                    }}>{element}</TabName>
                                })}
                            </TabNameContainer>
                            <TabContentWrapper>
                                {activeTab === 'Alle' ? Object.keys(sortedData).map((element) => {
                                        return <TabContentInnerWrapper key={element}>
                                            <TabContentCategory>{element}</TabContentCategory>

                                            {sortedData[element].map((element) => {
                                                return <TabContent key={element.id}
                                                                   onClick={() => seeMore(element.id, element.name, element.channelType)}>
                                                    <TabContentName>{element.name}</TabContentName>
                                                    <TabContentDescription
                                                        dangerouslySetInnerHTML={{__html: element.description ? element.description.replace(/<\/?[^>]+(>|$)/g, '') : ''}}/>
                                                </TabContent>
                                            })}
                                        </TabContentInnerWrapper>
                                    }) :
                                    (<TabContentInnerWrapper>
                                        {sortedData[activeTab].map((element) => {
                                            return <TabContent key={element.id}
                                                               onClick={() => seeMore(element.id, element.name, element.channelType)}>
                                                <TabContentName>{element.name}</TabContentName>
                                                <TabContentDescription
                                                    dangerouslySetInnerHTML={{__html: element.description ? element.description.replace(/<\/?[^>]+(>|$)/g, '') : ''}}/>
                                            </TabContent>
                                        })}
                                    </TabContentInnerWrapper>)
                                }
                            </TabContentWrapper>
                        </div>
                    }
                </InnerContainerDiv>
            </ContainerDiv>
        </PageContainer>
    )
}

export default ChannelsLanding;