import React from 'react';
import ViewMoreLanding from '../components/view-more-landing';
import Footer from '../components/layout/footer';

function ViewMoreLandingPage () {
    return (
        <>
            <ViewMoreLanding/>
            <Footer />
        </>
    );
}

export default ViewMoreLandingPage;