import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { Accordion } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const StyledDrawer = styled(Drawer)(({theme}) => ({
    '.MuiPaper-root': {
        boxShadow: 'none',
        transition: 'none',
        width: '100%',
    },
}));

export const Container = styled.div(({theme}) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1D1D1D',
    padding: '0 20px',
    overflow: 'scroll'
}));

export const LinksWithImages = styled.div(({theme}) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
}));

export const LinkWithImage = styled.div(({theme,}) => ({
    padding: '24px 0',
    width: '100%',
    borderBottom: '1px solid #C4C4C4',
    ':hover': {
        cursor: 'pointer',
    },
    ':hover .linkText': {
        color: '#C4C4C4'
    },
}));

export const LinkText = styled.p(({theme}) => ({
    fontSize: 17,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: ['Inter', 'normal'].join(','),
    color: theme.palette.white,
    margin: 0,
}));

export const NavLinkText = styled(NavLink)(({theme}) => ({
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 500,
    fontFamily: ['Inter', 'normal'].join(','),
    color: theme.palette.white,
}));

export const TextLinks = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    marginTop: 11,
    marginLeft: 38,
    gap: 20,
}));

export const StyledAccordion = styled(Accordion)(({theme}) => ({
    '&.MuiAccordion-root': {
        width: '100%',
        backgroundColor: '#1D1D1D',
        color: '#FFF',
        fontFamily: 'Inter',
        lineHeight: 1.2,
        fontWeight: 600,
        fontSize: '17px',
        borderBottom: '1px solid #C4C4C4',
        borderRadius: '0 !important',
        padding: '20px 0',

        '&.Mui-expanded': {
            margin: 0
        }
    },

    '.MuiButtonBase-root': {
        padding: 0,
        minHeight: 0,

        '&.Mui-expanded': {
            minHeight: 0
        }
    },

    '.MuiAccordionSummary-content': {
        margin: 0,

        '&.Mui-expanded': {
            margin: 0
        }
    },

    '.MuiAccordionDetails-root': {
        padding: '20px 0 0 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',

        'a': {
            cursor: 'pointer',
            fontSize: 15,
            lineHeight: '20px',
            fontWeight: 500,
            fontFamily: ['Inter', 'normal'].join(','),
            color: theme.palette.white,
            textDecoration: 'none'
        }
    },

    '.MuiAccordionSummary-expandIconWrapper': {
        '&.Mui-expanded': {
            transform: 'rotate(-90deg)'
        }
    }
}));
