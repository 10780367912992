import styled from 'styled-components';

export const ImageLoading = styled.img(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '45%',
    height: 75,
    width: 75,

    [theme.breakpoints.down("sm")]: {
        width: 50,
        height: 50
    },
}));