import React, {useContext, useEffect, useRef, useState} from 'react';
import YouTube from 'react-youtube';
//@ts-ignore
import Player from '@vimeo/player';
import {
    Buttons,
    Container,
    DFPInfoBox,
    DFPInfoBoxContentContainer,
    DFPInfoBoxContent,
    DFPInfoBoxLabel,
    Div,
    VideoBox,
    VideoBoxDescription,
    VideoBoxText,
    Spacer,
    DFPInfoBoxInnerContainer,
    DFPInfoBoxOuterContainer
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from '../../../utils/time';
import VideoAPI from '../../../api/video';
import PrimaryButton from '../../common/buttons/primary-button';
import Modal from '@mui/material/Modal';
import { Widget } from '@typeform/embed-react'
import ErrorModal from '../../common/error-modal';
import { AuthContext } from '../../../context/authContext';
import ProfileAPI from '../../../api/profile';
import { Box } from '@mui/material';
import NotEligible from '../not-eligible';
import StatusModal from '../status-modal';
import ArrowRightIcon from '../../icons/arrow-right-icon';
import CaretIcon from '../../icons/caret-icon';
import SignupModal from '../../signup/signup-modal';
import {useNavigate} from 'react-router-dom';

const getDfpPoints = (video: any) => video && video.dfpPoints ? video.dfpPoints : '';

const getTestResult = (video: any) => {
    if (video && video.questionnairePoints && video.enrollment && video.enrollment.score) {
        return (video.enrollment.score / video.questionnairePoints) * 100;
    }

    return 0;
};

// @ts-ignore
const Hero = ({video, setVideo}) => {
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const [showSignupModal, setShowSignupModal] = React.useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [typeformHash, setTypeformHash] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isTypeformModalOpen, setIsTypeformModalOpen] = useState(false);
    const [eligibilityModal, setEligibilityModal] = useState({
        isOpen: false,
        type: ''
    });
    const [dfpTestStatusModal, setDfpTestStatusModal] = useState({
        isOpen: false,
        type: '',
        dfpPoints: getDfpPoints(video),
        percentage: getTestResult(video)
    });
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        title: '',
        subtitle: ''
    });
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function extracted(video: any) {
        const url = video?.video?.videoURL?.substring(video?.video?.videoURL?.indexOf("/"));
        if (url && url.includes('&list=')) {
            return video?.video?.videoURL?.substring(video?.video?.videoURL?.indexOf("/") + 26).split('&list',)[0];
        } else {
            return video?.video?.videoURL?.substring(video?.video?.videoURL?.indexOf("/") + 26);
        }
    }

    useEffect(() => {
        const vimeoIframe = document.querySelector('#vimeo-video');

        if (vimeoIframe) {
            // @ts-ignore
            const vimeoPlayer = new Player(vimeoIframe);

            vimeoPlayer.on('play', () => {
                if (!user) {
                    setShowSignupModal(true);
                    vimeoPlayer.pause();
                }
            });
        }
    }, []);

    const onReady = (event: any) => {
        if (user) {
            event.target.seekTo(video?.watching?.stoppedWatchingAt);
        }
    }

    const onEnd = (event: any) => {
        if (user) {
            videoPaused(0, true);
        }
    }

    const onPause = (event: any) => {
        if (user) {
            videoPaused(event.target.getCurrentTime(), false);
        }
    }

    const onPlay = (event: any) => {
        if (!user) {
            setShowSignupModal(true);
            event.target.pauseVideo();
        }
    }

    async function videoPaused(time: any, isEnd: any) {
        // @ts-ignore
        await Promise.all([VideoAPI.videoStopped(video?.id, time, isEnd)]);
    }

    const extractVimeoSource = (video: any) => {
        const vimeoExtractFirstPart = video?.video?.videoURL.split('com/')[1].split('/')[0];
        const vimeoExtractSecondPart = video?.video?.videoURL.split('com/')[1].split('/')[1];

        return 'https://player.vimeo.com/video/' + vimeoExtractFirstPart + '?h=' + vimeoExtractSecondPart + '&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed';
    }

    const titleLength = video?.title?.length
    const isVimeoVideo = video?.video?.videoURL.includes('vimeo')

    const openGenericErrorMessage = () => {
        setErrorMessage({
            title: 'Ooops, da ist ein Fehler passiert',
            subtitle: 'Bitte klicke auf den "DFP Test Starten" Button und versuche es erneut'
        });
        setIsErrorModalOpen(true);
    };

    const handleTypeformOpen = async () => {
        if (!user) {
            setShowSignupModal(true);
        } else {
            setIsButtonDisabled(true);
            const [getUserProfileError, userProfileData] = await ProfileAPI.getCurrentUserProfile(user.userId);
            if (getUserProfileError) {
                // show generic error message if we can't fetch user data
                openGenericErrorMessage()
            } else if (!userProfileData || userProfileData.occupation !== 1) {
                // handle case if user is not a doctor (occupation !== 1)
                setEligibilityModal({
                    isOpen: true,
                    type: 'wrongOccupation'
                });
            } else if (!userProfileData.oeak || userProfileData.oeak === '') {
                // handle case if doctor doesn't have ÖÄK number set on their Profile
                setEligibilityModal({
                    isOpen: true,
                    type: 'missingOeak'
                });
            } else if (video && video.id) {
                // handle cases if user is/isn't enrolled and if the user has already passed/failed the test
                if (!video.isEnrolled) {
                    // handle case if user isn't enrolled
                    const res = await VideoAPI.enrollInDFP(video.id);
                    if (res[1] && res[1].hash) {
                        setUserEmail(userProfileData.email);
                        setTypeformHash(res[1].hash);
                        setIsTypeformModalOpen(true);

                        // update video status after enrollment
                        const resVideo = await VideoAPI.getDFPVideoById(video.id);
                        if (resVideo[1]) {
                            setVideo(resVideo[1]);
                        }
                    } else {
                        openGenericErrorMessage();
                    }
                } else if (!video.enrollment.status || video.enrollment.status === "started") {
                    // handle case if user is enrolled but status isn't set or its value is "started"
                    const hash = video.enrollment.hash;

                    setUserEmail(userProfileData.email);
                    setTypeformHash(hash);
                    setIsTypeformModalOpen(true);
                } else {
                    // handle cases if user has already finished the course, status can be "passed" or "failed"
                    setDfpTestStatusModal(values => ({
                        ...values,
                        isOpen: true,
                        type: video.enrollment.status
                    }));
                }
            } else {
                // fallback - show generic error message if we're missing necessary data
                openGenericErrorMessage();
            }
            setIsButtonDisabled(false);
        }
    };

    const handleTypeformClose = () => {
        setTypeformHash('');
        setUserEmail('');
        setIsTypeformModalOpen(false);
    };

    const handleTypeformSubmit = () => {
        handleTypeformClose();

        setDfpTestStatusModal({
            ...dfpTestStatusModal,
            isOpen: true,
            type: 'loading',
        });

        // this is a "fake" timeout which gives backend enough time to communicate with Typeform and store results
        setTimeout(async () => {
            const res = await VideoAPI.getDFPVideoById(video.id);

            if (res[1] && res[1].enrollment) {
                const {status} = res[1].enrollment;

                setDfpTestStatusModal({
                    isOpen: true,
                    type: status,
                    dfpPoints: getDfpPoints(res[1]),
                    percentage: getTestResult(res[1])
                });

                setVideo(res[1]);
            } else {
                setDfpTestStatusModal({
                    ...dfpTestStatusModal,
                    isOpen: false,
                    type: '',
                });
                openGenericErrorMessage();
            }
        }, 5000);
    };

    return (
        // <Div style={{padding: !isMobile ? '40px 0 50px 0' : video?.speakers?.length === 0 ? 350 : 500}}>
        <>
            <Div>
                <Container>
                    <VideoBox>
                        {isVimeoVideo
                            ?
                            <iframe id="vimeo-video"
                                    src={extractVimeoSource(video)}
                                    width={isMobile ? '100%' : '865'}
                                    height={isMobile ? '200' : '514'}
                                    frameBorder="0"
                                    allowFullScreen
                                    allow={`encrypted-media; ${user ? 'autoplay' : ''}`}/>
                            : <YouTube
                                videoId={extracted(video)}
                                onReady={onReady}
                                onPlay={onPlay}
                                onEnd={onEnd}
                                onPause={onPause}
                                opts={{
                                    height: !isMobile ? '514' : '200',
                                    width: !isMobile ? '865' : '100%',
                                    playing: 0,
                                    loop: 1,

                                    controls: 0,
                                    rel: 0,
                                    modestBranding: 1,
                                    playerVars: {
                                        autoplay: user ? 1 : 0
                                    }
                                }}
                            />
                        }
                        <div style={{position: 'relative'}}>
                            <VideoBoxText style={{
                                fontSize: titleLength < 70
                                    ? isMobile ? 22 : 27
                                    : titleLength < 90
                                        ? isMobile ? 22 : 22
                                        : isMobile ? 22 : 18
                            }}>
                                {video?.title}</VideoBoxText>
                            {
                                !isMobile && (
                                    <VideoBoxDescription style={{
                                        top: isMobile ? titleLength < 70
                                                ? 80
                                                : titleLength < 90
                                                    ? 105
                                                    : titleLength < 130
                                                        ? 130
                                                        : 155
                                            : 61
                                    }}>{format(video?.video?.publicationDate, 'dd. MMM yyyy.')} | {video?.video?.source} | {video?.dfpPoints} DFP</VideoBoxDescription>
                                )
                            }
                        </div>
                    </VideoBox>

                    {
                        !isMobile ? (
                            <DFPInfoBox>
                                <DFPInfoBoxOuterContainer>
                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            <Box sx={{
                                                paddingRight: {
                                                    xs: 0,
                                                    sm: '30px'
                                                }
                                            }}>
                                                {video?.description?.length !== 0 &&
                                                  <DFPInfoBoxLabel>BESCHREIBUNG</DFPInfoBoxLabel>
                                                }
                                                {video?.description?.length !== 0 &&
                                                  <DFPInfoBoxContent>
                                                      {video.description}
                                                  </DFPInfoBoxContent>
                                                }
                                            </Box>
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>
                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            <Buttons>
                                                <PrimaryButton sx={{
                                                    width: '100%',
                                                    '&.Mui-disabled': {
                                                        color: '#FFFFFF'
                                                    }
                                                }}
                                                               disabled={isButtonDisabled}
                                                               onClick={() => {
                                                                   handleTypeformOpen()
                                                               }}>
                                                    DFP Test starten
                                                </PrimaryButton>
                                            </Buttons>
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>
                                </DFPInfoBoxOuterContainer>

                                <DFPInfoBoxOuterContainer>
                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            {video?.speakers?.length !== 0 &&
                                              <DFPInfoBoxLabel>SPEAKERS</DFPInfoBoxLabel>
                                            }
                                            {video?.speakers?.map((speaker: any, index: any) => (
                                                <DFPInfoBoxContent
                                                    key={index}> {speaker?.speaker?.title + ' ' + speaker?.speaker?.firstName + ' ' + speaker?.speaker?.lastName}
                                                    {video?.speakers?.length - 1 > index &&
                                                      <Spacer>•</Spacer>
                                                    }
                                                </DFPInfoBoxContent>
                                            ))}
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>

                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            {video?.organizers?.length !== 0 &&
                                              <DFPInfoBoxLabel>FORTBILDUNGSANBIETER</DFPInfoBoxLabel>
                                            }
                                            {video?.organizers?.map((organizer: any, index: any) => (
                                                <DFPInfoBoxContent
                                                    key={index}> {organizer?.sponsor?.name}
                                                    {video?.organizers?.length - 1 > index &&
                                                      <Spacer>•</Spacer>
                                                    }
                                                </DFPInfoBoxContent>
                                            ))}
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>
                                </DFPInfoBoxOuterContainer>

                                <DFPInfoBoxOuterContainer>
                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            {video?.provider !== 0 &&
                                              <DFPInfoBoxLabel>WISSENSCHAFTLICHE LEITER </DFPInfoBoxLabel>
                                            }
                                            <DFPInfoBoxContent> {video?.provider}</DFPInfoBoxContent>
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>

                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            {video?.lectureBoardDescription !== 0 &&
                                              <DFPInfoBoxLabel>LECTURE BOARD</DFPInfoBoxLabel>
                                            }
                                            <DFPInfoBoxContent> {video?.lectureBoardDescription}</DFPInfoBoxContent>
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>
                                </DFPInfoBoxOuterContainer>

                                <DFPInfoBoxOuterContainer>
                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            {video?.sponsors?.length !== 0 &&
                                              <DFPInfoBoxLabel>SPONSORS</DFPInfoBoxLabel>
                                            }
                                            {video?.sponsors?.map((sponsor: any, index: any) => (
                                                <DFPInfoBoxContent
                                                    key={index}> {sponsor?.sponsor?.name}
                                                    {video?.sponsors?.length - 1 > index &&
                                                      <Spacer>•</Spacer>
                                                    }
                                                </DFPInfoBoxContent>
                                            ))}
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>
                                </DFPInfoBoxOuterContainer>
                            </DFPInfoBox>
                        ) : (
                            // mobile view
                            <Box width="100%">
                                <Box component="span"
                                     fontFamily="Inter"
                                     fontWeight={600}
                                     margin="0 0 0 10px"
                                     display="flex"
                                     alignItems="center"
                                     color="#fff"
                                     onClick={() => setIsDetailsVisible(!isDetailsVisible)}
                                     sx={{
                                         cursor: 'pointer'
                                     }}
                                >
                                    {isDetailsVisible ? 'Details verbergen' : 'Details'}
                                    <CaretIcon style={{
                                        transform: `rotate(${isDetailsVisible ? '-180deg' : '-90deg'})`,
                                        marginLeft: '10px'
                                    }}/>
                                </Box>
                                <Box display={isDetailsVisible ? 'block' : 'none'}>
                                    <Box width="100%" mt={2}>
                                        <DFPInfoBoxOuterContainer>
                                            <DFPInfoBoxInnerContainer>
                                                <DFPInfoBoxContentContainer>
                                                    <Box sx={{
                                                        paddingRight: {
                                                            xs: 0,
                                                            sm: '30px'
                                                        }
                                                    }}>
                                                        {video?.description?.length !== 0 &&
                                                          <DFPInfoBoxLabel>BESCHREIBUNG</DFPInfoBoxLabel>
                                                        }
                                                        {video?.description?.length !== 0 &&
                                                          <DFPInfoBoxContent>
                                                              {video.description}
                                                          </DFPInfoBoxContent>
                                                        }
                                                    </Box>
                                                </DFPInfoBoxContentContainer>
                                            </DFPInfoBoxInnerContainer>
                                        </DFPInfoBoxOuterContainer>

                                        <DFPInfoBoxOuterContainer>
                                            <DFPInfoBoxInnerContainer>
                                                <DFPInfoBoxContentContainer>
                                                    {video?.speakers?.length !== 0 &&
                                                      <DFPInfoBoxLabel>SPEAKERS</DFPInfoBoxLabel>
                                                    }
                                                    {video?.speakers?.map((speaker: any, index: any) => (
                                                        <DFPInfoBoxContent
                                                            key={index}> {speaker?.speaker?.title + ' ' + speaker?.speaker?.firstName + ' ' + speaker?.speaker?.lastName}
                                                            {video?.speakers?.length - 1 > index &&
                                                              <Spacer>•</Spacer>
                                                            }
                                                        </DFPInfoBoxContent>
                                                    ))}
                                                </DFPInfoBoxContentContainer>
                                            </DFPInfoBoxInnerContainer>

                                            <DFPInfoBoxInnerContainer>
                                                <DFPInfoBoxContentContainer>
                                                    {video?.organizers?.length !== 0 &&
                                                      <DFPInfoBoxLabel>FORTBILDUNGSANBIETER</DFPInfoBoxLabel>
                                                    }
                                                    {video?.organizers?.map((organizer: any, index: any) => (
                                                        <DFPInfoBoxContent
                                                            key={index}> {organizer?.sponsor?.name}
                                                            {video?.organizers?.length - 1 > index &&
                                                              <Spacer>•</Spacer>
                                                            }
                                                        </DFPInfoBoxContent>
                                                    ))}
                                                </DFPInfoBoxContentContainer>
                                            </DFPInfoBoxInnerContainer>
                                        </DFPInfoBoxOuterContainer>

                                        <DFPInfoBoxOuterContainer>
                                            <DFPInfoBoxInnerContainer>
                                                <DFPInfoBoxContentContainer>
                                                    {video?.provider !== 0 &&
                                                      <DFPInfoBoxLabel>WISSENSCHAFTLICHE LEITER </DFPInfoBoxLabel>
                                                    }
                                                    <DFPInfoBoxContent> {video?.provider}</DFPInfoBoxContent>
                                                </DFPInfoBoxContentContainer>
                                            </DFPInfoBoxInnerContainer>

                                            <DFPInfoBoxInnerContainer>
                                                <DFPInfoBoxContentContainer>
                                                    {video?.lectureBoardDescription !== 0 &&
                                                      <DFPInfoBoxLabel>LECTURE BOARD</DFPInfoBoxLabel>
                                                    }
                                                    <DFPInfoBoxContent> {video?.lectureBoardDescription}</DFPInfoBoxContent>
                                                </DFPInfoBoxContentContainer>
                                            </DFPInfoBoxInnerContainer>
                                        </DFPInfoBoxOuterContainer>

                                        <DFPInfoBoxOuterContainer>
                                            <DFPInfoBoxInnerContainer>
                                                <DFPInfoBoxContentContainer>
                                                    {video?.sponsors?.length !== 0 &&
                                                      <DFPInfoBoxLabel>SPONSORS</DFPInfoBoxLabel>
                                                    }
                                                    {video?.sponsors?.map((sponsor: any, index: any) => (
                                                        <DFPInfoBoxContent
                                                            key={index}> {sponsor?.sponsor?.name}
                                                            {video?.sponsors?.length - 1 > index &&
                                                              <Spacer>•</Spacer>
                                                            }
                                                        </DFPInfoBoxContent>
                                                    ))}
                                                </DFPInfoBoxContentContainer>
                                            </DFPInfoBoxInnerContainer>
                                        </DFPInfoBoxOuterContainer>
                                    </Box>
                                </Box>
                                <VideoBoxDescription style={{
                                    top: isMobile ? titleLength < 70
                                            ? 80
                                            : titleLength < 90
                                                ? 105
                                                : titleLength < 130
                                                    ? 130
                                                    : 155
                                        : 61
                                }}>{format(video?.video?.publicationDate, 'dd. MMM yyyy.')} | {video?.video?.source} | {video?.dfpPoints} DFP</VideoBoxDescription>
                                <Box p="0 10px">
                                    <DFPInfoBoxInnerContainer>
                                        <DFPInfoBoxContentContainer>
                                            <PrimaryButton sx={{
                                                width: '100%',
                                                '&.Mui-disabled': {
                                                    color: '#FFFFFF'
                                                }
                                            }}
                                                           disabled={isButtonDisabled}
                                                           onClick={() => {
                                                               handleTypeformOpen()
                                                           }}>
                                                DFP Test starten
                                            </PrimaryButton>
                                        </DFPInfoBoxContentContainer>
                                    </DFPInfoBoxInnerContainer>
                                </Box>
                            </Box>
                        )
                    }
                </Container>
            </Div>
            {/* Modals section */}
            <Modal open={eligibilityModal.isOpen}
                   aria-labelledby="eligibility-modal-title"
                   aria-describedby="eligibility-modal">
                <NotEligible type={eligibilityModal.type} onClose={() => setEligibilityModal({
                    isOpen: false,
                    type: ''
                })}/>
            </Modal>
            <Modal open={dfpTestStatusModal.isOpen}
                   aria-labelledby="status-modal-title"
                   aria-describedby="status-modal">
                <StatusModal type={dfpTestStatusModal.type}
                             dfpPoints={dfpTestStatusModal.dfpPoints}
                             score={dfpTestStatusModal.percentage}
                             onClose={() => setDfpTestStatusModal(prevValues => ({
                                 ...prevValues,
                                 isOpen: false,
                                 type: ''
                             }))}/>
            </Modal>
            <Modal open={isTypeformModalOpen}
                   aria-labelledby="typeform-modal-title"
                   aria-describedby="typeform-modal">
                <>
                    {
                        typeformHash !== '' && userEmail !== '' && video && video.questionnaireId ? (
                            <>
                                <Widget id={video.questionnaireId}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            width: '90%',
                                            height: '90%',
                                            transform: 'translate(-50%, -50%)'
                                        }}
                                        inlineOnMobile={true}
                                        onSubmit={handleTypeformSubmit}
                                        tracking={{email: userEmail, hash: typeformHash}}/>
                                <PrimaryButton onClick={handleTypeformClose} sx={{
                                    position: 'absolute',
                                    top: '7%',
                                    right: '7%'
                                }}>Zurück</PrimaryButton>
                            </>
                        ) : null
                    }
                </>
            </Modal>
            <ErrorModal
                open={isErrorModalOpen}
                handleClose={() => setIsErrorModalOpen(false)}
                title={errorMessage.title}
                message={errorMessage.subtitle}
            />
            <SignupModal
                open={showSignupModal}
                handleClose={() => setShowSignupModal(false)}
                onAuthFinished={() => navigate(0)}
                doneFunction={() => navigate(0)}
            />
        </>
    )
}

export default Hero;
