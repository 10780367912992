import React from 'react';
import VideoPlayLanding from '../components/video-play-landing';
import Footer from '../components/layout/footer';

function VideoPlayLandingPage () {
    return (
        <>
            <VideoPlayLanding/>
            <Footer />
        </>
    );
}

export default VideoPlayLandingPage;