import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import UserAPI from '../../api/user';
import { Div, Text, Form, Img } from './style';
import Card from '../common/card';
import TextField from '../common/fields/text-field';
import Button from '../common/buttons/primary-button';
import ForwardButton from '../common/buttons/forward-button';
import { deTranslations } from '../../translations';
import ErrorModal from '../common/error-modal';
import FormHelperText from '@mui/material/FormHelperText';

// @ts-ignore
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, deTranslations.password_short_warning)
    .required(deTranslations.password_empty_warning),
  passwordConfirm: Yup.string()
    .min(6, deTranslations.password_short_warning)
    .required(deTranslations.password_empty_warning)
    .when('password', {
      // @ts-ignore
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        deTranslations.password_no_match,
      )
    }),
});

// @ts-ignore
const ResetPassword = ({ email, passwordToken }) => {
  const navigate = useNavigate();
  const [initialStepDone, setInitialStepDone] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tokenUsedError, setTokenUsedError] = useState('');
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
      email: email,
      passwordToken: passwordToken,
    },
    validationSchema: validationSchema,
    onSubmit: (async values => {
      // @ts-ignore
      const [sendError, send] = await UserAPI.resetPassword({
        email: values.email,
        passwordToken: values.passwordToken,
        password: values.password,
      });
      // @ts-ignore
      if (sendError === 'Token invalid or already used') {
        setTokenUsedError(deTranslations.token_already_used_error);
        return;
      }
      // @ts-ignore
      if (sendError) {
        setOpenModal(true);
        return;
      }
      setInitialStepDone(true);
    }),
  });

  return (
    <Div>
      <ErrorModal open={openModal} handleClose={() => setOpenModal(false)} retry={formik.handleSubmit} />
      {!initialStepDone &&
      <Form onSubmit={formik.handleSubmit}>
        <Card title={deTranslations.set_password_title}>
          <Text style={{ marginTop: -8 }}>{deTranslations.set_password_instructions}</Text>
          <TextField
            fullWidth={true}
            id='password'
            name='password'
            label={deTranslations.password_label_text}
            type='password'
            placeholderText='•••••••••••••••'
            value={formik.values.password}
            onChange={formik.handleChange}
            errorMessage={formik.touched.password ? formik.errors.password : ''}
          />
          <TextField
            fullWidth={true}
            id='passwordConfirm'
            name='passwordConfirm'
            label={deTranslations.password_label_text}
            type='password'
            placeholderText='•••••••••••••••'
            value={formik.values.passwordConfirm}
            onChange={formik.handleChange}
            errorMessage={formik.touched.passwordConfirm ? formik.errors.passwordConfirm : ''}
          />
          <FormHelperText
            sx={{
              color: '#F05B4D',
              fontSize: 13,
              fontWeight: 400,
              marginLeft: 1.5
            }}
            error={tokenUsedError !== ''}
          >
            {tokenUsedError}
          </FormHelperText>
          <Button style={{marginTop: 8}} type='submit'>{deTranslations.set_password_cta}</Button>
        </Card>
      </Form>
      }
      {initialStepDone &&
      <Card title={deTranslations.password_successfully_reset}>
        <Text style={{ marginTop: -10, textAlign: 'center'}}>{deTranslations.you_ve_successfully_reset_password}</Text>
        <Img src='/success.png' alt='' />
        <ForwardButton sx={{marginTop: 1, marginBottom: -1}} onClick={() => navigate('/login')}>
          {deTranslations.login_title}
        </ForwardButton>
      </Card>
      }
    </Div>
  );
}

export default ResetPassword;
