import styled from 'styled-components';
import Card from '@mui/material/Card';
import { Theme } from '@mui/material';

export const Div = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
        marginTop: 0,
    },
}));

export const Form = styled.form(({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const TermsAndConditions = styled.div(({theme}) => ({
    display: 'flex',
    // justifyContent: 'space-between',
    width: '100%',
    margin: 0,
}));

// @ts-ignore
export const Text = styled.p(({theme, error}) => ({
    fontSize: 12,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    color: error ? theme.palette.ctaError : theme.palette.textColors.body,
}));

export const Link = styled.a(({theme}) => ({
    fontSize: 12,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    color: '#063F58',
    textDecorationLine: 'underline',
}));

// @ts-ignore
export const OnboardingContainer = styled.div(({theme, hideOnboardingWidget}) => ({
    display: 'flex',
    flexDirection: 'row',

    ['.MuiPaper-root:first-of-type']: {
        [theme.breakpoints.up("sm")]: {
            borderRadius: hideOnboardingWidget ? '18.24px' : '18.24px 0 0 18.24px'
        },

        [theme.breakpoints.down("lg")]: {
            borderRadius: '18.24px'
        },
    },

    '.MuiPaper-root>h2': {
        fontWeight: 600,
        textAlign: 'left',
        fontSize: '20px',
        lineHeight: '24px',
        paddingBottom: '10px',
        color: '#2A2A2A'
    }
}));

export const OnboardingCard = styled(Card)(({theme}) => ({
    '&&': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        // width: 300,
        padding: 22,
        borderRadius: '0 18.24px 18.24px 0',
        boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.05), 0px 0.912px 0.912px rgba(0, 0, 0, 0.02)',
        backgroundColor: '#063F58',
        [theme.breakpoints.down("lg")]: {
            display: 'none'
        },
        [theme.breakpoints.up("sm")]: {
            width: 420,
        },
        alignItems: 'center',
        position: 'relative'
    }
}));

export const OnboardingCardHeader = styled.div(({theme}) => ({
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#FFFFFF',
    opacity: 0.9,
}));

export const OnboardingCardContent = styled.div(({theme}) => ({
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FFFFFF',
    opacity: 0.8,
    paddingLeft: '44px',
    marginTop: '50px',
    marginBottom: '35px'
}));

export const OnboardingCardFooter = styled.div(({theme}) => ({
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FFFFFF',
    opacity: 0.7,
}));

export const OnboardingCardWaveImage = styled.div(({theme}) => ({
    position: 'absolute',
    width: '100%',
    bottom: '-30px',
}));

export const OnboardingCardLogoImage = styled.div(({theme}) => ({
    position: 'absolute',
    bottom: '20px',
    right: '30px'
}));

export const LineItem = styled.div(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    ['img']: {
        marginRight: '9px'
    }
}));
