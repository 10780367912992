import Kontakt from '../components/static-pages/kontakt';
import Footer from '../components/layout/footer';

const KontaktPage = () => {
    return (
        <>
          <Kontakt />
          <Footer />
        </>
      );
};

export default KontaktPage;