import { Box } from '@mui/material';
import styled from 'styled-components';

export const Title = styled.h1(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 800,
  fontSize: '40px',
  lineHeight: '48px',
  color: '#0E0E0E',
  margin: '0 0 18px 0',

  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
    lineHeight: '34px'
  },
}));

export const Text = styled.p(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#676767',
  margin: '0',
}));

export const Divider = styled(Box)(({ theme }) => ({
  height: '1px',
  backgroundColor: '#C4C4C4',
}));
