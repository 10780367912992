import React from 'react';
import { StyledModal, StyledDiv } from './style';
import Card from '../../../../common/card';
import { deTranslations } from '../../../../../translations';
import SearchList from '../../../../menus/search-list';
import LocationAPI from '../../../../../api/location';

// @ts-ignore
const LocationModal = ({ open, handleClose, onPicked, formData }) => {
  const [list, setList] = React.useState([]);
  const [returnData, setReturnData] = React.useState({
    name: '',
    id: 0,
  });

  React.useEffect(() => {
    async function getLocationList() {
      // hospital doctor or nurse
      if (formData.placeOfWork === 'hospital' || formData.occupation === 4) {
        const [_, hospitals] = await LocationAPI.getAllHospitalNamesAndIds();
        setList(hospitals);
        return;
      }

      // public or private doctor, or pharmacist
      if (formData.placeOfWork === 'public' || formData.placeOfWork === 'private' || formData.occupation === 2) {
        const [_, regions] = await LocationAPI.getAllRegionNamesAndIds();
        setList(regions);
        return;
      }

      // student
      if (formData.occupation === 3) {
        const [_, universities] = await LocationAPI.getAllUniversityNamesAndIds();
        setList(universities);
        return;
      }
    }

    getLocationList();
  }, [formData]);

  return <StyledModal
    open={open}
    disableAutoFocus={true}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledDiv>
      <Card title={deTranslations.place_of_work}>
        <SearchList
          list={list}
          onPicked={(value: any) => {
            let item: any;
            for (const group of list) {
              // @ts-ignore
              item = group[1].find((item: any) => {
                return item.id === value;
              });
              if (item !== undefined) {
                break;
              }
            }

            onPicked({ id: item.id, name: item.value })
            // list[1].find((item: any) => {
            //
            //   return item.id === value;
            // });
          }}
          sx={{
            maxHeight: 344,
            minHeight: 344,
            overflow: 'auto',
            marginRight: -3,
            marginLeft: -3,
          }}
        />
      </Card>
    </StyledDiv>
  </StyledModal>
}

export default LocationModal;
