import styled from 'styled-components';

export const Separator = styled.div(({theme}) => ({
    borderTop: `1px solid #C4C4C4`,
    width: 638,
    opacity: 0.4,
    margin: '0 auto',

    [theme.breakpoints.down("md")]: {
        width: '90%',
    },
}));