import { Box } from '@mui/material';
import InfoWithMapWidget from './info-with-map-widget';
import { Title, Text } from './style';

const Impressum = () => {
    return (
        <Box p="0 20px" sx={{ backgroundColor: { xs: '#FCFCFC', md: '#FFF' } }}>
            <Box maxWidth="940px" m="0 auto" p={{ xs: '0', md: '0 0 250px 0' }}>
                <Box m={{ xs: '40px 0 0 0', md: '40px 110px' }}>
                    <Title>Impressum</Title>
                    <Text>
                        Meducately ist eine eingetragene Marke der Hellomint GmbH
                    </Text>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: '#FCFCFC' }} height={{ xs: 'auto', md: '289px' }} p={{ xs: '45px 0 40px 0', md: '0 0 20px 0' }} m="0 -20px">
                <Box maxWidth="940px" m={{ xs: '0', md: '0 auto' }} position="relative" top={{ xs: 0, md: '-250px' }}>
                    <Box display="flex" justifyContent="center">
                        <InfoWithMapWidget />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
};

export default Impressum;