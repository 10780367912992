import styled from 'styled-components';
import Card from '@mui/material/Card';

export const DivCard = styled.div(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    width: 770,
    padding: 22,
    borderRadius: '18.24px',
    boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.05), 0px 0.912px 0.912px rgba(0, 0, 0, 0.02)',
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  '&&': {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    padding: 22,
    borderRadius: '18.24px',
    boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.05), 0px 0.912px 0.912px rgba(0, 0, 0, 0.02)',
    [theme.breakpoints.up("sm")]: {
      width: 460,
    },
  }
}));

export const Title = styled.h2(({ theme }) => ({
  textAlign: 'center',
  fontSize: 27,
  fontWeight: 400,
  color: '#2E3436',
  margin: 0,
  marginBottom: 5,
  fontFamily: ['Inter', 'normal'].join(','),
}));

export const ImageSignUp = styled.img(({ theme }) => ({
  width: 380,
  height: 485
}));
