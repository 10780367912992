import styled from 'styled-components';

export const Text = styled.p(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#434849',
}));

export const Img = styled.img(({ theme }) => ({
  display: 'flex',
  width: 153,
  alignSelf: 'center',
}));
