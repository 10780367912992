import {useState} from 'react';
import {SwiperSlide} from 'swiper/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Section,
    SectionTitle,
    SectionText,
    SidemenuLink,
    Swiper,
    Title,
    SectionSubtitle,
    CustomAccordionSummary,
    Sections
} from './style';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Faq = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const numberOfSlides = isMobile ? 1.4 : 3;

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box display="flex">
            {/* @ts-ignore */}
            <Box flex={1}
                 pt="80px"
                 backgroundColor="#fff"
                 display={{
                     xs: 'none',
                     md: 'block'
                 }}>
                <Box position="sticky" top={110} display="flex" alignItems="center" justifyContent="center">
                    <Box display="flex" flexDirection="column" gap="16px" width="200px" p="0 10px">
                        <SidemenuLink href="#produkt">FAQ Produkt</SidemenuLink>
                        <SidemenuLink href="#partnerschaft">FAQ Partnerschaft</SidemenuLink>
                        <SidemenuLink href="#webinar">FAQ Webinar</SidemenuLink>
                        <SidemenuLink href="#videos">FAQ Videos & Channels</SidemenuLink>
                    </Box>
                </Box>
            </Box>
            {/* @ts-ignore */}
            <Box width={{
                xs: '100%',
                md: '820px'
            }}
                 boxSizing="border-box"
                 backgroundColor={{
                     xs: '#FFF',
                     md: '#FCFCFC'
                 }}
                 p={{
                     xs: '40px 20px 14px 20px',
                     md: '80px 20px 14px 20px'
                 }}>
                <Box mb={{
                    xs: 3,
                    md: 6
                }}>
                    <Title>
                        Häufige Fragen
                    </Title>
                    <Box display={{
                        xs: 'flex',
                        md: 'none'
                    }}
                         m="0 -20px 8px -20px"
                         p="0 20px"
                         alignItems="center"
                         minHeight="56px">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={numberOfSlides}
                        >
                            <SwiperSlide>
                                <SidemenuLink href="#produkt">FAQ Produkt</SidemenuLink>
                            </SwiperSlide>
                            <SwiperSlide>
                                <SidemenuLink href="#partnerschaft">FAQ Partnerschaft</SidemenuLink>
                            </SwiperSlide>
                            <SwiperSlide>
                                <SidemenuLink href="#webinar">FAQ Webinar</SidemenuLink>
                            </SwiperSlide>
                            <SwiperSlide>
                                <SidemenuLink href="#videos">FAQ Videos & Channels</SidemenuLink>
                            </SwiperSlide>
                        </Swiper>
                    </Box>
                    <Box component="span" color="#676767" fontFamily="Inter" fontWeight={400} fontSize="16px"
                         lineHeight="24px">
                        Hier findest du Antworten auf häufig gestellte Fragen aus den verschiedenen Bereichen. Konnten
                        wir deine Frage dennoch nicht beantworten? Dann klicke hier?&nbsp;
                        <Box component="a" href="#" fontWeight={500} lineHeight="20px" color="#404040"
                             sx={{textDecoration: 'none'}}>
                            Kontaktieren
                        </Box>
                    </Box>
                </Box>
                {/* @ts-ignore */}
                <Box backgroundColor="#FCFCFC"
                     mb={{
                         xs: 4,
                         md: 0
                     }}>
                    <Sections>
                        <Section id="produkt">
                            <SectionTitle>
                                Häufige Fragen zum Produkt
                            </SectionTitle>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>
                                        Was genau ist meducately?
                                    </Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Meducately ist eine kostenlose Plattform für medizinische Videoinhalte.
                                        Wir sammeln und kuratieren Fortbildungsvideos von unterschiedlichen Quellen und
                                        stellen diese gesammelt und passend zu deiner Fachrichtung zur Verfügung.
                                        Außerdem veranstalten wir regelmäßig DFP-approbierter Webinare, für die du dich
                                        mit deinen meducately Zugangsdaten einfach und unkompliziert anmelden
                                        kannst.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Woher bezieht meducately seine Videos?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Die Videos stammen von mehr als 100 unterschiedlichen Quellen. Darunter
                                        fallen medizinische Fachgesellschaften, Kongressveranstalter, Agenturen oder
                                        Pharmafirmen. Außerdem produziert meducately auch selbst Inhalte, um das Angebot
                                        stetig zu erweitern.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Muss ich Arzt oder Ärztin sein, um mich
                                        anzumelden?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Du musst nicht Arzt bzw. Ärztin sein, um meducately nutzen zu können.
                                        Auch für anderes Gesundheitspersonal wie ApothekerInnen, MedizinstudentInnen
                                        oder MitarbeiterInnen der Pharmaindustrie gibt es spezifische Inhalte. Einige
                                        Inhalte oder Webinare sind aber nur für ÄrztInnen verfügbar.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Warum brauche ich einen Account für
                                        meducately?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Da einige unserer Videos nur für medizinisches Fachpersonal zur
                                        Verfügung stehen, sind wir verpflichtet, diese Personen zu überprüfen. Dafür
                                        benötigen wir einige Informationen zu deiner Person.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel5bh-content"
                                    id="panel5bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Ist meducately kostenlos?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Die Nutzung unserer Plattform ist kostenlos und wird es auch
                                        bleiben.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel6bh-content"
                                    id="panel6bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Kann ich selbst auch Videos oder Webinare auf
                                        meducately hochladen?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Meducately ist eine offene Plattform und bietet jedem die Möglichkeit,
                                        seine/ihre Videos oder Webinareinladungen zu platzieren. Voraussetzung ist
                                        natürlich, dass unsere Qualitätsstandards eingehalten werden. Willst du Videos
                                        hochladen oder Webinare promoten, dann klicke hier: Kontaktieren</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel7bh-content"
                                    id="panel7bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Warum soll ich einen Ad-Blocker
                                        installieren?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Viele der Videos sind über YouTube eingebettet. Damit deine Experience
                                        nicht durch Werbungen unterbrochen wird, raten wir dir, bestimmte YouTube
                                        Ad-Blocker zu installieren. Dabei vertrauen wir auf etablierte und seriöse
                                        Plugins, die millionenfach im Einsatz sind.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                        <Section id="partnerschaft">
                            <SectionTitle>HÄUFIGE FRAGEN ZUR PARTNERSCHAFT</SectionTitle>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel8bh-content"
                                    id="panel8bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Wie kann eine Partnerschaft aussehen?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Es gibt verschiedene Wege, mit meducately zu kooperieren. Einerseits ist
                                        es möglich, Videos auf unserer Plattform zu hosten, andererseits gibt es auch
                                        die Möglichkeit, künftige Webinare anzukündigen oder Channels zu
                                        erstellen.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel9bh-content"
                                    id="panel9bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Ist eine Partnerschaft
                                        kostenpflichtig?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>In den meisten Fällen ist eine Partnerschaft mit Kosten verbunden. Die
                                        genauen Kosten stehen immer in Abhängigkeit zum Aufwand und der Intensität der
                                        Vermarktung. Wir sind jedoch auch für andere Möglichkeiten der Kooperation
                                        offen. </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                        <Section id="webinar">
                            <SectionTitle>HÄUFIGE FRAGEN ZU WEBINAREN</SectionTitle>
                            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel10bh-content"
                                    id="panel10bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Welche Kriterien müssen Live Webinare erfüllen, um
                                        auf meducately angekündigt zu werden?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Die Webinarinhalte müssen frei von werblichen Inhalten sein und dürfen
                                        keine Produktwerbung beinhalten. Darüber hinaus ist es wichtig, dass uns der
                                        Einwahllink zum Live-Webinar (z.B. Zoom oder WebEx) direkt bereitgestellt wird,
                                        damit sich interessierte NutzerInnen nicht erneut extern anmelden
                                        müssen.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel11bh-content"
                                    id="panel11bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Wie kann ich mein Webinar auf meducately
                                        ankündigen?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Um ein Webinar auf meducately ankündigen zu lassen, ist es notwendig,
                                        alle zur Verfügung gestellten Felder auszufüllen. Neben Titel, Uhrzeit und Datum
                                        sind auch Informationen zu Agenda sowie Vortragenden entscheidend. Bei DFP
                                        approbierten Webinaren sind auch der Fortbildungsanbieter, Anzahl der DFP Punkte
                                        und der wissenschaftliche Leiter zu erwähnen.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel12bh-content"
                                    id="panel12bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Muss mein Webinar DFP approbiert sein, damit es
                                        angekündigt wird?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Nein. Wir stellen auch Webinare auf unsere Plattform, die keine DFP
                                        Approbation erhalten haben.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel13bh-content"
                                    id="panel13bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Wie erfahren ÄrztInnen und Ärzte von meinem
                                        Webinar?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Das kommt auf den Umfang der Kooperation an. Wir können einerseits die
                                        Einladung exklusiv per Newsletter an die gewünschte Zielgruppe senden, das
                                        Webinar im Rahmen anderer Webinare ankündigen oder wir nehmen die
                                        Webinareinladung in unseren regelmäßigen Newsletter auf. Egal für welche
                                        Variante du dich entscheiden solltest, wir finden hier sicherlich die passende
                                        Lösung.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel14bh-content"
                                    id="panel14bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Wieviel kostet eine
                                        Webinar-Platzierung?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Das ist abhängig vom Umfang der gewünschten Vermarktung. Es startet
                                        jedoch bei EUR 1.500,- exkl. MwSt. Setze dich mit unserem Sales Team in
                                        Verbindung und erfahre mehr dazu hier. </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Section>


                        <Section id="videos">
                            <SectionTitle>HÄUFIGE FRAGEN ZU VIDEOS & CHANNELS</SectionTitle>
                            <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel15bh-content"
                                    id="panel15bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Welche Kriterien müssen Videos erfüllen, um auf
                                        meducately eingebettet zu werden?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Die Videos müssen frei von werblichen Inhalten sein und dürfen keine
                                        Produktwerbung beinhalten. Darüber hinaus ist es wichtig, dass der
                                        Kooperationspartner auch rechtlich befugt ist, das Video bzw. den Inhalt bei uns
                                        listen zu lassen. </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel16bh-content"
                                    id="panel16bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Wie erhält mein Video auf meducately die gewünschte
                                        Reichweite?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Das kommt auf den Umfang der Kooperation an. Hier gibt es beispielsweise
                                        die Möglichkeit eines exklusiven Newsletters, in dem die jeweiligen Videos an
                                        die gewünschte Zielgruppe ausgesendet werden. Oder du entscheidest dich dafür,
                                        eine Platzierung in unseren regelmäßigen Newslettern zu buchen. Außerdem gibt es
                                        die Möglichkeit, Videos als “featured” zu markieren, damit die Sichtbarkeit
                                        zusätzlich erhöht wird.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel17bh-content"
                                    id="panel17bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Mit welchen Kosten muss ich rechnen, wenn ich
                                        Videos auf meducately hochladen möchte?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Diese Frage lässt sich nicht so einfach beantworten und ist wiederum von
                                        der Art der Kooperation abhängig. Das einfache “Hochladen” von Videos beginnt
                                        bei einer Einmalgebühr von EUR 200,-. Möchte man die Reichweite steigern oder
                                        auch wissen, wie oft das Video in einem bestimmten Zeitraum angesehen wurde,
                                        werden zusätzliche Entgelte fällig. </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                                <CustomAccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon/>}
                                    aria-controls="panel18bh-content"
                                    id="panel18bh-header"
                                >
                                    <Typography sx={{flexShrink: 0}}>Kann ich bei meducately einen eigenen Channel
                                        erstellen?</Typography>
                                </CustomAccordionSummary>
                                <AccordionDetails>
                                    <Typography>Auch das ist möglich. Gerne gruppieren wir deine Videos und legen einen
                                        passenden Channel für dich an. Dieser kann auch über einen eigenen Link extern
                                        mit anderen geteilt werden. Somit ist meducately auch eine passende und
                                        geschützte Plattform, um deine Videos für weiterführende Zwecke zu
                                        hosten. </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                    </Sections>
                </Box>
            </Box>
            {/* @ts-ignore */}
            <Box flex={1}
                 backgroundColor="#fff"
                 display={{
                     md: 'none',
                     lg: 'block',
                 }}/>
        </Box>
    )
}

export default Faq;