import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';

export const Div = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 380,
    overflow: 'hidden',

    [theme.breakpoints.down("sm")]: {
        height: 400
    },
}));

{/* @ts-ignore */}
export const ButtonBoxRight = styled.div(({ theme, headlineCount }) => ({
    position: 'absolute',
    right: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 169,
    width: 20,
    marginTop: 112,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    zIndex: 150,

    [theme.breakpoints.down("sm")]: {
        marginTop: headlineCount > 30 ? 85 : 144,
        height: 160
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

{/* @ts-ignore */}
export const ButtonBoxLeft = styled.div(({ theme, headlineCount }) => ({
    position: 'absolute',
    left: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 169,
    width: 20,
    marginTop: 112,
    marginRight: -19,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 150,

    [theme.breakpoints.down("sm")]: {
        marginTop: headlineCount > 30 ? 85 : 144,
        height: 160,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageRightCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,
    transform: 'rotate(180deg)',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLeftCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLoading = styled.img(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '45%',
    height: 75,
    width: 75,

    [theme.breakpoints.down("sm")]: {
        width: 50,
        height: 50
    },
}));

export const Outline = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 280,
    marginLeft: 45,
    marginRight: 45,
    marginTop: 35,

    '.descriptionRichText p': {
        color: '#676767',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '18px'
    },

    [theme.breakpoints.down("sm")]: {
        height: 300,
        marginLeft: 25,
        marginRight: 30,
        marginTop: 15,
    },
}));

export const TextCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 15,
    flex: 'none',
    order: 0,
    flexGrow: 0,
    marginBottom: -10,

    '.descriptionRichTextIsMobile p': {
        color: '#404040',
        fontSize: '13px',
        lineHeight: '16px',
    },

    [theme.breakpoints.down("sm")]: {
        gap: 2,
        flexDirection: 'column',
        alignItems: 'start',
    },
}));

export const HeadlineText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#2A2A2A',
}));

export const LinkToSeeMore = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#F05B4D',
    flex: 'none',
    order: 0,
    flexGrow: 0,

    [theme.breakpoints.down("sm")]: {
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const LinkTo = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: 4,
    flex: 'none',
    order: 1,
    flexGrow: 0
}));

export const VideoCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    height: 273,
    gap: 15,
    marginTop: 20,

    [theme.breakpoints.down("sm")]: {
        gap: 10,
        width: 300,
        height: 273
    }
}));

export const VideoDiv = styled.div(({ theme }) => ({
    width: 300,
    height: 255,
    transition: 'all 0.3s',

    [theme.breakpoints.down("sm")]: {
        width: 300,
        height: 330,
        maxWidth: '100%'
    },

    ':hover': {
        // width: 350,
        // height: 350,
        transform: useMediaQuery(theme.breakpoints.down("sm")) ? 'scale(1.0)' : 'scale(1.1)',
        // transform: 'scale(1.1)',
        zIndex: 100
    }
}));

export const VideoImage = styled.img(({ theme }) => ({
    width: '100%',
    height: '100%',
    borderRadius: 4,

    [theme.breakpoints.down("sm")]: {
        width: 300,
        height: 160
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const VideoLength = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px 3px',
    gap: 10,

    position: 'absolute',
    height: 12,
    right: 10,
    bottom: 10,

    background: '#2E3436',
    borderRadius: 3
}));

export const VideoLengthNumber = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 10,
    fontWeight: 500,
    color: '#FFFFFF',
    width: 26,
    height: 12,
    textAlign: 'end'
}));

export const VideoDivTitle = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: 15,
    color: '#2E3436',
    maxHeight: 75,
    overflow: 'hidden',

    [theme.breakpoints.down("sm")]: {
    }
}));

export const VideoDivDate = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: 3,
    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 0,
    color: '#676767'
}));

export const VideoDivSource = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 14,
    color: '#676767',

    [theme.breakpoints.down("sm")]: {
    }
}));

export const VideoDivPublicationDate = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: 14,
    color: '#8E9190',

    [theme.breakpoints.down("sm")]: {
    }
}));

export const ButtonBoxElement = styled.div(({ theme }) => ({
    alignSelf: 'center',
    marginLeft :5
}));

export const StepperElement = styled.div(({ theme }) => ({
    position: 'absolute',
    right: '1%',
    top: '26%'
}));

