import api from '../';
import { WebinarData } from './type';
import mockWebinar from '../../utils/mockWebinar';

const WEBINAR_ID = '7e5484c37c';

// @ts-ignore
const getWebinarById = async (id, isMockWebinar?): Promise<[Error, WebinarData]> => {
  let response;
  let error;

  if (isMockWebinar) {
    response = {
      data: mockWebinar
    };
  } else {
    try {
      response = await api.get(`/v1/webinars/${id}?join=speakers&join=speakers.speaker&join=sponsors&join=sponsors.sponsor&join=organizers&join=organizers.sponsor&join=agenda&join=featuredVideo&join=filterOccupations&join=filterOccupations.Occupation&join=filterSpecialties&join=filterSpecialties.specialty`);
    } catch (err) {
      // @ts-ignore
      error = err.message;
    }
  }

  return [error, response?.data];
};

// @ts-ignore
const getWebinarTitleById = async (id): Promise<[Error, WebinarData]> => {
  let response;
  let error;
  try {
    response = await api.get(`/v1/webinars/${id}?select=title`);
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }
  return [error, response?.data];
};

// @ts-ignore
const getWebinarByTitle = async (title): Promise<[Error, WebinarData]> => {
  let response;
  let error;
  try {
    response = await api.get(`/v1/webinars?filter=title||$contL||${title}`);
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }
  return [error, response?.data[0]];
};

// @ts-ignore
const subscribeToWebinar = async (webinarId, utmSource = null, attendance = 'virtual') => {
  let response;
  let error;
  try {
    if (utmSource) {
      response = await api.get(`/v1/webinars/${webinarId}/subscribe?utm_source=${utmSource}&attendance=${attendance}`);
    } else {
      response = await api.get(`/v1/webinars/${webinarId}/subscribe?attendance=${attendance}`);
    }
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }
  return [error, response?.data];
};

// @ts-ignore
const subscribeToExternalWebinar = async (audienceId, attendance, subscriptionToken) => {
  let response;
  let error;
  try {
    let url = `/v1/webinar/subscribe?audienceId=${audienceId}&attendance=${attendance}`;

    if (subscriptionToken) {
      url += `&subscriptionToken=${subscriptionToken}`;
    }

    response = await api.get(url);
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }
  return [error, response?.data];
};

const getAllWebinars = async (filter: any) => {
  let response;
  let error;
  try {
    response = await api.get(`/v1/webinars${filter}&join=agenda`);
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }
  return [error, response?.data];
};

const unsubscribeToWebinar = async (webinarId: any) => {
  let response;
  let error;
  try {
    response = await api.get(`/v1/webinars/${webinarId}/unsubscribe`);
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }
  return [error, response?.data];
};

// @ts-ignorer
const subscribeToWebinarWithoutToken = async () => {
  let response;
  let error;
  try {
    // response = await api.post(`/v1/users?skipMail=${SKIP_MAIL}`, payload);
    response = await api.get(`/v1/webinar/subscribe?audienceId=${WEBINAR_ID}`);
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }
  return [error, response?.data];
};

const searchWebinars = async (query: any, filter = null) => {
  let response;
  const [textQuery, idQuery] = extractSearchQuery(query)


  if (filter) {
    response = await api.get(`/v1/webinars?limit=50${textQuery}${idQuery}&filter=${filter}&join=agenda&join=featuredVideo&sort=startDate,DESC`)
  } else {
    response = await api.get(`/v1/webinars?limit=50${textQuery}${idQuery}&join=agenda&join=featuredVideo&sort=startDate,DESC`)
  }

  return response?.data
}

const extractSearchQuery = (query: any) => {
  let textQuery = ''
  let idQuery = ''
  if (query?.length > 0) {
    textQuery = `&filter=title||$contL||${query}`
    if (!isNaN(query)) {
      idQuery = `&or=id||$eq||${query}`
    }
  }

  return [textQuery, idQuery]
}

export default {
  getWebinarById,
  getWebinarTitleById,
  getWebinarByTitle,
  getAllWebinars,
  subscribeToWebinar,
  subscribeToExternalWebinar,
  unsubscribeToWebinar,
  subscribeToWebinarWithoutToken,
  searchWebinars
};
