import styled from 'styled-components';

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 25,
  height: 25,
  margin: 0,
  padding: 0,
  border: '0.5px solid #434849',
  backgroundColor: '#F7F7F7',
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  '&:before': {
    display: 'block',
    width: 23,
    height: 23,
    margin: 0,
    padding: 0,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 7.73322L7.3 13.5332L16 0.966553' stroke='%23434849' stroke-width='1.93333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    content: '""',
  },
});
