import React from 'react';
import {
    Div,
    ButtonBoxRight,
    ButtonBoxLeft,
    ButtonBoxElement,
    Outline,
    Container,
    FeaturedContent,
    WebinarDiv,
    WebinarDivTitle,
    WebinarDivDate,
    IconAndText,
    ContactButton,
    Image,
    ImageRightCaret,
    ImageLeftCaret,
    IconText,
    ImageVideoIcon,
    ContactButtonText,
    StepperElement,
    Subscribed,
    ImageCheck
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import SubscribeModal from '../../webinar-landing/subscribe-modal';
import { format } from '../../../utils/time';
import DFPIcon from '../../icons/dfp-icon';
import Stepper from "../../common/stepper";
import WebinarAPI from '../../../api/webinar';

// @ts-ignore
const HeroFeatured = ({ secondaryData, backgroundColor, featuredColor }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const sizeOfScreen550 = useMediaQuery('(min-width:550px)');
    const sizeOfScreen900 = useMediaQuery('(min-width:900px)');
    const sizeOfScreen1250 = useMediaQuery('(min-width:1250px)');
    const sizeOfScreen1600 = useMediaQuery('(min-width:1600px)');
    const sizeOfScreen1950 = useMediaQuery('(min-width:1950px)');
    const sizeOfScreen2300 = useMediaQuery('(min-width:2300px)');
    const sizeOfScreen2650 = useMediaQuery('(min-width:2650px)');
    const sizeOfScreen3000 = useMediaQuery('(min-width:3000px)');

    const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
    const [webinarData, setWebinarData] = React.useState({});
    const [start, setStart] = React.useState(0)
    const [last, setLast] = React.useState(5)
    const [step, setStep] = React.useState(1);
    const [showContactButton, setShowContactButton] = React.useState(false);
    const [keyPlayButton, setKeyPlayButton] = React.useState(0);
    const [countStep, setCountStep] = React.useState(0);
    const [touchStart, setTouchStart] = React.useState(null)
    const [touchEnd, setTouchEnd] = React.useState(null)

    const openSubscribeModal = React.useCallback((webinar: any) => {
        WebinarAPI.getWebinarById(webinar.id).then(response => {
            setWebinarData(response[1])
            setShowSubscribeModal(true);
        })
    }, [showSubscribeModal]);

    const closeSubscribeModal = React.useCallback(() => {
        setShowSubscribeModal(false);
        navigate(0)
    }, [showSubscribeModal]);

    React.useEffect(() => {
        if (sizeOfScreen3000) {
            setLast(9)
            setCountStep(8)
        } else if (sizeOfScreen2650) {
            setLast(8)
            setCountStep(7)
        } else if (sizeOfScreen2300) {
            setLast(7)
            setCountStep(6)
        } else if (sizeOfScreen1950) {
            setLast(6)
            setCountStep(5)
        } else if (sizeOfScreen1600) {
            setLast(5)
            setCountStep(4)
        } else if (sizeOfScreen1250) {
            setLast(4)
            setCountStep(3)
        } else if (sizeOfScreen900) {
            setLast(3)
            setCountStep(2)
        } else if (sizeOfScreen550) {
            setLast(2)
            setCountStep(1)
        } else if (!sizeOfScreen550 && !isMobile) {
            setLast(1)
            setCountStep(1)
        } else if (isMobile) {
            setLast(secondaryData?.length)
        }
    }, [sizeOfScreen3000, sizeOfScreen2650, sizeOfScreen2300, sizeOfScreen1950, sizeOfScreen1600, sizeOfScreen1250, sizeOfScreen900, sizeOfScreen550, isMobile, secondaryData])

    const calculateDFPPoints = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.dfpPoints))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    const calculateVortrage = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.slots.length))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    const setMoreWebinars = () => {
        setStart(start + countStep)
        setLast(last + countStep)
        setStep( step + 1)
    }

    const setLessWebinars = () => {
        setStart(start - countStep)
        setLast(last - countStep)
        setStep( step - 1)
    }

    const hoverChangeColorContactButton = (shouldShow: any, key: any) => {
        setShowContactButton(shouldShow)
        setKeyPlayButton(key)
    }

    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            if (start !== last - 1) {
                setStart(start + 1)
            }
        }
        if (isRightSwipe) {
            if (start !== 0) {
                setStart(start - 1)
            }
        }
    }

    return (
        <div>
            {/* @ts-ignore */}
            <Div backgroundColor={backgroundColor}>
                {start > 0 && !isMobile &&
                  <ButtonBoxLeft onClick={() => setLessWebinars()}>
                    <ButtonBoxElement>
                      <ImageLeftCaret src={'chevron.png'} />
                    </ButtonBoxElement>
                  </ButtonBoxLeft>
                }
                {(!isMobile && (secondaryData?.length - (last - countStep) > 0)) &&
                  <StepperElement>
                    <Stepper isVideoPlayPage={false} steps={Math.floor(secondaryData?.length/countStep) > 5
                        ? 5
                        : (Math.floor(secondaryData?.length/countStep) >= 1
                            ? Math.floor(secondaryData?.length/countStep) + 1
                            : 0)} step={step}/>
                  </StepperElement>
                }
                <SubscribeModal
                    open={showSubscribeModal}
                    handleClose={closeSubscribeModal}
                    webinarData={webinarData}
                />
                <Outline>
                    {/* @ts-ignore */}
                    <FeaturedContent featuredColor={featuredColor}>Featured</FeaturedContent>
                    <Container onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                        {secondaryData.slice(start,last).map((secondary: any, index: number) => (
                            <WebinarDiv key={index}
                                        onMouseEnter={() => hoverChangeColorContactButton(true, index)}
                                        onMouseLeave={() => hoverChangeColorContactButton(false, index)}>
                                <Image style={{ height: secondary?.title.length < 34
                                    ? 250
                                    : (secondary?.title.length >= 34 && secondary?.title.length < 63) ? 220 : 190}}
                                       src={secondary?.coverURL}
                                       onClick={() => navigate('/webinar/' + secondary.id + '/' + secondary.title.replaceAll(' ', '').replaceAll('/', ''))}/>
                                <WebinarDivTitle style={{ top: secondary?.title.length < 34
                                        ? 255
                                        : (secondary?.title.length >= 34 && secondary?.title.length < 63) ? 230 : 205}}
                                    onClick={() => navigate('/webinar/' + secondary.id + '/' + secondary.title.replaceAll(' ', '').replaceAll('/', ''))}>
                                    {secondary?.title}
                                </WebinarDivTitle>
                                {(format(secondary?.startDate, 'dd. MMM yyyy.') === format(secondary?.endDate, 'dd. MMM yyyy.'))
                                    ? <WebinarDivDate>{format(secondary?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                    : (format(secondary?.startDate, 'yyyy.') !== format(secondary?.endDate, 'yyyy.'))
                                        ? <WebinarDivDate>{format(secondary?.startDate, 'dd. MMM yyyy.')} - {format(secondary?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                        : (format(secondary?.startDate, 'MMM yyyy.') !== format(secondary?.endDate, 'MMM yyyy.'))
                                            ? <WebinarDivDate>{format(secondary?.startDate, 'dd. MMM')} - {format(secondary?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                            : <WebinarDivDate>{format(secondary?.startDate, 'dd.')} - {format(secondary?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>}
                                <IconAndText>
                                    <DFPIcon style={{ width: 23, marginTop: 5 }} />
                                    <IconText>{secondary?.agenda !== null ? calculateDFPPoints(secondary?.agenda) : 0} DFP Points</IconText>
                                    <ImageVideoIcon src={'streamline-icon-video-player-movie.png'}/>
                                    <IconText>{secondary?.agenda !== null ? calculateVortrage(secondary?.agenda) : 0} Vorträge</IconText>
                                </IconAndText>
                                {secondary?.isSubscribed
                                    ? <Subscribed>
                                        Angemeldet
                                        <ImageCheck src={'/back-button.png'}/>
                                    </Subscribed>
                                    : <ContactButton style={{ backgroundColor: showContactButton && keyPlayButton === index ? '#063F58' : '#FFFFFF' }}
                                                     onClick={() => openSubscribeModal(secondary)}>
                                        <ContactButtonText style={{ color: showContactButton && keyPlayButton === index ? '#FFFFFF' : '#063F58' }}>
                                            Anmelden
                                        </ContactButtonText>
                                    </ContactButton>
                                }
                            </WebinarDiv>
                        ))}
                    </Container>
                </Outline>
                {secondaryData?.length > (last - 1) && !isMobile &&
                  <ButtonBoxRight onClick={() => setMoreWebinars()}>
                    <ButtonBoxElement>
                      <ImageRightCaret src={'chevron.png'} />
                    </ButtonBoxElement>
                  </ButtonBoxRight>
                }
            </Div>
        </div>

    )
}

export default HeroFeatured;