import React from 'react';
import { StyledButton } from './style';
import { ButtonProps } from '@mui/material/Button';

interface IProps extends ButtonProps {
  children: string
}

// @ts-ignore
const Button = ({ children, ...rest }: IProps) => {
  return <StyledButton {...rest} disableRipple>{children}</StyledButton>
};

export default Button;
