import styled from 'styled-components';

export const Form = styled.form(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1
}));

export const Hr = styled.div(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.textColors.label}`,
  width: 'calc(100% + 44px)',
  marginLeft: -22,
}));

// @ts-ignore
export const OnboardingContainer = styled.div(({theme, hideOnboardingWidget}) => ({
  display: 'flex',
  flexDirection: 'row',

  ['.MuiPaper-root:first-of-type']: {
    [theme.breakpoints.up("sm")]: {
      borderRadius: hideOnboardingWidget ? '18.24px' : '18.24px 0 0 18.24px',
      height: '490px',
    },

    [theme.breakpoints.down("lg")]: {
      borderRadius: '18.24px',
      height: 'auto'
    },
  },

  '.MuiPaper-root>h2': {
    fontWeight: 600,
    textAlign: 'left',
    fontSize: '20px',
    lineHeight: '24px',
    paddingBottom: '10px',
    color: '#0E0E0E'
  },

  '.MuiListItem-root': {
    color: '#2A2A2A',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px'
  },
}));
