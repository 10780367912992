import axios from 'axios';
import TokenService from '../utils/tokenService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_MEDUCATION_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getToken();
    if (token) {
      // @ts-ignore
      config.headers['Authorization'] = `Bearer ${token}`; // 'Bearer ' + JWT
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/admin/login'  && originalConfig.url !== '/auth/login' && originalConfig.url !== '/auth/refresh' && err.response) {
      // Access Token has expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post('/auth/refresh', {
            refreshToken: TokenService.getRefreshToken(),
          });

          const { token } = rs?.data?.auth;
          TokenService.setToken(token);

          return instance(originalConfig);
        }
        catch (_error) {
          window.location.href = '/'
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
)

export default instance;
