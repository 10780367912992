import styled from 'styled-components';

export const DFPSuccessContainer = styled.div(({theme}) => ({
    color: '#F05B4D',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    marginTop: '10px',
    textAlign: 'center',
    padding: '10px 16px'
}));