import {
  Div,
  TicketBackground,
  TicketCanvas,
  Img,
  Title,
  Subtitle,
  Hr,
  WebinarInfo,
  FirstRow,
  SecondRow,
  DashedHr,
  ConfirmationText,
  Text,
  BoldTextBottom,
} from './style';
import TextWithLabel from '../../common/text-with-label';
import { deTranslations } from '../../../translations';
import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// @ts-ignore
const ConfirmationTicket = ({ firstName, lastName, title, email, formattedDate, formattedTime, webinarTitle, webinarSubtitle, isSubscribed, coverURL, isExternalEvent = false }) => {
  const fullName = `${title} ${firstName} ${lastName}`;

  return (
        <Div>
          <TicketBackground>
            <TicketCanvas>
              <Img src={coverURL} alt=""/>
              <Title>{webinarTitle}</Title>
              <Subtitle>{webinarSubtitle}</Subtitle>
              <Hr />
              <WebinarInfo>
                <FirstRow>
                  <TextWithLabel label={deTranslations.first_name}>{fullName}</TextWithLabel>
                </FirstRow>
                <SecondRow>
                  <TextWithLabel label={deTranslations.date}>{formattedDate}</TextWithLabel>
                  <TextWithLabel label={deTranslations.time}>{formattedTime}</TextWithLabel>
                </SecondRow>
              </WebinarInfo>
              <DashedHr/>
              {!isSubscribed
                  ?
                  <div style={{ marginTop: 20, textAlign: 'center', width: 210}}>
                    <ConfirmationText>
                      <Text>{deTranslations.we_ve_sent_confirmation_mail}: <b>{email}.</b></Text>
                    </ConfirmationText>
                    <BoldTextBottom><b>{isExternalEvent ? deTranslations.see_you_at_the_event : deTranslations.see_you_at_the_summit}</b></BoldTextBottom>
                  </div>
                  : <BoldTextBottom><b>we are sorry to not see you at Webinar!</b></BoldTextBottom>
              }
            </TicketCanvas>
          </TicketBackground>
        </Div>
  )
};

export default ConfirmationTicket;
