import styled from 'styled-components';

export const AbsoluteContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  right: 'calc((100vw - 980px)/2 + 1px)',

  [theme.breakpoints.down("sm")]: {
    position: 'absolute',
    left: 'calc((100vw - 340px)/2)',
    width: 340,
    marginTop: 8,
  },
}));

{/* @ts-ignore */}
export const Sticky = styled.div(({ theme, cardMove, cardTop }) => ({
  position: 'sticky',
  top: (cardTop ? 200 : 235) - cardMove,
  marginBottom: 75,

  [theme.breakpoints.down("sm")]: {
    position: 'static',
    left: 'calc((100vw - 340px)/2)',
  },
}));

export const Card = styled.div(({ theme }) => ({
  zIndex: 3,
  display: 'flex',
  flexDirection: 'column',
  width: 340,
  alignItems: 'center',
  backgroundColor: theme.palette.white,
  boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.15), 0px 0.912px 0.912px rgba(0, 0, 0, 0.06)',
  borderRadius: '6px 6px 5px 5px',
}));

export const WebinarContents = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '11px 22px 23px 22px',
}));

export const WebinarTitle = styled.h3(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '36px',
  fontFamily: ['Average', 'normal'].join(','),
  fontWeight: 400,
  color: '#2E3436',
  margin: 0,
}));

export const WebinarDescription = styled.p(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#676767',
  margin: 0,
  marginTop: 2,
}));

export const DatesAndTimes = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 14,
  gap: 3,
}));

export const Dates = styled.p(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '18px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#0E0E0E',
  margin: 0,
}));

export const Dot = styled.p(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: theme.palette.textColors.body,
  margin: 0,
}));

export const Times = styled.p(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.2,
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: theme.palette.textColors.body,
  margin: 0,
}));

export const SectionHeader = styled.h4(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '15px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#404040',
  margin: 0,
  marginTop: 26,
}));

export const IconAndText = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 12,
  gap: 12,
}));

export const BoldText = styled.p(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 600,
  color: '#2A2A2A',
  margin: 0,
}));

export const RegularText = styled.p(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontFamily: ['Inter', 'normal'].join(','),
  fontWeight: 400,
  color: '#0E0E0E',
  margin: 0,
}));

export const Subscribed = styled.div(({ theme }) => ({
  marginTop: 20,
  color: '#608856',
  fontFamily: ['Inter', 'normal'].join(','),
  fontSize: 18,
  fontWeight: 700,
  width: '100%',
  textAlign: 'center'
}));

export const ImageCheck = styled.img(({ theme }) => ({
  width: 12,
  height: 12,
  marginLeft: 5
}));
