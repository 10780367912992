import React  from 'react';
import Landing from '../components/landing';
import Footer from '../components/layout/footer';

function LandingPage () {
  return (
    <>
      <Landing/>
      <Footer />
    </>
  );
}

export default LandingPage;
