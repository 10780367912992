import React from 'react';
import {
    Div,
    Container,
    Content,
} from './style';
import SectionHeader from '../common/section-header';
import DescriptionText from '../common/description-text';

// @ts-ignore
const About = ({description}) => {
    return (
        <Div>
            <Container>
                <Content>
                    <SectionHeader style={{
                        color: '#0E0E0E',
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '24px',
                        marginBottom: 6
                    }}>Über das Webinar</SectionHeader>
                    <DescriptionText style={{
                      color: 'red'
                    }}>{description}</DescriptionText>
                </Content>
            </Container>
        </Div>
    )
}

export default About;