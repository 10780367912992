import React from 'react';
import {
    Div,
    Container,
    Content,
} from './style';
import SectionHeader from '../common/section-header';
import AgendaDay from './agenda-day';

// @ts-ignore
const Agenda = ({agendaEntries, speakerMap}) => {
    return (
        <Div>
            <Container>
                <Content>
                    <SectionHeader style={{
                        color: '#0E0E0E',
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '24px'
                    }}>Agenda</SectionHeader>
                    {agendaEntries?.map((entry: { date: any; title: any; dfpPoints: any; slots: any; }, index: number) => {
                        return <AgendaDay
                            key={index}
                            agenda={{
                                date: entry.date,
                                dayOfWeek: entry.title,
                                dfp: entry.dfpPoints,
                                // TODO: this is hardcoded right now, maybe it shouldn't be
                                type: 'In Approbation',
                                talks: entry.slots,
                                speakerMap,
                            }}
                        />
                    })}
                </Content>
            </Container>
        </Div>
    )
}

export default Agenda;