import styled from "styled-components";
import Card from "@mui/material/Card";

export const OnboardingContainer = styled.div(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',

    ['.MuiPaper-root:first-of-type']: {
        [theme.breakpoints.up("sm")]: {
            borderRadius: '18.24px 0 0 18.24px'
        },

        [theme.breakpoints.down("lg")]: {
            borderRadius: '18.24px'
        },
    },
}));

export const OnboardingCard = styled(Card)(({theme}) => ({
    '&&': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0 18.24px 18.24px 0',
        boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.05), 0px 0.912px 0.912px rgba(0, 0, 0, 0.02)',
        backgroundColor: '#FCFCFC',
        [theme.breakpoints.down("lg")]: {
            display: 'none'
        },
        [theme.breakpoints.up("sm")]: {
            width: 380,
            height: 490
        },
        // alignItems: 'center',
        position: 'relative'
    }
}));

export const OnboardingCardHeader = styled.div(({theme}) => ({
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#1D1D1D',
    opacity: 0.9,
    paddingTop: 28,
    paddingLeft: 68
}));

export const OnboardingCardContentBold = styled.span(({theme}) => ({
    fontFamily: 'Inter',
    fontWeight: 800
}));


export const OnboardingCardContent = styled.div(({theme}) => ({
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1D1D1D',
    opacity: 0.8,
    paddingLeft: '44px',
    margin: '88px 0',
}));

export const OnboardingCardFooter = styled.div(({theme}) => ({
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#1D1D1D',
    opacity: 0.7,
    paddingLeft: 67,
    paddingRight: 40,

}));

export const LineItem = styled.div(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    ['img']: {
        marginRight: '9px'
    }
}));
