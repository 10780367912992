import api from '../';

const getAllSpecialtyNamesAndIds = async () => {
  let response;
  let error;
  try {
    response = await api.get('/v1/specialties?select=id,name');
  } catch (err) {
    // @ts-ignore
    error = err.message;
  }

  const mappedResponse = response?.data.map((datum: any) => ({
    id: datum.id,
    value: datum.name,
  }));
  // @ts-ignore
  return [error, mappedResponse]
}

export default {
  getAllSpecialtyNamesAndIds,
};
