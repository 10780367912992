import { StyledModal, StyledDiv } from './style';
import React, { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import Login from '../../login/initial';
import Signup from '../initial';

// @ts-ignore
const SignupModal = ({ open, handleClose, doneFunction, onAuthFinished, videoData }: { open: boolean, handleClose: any, doneFunction?: any, onAuthFinished?: any, videoData?: any }) => {
  const { userProfile } = useContext(AuthContext);
  const [isSignup, setIsSignup] = React.useState(true);

  React.useEffect(() => {
    setIsSignup(true);
  }, [open])

  return <StyledModal
    open={open}
    disableAutoFocus={true}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledDiv>
      {isSignup ?
          <Signup videoData={videoData} onLoginClick={() => setIsSignup(false)} doneFunction={doneFunction} onAuthFinished={onAuthFinished} />
          :
          <Login videoData={videoData} onSignupClick={() => setIsSignup(true)} handleClose={handleClose} doneFunction={doneFunction} onAuthFinished={onAuthFinished} />
      }
    </StyledDiv>
  </StyledModal>
}

export default SignupModal;
