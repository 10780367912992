import { DivCard, StyledCard, Title, ImageSignUp } from './style';
import { CardProps } from '@mui/material/Card';

interface IProps extends CardProps {
  sx?: object
  children: any
  title?: string
}

// @ts-ignore
const Card = ({ children, title, sx }: IProps) => {
  return (
          <StyledCard sx={sx}>
              <Title>{title}</Title>
              {children}
          </StyledCard>
  )
};

export default Card;
