import { StyledModal, StyledDiv } from './style';
import React, { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import Login from '../../login/initial';
import SignupProxy from '../initial-proxy';

// @ts-ignore
const SignupModalProxy = ({ open, handleClose, doneFunction, videoData, proxyUserHash }: { open: boolean, handleClose: any, doneFunction?: any, videoData?: any, proxyUserHash: string }) => {
  const [isSignup, setIsSignup] = React.useState(true);

  React.useEffect(() => {
    setIsSignup(true);
  }, [open])

  return <StyledModal
    open={open}
    disableAutoFocus={true}
    onClose={handleClose}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal"
  >
    <StyledDiv>
      {isSignup ?
          <SignupProxy proxyUserHash={proxyUserHash} onLoginClick={() => setIsSignup(false)}/>
          :
          <Login videoData={videoData} onSignupClick={() => setIsSignup(true)} handleClose={handleClose} doneFunction={doneFunction}/>
      }
    </StyledDiv>
  </StyledModal>
}

export default SignupModalProxy;
