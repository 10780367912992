import { StyledList, StyledListItem, StyledDivider } from './style';

interface Props {
  onPicked: Function
  id: string
  sx?: object
  areas: Option[]
}

interface Option {
  id: number
  value: string
}

// @ts-ignore
const AreaOfPracticeList = ({ onPicked, id, areas, sx }: Props) => {
  return (
    <StyledList
      id={id}
      sx={sx}
    >
      {areas.map((area, index) => (
        <div key={`div${area.id}`}>
          <StyledListItem disablePadding key={area.id} onClick={() => onPicked(area.id)}>{area.value}</StyledListItem>
          {index !== areas.length -1 && <StyledDivider key={`divider${index}`} />}
        </div>
      ))}
    </StyledList>
  )
};

export default AreaOfPracticeList;
