import styled from 'styled-components';

export const Div = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 450,
    maxHeight: 450,
    marginBottom: 30,
    overflow: 'hidden',
}));

{/* @ts-ignore */}
export const ButtonBoxRight = styled.div(({ theme, headlineCount }) => ({
    position: 'absolute',
    right: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 148,
    width: 20,
    marginTop: 95,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,

    [theme.breakpoints.down("sm")]: {
        // marginTop: headlineCount > 42 ? 73 : 62,
        top: headlineCount > 42 ? '6%' : '-6%',
        height: 170,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

{/* @ts-ignore */}
export const ButtonBoxLeft = styled.div(({ theme, headlineCount }) => ({
    position: 'absolute',
    left: '0%',
    display: 'flex',
    borderWidth: 1,
    height: 148,
    width: 20,
    marginTop: 95,
    marginRight: -19,
    boxShadow: '1px 1px 5px grey',
    backgroundColor: '#FFFFFF',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,

    [theme.breakpoints.down("sm")]: {
        // marginTop: headlineCount > 42 ? 73 : 62,
        top: headlineCount > 42 ? '6%' : '-6%',
        height: 170,
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageRightCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,
    transform: 'rotate(180deg)',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLeftCaret = styled.img(({ theme }) => ({
    width: 11,
    height: 14,

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLoading = styled.img(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '45%',
    height: 75,
    width: 75,

    [theme.breakpoints.down("sm")]: {
        width: 50,
        height: 50
    },
}));

export const Outline = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    marginLeft: 45,
    marginRight: 45,
    marginTop: 35,

    [theme.breakpoints.down("sm")]: {
        height: 220,
        marginLeft: 25,
        marginRight: 30,
        marginTop: 15,
    },
}));

export const TextCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',

    [theme.breakpoints.down("sm")]: {
        gap: 5,
    },
}));

export const HeadlineText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#2A2A2A',
}));

export const WebinarCategoriesDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 25,
    marginTop: 28,
    height: 318,

    [theme.breakpoints.down("sm")]: {
        gap: 10,
        width: 300,
        height: 300
    }
}));

export const WebinarDiv = styled.div(({ theme }) => ({
    position: 'relative',
    width: 270,
    height: 330,
    boxShadow: '0px 2.736px 27.36px rgba(0, 0, 0, 0.09), 0px 0.912px 0.912px rgba(0, 0, 0, 0.01)',
    borderRadius: 4,

    [theme.breakpoints.down("sm")]: {
    }
}));

export const Image = styled.img(({ theme }) => ({
    width: 270,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,

    [theme.breakpoints.down("sm")]: {
        height: 147
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const ImageLive = styled.img(({ theme }) => ({
    position: 'absolute',
    width: 27,
    height: 16,
    left: 213,
    top: 10,
    borderRadius: 3,

    [theme.breakpoints.down("sm")]: {
        left: 265,
    },
}));

export const WebinarDivTitle = styled.div(({ theme }) => ({
    // position: 'absolute',
    marginLeft: 10,
    marginRight: 10,
    maxHeight: 57,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#2A2A2A',
    overflow: 'hidden',

    ':hover': {
        cursor: 'pointer',
    }
}));

export const WebinarDivDate = styled.div(({ theme }) => ({
    // position: 'absolute',
    top: 216,
    marginLeft: 10,
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#676767',
    opacity: 0.9
}));

export const ButtonBoxElement = styled.div(({ theme }) => ({
    alignSelf: 'center',
    marginLeft :5
}));

export const StepperElement = styled.div(({ theme }) => ({
    position: 'absolute',
    right: '1%',
    top: '18%'
}));

export const IconAndText = styled.div(({ theme }) => ({
    // position: 'absolute',
    top: 235,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    marginLeft: 10,

    [theme.breakpoints.down("sm")]: {
    }
}));

export const IconText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    color: '#434849',
}));

export const ImageVideoIcon = styled.img(({ theme }) => ({
    width: 17.5,
    height: 17.5
}));

export const ContactButton = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 280,
    width: 233,
    height: 34,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    border: '1px solid #063F58',
    color: '#063F58',
    borderRadius: 4,
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15)) drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15))',
    marginLeft: 18,

    [theme.breakpoints.down("sm")]: {
    },

    ':hover': {
        cursor: 'pointer',
    }
}));

export const Subscribed = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 272,
    color: '#608856',
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: 12,
    paddingTop: 12,
    width: 240,
    marginLeft: 14,
    textAlign: 'center'
}));

export const ContactButtonText = styled.div(({ theme }) => ({
    fontFamily: ['Inter', 'normal'].join(','),
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',

    ':hover': {
        cursor: 'pointer',
    }
}));

