import React from 'react';
import Initial from '../components/login/initial';
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();

  return (
    <>
      <Initial onSignupClick={() => navigate('/signup')}/>
    </>
  )
}

export default Login;
