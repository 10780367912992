import React from 'react';
import {
  Container,
  HeaderRow,
  DayAndTime,
  Points,
  AmountOFDFP,
  PointsType,
  HeaderSeparator,
  Talk,
  TalkTime,
  TalkColumn,
  TalkTitle,
  TalkType,
  TalkSpeaker,
  Dot,
} from './style';
import DFPIcon from '../../../icons/dfp-icon';
import { format } from '../../../../utils/time';

// @ts-ignore
const AgendaDay = ({ agenda }) => {
  return (
    <Container>
      <HeaderRow>
        <DayAndTime>{agenda.dayOfWeek.toUpperCase()}, {format(agenda.date, 'dd.MM.yyyy')}</DayAndTime>
        <Points>
          <DFPIcon style={{ width: 18, marginTop: 3 }} />
          <AmountOFDFP>{agenda.dfp} DFP</AmountOFDFP>
        </Points>
      </HeaderRow>
      <HeaderSeparator />
      {/* @ts-ignore */}
      {agenda.talks.map((talk, index) => {
        const speakers = talk.speakerIds.map((speakerId: number) => agenda.speakerMap.get(speakerId));
        return (
          <Talk key={index}>
            <TalkTime>{format(talk.startTime, 'HH:mm')} - {format(talk.endTime, 'HH:mm')}</TalkTime>
            <TalkColumn>
              <TalkTitle>{talk.title}</TalkTitle>
              {talk.subtitle && <TalkType>{talk.subtitle}</TalkType>}
              {/* @ts-ignore */}
              <TalkSpeaker>{speakers.map(speaker => `${speaker.title} ${speaker.firstName} ${speaker.lastName}`).join(', ')} </TalkSpeaker>
            </TalkColumn>
          </Talk>
        )
      })}
    </Container>
  )
}

export default AgendaDay;