import React from 'react';
import {
  AbsoluteContainer,
  Sticky,
  Card,
  WebinarContents,
  WebinarTitle,
  WebinarDescription,
  DatesAndTimes,
  Dates,
  Dot,
  Times,
  SectionHeader,
  BoldText,
  RegularText,
  IconAndText,
  Subscribed,
  ImageCheck
} from './style';
import { useTheme } from '@mui/material/styles';
import DFPIcon from '../../icons/dfp-icon';
import PrimaryButton from '../../common/buttons/primary-button';
import VideoThumbnail from './video-thumbnail';
import YoutubeEmbed from '../../common/youtube-embed';
import VideoThumbnailComponent from './video-thumbnail';
import useMediaQuery from '@mui/material/useMediaQuery';
import {ImageVideoIcon} from "../../event-landing/hero-featured/style";

// @ts-ignore
const FloatingCard = ({ card, user, subscribeOnClick, openSignupModalOpen }) => {
  // document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [cardMove, setCardMove] = React.useState(0);

  const changeCardMove = () => {
    if (window.scrollY >= 0 && window.scrollY <= 150) {
      setCardMove(window.scrollY)
    }
  }

  window.addEventListener('scroll', changeCardMove)

  return (
    <AbsoluteContainer>
      {/* @ts-ignore */}
      <Sticky cardMove={cardMove} cardTop={card?.title?.length <= 35 && card?.featuredVideo == null}>
        <Card>
          {card?.featuredVideo != null ?
              <YoutubeEmbed src={{
                url: card?.featuredVideo?.videoURL.substring(card?.featuredVideo?.videoURL.indexOf("/") + 17),
                width: 340,
                height: 195,
                title: card?.featuredVideo?.title
              }}
              />
              : <VideoThumbnailComponent card={card} />
          }
          <WebinarContents>
            <WebinarDescription>{card.description}</WebinarDescription>
            <Dates>{card.formattedDate}</Dates>
            {
              (card.hasOnDemandVideo || card.hasDFPApprobation) && (
                  <>
                    <SectionHeader>ÜBER DAS WEBINAR</SectionHeader>
                      {
                          card.hasDFPApprobation && (
                              <IconAndText>
                                  <DFPIcon style={{ width: 22, marginTop: 3 }} />
                                  <BoldText>{card.points} DFP Punkte in Approbation</BoldText>
                              </IconAndText>
                          )
                      }
                      {
                          card.hasOnDemandVideo && (
                              <IconAndText>
                                  <ImageVideoIcon style={{ marginTop: 3 }} src={'/streamline-icon-video-player-movie.png'}/>
                                  <RegularText>{card.type}</RegularText>
                              </IconAndText>
                          )
                      }
                  </>
                )
            }
            <SectionHeader style={{ marginBottom: 6 }}>FORTBILDUNGSANBIETER</SectionHeader>
            <RegularText>{card.specialties}</RegularText>
            <SectionHeader style={{ marginBottom: 6 }}>SPONSOREN</SectionHeader>
            <RegularText>{card?.sponsors?.map((sponsor: { sponsor: { name: string; }; }) => sponsor.sponsor.name).join(', ')}</RegularText>
            <SectionHeader>ORT</SectionHeader>
            <RegularText>{card.locationType === 'virtual' ? 'Virtuell' : card.locationAddress ? card.locationAddress : '-'}</RegularText>
            {(isMobile && card.isSubscribed) ? <Subscribed>Angemeldet<ImageCheck src={'/back-button.png'}/></Subscribed> :
                user === null
                    ? <PrimaryButton style={{ marginTop: 30 }} fullWidth={true} onClick={subscribeOnClick}>Jetzt Anmelden</PrimaryButton>
                      : card.isSubscribed
                        ? <Subscribed>Angemeldet<ImageCheck src={'/back-button.png'}/></Subscribed>
                        : <PrimaryButton style={{ marginTop: 30 }} fullWidth={true} onClick={subscribeOnClick}>Jetzt Anmelden</PrimaryButton>
            }
          </WebinarContents>
        </Card>
      </Sticky>
    </AbsoluteContainer>
  )
}

export default FloatingCard;
