import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// @ts-ignore
import {useTheme} from '@mui/material/styles';
import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    ContainerDiv,
    LandingPageHero,
    HeroContent,
    Title,
    ExtraText,
    Buttons,
    WebinarDescriptionContainer,
    VideosContainer,
    VideosContent,
    Img,
    VideosDescriptionContainer,
    SplitTitle,
    SplitSubtitle,
    Header,
    Description,
    WebinarsContainer,
    WebinarsContent,
    SponsorsContainer,
    Separator,
    SponsorsContent,
    Sponsors,
    SplitTitleForSponsors
} from './style';
import PrimaryButton from '../common/buttons/primary-button';
import SecondaryButton from '../common/buttons/secondary-button';
import LoginModal from '../login/login-modal';
import SignupModal from '../signup/signup-modal';
import CheckCircleIcon from "../icons/check-circle";
import FaqWidget from "../faq";
import SignupModalProxy from '../signup/signup-modal-proxy';
import { getParameterByName } from '../../utils/location';


const Landing = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const sizeOfScreen1200 = useMediaQuery('(min-width:1200px)');
    const [loginModalOpen, setLoginModalOpen] = React.useState(false);
    const [signupModalOpen, setSignupModalOpen] = React.useState(false);
    const [proxySignupModalOpen, setProxySignupModalOpen] = React.useState(false);
    const proxyUserHash = getParameterByName('hash');
    const setSearchParams = useSearchParams()[1];

    useEffect(() => {
        if (proxyUserHash) {
            setProxySignupModalOpen(true);
        }
    }, [proxyUserHash]);

    return (
        <>
            <LoginModal
                open={loginModalOpen}
                handleClose={() => setLoginModalOpen(false)}
                doneFunction={() => navigate(0)}
            />
            <SignupModal
                open={signupModalOpen}
                handleClose={() => setSignupModalOpen(false)}
                doneFunction={() => navigate(0)}
            />
            {
                proxyUserHash && (
                    <SignupModalProxy
                        open={proxySignupModalOpen}
                        handleClose={() => {
                            setProxySignupModalOpen(false);
                            // remove proxy hash from query params to prevent re-opening of the proxy signup modal after navigating back to the page
                            setSearchParams('');
                        }}
                        doneFunction={() => navigate(0)}
                        proxyUserHash={proxyUserHash}
                    />
                )
            }
            <ContainerDiv style={{backgroundColor: 'white'}}>
                {/* @ts-ignore */}
                <Box backgroundColor="#1D2123" overflow="hidden">
                    <LandingPageHero>
                        <HeroContent>
                            <Box mb="5px">
                                <Title>don't stop</Title>
                                <Title>learning</Title>
                            </Box>
                            <ExtraText>Werde jetzt Teil unserer wachsenden Community und lerne jeden Tag etwas Neues.</ExtraText>
                            <Box mb={{
                                xs: '10px',
                                sm: '2px'
                            }}
                                 mt={{
                                     xs: '20px',
                                     sm: '15px'
                                 }}>
                                <Box display="flex" alignItems="center" gap={1.2} mb="16px">
                                    <CheckCircleIcon/>
                                    <ExtraText style={{color: '#fff'}}>Mehr als 4000 Wissenvideos</ExtraText>
                                </Box>
                                <Box display="flex" alignItems="center" gap={1.2} mb="16px">
                                    <CheckCircleIcon/>
                                    <ExtraText style={{color: '#fff'}}>Teilnahme an Webinaren</ExtraText>
                                </Box>
                                <Box display="flex" alignItems="center" gap={1.2} mb="16px">
                                    <CheckCircleIcon/>
                                    <ExtraText style={{color: '#fff'}}>DFP Punkte online sammeln</ExtraText>
                                </Box>
                            </Box>
                            <Buttons>
                                <PrimaryButton onClick={() => setSignupModalOpen(true)}>Werde jetzt
                                    Mitglied</PrimaryButton>
                                <SecondaryButton
                                    sx={{'&&': {color: '#fff', borderColor: '#fff', '&:hover': {borderColor: '#fff'}}}}
                                    onClick={() => setLoginModalOpen(true)}>Einloggen</SecondaryButton>
                            </Buttons>
                        </HeroContent>
                    </LandingPageHero>
                </Box>

                {/* @ts-ignore */}
                <Box display="flex" justifyContent="center" alignItems="center" gap={{xs: 6, sm: 11}}
                     flexDirection={{xs: 'column', sm: 'row'}} width={{xs: '90%', md: '80%', lg: '1200px'}}
                     margin="-60px auto 0 auto" p={{xs: '32px 0', sm: '64px 0'}} backgroundColor="#FCFCFC"
                     flexWrap={{xs: 'wrap'}}>
                    <Box width="235px">
                        <Box component="span"
                             fontSize="40px"
                             lineHeight="48px"
                             fontFamily="Inter"
                             fontWeight={800}>
                            4000+
                            {/* @ts-ignore */}
                            <Box component="div" height="10px" width="180px" backgroundColor="#F05B4D"
                                 marginTop="-12px"/>
                        </Box>
                        <Box component="p"
                             margin={0}
                             fontFamily="Inter"
                             fontSize="15px"
                             color="#676767"
                             lineHeight="18px"
                             mt="15px">Unsere Videothek besteht aus mehr als 4000 Videos, die aus unterschiedlichen
                            Quellen zusammengestellt wurden.</Box>
                    </Box>

                    <Box width="235px">
                        <Box component="span"
                             fontSize="40px"
                             lineHeight="48px"
                             fontFamily="Inter"
                             fontWeight={800}>
                            80+
                            {/* @ts-ignore */}
                            <Box component="div" height="10px" width="180px" backgroundColor="#F05B4D"
                                 marginTop="-12px"/>
                        </Box>
                        <Box component="p"
                             margin={0}
                             fontFamily="Inter"
                             fontSize="15px"
                             color="#676767"
                             lineHeight="18px"
                             mt="15px">DFP Punkte kannst du direkt über uns sammeln. Oder man meldet sich direkt und einfach für kommende Webinare an.</Box>
                    </Box>

                    <Box width="235px">
                        <Box component="span"
                             fontSize="40px"
                             lineHeight="48px"
                             fontFamily="Inter"
                             fontWeight={800}>
                            200+
                            {/* @ts-ignore */}
                            <Box component="div" height="10px" width="180px" backgroundColor="#F05B4D"
                                 marginTop="-12px"/>
                        </Box>
                        <Box component="p"
                             margin={0}
                             fontFamily="Inter"
                             fontSize="15px"
                             color="#676767"
                             lineHeight="18px"
                             mt="15px">hand-selektierte Kanäle, die themenrelevant genau auf dich und deine Fachrichtung zugeschnitten sind.</Box>
                    </Box>
                </Box>

                <Separator/>

                <WebinarsContainer>
                    <WebinarsContent>
                        <WebinarDescriptionContainer>
                            <SplitSubtitle>DEINE neue lernquelle</SplitSubtitle>
                            <SplitTitle>WISSEN VIDEOS</SplitTitle>
                            <Header>Ein Feed der auf dich zugeschnitten ist</Header>
                            <Description>Alle Videos sind hand-selektiert und an deine Fachrichtung angepasst.</Description>
                            <Header>Kanäle aus deinem Fachgebiet</Header>
                            <Description>Wir haben bestimmte Themenbereiche für ausgewählte Indikationen erstellt.</Description>
                        </WebinarDescriptionContainer>
                        <Img src='/landing-page-videos-new.png'
                             style={{width: isMobile ? 480 : 650}} alt=''/>
                    </WebinarsContent>
                </WebinarsContainer>

                <Separator/>

                <VideosContainer>
                    <VideosContent>
                        <Img src='/landing-page-webinars-new.png' style={{
                            width: isMobile ? 435 : 520,
                            marginLeft: !sizeOfScreen1200 ? '' : '-50px',
                            marginRight: !sizeOfScreen1200 ? '' : '200px',
                            marginTop: !sizeOfScreen1200 ? 0 : '65px',
                        }} alt=''/>
                        <VideosDescriptionContainer>
                            <SplitSubtitle>ZEIG WAS du KANNST</SplitSubtitle>
                            <SplitTitle>LIVE EVENTS & WEBINARE</SplitTitle>
                            <Header>Sei live dabei bei spannenden Events und Webinaren</Header>
                            <Description>Melde dich direkt über meducately für zukünftige Webinare an.</Description>
                            <Header>Sichere dir DFP Punkte</Header>
                            <Description>Du kannst direkt über meducately DFP Punkte sammeln.</Description>
                        </VideosDescriptionContainer>
                    </VideosContent>
                </VideosContainer>

                <Separator/>

                <FaqWidget/>

                {!isMobile && <Separator/>}

                <SponsorsContainer>
                    <SponsorsContent>
                        <SplitSubtitle>EINIGE UNSERER QUELLEN</SplitSubtitle>
                        <SplitTitleForSponsors>Lerne von den Besten</SplitTitleForSponsors>
                        <Sponsors>
                            {isMobile
                                ? <Img src='/logos-mobile-new.png'/>
                                : <Img src='/logos-desktop-new.png'/>
                            }
                        </Sponsors>
                    </SponsorsContent>
                </SponsorsContainer>
            </ContainerDiv>
        </>
    )
}

export default Landing;
