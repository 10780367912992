import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { GlobalStyle, theme } from './styles';
import { ThemeProvider } from 'styled-components';
import Signup from './pages/Signup';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import LandingPage from './pages/LandingPage';
import ImpressumPage from './pages/Impressum';
import UberUnsPage from './pages/UberUnsPage';
import DatenschutzPage from './pages/Datenschutz';
import AGBPage from './pages/Agb';
import WebinarLandingPage from './pages/WebinarLandingPage';
import VideoLandingPage from './pages/VideoLandingPage';
import EventLandingPage from './pages/EventLandingPage';
import VideoPlayLandingPage from './pages/VideoPlayLandingPage';
import ViewMoreLandingPage from './pages/ViewMoreLandingPage';
import Navbar from './components/layout/navbar';
import { AuthContextProvider } from './context/authContext';
import ScrollToTop from '../src/utils/ScrollToTop';
import ChannelsLandingPage from "./pages/ChannelsLandingPage";
import DFPVideoLandingPage from "./pages/DFPVideoLandingPage";
import DFPVideoPlayLandingPage from "./pages/DFPVideoPlayLandingPage";
import DFPChannelsLandingPage from "./pages/DFPChannelsLandingPage";
import Faq from './pages/Faq';
import KontaktPage from './pages/Kontakt';
import PartnershipPage from "./pages/Partnership";
import DFPVideoLandingNew from "./components/dfp-new/dfp-video-landing-new";

export default function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
        <Router>
          <ScrollToTop />
          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar />
            <Routes>
              <Route path='/' element={<LandingPage />} />
              <Route path='/webinar/:id/:title' element={<WebinarLandingPage />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/login' element={<Login />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/faq' element={<Faq />} />
              <Route path='/impressum' element={<ImpressumPage />} />
              <Route path='/ueberuns' element={<UberUnsPage />} />
              <Route path='/kontakt' element={<KontaktPage />} />
              <Route path='/datenschutz' element={<DatenschutzPage />} />
              <Route path='/agb' element={<AGBPage />} />
              <Route path='/partnership' element={<PartnershipPage />} />
              <Route path='/videos' element={<VideoLandingPage />} />
              <Route path='/events' element={<EventLandingPage />} />
              <Route path='/video-play/:type/:typeId/:id/:title' element={<VideoPlayLandingPage />} />
              <Route path='/:type/:id/:title' element={<ViewMoreLandingPage />} />
              <Route path='/channels' element={<ChannelsLandingPage />} />
              {/*<Route path='/dfp-videos' element={<DFPVideoLandingPage />} />*/}
              <Route path='/dfp-videos' element={<DFPVideoLandingNew />} />
              <Route path='/dfp-video-play/:type/:typeId/:dfpCourseId/:id/:title' element={<DFPVideoPlayLandingPage />} />
              <Route path='/dfp-channels/:id/:title' element={<DFPChannelsLandingPage />} />
            </Routes>
          </div>
        </Router>
        </AuthContextProvider>
      </ThemeProvider>
    </>
  );
}
