import React, {useContext} from 'react';
import {useLocation, useParams, useNavigate} from 'react-router-dom';
import Hero from './hero';
import ListOfVideos from './list-of-videos';
import VideoAPI from '../../api/video';
import FeedAPI from '../../api/feed';
import {AuthContext} from '../../context/authContext';

const VideoPlayLanding = () => {

    const {user} = useContext(AuthContext);
    const {type, typeId, id} = useParams();
    const [video, setVideo] = React.useState([])
    const [videoLoading, setVideoLoading] = React.useState(false)
    const [listOfVideos, setListOfVideos] = React.useState([])
    const [browserURL, setBrowserURL] = React.useState('')
    const [nextURL, setNextURL] = React.useState('')
    const [nextTitle, setNextTitle] = React.useState('')
    const nextState = {additionalInformation: 'Updated the URL with JS'}

    React.useEffect(() => {
        if (type === 'channels') {
            VideoAPI.getChannelByIdForVideoPlay(typeId).then((response: any) => {
                setListOfVideos(response[1])
            });
            VideoAPI.getVideoById(id).then((response: any) => {
                setVideo(response[1])
                setVideoLoading(true)
                setBrowserURL(window.location.href.split('channels')[0] + 'channels/' + typeId + '/')
                setNextTitle(response[1].id + '/' + response[1].title)
                setNextURL(window.location.href.split('channels')[0] + 'channels/' + typeId + '/' + response[1].id + '/' + response[1].title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll('&nbsp;', ''))
            });
        }

        if (type === 'search') {
            VideoAPI.searchVideo(typeId).then((response: any) => {
                setListOfVideos(response)
            });
            VideoAPI.getVideoById(id).then((response: any) => {
                setVideo(response[1])
                setVideoLoading(true)
                setBrowserURL(window.location.href.split('search')[0] + 'search/' + typeId + '/')
                setNextTitle(response[1].id + '/' + response[1].title)
                setNextURL(window.location.href.split('search')[0] + 'search/' + typeId + '/' + response[1].id + '/' + response[1].title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll(/&nbsp;/g, ''))
            });
        }

        if (type === 'featured') {
            if (user) {
                FeedAPI.getVideoFeed().then((response: any) => {
                    VideoAPI.getAllVideos(response[1][0].filter + '&s=' + JSON.stringify(JSON.parse(response[1][0].meta.query)) + '').then((response: any) => {
                        setListOfVideos(response[1])
                    });
                });
            }
            VideoAPI.getVideoById(id).then((response: any) => {
                setVideo(response[1])
                setVideoLoading(true)
                setBrowserURL(window.location.href.split('featured')[0] + 'featured/' + typeId + '/')
                setNextTitle(response[1].id + '/' + response[1].title)
                setNextURL(window.location.href.split('featured')[0] + 'featured/' + typeId + '/' + response[1].id + '/' + response[1].title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            });
        }

        if (type === 'toContinue') {
            FeedAPI.getVideoFeed().then((response: any) => {
                VideoAPI.getAllVideos(response[1].filter((feed: any) => feed.headline === 'video_list_to_continue_watching')[0].filterMore).then((response: any) => {
                    setListOfVideos(response[1])
                });
            });
            VideoAPI.getVideoById(id).then((response: any) => {
                setVideo(response[1])
                setVideoLoading(true)
                setBrowserURL(window.location.href.split('toContinue')[0] + 'toContinue/' + typeId + '/')
                setNextTitle(response[1].id + '/' + response[1].title)
                setNextURL(window.location.href.split('toContinue')[0] + 'toContinue/' + typeId + '/' + response[1].id + '/' + response[1].title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
            });
        }
    }, [type, typeId, id])

    React.useEffect(() => {
        window.history.pushState(nextState, nextTitle, nextURL);
        window.history.replaceState(nextState, nextTitle, nextURL)
    }, [nextTitle])

    // @ts-ignore
    const title = type === 'toContinue' ? 'Weiter Ansehen' : type === 'featured' ? 'Featured' : type === 'search' ? 'Suche nach' : listOfVideos?.name
    // @ts-ignore
    const description = type === 'featured' ? null : type === 'search' ? '"' + typeId + '"' : listOfVideos?.description
    // @ts-ignore
    const videoList = type === 'search' || type === 'featured' || type === 'toContinue' ? listOfVideos : listOfVideos?.videos?.map((video) => video.video)

    return (
        <>
            {videoLoading &&
              <Hero
                video={video}
              />
            }

            {(listOfVideos.length !== 0) &&
              <ListOfVideos
                videoPlayId={video}
                listOfVideos={videoList}
                description={description}
                title={title}
                browserURL={browserURL}
                setVideo={setVideo}
                setNextURL={setNextURL}
                setNextTitle={setNextTitle}
              />
            }
        </>

    )
}

export default VideoPlayLanding;
