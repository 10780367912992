import React from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import ProfileAPI from '../../../../api/profile';
import {StyledModal, StyledDiv, Form} from './style';
import {deTranslations} from '../../../../translations';
import TextField from '../../../common/fields/text-field';
import Button from '../../../common/buttons/primary-button';
import ErrorModal from '../../../common/error-modal';
import Card from '../../../common/card';
import CaretIcon from '../../../icons/caret-icon';
import DoctorModal from './doctor-modal';
import LocationModal from './location-modal';

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required(`${deTranslations.first_name_first_uppercased} ${deTranslations.must_not_empty}`),
    lastName: Yup.string()
        .required(`${deTranslations.last_name_first_uppercased} ${deTranslations.must_not_empty}`),
    title: Yup.string()
        .min(2, deTranslations.title_short_warning)
        .notRequired(),
});

// @ts-ignore
const ProfileEditorModal = ({open, handleClose, userId}) => {
    const [openModal, setOpenModal] = React.useState(false);
    const [openDoctorModal, setOpenDoctorModal] = React.useState(false);
    const [openLocationModal, setOpenLocationModal] = React.useState(false);
    const [userProfile, setUserProfile] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        id: 0,
        oeak: '',
        title: '',
        placeOfWork: '',
        location: '',
        locationName: '',
        occupation: 0,
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: userProfile,
        validationSchema: validationSchema,
        onSubmit: (async values => {
            const [updateProfileError, updateProfileResponse] = await ProfileAPI.updateProfileForUser(userProfile.id, values);
            if (updateProfileError) {
                setOpenModal(true);
                return;
            }

            handleClose();
        }),
    });

    React.useEffect(() => {
        async function getUserProfile() {
            const [getUserProfileError, userProfileData] = await ProfileAPI.getCurrentUserProfile(userId);
            if (getUserProfileError) {
                setOpenModal(true);
                return;
            }

            setUserProfile(userProfileData);
        }

        getUserProfile();
    }, [])

    return <StyledModal
        open={open}
        disableAutoFocus={true}
        onClose={handleClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal"
    >
        <>
            <ErrorModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                retry={formik.handleSubmit}
                message='There was an error while trying to update your profile'
            />
            <DoctorModal
                open={openDoctorModal}
                handleClose={() => setOpenDoctorModal(false)}
                onPicked={(placeOfWork: any) => {
                    formik.setFieldValue('placeOfWork', placeOfWork);
                    setOpenDoctorModal(false)
                    setOpenLocationModal(true)
                }}
            />
            <LocationModal
                open={openLocationModal}
                handleClose={() => setOpenLocationModal(false)}
                formData={formik.values}
                onPicked={(location: any) => {
                    formik.setFieldValue('location', location.id);
                    formik.setFieldValue('locationName', location.name);
                    setOpenLocationModal(false)
                }}
            />
            <StyledDiv>
                <Card title='Deine Persönlichen Daten' sx={{
                    'h2': {
                        textAlign: 'left',
                        fontSize: '20px',
                        lineHeight: '24px',
                        fontWeight: 600,
                        color: '#2A2A2A',
                    }
                }}>
                    <Form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth={true}
                            id='firstName'
                            name='firstName'
                            label={deTranslations.first_name}
                            placeholderText={deTranslations.enter_first_name}
                            value={formik.values.firstName || ''}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.firstName ? formik.errors.firstName : ''}
                        />
                        <TextField
                            fullWidth={true}
                            id='lastName'
                            name='lastName'
                            label={deTranslations.last_name}
                            placeholderText={deTranslations.enter_last_name}
                            value={formik.values.lastName || ''}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.lastName ? formik.errors.lastName : ''}
                        />
                        <TextField
                            fullWidth={true}
                            id='title'
                            name='title'
                            label={deTranslations.title}
                            placeholderText={deTranslations.enter_title}
                            value={formik.values.title || ''}
                            onChange={formik.handleChange}
                            errorMessage={formik.touched.title ? formik.errors.title : ''}
                        />
                        <TextField
                            fullWidth={true}
                            id='email'
                            name='email'
                            label={deTranslations.email}
                            disabled={true}
                            value={formik.values.email || ''}
                            placeholderText={deTranslations.email}
                            errorMessage={''}
                        />
                        {formik.values.location !== null && <div style={{display: 'flexbox'}}>
                            <TextField
                                fullWidth={true}
                                id='email'
                                name='email'
                                label='Arbeitsort'
                                disabled={true}
                                value={formik.values.locationName || ''}
                                placeholderText='Location not set'
                                errorMessage={''}
                                onClick={() => formik.values.occupation === 1 ? setOpenDoctorModal(true) : setOpenLocationModal(true)}
                                sx={{
                                    '.Mui-disabled': {
                                        textFillColor: '#434849',
                                        webkitTextFillColor: '#434849',
                                        ':hover': {
                                            cursor: 'pointer',
                                        }
                                    }
                                }}
                            />
                            <CaretIcon
                                style={{transform: 'rotate(270deg)', position: 'absolute', marginTop: -37, right: 36}}
                                fill="#063F58"/>
                        </div>}
                        <TextField
                            fullWidth={true}
                            id='oeak'
                            name='oeak'
                            label={deTranslations.oeak}
                            value={formik.values.oeak || ''}
                            placeholderText={deTranslations.oeak}
                            onChange={formik.handleChange}
                            errorMessage={''}
                        />
                        <Button style={{marginTop: 8, marginBottom: 16, fontSize: '17px', lineHeight: '21px', fontWeight: 600}} type='submit'>Änderungen Speichern</Button>
                    </Form>
                </Card>
            </StyledDiv>
        </>
    </StyledModal>
}

export default ProfileEditorModal;
