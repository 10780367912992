import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { StyledTextInput, StyledInputLabel, Div } from './style';
import { InputProps } from '@mui/material/Input';

interface IProps extends InputProps {
  label: string
  placeholderText: string
  errorMessage: string | undefined
}

// @ts-ignore
const TextField = ({label, placeholderText, errorMessage, autoFocus = false, ...rest }: IProps ) => {
  // @ts-ignore
  return (
    <Div>
      <StyledInputLabel htmlFor={rest.id}>
        {label}
      </StyledInputLabel>
      <StyledTextInput autoFocus={autoFocus} placeholder={placeholderText} {...rest}/>
      <FormHelperText
        sx={{
          color: '#F05B4D',
          fontSize: 13,
          fontWeight: 400,
          marginLeft: 1.5
        }}
        error={errorMessage !== ''}
      >
        {errorMessage}
      </FormHelperText>
    </Div>
  )
};

export default TextField;
