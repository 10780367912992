import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

export const StyledList = styled(List)(({ theme }) => ({
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&&::-webkit-scrollbar': {
    display: 'none',
  },
  minHeight: 350,

  /* Hide scrollbar for IE, Edge and Firefox */
  '&&': {
    msOverflowStyle: 'none',  /* IE and Edge */
    scrollbarWidth: 'none',  /* Firefox */
    padding: 0,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&&': {
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: 22,
    paddingTop: 13,
    paddingBottom: 13,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: ['Inter', 'normal'].join(','),
    lineHeight: 1.1,
    color: theme.palette.textColors.title,
  },
  '&&:hover': {
    // fontStyle: 'bold',
  }
}));

export const StyledDivider = styled(Divider)({

});
