import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.ctaError,
    color: theme.palette.white,
    fontSize: 17,
    lineHeight: 1.2,
    borderRadius: 8,
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'bold',
    textTransform: 'none',
    padding: '12px 20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.17)',
    transition: '0.2s',
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: '12px 8px',
    }
  },
  '&&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.ctaError,
    color: theme.palette.white,
  },
  '&&:active': {
    boxShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.14), inset 0px 1px 2px rgba(0, 0, 0, 0.17)',
    transition: '0s',
  }
}));
