import Partnership from "../components/static-pages/partnership";
import Footer from '../components/layout/footer';

const PartnershipPage = () => {
    return (
        <>
            <Partnership/>
            <Footer/>
        </>
    );
};

export default PartnershipPage;