import React from 'react';
import {
    Div,
    Container,
    Content,
} from './style';
import SectionHeader from '../common/section-header';
import Speaker from './speaker';

// @ts-ignore
const Speakers = ({speakers}) => {
    return (
        <Div>
            <Container>
                <SectionHeader style={{
                    color: '#0E0E0E',
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '24px'
                }}>Die Vortragenden</SectionHeader>
                <Content>
                    {speakers.map((speaker: any, index: number) => (<Speaker key={index} speaker={speaker}/>))}
                </Content>
            </Container>
        </Div>
    )
}

export default Speakers;