import React from 'react';
import DFPChannelsLanding from '../components/dfp-channels-landing';
import Footer from '../components/layout/footer';

function DFPChannelsLandingPage() {
    return (
        <>
            <DFPChannelsLanding/>
            <Footer/>
        </>
    );
}

export default DFPChannelsLandingPage;