import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Div,
    ButtonBoxRight,
    ButtonBoxLeft,
    ImageLoading,
    Outline,
    HeadlineText,
    TextCategoriesDiv,
    WebinarCategoriesDiv,
    WebinarDiv,
    WebinarDivTitle,
    WebinarDivDate,
    ButtonBoxElement,
    StepperElement,
    Image,
    IconAndText,
    Subscribed,
    ImageCheck,
    ContactButton,
    ImageRightCaret,
    ImageLeftCaret,
    IconText,
    ImageVideoIcon,
    ContactButtonText
} from './style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DescriptionText from '../../webinar-landing/common/description-text';
import Stepper from '../../common/stepper';
import { format } from '../../../utils/time';
import DFPIcon from '../../icons/dfp-icon';
import VideoIcon from '../../icons/video-icon';
import SubscribeModal from '../../webinar-landing/subscribe-modal';
import WebinarAPI from '../../../api/webinar';
import NoLiveContent from '../no-live-content';
import { deTranslations } from '../../../translations';

// @ts-ignore
const WebinarFeed = ({ filter, headlineValue, onWebinarsLoad }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();
    const [start, setStart] = React.useState(0)
    const [last, setLast] = React.useState(6)
    const [step, setStep] = React.useState(1);
    const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
    const [webinarData, setWebinarData] = React.useState({});
    const [data, setData] = React.useState([]);
    const [showContactButton, setShowContactButton] = React.useState(false);
    const [keyPlayButton, setKeyPlayButton] = React.useState(0);
    const [countStep, setCountStep] = React.useState(0);
    const [touchStart, setTouchStart] = React.useState(null)
    const [touchEnd, setTouchEnd] = React.useState(null)
    const [loadDone, setLoadDone] = React.useState(false)

    React.useEffect(() => {
        // @ts-ignore
        WebinarAPI.getAllWebinars(filter).then( (response: any)=> {
            setData(response[1])
            setLoadDone(true)

            if (headlineValue !== 'webinar_list_user_might_like' && onWebinarsLoad != null) {
                // @ts-ignore
                onWebinarsLoad(response[1])
            }
        });

    }, [])

    React.useEffect(() => {
        if (window.innerWidth >= 3200) {
            setLast(11)
            setCountStep(11)
        } else if (window.innerWidth >= 3000) {
            setLast(10)
            setCountStep(10)
        } else if (window.innerWidth >= 2700) {
            setLast(9)
            setCountStep(9)
        } else if (window.innerWidth >= 2400) {
            setLast(8)
            setCountStep(8)
        } else if (window.innerWidth >= 2133) {
            setLast(7)
            setCountStep(7)
        } else if (window.innerWidth >= 1777) {
            setLast(6)
            setCountStep(6)
        } else if (window.innerWidth >= 1455) {
            setLast(5)
            setCountStep(5)
        } else if (window.innerWidth >= 1200) {
            setLast(4)
            setCountStep(4)
        } else if (window.innerWidth >= 914) {
            setLast(3)
            setCountStep(3)
        } else if (window.innerWidth >= 640) {
            setLast(2)
            setCountStep(2)
        } else if (window.innerWidth < 640 && !isMobile) {
            setLast(1)
            setCountStep(1)
        } else if (isMobile) {
            setLast(data?.length - 1)
        }
    }, [window.innerWidth, isMobile, data])

    let webinars = data?.slice(start, last + 1)

    const calculateDFPPoints = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.dfpPoints))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    const calculateVortrage = (agenda: any) => {
        return agenda !== null ?
            agenda?.entries.map((entry: any) => (parseInt(entry.slots.length))).reduce((prev: any , next: any) => prev + next)
            : 0
    }

    const setMoreWebinars = () => {
        setStart(start + countStep)
        setLast(last + countStep)
        setStep( step + 1)
    }

    const setLessWebinars = () => {
        setStart(start - countStep)
        setLast(last - countStep)
        setStep( step - 1)
    }

    const openSubscribeModal = React.useCallback((webinar: any) => {
        WebinarAPI.getWebinarById(webinar.id).then(response => {
            setWebinarData(response[1])
            setShowSubscribeModal(true);
        })
    }, [showSubscribeModal]);

    const closeSubscribeModal = React.useCallback(() => {
        setShowSubscribeModal(false);
        navigate(0)
    }, [showSubscribeModal]);

    const hoverChangeColorContactButton = (shouldShow: any, key: any) => {
        setShowContactButton(shouldShow)
        setKeyPlayButton(key)
    }

    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            if (start !== last) {
                setStart(start + 1)
            }
        }
        if (isRightSwipe) {
            if (start !== 0) {
                setStart(start - 1)
            }
        }
    }

    return ((headlineValue === 'webinar_list_subscribed' || data?.length !== 0) &&
      // @ts-ignore
      <Div>
          {/* @ts-ignore */}
          {start > 0 && !isMobile && <ButtonBoxLeft onClick={() => setLessWebinars()} headlineCount={headlineValue.length}>
            <ButtonBoxElement>
              <ImageLeftCaret src={'chevron.png'} />
            </ButtonBoxElement>
          </ButtonBoxLeft>
          }
          {(!isMobile && (data?.length - (last - countStep) > 0)) &&
            <StepperElement>
              <Stepper isVideoPlayPage={false} steps={Math.floor(data?.length/countStep) > 5
                  ? 5
                  : (Math.floor(data?.length/countStep) >= 1
                      ? Math.floor(data?.length/countStep) + 1
                      : 0)} step={step}/>
            </StepperElement>
          }
        <SubscribeModal
          open={showSubscribeModal}
          handleClose={closeSubscribeModal}
          webinarData={webinarData}
        />
          {!loadDone
              ? <ImageLoading src={'loading-transparent-gif.gif'}/>
              :
              <Outline>
                  <TextCategoriesDiv>
                      <HeadlineText>{headlineValue === 'webinar_list_subscribed' ? deTranslations.webinar_list_subscribed :
                          headlineValue === 'webinar_list_user_might_like' ? deTranslations.webinar_list_user_might_like : headlineValue}</HeadlineText>
                  </TextCategoriesDiv>
                  {/* @ts-ignore */}
                  <WebinarCategoriesDiv isEmptyContent={webinars?.length === 0} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                      {webinars?.length === 0
                          ? <NoLiveContent webinarChannel={false}/>
                          : webinars?.map((webinar: any, index: number) => {
                              const dfpPoints = webinar?.agenda !== null ? calculateDFPPoints(webinar?.agenda) : 0;
                              const vortrage = webinar?.agenda !== null ? calculateVortrage(webinar?.agenda) : 0;

                              return (
                                  <WebinarDiv key={index}
                                              onMouseEnter={() => hoverChangeColorContactButton(true, index)}
                                              onMouseLeave={() => hoverChangeColorContactButton(false, index)}>
                                      <Image style={{ height: webinar?.title.length < 36
                                              ? 185
                                              : (webinar?.title.length >= 36 && webinar?.title.length < 63) ? 166 : 147}}
                                             src={webinar?.coverURL}
                                             onClick={() => navigate('/webinar/' + webinar.id + '/' + webinar.title.replaceAll(' ', '').replaceAll('/', ''))}/>
                                      <WebinarDivTitle style={{ top: webinar?.title.length < 36
                                              ? 195
                                              : (webinar?.title.length >= 36 && webinar?.title.length < 63) ? 176 : 157}}
                                                       onClick={() => navigate('/webinar/' + webinar.id + '/' + webinar.title.replaceAll(' ', '').replaceAll('/', ''))}>
                                          {webinar?.title}
                                      </WebinarDivTitle>
                                      {(format(webinar?.startDate, 'dd. MMM yyyy.') === format(webinar?.endDate, 'dd. MMM yyyy.'))
                                          ? <WebinarDivDate>{format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                          : (format(webinar?.startDate, 'yyyy.') !== format(webinar?.endDate, 'yyyy.'))
                                              ? <WebinarDivDate>{format(webinar?.startDate, 'dd. MMM yyyy.')} - {format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                              : (format(webinar?.startDate, 'MMM yyyy.') !== format(webinar?.endDate, 'MMM yyyy.'))
                                                  ? <WebinarDivDate>{format(webinar?.startDate, 'dd. MMM')} - {format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                                  : <WebinarDivDate>{format(webinar?.startDate, 'dd.')} - {format(webinar?.endDate, 'dd. MMM yyyy.')}</WebinarDivDate>
                                      }
                                      <IconAndText>
                                          <DFPIcon style={{ width: 17.5, marginTop: 4 }} />
                                          <IconText>{dfpPoints === 1 ? '1 DFP Punkt' : `${dfpPoints} DFP Punkte`}</IconText>
                                          <ImageVideoIcon src={'streamline-icon-video-player-movie.png'}/>
                                          <IconText>{vortrage === 1 ? '1 Vortrag' : `${vortrage} Vorträge`}</IconText>
                                      </IconAndText>
                                      {webinar?.isSubscribed
                                          ? <Subscribed>
                                              Angemeldet
                                              <ImageCheck src={'/back-button.png'}/>
                                          </Subscribed>
                                          : <ContactButton style={{ backgroundColor: showContactButton && keyPlayButton === index ? '#063F58' : '#FFFFFF' }}
                                                           onClick={() => openSubscribeModal(webinar)}>
                                              <ContactButtonText style={{ color: showContactButton && keyPlayButton === index ? '#FFFFFF' : '#063F58' }}>
                                                  Anmelden
                                              </ContactButtonText>
                                          </ContactButton>
                                      }

                                  </WebinarDiv>
                              )
                          })}

                  </WebinarCategoriesDiv>
              </Outline>
          }
          {/* @ts-ignore */}
          {data?.length > last && !isMobile && <ButtonBoxRight onClick={() => setMoreWebinars()} headlineCount={headlineValue.length}>
            <ButtonBoxElement>
              <ImageRightCaret src={'chevron.png'} />
            </ButtonBoxElement>
          </ButtonBoxRight>
          }
      </Div>
    )
}

export default WebinarFeed;
