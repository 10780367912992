import { useNavigate, useLocation } from 'react-router-dom';
import { theme } from '../../../styles';
import {
    Div,
    Logo,
    Title,
    SearchAndLinksDiv,
    LinksContainer,
    LinkWithImage,
    LinkText,
    MyProfilePopover,
    MyProfileLinkWithImage,
    ClickableSvg,
    SearchList,
    SearchListTitle,
    SearchListElement,
    SearchListElementImage,
    SearchListElementAllText,
    SearchListElementTextTitle,
    SearchListElementTextType,
    SearchListElementTextSource,
    SearchListLinkTo,
    SearchListMore,
    NavLinkWithImage,
    SearchListMoreImage
} from './style';
import SearchIcon from '../../icons/search-icon';
import VideoIcon from '../../icons/video-icon';
import EventsIcon from '../../icons/events-icon';
import ProfileIcon from '../../icons/profile-icon';
import CaretIcon from '../../icons/caret-icon';
import LogoutIcon from '../../icons/logout-icon';
import HamburgerIcon from '../../icons/hamburger-icon';
import EditProfileIcon from '../../icons/edit-profile-icon';
import React from 'react';
import Popover from '@mui/material/Popover';
import Fade from '@mui/material/Fade';
import { AuthContext } from '../../../context/authContext';
import LoginModal from '../../login/login-modal';
import Search from './search';
import MobileMenu from './mobile-menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProfileEditorModal from './profile-editor-modal';
import Divider from '@mui/material/Divider';
import PrimaryButton from "../../common/buttons/primary-button";
import SignupModal from "../../signup/signup-modal";
import { VideoLength, VideoLengthNumber } from "../../view-more-landing/style";
import ChannelsIcon from "../../icons/channels-icon";
import DFPIcon from "../../icons/dfp-icon";
import { Box } from '@mui/material';
import ArrowRightIcon from '../../icons/arrow-right-icon';

const Navbar = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('@media (max-width:1024px)');
    const location = useLocation();
    const isLandingPage = location.pathname === '/';
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const {user, userProfile, logout} = React.useContext(AuthContext);
    const [showLoginModal, setShowLoginModal] = React.useState(false);
    const [showProfileEditorModal, setShowProfileEditorModal] = React.useState(false);
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);
    const [searchList, setSearchList] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [showMore, setShowMore] = React.useState(3);
    const [signupModalOpen, setSignupModalOpen] = React.useState(false);
    const [color, setColor] = React.useState(false);
    const [showMobileNavbar, setShowMobileNavbar] = React.useState(false);
    const [isSearchOpen, setIsSearchOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const closeMobileMenu = React.useCallback(() => {
        setShowMobileMenu(false);
    }, [showMobileMenu]);

    const open = Boolean(anchorEl);
    const id = open ? 'profile-popover' : undefined;

    React.useEffect(() => {
        function checkIfLoggedIn() {
            if (user != null) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        }

        checkIfLoggedIn();
    }, [user, setIsLoggedIn])

    const openLoginModal = React.useCallback(() => {
        setShowLoginModal(true);
    }, [showLoginModal]);

    const closeLoginModal = React.useCallback(() => {
        setShowLoginModal(false);
    }, [showLoginModal])

    const loginDone = React.useCallback(() => {
        // refresh when logged in or signed up
        navigate(0);
    }, [])

    const handleClickOutside = () => {
        setSearchList([]);
    };

    const useOutsideClick = (callback: any) => {
        const ref = React.useRef();

        React.useEffect(() => {
            const handleClick = (event: any) => {
                // @ts-ignore
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref]);

        return ref;
    };

    const ref = useOutsideClick(handleClickOutside);

    const navigateToElement = (data: any) => {
        setSearchList([]);
        setSearch('')
        // @ts-ignore
        const bigData = searchList?.filter((videos) => videos?.hasOwnProperty('videoURL'))
        data.hasOwnProperty('featuredVideo')
            ? navigate('/webinar/' + data.id + '/' + data.title.replaceAll(' ', '').replaceAll('/', ''))
            : navigate('/video-play' + '/' + 'search' + '/' + search + '/' + data.id + '/' +
                data.title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll('&nbsp;', ''))
    }

    const navigateToPageViewMore = (data: any, search: any) => {
        navigate('/' + 'search' + '/' + '99999' + '/' + search.replaceAll(' ', '').replaceAll('/', ''),
            {state: {data: data, title: 'Search for "' + search + '"'}})
        setSearchList([]);
        setSearch('')
        setShowMobileMenu(false);
    }

    const goToPage = (page: any) => {
        closeMobileMenu()
        navigate(page)
    }

    const changeColor = () => {
        if (isMobile && window.scrollY >= 10) {
            setColor(true)
        } else if (window.scrollY >= 680) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    const changeMobileNavbar = () => {
        if (window.scrollY >= 350) {
            setShowMobileNavbar(true)
        } else {
            setShowMobileNavbar(false)
        }
    }

    const handleSearchListElementClick = (data: any) => {
        navigateToElement(data);
        setIsSearchOpen(false);
        setSearch('');
    }

    window.addEventListener('scroll', changeColor)
    window.addEventListener('scroll', changeMobileNavbar)

    // @ts-ignore
    return (
        <Div
            style={{
                position: 'sticky', top: 0,
                backgroundColor: (isLandingPage && color) ? '#2A2A2A' : isLandingPage ? 'transparent' : '#2A2A2A'
            }}
            isLandingPage={isLandingPage}
            isMobile={isMobile}
        >
            <LoginModal
                open={showLoginModal}
                handleClose={closeLoginModal}
                doneFunction={loginDone}
            />
            <SignupModal
                open={signupModalOpen}
                handleClose={() => setSignupModalOpen(false)}
                doneFunction={() => navigate(0)}
            />
            {showProfileEditorModal && <ProfileEditorModal
              open={showProfileEditorModal}
              handleClose={() => setShowProfileEditorModal(false)}
              userId={user?.userId}
            />}
            <Logo>
                {(isMobile)
                    ? isLoggedIn
                        ? <Title onClick={() => navigate('/')}>meducately</Title>
                        : <Title onClick={() => navigate('/')}>meducately</Title>
                    : <Title onClick={() => navigate('/')}>meducately</Title>
                }
            </Logo>
            <>
                {!isMobile ? (
                    <>
                        <SearchAndLinksDiv style={{
                            justifyContent: isLandingPage ? 'flex-end' : 'space-between',
                            width: !isLoggedIn ? 0 : 'calc((100vw - 980px) / 2 + 980px)'
                        }}>
                            {!isLandingPage && isLoggedIn &&
                              <>
                                <Search setSearchList={setSearchList} setShowMore={setShowMore} search={search}
                                        setSearch={setSearch}
                                        navigateToPageViewMore={navigateToPageViewMore}/>
                                  {(searchList.length !== 0) &&
                                      // @ts-ignore
                                    <SearchList ref={ref} decreaseHeight={searchList.length}>
                                      <SearchListTitle>Vorschläge</SearchListTitle>
                                      <Divider
                                        sx={{margin: '10px 0', borderColor: theme.palette.textColors.bodyLight}}/>
                                        {searchList.slice(0, 3).map((data: any, index: number) => (
                                            <SearchListElement onClick={() => navigateToElement(data)} key={index}>
                                                {data.hasOwnProperty('featuredVideo')
                                                    ? <SearchListElementImage src={data.coverURL}/>
                                                    : <div style={{position: 'relative'}}>
                                                        <SearchListElementImage src={data.coverURL}/>
                                                        <VideoLength
                                                            style={{width: data?.length / 3600 > 1 ? 33 : data?.length / 60 < 10 ? 24 : 27}}>
                                                            <VideoLengthNumber>
                                                                {(data?.length / 3600 > 1 ? Math.floor(data?.length / 3600) + ':' : null)}
                                                                {((data?.length % 3600) / 60 < 10 ? '0' + Math.floor(data?.length % 3600 / 60) : Math.floor(data?.length % 3600 / 60))}
                                                                :{data?.length % 60 < 10 ? '0' + data?.length % 60 : data?.length % 60}
                                                            </VideoLengthNumber>
                                                        </VideoLength>
                                                    </div>
                                                }
                                                <SearchListElementAllText>
                                                    <SearchListElementTextType>
                                                        {data.hasOwnProperty('featuredVideo') ? 'WEBINAR' : 'VIDEO'}
                                                    </SearchListElementTextType>
                                                    <SearchListElementTextTitle>
                                                        {data.title}
                                                    </SearchListElementTextTitle>
                                                    <SearchListElementTextSource>
                                                        {data.hasOwnProperty('featuredVideo') ? data.featuredVideo?.source : data.source}
                                                    </SearchListElementTextSource>
                                                </SearchListElementAllText>
                                            </SearchListElement>
                                        ))}
                                      <Divider
                                        sx={{margin: '10px 0', borderColor: theme.palette.textColors.bodyLight}}/>
                                        {
                                            <SearchListLinkTo>
                                                {/* @ts-ignore */}
                                                <SearchListMore ref={ref}
                                                                onClick={() => navigateToPageViewMore(searchList, search)}>
                                                    Mehr anzeigen
                                                </SearchListMore>
                                                <ArrowRightIcon/>
                                            </SearchListLinkTo>
                                        }
                                    </SearchList>
                                  }
                              </>
                            }
                            {isLoggedIn && (
                                <LinksContainer>
                                    <NavLinkWithImage to={'/videos'}>
                                        {({isActive}) => (
                                            <LinkText
                                                className={`linkText ${isActive && 'linkText-active'}`}>Videos</LinkText>
                                        )}
                                    </NavLinkWithImage>
                                    <NavLinkWithImage to={'/events'}>
                                        {({isActive}) => (
                                            <LinkText
                                                className={`linkText ${isActive && 'linkText-active'}`}>Events</LinkText>
                                        )}
                                    </NavLinkWithImage>
                                    <NavLinkWithImage to={'/channels'}>
                                        {({isActive}) => (
                                            <LinkText
                                                className={`linkText ${isActive && 'linkText-active'}`}>Channels</LinkText>
                                        )}
                                    </NavLinkWithImage>
                                    <NavLinkWithImage to={'/dfp-videos'}>
                                        {({isActive}) => (
                                            <LinkText className={`linkText ${isActive && 'linkText-active'}`}>DFP Kurse</LinkText>
                                        )}
                                    </NavLinkWithImage>
                                    <LinkWithImage aria-describedby={id} onClick={handleProfileClick}>
                                        <LinkText className='linkText'>Mein Profil</LinkText>
                                        <CaretIcon/>
                                    </LinkWithImage>
                                </LinksContainer>
                            )
                            }
                        </SearchAndLinksDiv>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleProfileClose}
                            anchorOrigin={{
                                vertical: 52,
                                horizontal: 'left',
                            }}
                            TransitionComponent={Fade}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    display: 'flex',
                                    width: 231,
                                    backgroundColor: '#2A2A2A',
                                    overflow: 'visible',
                                    borderRadius: '5px',
                                    ':before': {
                                        content: "''",
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2A2A2A',
                                        position: 'absolute',
                                        right: 20,
                                        top: -5,
                                        transform: 'rotate(45deg)'
                                    }
                                },
                            }}
                        >
                            <MyProfilePopover>
                                <MyProfileLinkWithImage
                                    onClick={() => setShowProfileEditorModal(true)}>
                                    <LinkText className='linkText'>
                                        Profil Einstellungen
                                    </LinkText>
                                </MyProfileLinkWithImage>
                                <MyProfileLinkWithImage
                                    onClick={() => {
                                        logout();
                                        navigate('/');
                                        navigate(0);
                                    }}>
                                    <LinkText className='linkText'>
                                        Ausloggen
                                    </LinkText>
                                </MyProfileLinkWithImage>
                            </MyProfilePopover>
                        </Popover>
                    </>
                ) : (isLoggedIn ?
                    // mobile view
                  <>
                    <Box position="absolute" height="100%" width="100%"
                         display={isMobile && isSearchOpen ? 'block' : 'none'}>
                      <Search setSearchList={setSearchList}
                              setShowMore={setShowMore}
                              search={search}
                              setSearch={setSearch}
                              hideSearch={() => setIsSearchOpen(false)}
                              navigateToPageViewMore={navigateToPageViewMore}/>
                        {(searchList.length !== 0) &&
                            // @ts-ignore
                          <SearchList ref={ref} decreaseHeight={searchList.length}>
                            <SearchListTitle>Vorschläge</SearchListTitle>
                            <Divider sx={{margin: '10px 0', borderColor: theme.palette.textColors.bodyLight}}/>
                              {searchList.slice(0, 3).map((data: any, index: number) => (
                                  <SearchListElement onClick={() => navigateToElement(data)} key={index}>
                                      {data.hasOwnProperty('featuredVideo')
                                          ? <SearchListElementImage src={data.coverURL}/>
                                          : <div style={{position: 'relative'}}>
                                              <SearchListElementImage src={data.coverURL}/>
                                              <VideoLength
                                                  style={{width: data?.length / 3600 > 1 ? 33 : data?.length / 60 < 10 ? 24 : 27}}>
                                                  <VideoLengthNumber>
                                                      {(data?.length / 3600 > 1 ? Math.floor(data?.length / 3600) + ':' : null)}
                                                      {((data?.length % 3600) / 60 < 10 ? '0' + Math.floor(data?.length % 3600 / 60) : Math.floor(data?.length % 3600 / 60))}
                                                      :{data?.length % 60 < 10 ? '0' + data?.length % 60 : data?.length % 60}
                                                  </VideoLengthNumber>
                                              </VideoLength>
                                          </div>
                                      }
                                      <SearchListElementAllText>
                                          <SearchListElementTextType>
                                              {data.hasOwnProperty('featuredVideo') ? 'WEBINAR' : 'VIDEO'}
                                          </SearchListElementTextType>
                                          <SearchListElementTextTitle>
                                              {data.title.slice(0, 40) + '...'}
                                          </SearchListElementTextTitle>
                                          <SearchListElementTextSource>
                                              {data.hasOwnProperty('featuredVideo') ? data.featuredVideo?.source : data.source}
                                          </SearchListElementTextSource>
                                      </SearchListElementAllText>
                                  </SearchListElement>
                              ))}
                            <Divider sx={{margin: '10px 0', borderColor: theme.palette.textColors.bodyLight}}/>
                              {
                                  <SearchListLinkTo>
                                      {/* @ts-ignore */}
                                      <SearchListMore ref={ref}
                                                      onClick={() => navigateToPageViewMore(searchList, search)}>
                                          Mehr anzeigen
                                      </SearchListMore>
                                      <ArrowRightIcon/>
                                  </SearchListLinkTo>
                              }
                          </SearchList>
                        }
                    </Box>
                    <div style={{display: 'flex', gap: 18, marginRight: 25, marginTop: isMobile ? 12 : 0}}>
                      <ClickableSvg onClick={() => setIsSearchOpen(true)}>
                        <SearchIcon/>
                      </ClickableSvg>
                      <ClickableSvg onClick={() => setShowMobileMenu(true)}>
                        <HamburgerIcon/>
                      </ClickableSvg>
                    </div>
                    <MobileMenu
                      open={showMobileMenu}
                      handleClose={closeMobileMenu}
                      isLoggedIn={isLoggedIn}
                      openLoginModal={openLoginModal}
                      openProfileEditorModal={() => setShowProfileEditorModal(true)}
                      searchList={searchList}
                      navigateToElement={navigateToElement}
                      searchListRef={ref}
                      handleSearchListElementClick={handleSearchListElementClick}
                      setSearchList={setSearchList}
                      setShowMore={setShowMore}
                      navigateToPageViewMore={navigateToPageViewMore}
                      goToPage={goToPage}
                      setSignupModalOpen={setSignupModalOpen}
                      setSearch={setSearch}
                      search={search}
                      logout={logout}
                    />
                  </> : (
                      <>
                        <div style={{marginRight: 25, marginTop: isMobile ? 12 : 0}}>
                            <ClickableSvg onClick={() => setShowMobileMenu(true)}>
                                <HamburgerIcon/>
                            </ClickableSvg>
                        </div>
                        <MobileMenu
                            open={showMobileMenu}
                            handleClose={closeMobileMenu}
                            isLoggedIn={isLoggedIn}
                            openLoginModal={openLoginModal}
                            openProfileEditorModal={() => setShowProfileEditorModal(true)}
                            searchList={searchList}
                            navigateToElement={navigateToElement}
                            searchListRef={ref}
                            handleSearchListElementClick={handleSearchListElementClick}
                            setSearchList={setSearchList}
                            setShowMore={setShowMore}
                            navigateToPageViewMore={navigateToPageViewMore}
                            goToPage={goToPage}
                            setSignupModalOpen={setSignupModalOpen}
                            setSearch={setSearch}
                            search={search}
                            logout={logout}
                        />
                      </>
                    )
                )}
            </>
            <>{(isMobile)
                ? (showMobileNavbar && !isLoggedIn)
                    ? null
                    // ? <LinksContainer>
                    //     <PrimaryButton onClick={() => setSignupModalOpen(true)}>Werde jetzt Mitglied</PrimaryButton>
                    //     <LinkWithImage onClick={openLoginModal}>
                    //         <LinkText className='linkText'>Anmelden</LinkText>
                    //     </LinkWithImage>
                    // </LinksContainer>
                    : null
                : !isLoggedIn
                    ? <LinksContainer>
                        <PrimaryButton onClick={() => setSignupModalOpen(true)}>Werde jetzt Mitglied</PrimaryButton>
                        <LinkWithImage onClick={openLoginModal}>
                            <LinkText className='linkText'>Einloggen</LinkText>
                        </LinkWithImage>
                    </LinksContainer>
                    : null
            }
            </>
        </Div>
    )
}

export default Navbar;
