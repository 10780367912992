import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import Hero from './hero';
import ListOfVideos from './list-of-videos';
import VideoAPI from "../../api/video";
import FeedAPI from "../../api/feed";
import {AuthContext} from '../../context/authContext';

const DFPVideoPlayLanding = () => {

    const {user} = useContext(AuthContext);
    const {type, typeId, dfpCourseId, id} = useParams();
    const [video, setVideo] = React.useState()
    const [videoLoading, setVideoLoading] = React.useState(false)
    const [listOfVideos, setListOfVideos] = React.useState([])
    const [browserURL, setBrowserURL] = React.useState('')
    const [nextURL, setNextURL] = React.useState('')
    const [nextTitle, setNextTitle] = React.useState('')
    const nextState = {additionalInformation: 'Updated the URL with JS'}
    const [channelInfo, setChannelInfo] = React.useState({});

    React.useEffect(() => {
        if (type === 'channels') {

            // This is used for courses where enrollment has already started
            if (typeId == '92634') {
                FeedAPI.getDFPVideoFeed().then((response: any) => {
                    if (response[1] && response[1].length > 0) {
                        let enrolledVideoFeed = response[1].find((item: any) => {
                            return item?.type === 'dfp_course' && item?.headline === 'dfp_course_list_enrolled';
                        });

                        if (enrolledVideoFeed) {
                            VideoAPI.getAllDfpVideos(enrolledVideoFeed?.filter).then((response: any) => {
                                setListOfVideos(response[1])
                            });
                        }
                    }
                });
            } else {

                VideoAPI.getChannelById(typeId).then((response: any) => {
                    setChannelInfo(response[1]);
                });

                let filter = `?join=channels&filter=channels.channelId||eq||${typeId}&filter=channels.dfpCourseId||ne||${dfpCourseId}`;

                if (user) {
                    VideoAPI.getAllDfpVideos(filter).then((response: any) => {
                        setListOfVideos(response[1])
                    });
                }
            }

            VideoAPI.getDFPVideoById(dfpCourseId).then((response: any) => {
                setVideo(response[1])
                setVideoLoading(true)
                setBrowserURL(window.location.href.split('channels')[0] + 'channels/' + typeId + '/');
                setNextTitle(response[1].id + '/' + response[1].video.id + '/' + response[1].title);
                setNextURL(window.location.href.split('channels')[0] + 'channels/' + typeId + '/' + response[1].id + '/' + response[1].video.id + '/' + response[1].title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll('&nbsp;', ''));
            });
        }

        if (type === 'featured') {
            FeedAPI.getDFPVideoFeed().then((response: any) => {
                const videoFeed = response[1];

                if (videoFeed.length > 0) {
                    let featuredFeed = videoFeed.find((item: any) => {
                        return item?.type === 'dfp_course' && item?.headline === 'dfp_course_list_featured';
                    });

                    if (featuredFeed) {
                        VideoAPI.getAllDfpVideos(featuredFeed?.filter + '&s=' + JSON.stringify(JSON.parse(featuredFeed?.meta?.query)) + '').then((response: any) => {
                            setListOfVideos(response[1]);
                        });
                    }
                }
            });

            VideoAPI.getDFPVideoById(dfpCourseId).then((response: any) => {
                setVideo(response[1]);
                setVideoLoading(true);
                setBrowserURL(window.location.href.split('featured')[0] + 'featured/' + typeId + '/');
                setNextTitle(response[1].id + '/' + response[1].video.id + '/' + response[1].title);
                setNextURL(window.location.href.split('featured')[0] + 'featured/' + typeId + '/' + response[1].id + '/' + response[1].video.id + '/' + response[1].title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll('&nbsp;', ''));
            });
        }

        // TODO solve toContinue video later
        // if (type === 'toContinue') {
        //     FeedAPI.getVideoFeed().then((response: any) => {
        //         VideoAPI.getAllVideos(response[1].filter((feed: any) => feed.headline === 'video_list_to_continue_watching')[0].filterMore).then((response: any) => {
        //             // setListOfVideos(response[1])
        //         });
        //     });
        //     VideoAPI.getVideoById(id).then((response: any) => {
        //         setVideo(response[1])
        //         setVideoLoading(true)
        //         setBrowserURL(window.location.href.split('toContinue')[0] + 'toContinue/' + typeId + '/')
        //         setNextTitle(response[1].id + '/' + response[1].title)
        //         setNextURL(window.location.href.split('toContinue')[0] + 'toContinue/' + typeId + '/' + response[1].id + '/' + response[1].title.replaceAll(' ', '').replaceAll('/', '').replaceAll(':', '').replaceAll(/\n/g, ''))
        //     });
        // }
    }, [type, typeId, id])

    React.useEffect(() => {
        window.history.pushState(nextState, nextTitle, nextURL);
        window.history.replaceState(nextState, nextTitle, nextURL);
    }, [nextTitle])

    // @ts-ignore
    const title = channelInfo ? channelInfo?.name : type === 'toContinue' ? 'Weiter Ansehen' : type === 'featured' ? 'Featured' : type === 'search' ? 'Suche nach' : listOfVideos?.name
    // @ts-ignore
    const description = channelInfo ? channelInfo?.description : type === 'featured' ? null : type === 'search' ? '"' + typeId + '"' : listOfVideos?.description
    // @ts-ignore
    const videoList = type === 'channels' || 'search' || type === 'featured' || type === 'toContinue' ? listOfVideos : listOfVideos?.videos?.map((video) => video.video)

    return (
        <>
            {videoLoading &&
            <Hero
                // @ts-ignore
                video={video}
                // @ts-ignore
                setVideo={setVideo}
            />
            }
            {(listOfVideos.length !== 0) &&
            <ListOfVideos
                videoPlayId={video}
                listOfVideos={videoList}
                description={description}
                title={title}
                browserURL={browserURL}
                setVideo={setVideo}
                setNextURL={setNextURL}
                setNextTitle={setNextTitle}
                type={type}
            />
            }
        </>
    )
}

export default DFPVideoPlayLanding;
