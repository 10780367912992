import React from 'react';
import {Div, Container, Content, Title, Images, Image} from './style';
import HorizontalRule from '../../common/horizontal-rule';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// @ts-ignore
const Support = ({images}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Div>
            <Container>
                <Content>
                    {/* @ts-ignore */}
                    <HorizontalRule lighter={true} style={{marginBottom: !isMobile ? 56 : 47}}/>
                    <Title>Mit freundlicher Unterstützung von</Title>
                    {images?.length !== 0 &&
                    <Images>
                        {images?.map((image: string, index: number) => <Image key={index} src={image}/>)}
                    </Images>
                    }
                </Content>
            </Container>
        </Div>
    )
}

export default Support;