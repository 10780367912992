import React from 'react';
import UberUns from '../components/static-pages/uberuns';
import Footer from '../components/layout/footer';

function UberUnsPage () {
    return (
        <>
            <UberUns />
            <Footer />
        </>
    );
}

export default UberUnsPage;